import React from "react";
import Navbar from "../../CommonCompounds/Nav/Nav.jsx"
import MunicipalitieSrecreation from "../../Compounds/MunicipalitieSrecreation/MunicipalitieSrecreation.jsx";
import Footer from "../../CommonCompounds/Footer/Footer.jsx";


function MunicipalitieSrecreationPage(){
    return(
        <>

       <Navbar />
       <MunicipalitieSrecreation />
       <Footer />
        </>
    )
}
 export default MunicipalitieSrecreationPage;