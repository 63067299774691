import React from "react";



function Impact () {
    return (
<>
<>
  <main className="main" id="main">
    <section className="breadcrumbs">
      <ul>
        {/*li><a href="#">Home</a></li>
          <li class="current">Star Trac</li*/}
        <li>
          <span>
            <span>
              <a href="https://corehandf.com/" data-wpel-link="internal">
                Home
              </a>
            </span>{" "}
            »{" "}
            <span>
              <a
                href="https://corehandf.com/product_line/"
                data-wpel-link="internal"
              >
                Product Lines
              </a>
            </span>{" "}
            »{" "}
            <span>
              <a
                href="https://corehandf.com/product_line/nautilus-brand/"
                data-wpel-link="internal"
              >
                Nautilus
              </a>
            </span>{" "}
            »{" "}
            <span className="breadcrumb_last" aria-current="page">
              Impact
            </span>
          </span>
        </li>{" "}
      </ul>
    </section>
    <section
      className="section product-line-banner-section"
      style={{
        background:
          "url(https://corehandf.com/wp-content/uploads/2023/04/IMPACT_Hero_Shot_WIDE.webp) top center no-repeat",
        backgroundSize: "cover"
      }}
    >
      <div className="product-line-banner">
        <div className="content-wrap">
          <img src="https://corehandf.com/wp-content/uploads/2019/04/Nautilus-Logo-all-white1.png" />
          <h1 className="banner-title largest">Impact</h1>
        </div>
      </div>
    </section>
    <section className="section product-line-intro-section container">
      <h2>PROVEN. SMART. EFFECTIVE.</h2>
      <p>
        It is the evolution of the proven biomechanics and user features that
        have made the Impact Strength line a solid, competitive product. This
        line retains all the unique, proven, individual design features that
        customers have stated are ideal, such as pre-stretch, start assist,
        range of motion selection and graduated heavy weight stacks. Impact
        Strength incorporates new aesthetics and biomechanics features that have
        established its position as a competitive and diverse strength product
        line.
      </p>
    </section>
    <section className="section full-width-column-section container image-left">
      <div className="row">
        <div className="col-md-5">
          <div className="img-video-wrap">
            <div style={{ padding: "100% 0 0 0", position: "relative" }}>
              <iframe
                src="https://player.vimeo.com/video/791206580?h=02b66042f1&badge=0&autopause=0&player_id=0&app_id=58479"
                frameBorder={0}
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen=""
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%"
                }}
                title="Lock N Load"
              />
            </div>
          </div>
        </div>
        <div className="col-md-7">
          <div className="content-wrap">
            <h2>LOCK N LOAD WEIGHT SELECTION</h2>
            <p>
              Selecting a weight with the intuitive Lock N Load design is as
              easy as flicking a switch. Universally color coded switches engage
              and disengage the weight via an internal pin, providing a safer,
              more durable and maintenance free operation. Lock N Load prevents
              unnecessary maintenance headaches since there are no pins to lose
              or replace.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className="section fifty-fifty-column-section container">
      <div className="row gx-5">
        <div className="col-md-6">
          <div className="content-wrap">
            <img src="https://corehandf.com/wp-content/uploads/2023/04/Core_Nautilus_Impact_Deltoid_Fly_Kyle_45_Full_15.webp" />
            <h2>THE ESSENTIAL STRENGTH LINE</h2>
            <p>
              This 23 piece line covers all of the essentials needed make up the
              foundation of any facility. Suited for all fitness levels, users
              can isolate and focus on each body part, making their workout
              customizable and efficient. Proven biomechanics and a rock solid
              construction ensure the durability that club owners expect.
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="content-wrap">
            <img src="https://corehandf.com/wp-content/uploads/2023/04/Nautilus-Impact-Abductor-9NA-S1307-60-2-Product-Image.webp" />
            <h2>KNOWN FOR ITS ICONIC, TRADITIONAL DESIGNS</h2>
            <p>
              Nautilus Impact has progressed in time but never forgot it’s
              heritage with Flex Fitness. This versatile strength line of Impact
              kept many of the traditional design elements that users come to
              expect and appreciate today: pre-stretch adjustments, gravity
              assisted seat adjustments, range of motion selections, graduated
              heavy stacks, and single station specialized machines without
              sacrificing on superior bio mechanics and movement patterns that
              made Flex Fitness famous.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className="series-prods-section">
      <div className="container">
        <h2>Browse Impact Products</h2>
        <div className="row">
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/nautilus-impact-abdominal/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/nautilus-impact-abdominal/"
                data-wpel-link="internal"
              >
                <img
                  width={1024}
                  height={695}
                  src="https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Impact-Abdominal-9NA-S6301-4-Product-Image-Web-1024x695.png"
                  className="attachment-large size-large wp-post-image"
                  alt="Nautilus Impact Abdominal 9NA-S6301 4 Product Image Web"
                  decoding="async"
                  fetchpriority="high"
                  srcSet="https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Impact-Abdominal-9NA-S6301-4-Product-Image-Web-1024x695.png 1024w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Impact-Abdominal-9NA-S6301-4-Product-Image-Web-768x521.png 768w"
                  sizes="(max-width: 1024px) 100vw, 1024px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/nautilus-impact-abdominal/"
                  data-wpel-link="internal"
                >
                  Impact® Abdominal
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/nautilus-impact-kneeling-leg-curl/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/nautilus-impact-kneeling-leg-curl/"
                data-wpel-link="internal"
              >
                <img
                  width={1024}
                  height={695}
                  src="https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Impact-Kneeling-Leg-Curl-9NA-S1311-2-Product-Image-Web-1024x695.png"
                  className="attachment-large size-large wp-post-image"
                  alt="Nautilus Impact Kneeling Leg Curl 9NA-S1311 2 Product Image Web"
                  decoding="async"
                  srcSet="https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Impact-Kneeling-Leg-Curl-9NA-S1311-2-Product-Image-Web-1024x695.png 1024w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Impact-Kneeling-Leg-Curl-9NA-S1311-2-Product-Image-Web-768x521.png 768w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Impact-Kneeling-Leg-Curl-9NA-S1311-2-Product-Image-Web-600x407.png 600w"
                  sizes="(max-width: 1024px) 100vw, 1024px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/nautilus-impact-kneeling-leg-curl/"
                  data-wpel-link="internal"
                >
                  Impact® Kneeling Leg Curl
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/nautilus-impact-biceps-curl/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/nautilus-impact-biceps-curl/"
                data-wpel-link="internal"
              >
                <img
                  width={1024}
                  height={695}
                  src="https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Impact-Biceps-Curl-9NA-S5301-2-Product-Image-Web-1024x695.png"
                  className="attachment-large size-large wp-post-image"
                  alt="Nautilus Impact Biceps Curl 9NA-S5301 2 Product Image Web"
                  decoding="async"
                  srcSet="https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Impact-Biceps-Curl-9NA-S5301-2-Product-Image-Web-1024x695.png 1024w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Impact-Biceps-Curl-9NA-S5301-2-Product-Image-Web-768x521.png 768w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Impact-Biceps-Curl-9NA-S5301-2-Product-Image-Web-600x407.png 600w"
                  sizes="(max-width: 1024px) 100vw, 1024px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/nautilus-impact-biceps-curl/"
                  data-wpel-link="internal"
                >
                  Impact® Biceps Curl
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="/product-category/brand/nautilus/impact-line/"
              data-wpel-link="internal"
            >
              <div className="card">
                <div className="card-body">
                  <h3>
                    View All
                    <br />
                    Impact
                  </h3>
                </div>
              </div>
            </a>
          </div>
        </div>
        {/*/ .row */}
      </div>
      {/*/ .container */}
    </section>
    <section className="frequently-purchased-with-prods-section">
      <div className="container">
        <h2>Frequently Purchased With</h2>
        <div className="row">
          <div className="col-md-3">
            <a
              href="https://corehandf.com/product/nautilus-inspiration-glute-press/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/nautilus-inspiration-glute-press/"
                data-wpel-link="internal"
              >
                <img
                  width={1024}
                  height={1024}
                  src="https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Inspiration-Glute-Press-IPGM5-60-web-1024x1024.png"
                  className="attachment-large size-large wp-post-image"
                  alt=""
                  decoding="async"
                  loading="lazy"
                  srcSet="https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Inspiration-Glute-Press-IPGM5-60-web-1024x1024.png 1024w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Inspiration-Glute-Press-IPGM5-60-web-300x300.png 300w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Inspiration-Glute-Press-IPGM5-60-web-150x150.png 150w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Inspiration-Glute-Press-IPGM5-60-web-768x768.png 768w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Inspiration-Glute-Press-IPGM5-60-web-1536x1536.png 1536w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Inspiration-Glute-Press-IPGM5-60-web-2048x2048.png 2048w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Inspiration-Glute-Press-IPGM5-60-web-70x70.png 70w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Inspiration-Glute-Press-IPGM5-60-web-600x600.png 600w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Inspiration-Glute-Press-IPGM5-60-web-100x100.png 100w"
                  sizes="(max-width: 1024px) 100vw, 1024px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/nautilus-inspiration-glute-press/"
                  data-wpel-link="internal"
                >
                  Inspiration® Glute Press
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/throwdown-4-x-14-standard-xtc-rig/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/throwdown-4-x-14-standard-xtc-rig/"
                data-wpel-link="internal"
              >
                <img
                  width={1024}
                  height={683}
                  src="https://corehandf.com/wp-content/uploads/2020/04/Throwdown-XTC-Rig-4x14-9-XTC-414C-60-Matte-Black-Product-Image-1024x683.webp"
                  className="attachment-large size-large wp-post-image"
                  alt=""
                  decoding="async"
                  loading="lazy"
                  srcSet="https://corehandf.com/wp-content/uploads/2020/04/Throwdown-XTC-Rig-4x14-9-XTC-414C-60-Matte-Black-Product-Image-1024x683.webp 1024w, https://corehandf.com/wp-content/uploads/2020/04/Throwdown-XTC-Rig-4x14-9-XTC-414C-60-Matte-Black-Product-Image-300x200.webp 300w, https://corehandf.com/wp-content/uploads/2020/04/Throwdown-XTC-Rig-4x14-9-XTC-414C-60-Matte-Black-Product-Image-768x512.webp 768w, https://corehandf.com/wp-content/uploads/2020/04/Throwdown-XTC-Rig-4x14-9-XTC-414C-60-Matte-Black-Product-Image-1536x1024.webp 1536w, https://corehandf.com/wp-content/uploads/2020/04/Throwdown-XTC-Rig-4x14-9-XTC-414C-60-Matte-Black-Product-Image-2048x1365.webp 2048w, https://corehandf.com/wp-content/uploads/2020/04/Throwdown-XTC-Rig-4x14-9-XTC-414C-60-Matte-Black-Product-Image-600x400.webp 600w"
                  sizes="(max-width: 1024px) 100vw, 1024px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/throwdown-4-x-14-standard-xtc-rig/"
                  data-wpel-link="internal"
                >
                  4 x 14 Standard XTC Rig
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/stairmaster-8gx/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/stairmaster-8gx/"
                data-wpel-link="internal"
              >
                <img
                  width={1024}
                  height={1024}
                  src="https://corehandf.com/wp-content/uploads/2022/07/Stairmaster-8-Gx-Hero-Shot-Transparent-1024x1024.webp"
                  className="attachment-large size-large wp-post-image"
                  alt=""
                  decoding="async"
                  loading="lazy"
                  srcSet="https://corehandf.com/wp-content/uploads/2022/07/Stairmaster-8-Gx-Hero-Shot-Transparent-1024x1024.webp 1024w, https://corehandf.com/wp-content/uploads/2022/07/Stairmaster-8-Gx-Hero-Shot-Transparent-300x300.webp 300w, https://corehandf.com/wp-content/uploads/2022/07/Stairmaster-8-Gx-Hero-Shot-Transparent-150x150.webp 150w, https://corehandf.com/wp-content/uploads/2022/07/Stairmaster-8-Gx-Hero-Shot-Transparent-768x768.webp 768w, https://corehandf.com/wp-content/uploads/2022/07/Stairmaster-8-Gx-Hero-Shot-Transparent-1536x1536.webp 1536w, https://corehandf.com/wp-content/uploads/2022/07/Stairmaster-8-Gx-Hero-Shot-Transparent-2048x2048.webp 2048w, https://corehandf.com/wp-content/uploads/2022/07/Stairmaster-8-Gx-Hero-Shot-Transparent-70x70.webp 70w, https://corehandf.com/wp-content/uploads/2022/07/Stairmaster-8-Gx-Hero-Shot-Transparent-600x600.webp 600w, https://corehandf.com/wp-content/uploads/2022/07/Stairmaster-8-Gx-Hero-Shot-Transparent-100x100.webp 100w"
                  sizes="(max-width: 1024px) 100vw, 1024px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/stairmaster-8gx/"
                  data-wpel-link="internal"
                >
                  8Gx
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/10trx-freerunner-treadmill/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/10trx-freerunner-treadmill/"
                data-wpel-link="internal"
              >
                <img
                  width={1024}
                  height={1024}
                  src="https://corehandf.com/wp-content/uploads/2018/12/Startrac-10-TRx-Product-Image-scaled-e1706729713642-1024x1024.webp"
                  className="attachment-large size-large wp-post-image"
                  alt=""
                  decoding="async"
                  loading="lazy"
                  srcSet="https://corehandf.com/wp-content/uploads/2018/12/Startrac-10-TRx-Product-Image-scaled-e1706729713642-1024x1024.webp 1024w, https://corehandf.com/wp-content/uploads/2018/12/Startrac-10-TRx-Product-Image-scaled-e1706729713642-300x300.webp 300w, https://corehandf.com/wp-content/uploads/2018/12/Startrac-10-TRx-Product-Image-scaled-e1706729713642-150x150.webp 150w, https://corehandf.com/wp-content/uploads/2018/12/Startrac-10-TRx-Product-Image-scaled-e1706729713642-768x768.webp 768w, https://corehandf.com/wp-content/uploads/2018/12/Startrac-10-TRx-Product-Image-scaled-e1706729713642-70x70.webp 70w, https://corehandf.com/wp-content/uploads/2018/12/Startrac-10-TRx-Product-Image-scaled-e1706729713642-600x600.webp 600w, https://corehandf.com/wp-content/uploads/2018/12/Startrac-10-TRx-Product-Image-scaled-e1706729713642-100x100.webp 100w, https://corehandf.com/wp-content/uploads/2018/12/Startrac-10-TRx-Product-Image-scaled-e1706729713642.webp 1536w"
                  sizes="(max-width: 1024px) 100vw, 1024px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/10trx-freerunner-treadmill/"
                  data-wpel-link="internal"
                >
                  10TRx FreeRunner™ Treadmill
                </a>
              </div>
            </div>
          </div>
        </div>
        {/*/ .row */}
      </div>
      {/*/ .container */}
    </section>
    <section id="request-a-quote-form" className="section request-a-quote-form quote-form">
      <div className="container">
        <h2>
          <span>Start Your Consultation Today</span>
        </h2>
        <div className="row center-xs">
          <div className="col-md-6 offset-md-6">
            {/*[if lte IE 8]>

<![endif]*/}
          </div>
        </div>
      </div>
    </section>
    <a className="sticky-cta" href="#request-a-quote-form">
      <img src="/wp-content/uploads/2022/04/Icon_map-gym.png" />
      Start Consultation
    </a>{" "}
  </main>
  {/* /.main */}
</>

</>
    )
}

export default Impact;