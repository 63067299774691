import React from "react";
import Navbar from "../../CommonCompounds/Nav/Nav.jsx"
import Footer from "../../CommonCompounds/Footer/Footer.jsx";
import Fourseries from "../../Compounds/4Series/4Series.jsx";


function FourseriesPage(){
    return(
        <>

       <Navbar />
       <Fourseries />
       <Footer />
        </>
    )
}
 export default FourseriesPage;