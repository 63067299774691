import React from "react";
import Form from "../../CommonCompounds/Form/Form";

import images from "../../images/img";

function Nautilus() {
  return (
    <>
      <main className="main" id="main">
        <section className="breadcrumbs">
          <ul>
            {/*li><a href="#">Home</a></li>
          <li class="current">Star Trac</li*/}
            <li>
              <span>
                <span>
                  <a href="" data-wpel-link="internal">
                    Home
                  </a>
                </span>{" "}
                »{" "}
                <span className="breadcrumb_last" aria-current="page">
                  NAUTILUS®
                </span>
              </span>
            </li>
          </ul>
        </section>
        {/*  Conditional for Industry page style layout */}
        <section id="build" className="section banner">
          <div className="row brand-banner">
            <div className="col-xs-12 brand-banner-left">
              <img
                src="https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Logo-all-white.png"
                alt
              />
              <div
                className="bg"
                style={{
                  background:
                    "url() center center no-repeat",
                }}
              />
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="cnt">
              <h1 className="banner-title">TRUSTED STRENGTH</h1>
              <p>
                With the invention of strength training machines, Nautilus
                brought fitness to the masses. Focusing on biomechanics that
                complement natural human movements, Nautilus makes weightlifting
                approachable to people at all fitness levels. The authentic
                approach to building muscle that started 50 years ago is still
                true today with essential equipment recognized in strength
                training programs worldwide.
              </p>
            </div>
          </div>
        </section>
        <section id="featured" className="section cards">
          <div className="container wide">
            <div className="row card-row">
              <div className="col-xs-3">
                <div className="card">
                  <a
                    href="/product-category/brand/nautilus/inspiration-line/"
                    data-wpel-link="internal"
                  >
                    <img src="https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Inspiration-Abdominal-Crunch-IPAC3-60-Black-33-Product-Image-Web-e1654116295345.png" />
                    <div className="card-info">
                      <p className="name">INSPIRATION® LINE</p>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-xs-3">
                <div className="card">
                  <a
                    href="/product-category/brand/nautilus/impact-line/"
                    data-wpel-link="internal"
                  >
                    <img src="https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Impact-Leg-Curl-9NA-S1301-2-Product-Image-Web-e1655755616876.png" />
                    <div className="card-info">
                      <p className="name">IMPACT® LINE</p>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-xs-3">
                <div className="card">
                  <a
                    href="/product-category/brand/nautilus/instinct-line/"
                    data-wpel-link="internal"
                  >
                    <img src="https://corehandf.com/wp-content/uploads/2017/02/Instinct_Dual_Biceps_Curl_Triceps_Extension_-60AR.png" />
                    <div className="card-info">
                      <p className="name">INSTINCT® LINE</p>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-xs-3">
                <div className="card">
                  <a
                    href="/product-category/brand/nautilus/humansport-line/"
                    data-wpel-link="internal"
                  >
                    <img src="https://corehandf.com/wp-content/uploads/2017/02/HumanSport-FreedomTrainer-HSFT3-60AAE-Product-Image-Web.png" />
                    <div className="card-info">
                      <p className="name">HUMANSPORT® LINE</p>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-xs-3">
                <div className="card">
                  <a
                    href="/product-category/brand/nautilus/leverage-line/"
                    data-wpel-link="internal"
                  >
                    <img src="https://corehandf.com/wp-content/uploads/2023/10/Nautilus-Leverage-Bicep-Curl-60-9NP-L5002-Web-Image.png" />
                    <div className="card-info">
                      <p className="name">LEVERAGE® LINE</p>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-xs-3">
                <div className="card">
                  <a
                    href="/product-category/brand/nautilus/plate-loaded-line/"
                    data-wpel-link="internal"
                  >
                    <img src="https://corehandf.com/wp-content/uploads/2018/06/Leverage_Glute_Drive-60.png" />
                    <div className="card-info">
                      <p className="name">PLATE LOADED LINE</p>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-xs-3">
                <div className="card">
                  <a
                    href="/product-category/brand/nautilus/benches/"
                    data-wpel-link="internal"
                  >
                    <img src="https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Benches-Racks-Multi-Adjust-Bench-9NN-B7501-60.png" />
                    <div className="card-info">
                      <p className="name">Benches</p>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-xs-3">
                <div className="card">
                  <a
                    href="/product-category/brand/nautilus/platforms-racks-line/"
                    data-wpel-link="internal"
                  >
                    <img src="https://corehandf.com/wp-content/uploads/2020/09/Half-Rack-with-4ft-Platform-Bamboo-II-9-HDHR2-9-HDP4-BB2-6-e1678998417953.png" />
                    <div className="card-info">
                      <p className="name">Platforms &amp; Racks Line</p>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-xs-3">
                <div className="card">
                  <a
                    href="/product_line/nautilus-brand/multi-stations/"
                    data-wpel-link="internal"
                  >
                    <img src="https://corehandf.com/wp-content/uploads/2020/04/20-00223-4-Station-Multi-Station-Image-web-1.jpg" />
                    <div className="card-info">
                      <p className="name">Multi-Stations</p>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-xs-3">
                <div className="card">
                  <a
                    href="/product-category/brand/nautilus/one-line/"
                    data-wpel-link="internal"
                  >
                    <img src="https://corehandf.com/wp-content/uploads/2017/02/ONE_ShoulderPress_-60.png" />
                    <div className="card-info">
                      <p className="name">ONE™ LINE</p>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-xs-3">
                <div className="card view-all">
                  <a
                    href="/product-category/brand/nautilus/"
                    data-wpel-link="internal"
                  >
                    <img src />
                    <div className="card-info">
                      <p className="name">
                        View All Nautilus{" "}
                        <i className="fas fa-arrow-right fa-2x" />
                      </p>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-xs-3">
                <div className="card">
                  <a href data-wpel-link="internal">
                    <img src />
                    <div className="card-info">
                      <p className="name" />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
         <section>
         <div className="container video-slider-wrap">
    <section id="blocks-1" className="video-slider">
      <div id="block-1" className="container block">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <figure className="block-image pad-the-video">
              <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
                <iframe
                  src="https://player.vimeo.com/video/723424844?title=0&byline=0&portrait=0"
                  title="Introducing the StairMaster 8Gx"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%"
                  }}
                  frameBorder={0}
                  allow="autoplay; fullscreen"
                  allowFullScreen=""
                />
              </div>
            </figure>
          </div>
          <div className="col-xs-12 col-md-6 content">
            <header className="block-header">
              <h3>THE PRODUCT MEMBERS ASK FOR BY NAME</h3>
            </header>
            <p>
              Introducing the all-new StairMaster 8Gx! Its combination of
              quality and innovation delivers a workout that’s quite literally a
              step up compared to everything else in the gym.
            </p>
          </div>
        </div>
      </div>
      <div id="block-2" className="container block">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <figure className="block-image pad-the-video">
              <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
                <iframe
                  src="https://player.vimeo.com/video/207224065?title=0&byline=0&portrait=0"
                  title="STAIRMASTER HIGH INTENSITY SOLUTIONS"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%"
                  }}
                  frameBorder={0}
                  allow="autoplay; fullscreen"
                  allowFullScreen=""
                />
              </div>
            </figure>
          </div>
          <div className="col-xs-12 col-md-6 content">
            <header className="block-header">
              <h3>HIGH INTENSITY SOLUTIONS</h3>
            </header>
            <p>
              From the makers of the toughest workouts in the gym, comes a
              fierce High Intensity Interval Training program that will leave
              your members hurting for more.
            </p>
          </div>
        </div>
      </div>
      <div id="block-3" className="container block">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <figure className="block-image pad-the-video">
              <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
                <iframe
                  src="https://player.vimeo.com/video/790486708?h=2afba2a3d7&badge=0&autopause=0&player_id=0&app_id=58479"
                  frameBorder={0}
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen=""
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%"
                  }}
                  title="StairMaster Jacobs Ladder JL Serious Cardio Video"
                />
              </div>
            </figure>
          </div>
          <div className="col-xs-12 col-md-6 content">
            <header className="block-header">
              <h3>SERIOUS CARDIO</h3>
            </header>
            <p>
              With a design that features high intensity but a low impact,
              Jacobs Ladder is serious cardio. Tests have shown that Jacobs
              Ladder burns more calories at a higher pace when compared to a
              treadmill.
            </p>
          </div>
        </div>
      </div>
    </section>
 
  </div>
         </section>
        <section className="section brand-page-bottom">
          <div className="container">
            <div className="row center-xs">
              <div className="col-xs-4 offset-xs-3">
                <p>FOLLOW NAUTILUS</p>
                <ul className="list flex-center flex-middle">
                  <li>
                    <a
                      href="https://www.facebook.com/NautilusStrength"
                      target="_blank"
                      data-wpel-link="external"
                      rel="nofollow external noopener noreferrer"
                    >
                      <i className="fa fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/NautilusEquip"
                      target="_blank"
                      data-wpel-link="external"
                      rel="nofollow external noopener noreferrer"
                    >
                      <i className="fa fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/nautilusstrength/"
                      target="_blank"
                      data-wpel-link="external"
                      rel="nofollow external noopener noreferrer"
                    >
                      <i className="fa fa-instagram" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="request-a-quote-form">
          <div className="container">
            <h2 id="request-a-quote-form" className="section-title">
              <span>Start Your Consultation Today</span>
            </h2>
            <div className="row center-xs">
              <div className="col-md-6 offset-md-6">
                <Form />
              </div>
            </div>
          </div>
        </section>
        <a className="sticky-cta" href="#request-a-quote-form">
          <img src={images.mapgym} />
          Start Consultation
        </a>
      </main>
    </>
  );
}

export default Nautilus;
