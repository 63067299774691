import React  from "react";


function MultiStations (){
    return(
        <>

<>
  <main className="main" id="main">
    <section className="breadcrumbs">
      <ul>
        {/*li><a href="#">Home</a></li>
          <li class="current">Star Trac</li*/}
        <li>
          <span>
            <span>
              <a href="https://corehandf.com/" data-wpel-link="internal">
                Home
              </a>
            </span>{" "}
            »{" "}
            <span>
              <a
                href="https://corehandf.com/product_line/"
                data-wpel-link="internal"
              >
                Product Lines
              </a>
            </span>{" "}
            »{" "}
            <span>
              <a
                href="https://corehandf.com/product_line/nautilus-brand/"
                data-wpel-link="internal"
              >
                Nautilus
              </a>
            </span>{" "}
            »{" "}
            <span className="breadcrumb_last" aria-current="page">
              Multi-Stations
            </span>
          </span>
        </li>{" "}
      </ul>
    </section>
    <section
      className="section product-line-banner-section"
      style={{
        background:
          "url(https://corehandf.com/wp-content/uploads/2023/04/Image-Lifestyle-MultiStations-Hero-Shot.webp) top center no-repeat",
        backgroundSize: "cover"
      }}
    >
      <div className="product-line-banner">
        <div className="content-wrap">
          <img src="https://corehandf.com/wp-content/uploads/2019/04/Nautilus-Logo-all-white1.png" />
          <h1 className="banner-title largest">Multi-Stations</h1>
        </div>
      </div>
    </section>
    <section className="section product-line-intro-section container">
      <h2>HUGE VARIETY. SMALL FOOTPRINT.</h2>
      <p>
        Each of our multi-stations have the smallest possible footprint, while
        combining the most essential elements from our strength lines. Add in
        great features like swiveling pulleys on the Lat Pull Down, Low Row and
        Triceps Press and fully adjustable pulleys on the Cable Crossover
        System, and you have an economical, space-saving equipment solution that
        doesn’t look, feel or train like one.
      </p>
    </section>
    <section className="section full-width-column-section container image-left">
      <div className="row">
        <div className="col-md-5">
          <div className="img-video-wrap">
            <div style={{ padding: "100% 0 0 0", position: "relative" }}>
              <iframe
                src="https://player.vimeo.com/video/791206580?h=02b66042f1&badge=0&autopause=0&player_id=0&app_id=58479"
                frameBorder={0}
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen=""
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%"
                }}
                title="Lock N Load"
              />
            </div>
          </div>
        </div>
        <div className="col-md-7">
          <div className="content-wrap">
            <h2>LOCK N LOAD WEIGHT SELECTION</h2>
            <p>
              Selecting a weight with the intuitive Lock N Load design is as
              easy as flicking a switch. Universally color-coded switches engage
              and disengage the weight via an internal pin, providing a safer,
              more durable, and maintenance-free operation. Lock N Load prevents
              unnecessary maintenance headaches since there are no pins to lose
              or replace.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className="series-prods-section">
      <div className="container">
        <h2>Browse Multi-Stations Products</h2>
        <div className="row">
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/nautilus-multi-stations-14-station-exercise-tower/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/nautilus-multi-stations-14-station-exercise-tower/"
                data-wpel-link="internal"
              >
                <img
                  width={1024}
                  height={1024}
                  src="https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Multistation-14-Station-6-Transparent-Product-Image-Web-1024x1024.jpg"
                  className="attachment-large size-large wp-post-image"
                  alt=""
                  decoding="async"
                  fetchpriority="high"
                  srcSet="https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Multistation-14-Station-6-Transparent-Product-Image-Web-1024x1024.jpg 1024w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Multistation-14-Station-6-Transparent-Product-Image-Web-300x300.jpg 300w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Multistation-14-Station-6-Transparent-Product-Image-Web-150x150.jpg 150w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Multistation-14-Station-6-Transparent-Product-Image-Web-768x768.jpg 768w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Multistation-14-Station-6-Transparent-Product-Image-Web-70x70.jpg 70w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Multistation-14-Station-6-Transparent-Product-Image-Web-600x600.jpg 600w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Multistation-14-Station-6-Transparent-Product-Image-Web-100x100.jpg 100w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Multistation-14-Station-6-Transparent-Product-Image-Web.jpg 1500w"
                  sizes="(max-width: 1024px) 100vw, 1024px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/nautilus-multi-stations-14-station-exercise-tower/"
                  data-wpel-link="internal"
                >
                  14 Station
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/nautilus-multi-stations-4-station/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/nautilus-multi-stations-4-station/"
                data-wpel-link="internal"
              >
                <img
                  width={1024}
                  height={1024}
                  src="https://corehandf.com/wp-content/uploads/2020/04/Nautilus-Multistation-4-Station-1-Transparent-Product-Image-Web.png-Web-1024x1024.jpg"
                  className="attachment-large size-large wp-post-image"
                  alt=""
                  decoding="async"
                  srcSet="https://corehandf.com/wp-content/uploads/2020/04/Nautilus-Multistation-4-Station-1-Transparent-Product-Image-Web.png-Web-1024x1024.jpg 1024w, https://corehandf.com/wp-content/uploads/2020/04/Nautilus-Multistation-4-Station-1-Transparent-Product-Image-Web.png-Web-300x300.jpg 300w, https://corehandf.com/wp-content/uploads/2020/04/Nautilus-Multistation-4-Station-1-Transparent-Product-Image-Web.png-Web-150x150.jpg 150w, https://corehandf.com/wp-content/uploads/2020/04/Nautilus-Multistation-4-Station-1-Transparent-Product-Image-Web.png-Web-768x768.jpg 768w, https://corehandf.com/wp-content/uploads/2020/04/Nautilus-Multistation-4-Station-1-Transparent-Product-Image-Web.png-Web-70x70.jpg 70w, https://corehandf.com/wp-content/uploads/2020/04/Nautilus-Multistation-4-Station-1-Transparent-Product-Image-Web.png-Web-600x600.jpg 600w, https://corehandf.com/wp-content/uploads/2020/04/Nautilus-Multistation-4-Station-1-Transparent-Product-Image-Web.png-Web-100x100.jpg 100w, https://corehandf.com/wp-content/uploads/2020/04/Nautilus-Multistation-4-Station-1-Transparent-Product-Image-Web.png-Web.jpg 1500w"
                  sizes="(max-width: 1024px) 100vw, 1024px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/nautilus-multi-stations-4-station/"
                  data-wpel-link="internal"
                >
                  4 Station
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/nautilus-multi-stations-5-station-exercise-tower/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/nautilus-multi-stations-5-station-exercise-tower/"
                data-wpel-link="internal"
              >
                <img
                  width={1024}
                  height={1024}
                  src="https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Multistation-5-Station-5-Transparent-Product-Image-Web-1024x1024.jpg"
                  className="attachment-large size-large wp-post-image"
                  alt=""
                  decoding="async"
                  srcSet="https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Multistation-5-Station-5-Transparent-Product-Image-Web-1024x1024.jpg 1024w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Multistation-5-Station-5-Transparent-Product-Image-Web-300x300.jpg 300w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Multistation-5-Station-5-Transparent-Product-Image-Web-150x150.jpg 150w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Multistation-5-Station-5-Transparent-Product-Image-Web-768x768.jpg 768w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Multistation-5-Station-5-Transparent-Product-Image-Web-70x70.jpg 70w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Multistation-5-Station-5-Transparent-Product-Image-Web-600x600.jpg 600w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Multistation-5-Station-5-Transparent-Product-Image-Web-100x100.jpg 100w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Multistation-5-Station-5-Transparent-Product-Image-Web.jpg 1500w"
                  sizes="(max-width: 1024px) 100vw, 1024px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/nautilus-multi-stations-5-station-exercise-tower/"
                  data-wpel-link="internal"
                >
                  5 Station
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/?s=&post_type=product&product_brand=160"
              data-wpel-link="internal"
            >
              <div className="card">
                <div className="card-body">
                  <h3>
                    View All
                    <br />
                    Multi-Stations
                  </h3>
                </div>
              </div>
            </a>
          </div>
        </div>
        {/*/ .row */}
      </div>
      {/*/ .container */}
    </section>
    <section className="frequently-purchased-with-prods-section">
      <div className="container">
        <h2>Frequently Purchased With</h2>
        <div className="row">
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/stairmaster-8gx/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/stairmaster-8gx/"
                data-wpel-link="internal"
              >
                <img
                  width={1024}
                  height={1024}
                  src="https://corehandf.com/wp-content/uploads/2022/07/Stairmaster-8-Gx-Hero-Shot-Transparent-1024x1024.webp"
                  className="attachment-large size-large wp-post-image"
                  alt=""
                  decoding="async"
                  loading="lazy"
                  srcSet="https://corehandf.com/wp-content/uploads/2022/07/Stairmaster-8-Gx-Hero-Shot-Transparent-1024x1024.webp 1024w, https://corehandf.com/wp-content/uploads/2022/07/Stairmaster-8-Gx-Hero-Shot-Transparent-300x300.webp 300w, https://corehandf.com/wp-content/uploads/2022/07/Stairmaster-8-Gx-Hero-Shot-Transparent-150x150.webp 150w, https://corehandf.com/wp-content/uploads/2022/07/Stairmaster-8-Gx-Hero-Shot-Transparent-768x768.webp 768w, https://corehandf.com/wp-content/uploads/2022/07/Stairmaster-8-Gx-Hero-Shot-Transparent-1536x1536.webp 1536w, https://corehandf.com/wp-content/uploads/2022/07/Stairmaster-8-Gx-Hero-Shot-Transparent-2048x2048.webp 2048w, https://corehandf.com/wp-content/uploads/2022/07/Stairmaster-8-Gx-Hero-Shot-Transparent-70x70.webp 70w, https://corehandf.com/wp-content/uploads/2022/07/Stairmaster-8-Gx-Hero-Shot-Transparent-600x600.webp 600w, https://corehandf.com/wp-content/uploads/2022/07/Stairmaster-8-Gx-Hero-Shot-Transparent-100x100.webp 100w"
                  sizes="(max-width: 1024px) 100vw, 1024px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/stairmaster-8gx/"
                  data-wpel-link="internal"
                >
                  8Gx
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/startrac-8trx-treadmill/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/startrac-8trx-treadmill/"
                data-wpel-link="internal"
              >
                <img
                  width={1024}
                  height={1000}
                  src="https://corehandf.com/wp-content/uploads/2020/08/Star-Trac-8-TRx-Hero-Shot-Transparent-1024x1000.png"
                  className="attachment-large size-large wp-post-image"
                  alt=""
                  decoding="async"
                  loading="lazy"
                  srcSet="https://corehandf.com/wp-content/uploads/2020/08/Star-Trac-8-TRx-Hero-Shot-Transparent-1024x1000.png 1024w, https://corehandf.com/wp-content/uploads/2020/08/Star-Trac-8-TRx-Hero-Shot-Transparent-300x293.png 300w, https://corehandf.com/wp-content/uploads/2020/08/Star-Trac-8-TRx-Hero-Shot-Transparent-768x750.png 768w, https://corehandf.com/wp-content/uploads/2020/08/Star-Trac-8-TRx-Hero-Shot-Transparent-1536x1500.png 1536w, https://corehandf.com/wp-content/uploads/2020/08/Star-Trac-8-TRx-Hero-Shot-Transparent-600x586.png 600w, https://corehandf.com/wp-content/uploads/2020/08/Star-Trac-8-TRx-Hero-Shot-Transparent.png 1949w"
                  sizes="(max-width: 1024px) 100vw, 1024px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/startrac-8trx-treadmill/"
                  data-wpel-link="internal"
                >
                  8TRx Treadmill
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/startrac-subx-upright-bike/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/startrac-subx-upright-bike/"
                data-wpel-link="internal"
              >
                <img
                  width={1024}
                  height={1024}
                  src="https://corehandf.com/wp-content/uploads/2017/02/Star-Trac-S-Series-SUBX-Upright-Bike-1024x1024.png"
                  className="attachment-large size-large wp-post-image"
                  alt=""
                  decoding="async"
                  loading="lazy"
                  srcSet="https://corehandf.com/wp-content/uploads/2017/02/Star-Trac-S-Series-SUBX-Upright-Bike-1024x1024.png 1024w, https://corehandf.com/wp-content/uploads/2017/02/Star-Trac-S-Series-SUBX-Upright-Bike-300x300.png 300w, https://corehandf.com/wp-content/uploads/2017/02/Star-Trac-S-Series-SUBX-Upright-Bike-150x150.png 150w, https://corehandf.com/wp-content/uploads/2017/02/Star-Trac-S-Series-SUBX-Upright-Bike-768x768.png 768w, https://corehandf.com/wp-content/uploads/2017/02/Star-Trac-S-Series-SUBX-Upright-Bike-600x600.png 600w, https://corehandf.com/wp-content/uploads/2017/02/Star-Trac-S-Series-SUBX-Upright-Bike-100x100.png 100w, https://corehandf.com/wp-content/uploads/2017/02/Star-Trac-S-Series-SUBX-Upright-Bike.png 1500w"
                  sizes="(max-width: 1024px) 100vw, 1024px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/startrac-subx-upright-bike/"
                  data-wpel-link="internal"
                >
                  SUBx Upright Bike
                </a>
              </div>
            </div>
          </div>
        </div>
        {/*/ .row */}
      </div>
      {/*/ .container */}
    </section>
    <section id="request-a-quote-form" className="section request-a-quote-form quote-form">
      <div className="container">
        <h2>
          <span>Start Your Consultation Form</span>
        </h2>
        <div className="row center-xs">
          <div className="col-md-6 offset-md-6">
            {/*[if lte IE 8]>

<![endif]*/}
          </div>
        </div>
      </div>
    </section>
    <a className="sticky-cta" href="#request-a-quote-form">
      <img src="/wp-content/uploads/2022/04/Icon_map-gym.png" />
      Start Consultation
    </a>{" "}
  </main>
  {/* /.main */}
</>

        </>
    )
}

export default MultiStations ;