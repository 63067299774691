import React  from "react";
import Form from "../../CommonCompounds/Form/Form";


function Leverage (){
    return(
        <>
<>
  <main className="main" id="main">
    <section className="breadcrumbs">
      <ul>
        {/*li><a href="#">Home</a></li>
          <li class="current">Star Trac</li*/}
        <li>
          <span>
            <span>
              <a href="https://corehandf.com/" data-wpel-link="internal">
                Home
              </a>
            </span>{" "}
            »{" "}
            <span>
              <a
                href="https://corehandf.com/product_line/"
                data-wpel-link="internal"
              >
                Product Lines
              </a>
            </span>{" "}
            »{" "}
            <span>
              <a
                href="https://corehandf.com/product_line/nautilus-brand/"
                data-wpel-link="internal"
              >
                Nautilus
              </a>
            </span>{" "}
            »{" "}
            <span className="breadcrumb_last" aria-current="page">
              Leverage
            </span>
          </span>
        </li>
      </ul>
    </section>
    <section
      className="section product-line-banner-section"
      style={{
        background:
          "url(https://corehandf.com/wp-content/uploads/2023/04/Nautilus_Leverage_HeroShot.webp) top center no-repeat",
        backgroundSize: "cover"
      }}
    >
      <div className="product-line-banner">
        <div className="content-wrap">
          <img src="https://corehandf.com/wp-content/uploads/2018/11/Nautilus-Logo-all-white1-1.png" />
          <h1 className="banner-title largest">Leverage</h1>
        </div>
      </div>
    </section>
    <section className="section product-line-intro-section container">
      <h2>A ONE-OF-A-KIND PLATE LOADED EXPERIENCE</h2>
      <p>
        The Nautilus Leverage® line offers a one-of-a-kind plate-loaded
        experience with features that outshine the competition. Leverage
        features stainless steel knurled grips, assisted seat adjustments,and
        user pre-stretch settings. Using multiple levers and cams, Leverage
        creates a unique lifting profile for each movement that maximizes the
        mechanics of the human body. With a sleek look and integrated storage,
        the Leverage strength line will appeal to facility operators and their
        members. It’s all about the feel, and once you’ve exercised on Leverage,
        pushing and pulling weight plates will never be the same again.
      </p>
    </section>
    <section className="section full-width-column-section container image-left">
      <div className="row">
        <div className="col-md-5">
          <div className="img-video-wrap">
            <img src="https://corehandf.com/wp-content/uploads/2023/04/Nautilus-Leverage-Decline-Press-60-9NP-L2004-Product-Image.webp" />
          </div>
        </div>
        <div className="col-md-7">
          <div className="content-wrap">
            <h2>A PERFECT PARTNERSHIP</h2>
            <p>
              Throwdown Free Weights pair perfectly with Nautilus Strength
              products, allowing you to get everything in one place. You can
              even find free weight sets crafted specifically for popular
              Nautilus Benches &amp; Racks, Leverage and Plate-Loaded products.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className="section full-width-column-section container image-left">
      <div className="row">
        <div className="col-md-5">
          <div className="img-video-wrap">
            <div style={{ padding: "100% 0 0 0", position: "relative" }}>
              <iframe
                src="https://player.vimeo.com/video/791213822?h=cc81cc0191&badge=0&autopause=0&player_id=0&app_id=58479"
                frameBorder={0}
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen=""
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%"
                }}
                title="Nautilus Leverage Weight Load Animation"
              />
            </div>
          </div>
        </div>
        <div className="col-md-7">
          <div className="content-wrap">
            <h2>SAFE AND SIMPLE</h2>
            <p>Low load points on each machine for safe and simple use.</p>
          </div>
        </div>
      </div>
    </section>
    <section className="series-prods-section">
      <div className="container">
        <h2>Browse Leverage Products</h2>
        <div className="row">
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/nautilus-leverage-chest-press/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/nautilus-leverage-chest-press/"
                data-wpel-link="internal"
              >
                <img
                  width={500}
                  height={500}
                  src="https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Leverage-Chest-Press-60-9NP-L2002-Web-Image.png"
                  className="attachment-large size-large wp-post-image"
                  alt="Nautilus Leverage Chest Press -60 9NP-L2002"
                  decoding="async"
                  fetchpriority="high"
                  srcSet="https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Leverage-Chest-Press-60-9NP-L2002-Web-Image.png 500w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Leverage-Chest-Press-60-9NP-L2002-Web-Image-300x300.png 300w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Leverage-Chest-Press-60-9NP-L2002-Web-Image-100x100.png 100w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Leverage-Chest-Press-60-9NP-L2002-Web-Image-150x150.png 150w"
                  sizes="(max-width: 500px) 100vw, 500px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/nautilus-leverage-chest-press/"
                  data-wpel-link="internal"
                >
                  Leverage® Chest Press
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/nautilus-leverage-abdominal-crunch/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/nautilus-leverage-abdominal-crunch/"
                data-wpel-link="internal"
              >
                <img
                  width={500}
                  height={500}
                  src="https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Leverage-Abdominal-Crunch-60-9NP-L5003.png-Web-Image.png"
                  className="attachment-large size-large wp-post-image"
                  alt="Nautilus Leverage Abdominal Crunch -60 9NP-L5003"
                  decoding="async"
                  srcSet="https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Leverage-Abdominal-Crunch-60-9NP-L5003.png-Web-Image.png 500w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Leverage-Abdominal-Crunch-60-9NP-L5003.png-Web-Image-300x300.png 300w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Leverage-Abdominal-Crunch-60-9NP-L5003.png-Web-Image-100x100.png 100w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Leverage-Abdominal-Crunch-60-9NP-L5003.png-Web-Image-150x150.png 150w"
                  sizes="(max-width: 500px) 100vw, 500px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/nautilus-leverage-abdominal-crunch/"
                  data-wpel-link="internal"
                >
                  Leverage &amp; Abdominal Crunch
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/nautilus-leverage-deadlift-shrug/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/nautilus-leverage-deadlift-shrug/"
                data-wpel-link="internal"
              >
                <img
                  width={500}
                  height={500}
                  src="https://corehandf.com/wp-content/uploads/2020/09/Nautilus-Leverage-Deadlift-Shrug-60-9NP-L3006.png-Web-Image.png"
                  className="attachment-large size-large wp-post-image"
                  alt="Nautilus Leverage Deadlift Shrug -60 9NP-L3006"
                  decoding="async"
                  srcSet="https://corehandf.com/wp-content/uploads/2020/09/Nautilus-Leverage-Deadlift-Shrug-60-9NP-L3006.png-Web-Image.png 500w, https://corehandf.com/wp-content/uploads/2020/09/Nautilus-Leverage-Deadlift-Shrug-60-9NP-L3006.png-Web-Image-300x300.png 300w, https://corehandf.com/wp-content/uploads/2020/09/Nautilus-Leverage-Deadlift-Shrug-60-9NP-L3006.png-Web-Image-100x100.png 100w, https://corehandf.com/wp-content/uploads/2020/09/Nautilus-Leverage-Deadlift-Shrug-60-9NP-L3006.png-Web-Image-150x150.png 150w"
                  sizes="(max-width: 500px) 100vw, 500px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/nautilus-leverage-deadlift-shrug/"
                  data-wpel-link="internal"
                >
                  Leverage® Deadlift Shrug
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="/product-category/brand/nautilus/leverage-line/"
              data-wpel-link="internal"
            >
              <div className="card">
                <div className="card-body">
                  <h3>
                    View All
                    <br />
                    Leverage
                  </h3>
                </div>
              </div>
            </a>
          </div>
        </div>
        {/*/ .row */}
      </div>
      {/*/ .container */}
    </section>
    <section className="frequently-purchased-with-prods-section">
      <div className="container">
        <h2>Frequently Purchased With</h2>
        <div className="row">
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/nautilus-benches-racks-half-rack/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/nautilus-benches-racks-half-rack/"
                data-wpel-link="internal"
              >
                <img
                  width={500}
                  height={500}
                  src="https://corehandf.com/wp-content/uploads/2017/02/Benches__Racks_Half_Rack-60.png"
                  className="attachment-large size-large wp-post-image"
                  alt="Nautilus - Benches & Racks - Half Rack - 60"
                  decoding="async"
                  loading="lazy"
                  srcSet="https://corehandf.com/wp-content/uploads/2017/02/Benches__Racks_Half_Rack-60.png 500w, https://corehandf.com/wp-content/uploads/2017/02/Benches__Racks_Half_Rack-60-300x300.png 300w, https://corehandf.com/wp-content/uploads/2017/02/Benches__Racks_Half_Rack-60-100x100.png 100w, https://corehandf.com/wp-content/uploads/2017/02/Benches__Racks_Half_Rack-60-150x150.png 150w"
                  sizes="(max-width: 500px) 100vw, 500px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/nautilus-benches-racks-half-rack/"
                  data-wpel-link="internal"
                >
                  Half Rack
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/nautilus-plate-loaded-angled-leg-press/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/nautilus-plate-loaded-angled-leg-press/"
                data-wpel-link="internal"
              >
                <img
                  width={500}
                  height={500}
                  src="https://corehandf.com/wp-content/uploads/2017/02/Leverage_Angled_Leg_Press-60.png"
                  className="attachment-large size-large wp-post-image"
                  alt="Nautilus - Leverage - Angled Leg Press - 60"
                  decoding="async"
                  loading="lazy"
                  srcSet="https://corehandf.com/wp-content/uploads/2017/02/Leverage_Angled_Leg_Press-60.png 500w, https://corehandf.com/wp-content/uploads/2017/02/Leverage_Angled_Leg_Press-60-300x300.png 300w, https://corehandf.com/wp-content/uploads/2017/02/Leverage_Angled_Leg_Press-60-100x100.png 100w, https://corehandf.com/wp-content/uploads/2017/02/Leverage_Angled_Leg_Press-60-150x150.png 150w"
                  sizes="(max-width: 500px) 100vw, 500px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/nautilus-plate-loaded-angled-leg-press/"
                  data-wpel-link="internal"
                >
                  Angled Leg Press
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/nautilus-plate-loaded-freedom-rack/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/nautilus-plate-loaded-freedom-rack/"
                data-wpel-link="internal"
              >
                <img
                  width={500}
                  height={500}
                  src="https://corehandf.com/wp-content/uploads/2017/02/Leverage_Freedom_Rack-60.png"
                  className="attachment-large size-large wp-post-image"
                  alt="Nautilus - Leverage - Freedom Rack - 60"
                  decoding="async"
                  loading="lazy"
                  srcSet="https://corehandf.com/wp-content/uploads/2017/02/Leverage_Freedom_Rack-60.png 500w, https://corehandf.com/wp-content/uploads/2017/02/Leverage_Freedom_Rack-60-300x300.png 300w, https://corehandf.com/wp-content/uploads/2017/02/Leverage_Freedom_Rack-60-100x100.png 100w, https://corehandf.com/wp-content/uploads/2017/02/Leverage_Freedom_Rack-60-150x150.png 150w"
                  sizes="(max-width: 500px) 100vw, 500px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/nautilus-plate-loaded-freedom-rack/"
                  data-wpel-link="internal"
                >
                  Freedom Rack<sup>®</sup>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/*/ .row */}
      </div>
      {/*/ .container */}
    </section>
    <section id="request-a-quote-form" className="section request-a-quote-form quote-form">
      <div className="container">
        <h2>
          <span>Start Your Consultation Today</span>
        </h2>
        <div className="row center-xs">
          <div className="col-md-6 offset-md-6">
          <Form />
          </div>
        </div>
      </div>
    </section>
    <a className="sticky-cta" href="#request-a-quote-form">
      <img src="/wp-content/uploads/2022/04/Icon_map-gym.png" />
      Start Consultation
    </a>{" "}
  </main>
  {/* /.main */}
</>

        
        </>
    )
}

export default Leverage ;