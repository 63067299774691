import React  from "react";
import Form from "../../CommonCompounds/Form/Form";
import images from "../../images/img";

function Stairmaster (){
    return (
        <>
        <main className="main" id="main">
  <section className="breadcrumbs">
    <ul>
      {/*li><a href="#">Home</a></li>
          <li class="current">Star Trac</li*/}
      <li>
        <span>
          <span>
            <a href="" data-wpel-link="internal">
              Home
            </a>
          </span>{" "}
          »{" "}
          <span className="breadcrumb_last" aria-current="page">
            StairMaster®
          </span>
        </span>
      </li>
    </ul>
  </section>
  {/*  Conditional for Industry page style layout */}
  <section id="build" className="section banner">
    <div className="row brand-banner">
      <div className="col-xs-12 brand-banner-left">
        <img
          src="https://corehandf.com/wp-content/uploads/2016/12/Stairmaster_White.png"
          alt=""
        />
        <div
          className="bg"
          style={{
            background:
              "url(https://corehandf.com/wp-content/uploads/2019/12/StairMaster_930x700.jpg) center center no-repeat"
          }}
        ></div>
      </div>
    </div>
  </section>
  <section>
    <div className="container">
      <div className="cnt">
        <h1 className="banner-title">OWN THE SUMMIT</h1>
        <p>
          For more than four decades, StairMaster has been bringing innovation
          to the human experience in an unmatched workout that delivers results.
          Through climbing people have connected with StairMaster and Jacobs
          Ladder to raise their heart rates and burn calories faster with a
          line-up of products known to be “easier to work harder on.”
          StairMaster is the workout that everyone loves to hate.
        </p>
      </div>
    </div>
  </section>
  <section id="featured" className="section cards">
    <div className="container wide">
      <div className="row card-row">
        <div className="col-xs-3">
          <div className="card">
            <a
              href="/product-category/brand/stairmaster/stepmills-steppers-line/"
              data-wpel-link="internal"
            >
              <img src="https://corehandf.com/wp-content/uploads/2019/09/StairMaster-10G-10in-OpenHub-0008.png" />
              <div className="card-info">
                <p className="name">Steppers/Climbers</p>
              </div>
            </a>
          </div>
        </div>
        <div className="col-xs-3">
          <div className="card">
            <a
              href="/product-category/brand/stairmaster/hiitmill-line/"
              data-wpel-link="internal"
            >
              <img src="https://corehandf.com/wp-content/uploads/2017/02/HIIT-Bike-web.png" />
              <div className="card-info">
                <p className="name">HIIT Line</p>
              </div>
            </a>
          </div>
        </div>
        <div className="col-xs-3">
          <div className="card">
            <a
              href="/product-category/brand/stairmaster/boxmaster-line/"
              data-wpel-link="internal"
            >
              <img src="https://corehandf.com/wp-content/uploads/2017/02/StairMaster_BoxMaster_Quad_web.png" />
              <div className="card-info">
                <p className="name">BoxMaster Pro</p>
              </div>
            </a>
          </div>
        </div>
        <div className="col-xs-3">
          <div className="card view-all">
            <a
              href="/product-category/brand/stairmaster"
              data-wpel-link="internal"
            >
              <img src="" />
              <div className="card-info">
                <p className="name">
                  View All Stairmaster{" "}
                  <i className="fas fa-arrow-right fa-2x" />
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div className="container video-slider-wrap">
    <section id="blocks-1" className="video-slider">
      <div id="block-1" className="container block">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <figure className="block-image pad-the-video">
              <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
                <iframe
                  src="https://player.vimeo.com/video/723424844?title=0&byline=0&portrait=0"
                  title="Introducing the StairMaster 8Gx"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%"
                  }}
                  frameBorder={0}
                  allow="autoplay; fullscreen"
                  allowFullScreen=""
                />
              </div>
            </figure>
          </div>
          <div className="col-xs-12 col-md-6 content">
            <header className="block-header">
              <h3>THE PRODUCT MEMBERS ASK FOR BY NAME</h3>
            </header>
            <p>
              Introducing the all-new StairMaster 8Gx! Its combination of
              quality and innovation delivers a workout that’s quite literally a
              step up compared to everything else in the gym.
            </p>
          </div>
        </div>
      </div>
      <div id="block-2" className="container block">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <figure className="block-image pad-the-video">
              <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
                <iframe
                  src="https://player.vimeo.com/video/207224065?title=0&byline=0&portrait=0"
                  title="STAIRMASTER HIGH INTENSITY SOLUTIONS"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%"
                  }}
                  frameBorder={0}
                  allow="autoplay; fullscreen"
                  allowFullScreen=""
                />
              </div>
            </figure>
          </div>
          <div className="col-xs-12 col-md-6 content">
            <header className="block-header">
              <h3>HIGH INTENSITY SOLUTIONS</h3>
            </header>
            <p>
              From the makers of the toughest workouts in the gym, comes a
              fierce High Intensity Interval Training program that will leave
              your members hurting for more.
            </p>
          </div>
        </div>
      </div>
      <div id="block-3" className="container block">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <figure className="block-image pad-the-video">
              <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
                <iframe
                  src="https://player.vimeo.com/video/790486708?h=2afba2a3d7&badge=0&autopause=0&player_id=0&app_id=58479"
                  frameBorder={0}
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen=""
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%"
                  }}
                  title="StairMaster Jacobs Ladder JL Serious Cardio Video"
                />
              </div>
            </figure>
          </div>
          <div className="col-xs-12 col-md-6 content">
            <header className="block-header">
              <h3>SERIOUS CARDIO</h3>
            </header>
            <p>
              With a design that features high intensity but a low impact,
              Jacobs Ladder is serious cardio. Tests have shown that Jacobs
              Ladder burns more calories at a higher pace when compared to a
              treadmill.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section id="blocks-2" className="video-slider-nav">
      <div id="block-1" className="container block">
       
        <header className="block-header">
          <p>THE PRODUCT MEMBERS ASK FOR BY NAME</p>
        </header>
      </div>
      <div id="block-2" className="container block">
       
        <header className="block-header">
          <p>HIGH INTENSITY SOLUTIONS</p>
        </header>
      </div>
      <div id="block-3" className="container block">
        
        <header className="block-header">
          <p>SERIOUS CARDIO</p>
        </header>
      </div>
    </section>
  </div>
  <section className="section view-other-brands">
    <div className="container">
      <header className="block-header">
        <h3>View Other Brands</h3>
      </header>
      <ul className="brand-logos">
        <li className="">
          <a href="/product-brand/throwdown" data-wpel-link="internal">
            <img src="https://corehandf.com/wp-content/uploads/2021/10/logoasociate_throwoown-3.png" />
          </a>
        </li>
        <li className="">
          <a href="/product-brand/nautilus" data-wpel-link="internal">
            <img src="https://corehandf.com/wp-content/uploads/2021/10/logoasociate_Nautilus-3.png" />
          </a>
        </li>
        <li className="">
          <a href="/product-brand/schwinn" data-wpel-link="internal">
            <img src="https://corehandf.com/wp-content/uploads/2021/10/Schwinn-Logo-Black-1-1024x287-3.webp" />
          </a>
        </li>
        <li className="">
          <a href="/product-brand/star-trac" data-wpel-link="internal">
            <img src="https://corehandf.com/wp-content/uploads/2021/10/logoasociate_startrac-3.png" />
          </a>
        </li>
      </ul>
    </div>
  </section>
  <section className="section brand-page-bottom">
    <div className="container">
      <div className="row center-xs">
        <div className="col-xs-4 offset-xs-3">
          <p>FOLLOW STAIRMASTER</p>
          <ul className="list flex-center flex-middle">
            <li>
              <a
                href="https://www.facebook.com/StairMaster.Official"
                target="_blank"
                data-wpel-link="external"
                rel="nofollow external noopener noreferrer"
              >
                <i className="fa fa-facebook" />
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/StairMasterSays"
                target="_blank"
                data-wpel-link="external"
                rel="nofollow external noopener noreferrer"
              >
                <i className="fa fa-twitter" />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/stairmasterog/"
                target="_blank"
                data-wpel-link="external"
                rel="nofollow external noopener noreferrer"
              >
                <i className="fa fa-instagram" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <section className="request-a-quote-form">
    <div className="container">
      <h2 id="request-a-quote-form" className="section-title">
        <span>Start Your Consultation Today</span>
      </h2>
      <div className="row center-xs">
        <div className="col-md-6 offset-md-6">
       <Form />
        </div>
      </div>
    </div>
  </section>
  <a className="sticky-cta" href="#request-a-quote-form">
  <img src={images.mapgym} />
    Start Consultation
  </a>
  <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.css"
    integrity="sha512-wR4oNhLBHf7smjy0K4oqzdWumd+r5/+6QO/vDda76MW5iug4PT7v86FoEkySIJft3XA0Ae6axhIvHrqwm793Nw=="
    crossOrigin="anonymous"
    referrerPolicy="no-referrer"
  />
  <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.min.css"
    integrity="sha512-17EgCFERpgZKcm0j0fEq1YCJuyAWdz9KUtv1EjVuaOz8pDnh/0nZxmU6BBXwaaxqoi9PQXnRWqlcDB027hgv9A=="
    crossOrigin="anonymous"
    referrerPolicy="no-referrer"
  />
  {/*  Conditional for Industry page style layout */}
</main>
        </>
    )
}

export default Stairmaster;