import React from "react";

function FreeWeights(){
    return(
        <>
        <>
  <main className="main" id="main">
    <section className="breadcrumbs">
      <ul>
        {/*li><a href="#">Home</a></li>
          <li class="current">Star Trac</li*/}
        <li>
          <span>
            <span>
              <a href="https://corehandf.com/" data-wpel-link="internal">
                Home
              </a>
            </span>{" "}
            »{" "}
            <span>
              <a
                href="https://corehandf.com/product_line/"
                data-wpel-link="internal"
              >
                Product Lines
              </a>
            </span>{" "}
            »{" "}
            <span>
              <a
                href="https://corehandf.com/product_line/throwdown-3/"
                data-wpel-link="internal"
              >
                Throwdown
              </a>
            </span>{" "}
            »{" "}
            <span className="breadcrumb_last" aria-current="page">
              Free Weights
            </span>
          </span>
        </li>{" "}
      </ul>
    </section>
    <section
      className="section product-line-banner-section"
      style={{
        background:
          "url(https://corehandf.com/wp-content/uploads/2023/05/Free-Wights-Header.webp) top center no-repeat",
        backgroundSize: "cover"
      }}
    >
      <div className="product-line-banner">
        <div className="content-wrap">
          <img src="https://corehandf.com/wp-content/uploads/2021/10/throwdown-all-white-logo-crop-300x48-1.png" />
          <h1 className="banner-title largest">Free Weights</h1>
        </div>
      </div>
    </section>
    <section className="section product-line-intro-section container">
      <p>
        Throwdown® creates opportunities to push boundaries, enabling everyone
        to reach their peak performance by creating flexible and challenging
        exercise environments. Our diverse offerings make it easy for trainers
        and members to mix it up. Throwdown offers a wide variety of products to
        ensure you have everything you need to fully outfit your free weight
        area. Every product meets our high-quality standard and is made with
        premium hard-wearing materials
      </p>
    </section>
    <section className="section full-width-column-section container image-left">
      <div className="row">
        <div className="col-md-5">
          <div className="img-video-wrap">
            <img src="https://corehandf.com/wp-content/uploads/2023/05/Image-Throwdown-Custom-Dumbbell.webp" />
          </div>
        </div>
        <div className="col-md-7">
          <div className="content-wrap">
            <h2>PRODUCT CUSTOMIZATION</h2>
            <p>
              We know what it takes to build an authentic, meaningful brand
              experience. Our customization program is the perfect way to
              leverage your personal brand and make a strong first impression.
              Get Throwdown Free Weights in a variety of custom color options
              and add your logo.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className="section full-width-column-section container image-left">
      <div className="row">
        <div className="col-md-5">
          <div className="img-video-wrap">
            <img src="https://corehandf.com/wp-content/uploads/2023/05/Throwdown-Weights-GripDiscs_Hero.webp" />
          </div>
        </div>
        <div className="col-md-7">
          <div className="content-wrap">
            <h2>PERFECT PARTNERSHIP</h2>
            <p>
              Throwdown Free Weights pair perfectly with Nautilus Strength
              products allowing you to get everything in one place. You can even
              find free weight sets crafted specifically for popular Nautilus
              Benches &amp; Racks, Leverage and Plate Loaded products
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className="series-prods-section">
      <div className="container">
        <h2>Browse Free Weights Products</h2>
        <div className="row">
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/urethane-dumbbells/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/urethane-dumbbells/"
                data-wpel-link="internal"
              >
                <img
                  width={500}
                  height={281}
                  src="https://corehandf.com/wp-content/uploads/2019/12/Throwdown-Weights-Dumbells_Hero-Web.png"
                  className="attachment-large size-large wp-post-image"
                  alt=""
                  decoding="async"
                  fetchpriority="high"
                  srcSet="https://corehandf.com/wp-content/uploads/2019/12/Throwdown-Weights-Dumbells_Hero-Web.png 500w, https://corehandf.com/wp-content/uploads/2019/12/Throwdown-Weights-Dumbells_Hero-Web-300x169.png 300w"
                  sizes="(max-width: 500px) 100vw, 500px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/urethane-dumbbells/"
                  data-wpel-link="internal"
                >
                  Urethane Dumbbells
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/throwdown-light-neo-dumbbells/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/throwdown-light-neo-dumbbells/"
                data-wpel-link="internal"
              >
                <img
                  width={500}
                  height={500}
                  src="https://corehandf.com/wp-content/uploads/2020/09/Image-Throwdown-Light-Neo-Dumbbells-TD-NFB-XX-web.png"
                  className="attachment-large size-large wp-post-image"
                  alt="Image - Throwdown - Light Neo Dumbbells"
                  decoding="async"
                  srcSet="https://corehandf.com/wp-content/uploads/2020/09/Image-Throwdown-Light-Neo-Dumbbells-TD-NFB-XX-web.png 500w, https://corehandf.com/wp-content/uploads/2020/09/Image-Throwdown-Light-Neo-Dumbbells-TD-NFB-XX-web-300x300.png 300w, https://corehandf.com/wp-content/uploads/2020/09/Image-Throwdown-Light-Neo-Dumbbells-TD-NFB-XX-web-100x100.png 100w, https://corehandf.com/wp-content/uploads/2020/09/Image-Throwdown-Light-Neo-Dumbbells-TD-NFB-XX-web-150x150.png 150w"
                  sizes="(max-width: 500px) 100vw, 500px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/throwdown-light-neo-dumbbells/"
                  data-wpel-link="internal"
                >
                  Light Neo Dumbbells
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <a
              href="https://corehandf.com/product/throwdown-hiit-hex-dumbbells/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/throwdown-hiit-hex-dumbbells/"
                data-wpel-link="internal"
              >
                <img
                  width={500}
                  height={500}
                  src="https://corehandf.com/wp-content/uploads/2020/09/Image-Throwdown-HIIT-Hex-Dumbbells-Imperial-TD-HDB-XX-web.png"
                  className="attachment-large size-large wp-post-image"
                  alt="Image - Throwdown - HIIT Hex Dumbbells - Imperial"
                  decoding="async"
                  srcSet="https://corehandf.com/wp-content/uploads/2020/09/Image-Throwdown-HIIT-Hex-Dumbbells-Imperial-TD-HDB-XX-web.png 500w, https://corehandf.com/wp-content/uploads/2020/09/Image-Throwdown-HIIT-Hex-Dumbbells-Imperial-TD-HDB-XX-web-300x300.png 300w, https://corehandf.com/wp-content/uploads/2020/09/Image-Throwdown-HIIT-Hex-Dumbbells-Imperial-TD-HDB-XX-web-100x100.png 100w, https://corehandf.com/wp-content/uploads/2020/09/Image-Throwdown-HIIT-Hex-Dumbbells-Imperial-TD-HDB-XX-web-150x150.png 150w"
                  sizes="(max-width: 500px) 100vw, 500px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/throwdown-hiit-hex-dumbbells/"
                  data-wpel-link="internal"
                >
                  HIIT Hex Dumbbells
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product-category/brand/nautilus/?product_brand=&s=&product_exercise=&product_types=1360"
              data-wpel-link="internal"
            >
              <div className="card">
                <div className="card-body">
                  <h3>
                    View All
                    <br />
                    Free Weights
                  </h3>
                </div>
              </div>
            </a>
          </div>
        </div>
        {/*/ .row */}
      </div>
      {/*/ .container */}
    </section>
    <section className="frequently-purchased-with-prods-section">
      <div className="container">
        <h2>Frequently Purchased With</h2>
        <div className="row">
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/throwdown-xtr-suspension-storage/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/throwdown-xtr-suspension-storage/"
                data-wpel-link="internal"
              >
                <img
                  width={1024}
                  height={747}
                  src="https://corehandf.com/wp-content/uploads/2020/04/Throwdown-XTR_Storage_Option_A_SoftGoods_WEB-1024x747.jpg"
                  className="attachment-large size-large wp-post-image"
                  alt=""
                  decoding="async"
                  loading="lazy"
                  srcSet="https://corehandf.com/wp-content/uploads/2020/04/Throwdown-XTR_Storage_Option_A_SoftGoods_WEB-1024x747.jpg 1024w, https://corehandf.com/wp-content/uploads/2020/04/Throwdown-XTR_Storage_Option_A_SoftGoods_WEB-300x219.jpg 300w, https://corehandf.com/wp-content/uploads/2020/04/Throwdown-XTR_Storage_Option_A_SoftGoods_WEB-768x560.jpg 768w, https://corehandf.com/wp-content/uploads/2020/04/Throwdown-XTR_Storage_Option_A_SoftGoods_WEB-1536x1120.jpg 1536w, https://corehandf.com/wp-content/uploads/2020/04/Throwdown-XTR_Storage_Option_A_SoftGoods_WEB-600x438.jpg 600w, https://corehandf.com/wp-content/uploads/2020/04/Throwdown-XTR_Storage_Option_A_SoftGoods_WEB.jpg 1920w"
                  sizes="(max-width: 1024px) 100vw, 1024px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/throwdown-xtr-suspension-storage/"
                  data-wpel-link="internal"
                >
                  XTR Suspension Storage
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/alpha-xtc/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/alpha-xtc/"
                data-wpel-link="internal"
              >
                <img
                  width={1024}
                  height={1024}
                  src="https://corehandf.com/wp-content/uploads/2021/09/Rigs-1024x1024.jpg"
                  className="attachment-large size-large wp-post-image"
                  alt=""
                  decoding="async"
                  loading="lazy"
                  srcSet="https://corehandf.com/wp-content/uploads/2021/09/Rigs-1024x1024.jpg 1024w, https://corehandf.com/wp-content/uploads/2021/09/Rigs-300x300.jpg 300w, https://corehandf.com/wp-content/uploads/2021/09/Rigs-150x150.jpg 150w, https://corehandf.com/wp-content/uploads/2021/09/Rigs-768x768.jpg 768w, https://corehandf.com/wp-content/uploads/2021/09/Rigs-70x70.jpg 70w, https://corehandf.com/wp-content/uploads/2021/09/Rigs-600x600.jpg 600w, https://corehandf.com/wp-content/uploads/2021/09/Rigs-100x100.jpg 100w, https://corehandf.com/wp-content/uploads/2021/09/Rigs.jpg 1500w"
                  sizes="(max-width: 1024px) 100vw, 1024px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/alpha-xtc/"
                  data-wpel-link="internal"
                >
                  Alpha XTC Functional Core Station
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/throwdown-abr-9x18-12-bag/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/throwdown-abr-9x18-12-bag/"
                data-wpel-link="internal"
              >
                <img
                  width={500}
                  height={500}
                  src="https://corehandf.com/wp-content/uploads/2020/12/Image-Throwdown-ABR-1812-60-Web.jpg"
                  className="attachment-large size-large wp-post-image"
                  alt="Throwdown - ABR- 1812 - 60"
                  decoding="async"
                  loading="lazy"
                  srcSet="https://corehandf.com/wp-content/uploads/2020/12/Image-Throwdown-ABR-1812-60-Web.jpg 500w, https://corehandf.com/wp-content/uploads/2020/12/Image-Throwdown-ABR-1812-60-Web-300x300.jpg 300w, https://corehandf.com/wp-content/uploads/2020/12/Image-Throwdown-ABR-1812-60-Web-100x100.jpg 100w, https://corehandf.com/wp-content/uploads/2020/12/Image-Throwdown-ABR-1812-60-Web-150x150.jpg 150w"
                  sizes="(max-width: 500px) 100vw, 500px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/throwdown-abr-9x18-12-bag/"
                  data-wpel-link="internal"
                >
                  9 x 18 Alpha Bag Rack 12-Bag
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/urethane-dumbbells/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/urethane-dumbbells/"
                data-wpel-link="internal"
              >
                <img
                  width={500}
                  height={281}
                  src="https://corehandf.com/wp-content/uploads/2019/12/Throwdown-Weights-Dumbells_Hero-Web.png"
                  className="attachment-large size-large wp-post-image"
                  alt=""
                  decoding="async"
                  loading="lazy"
                  srcSet="https://corehandf.com/wp-content/uploads/2019/12/Throwdown-Weights-Dumbells_Hero-Web.png 500w, https://corehandf.com/wp-content/uploads/2019/12/Throwdown-Weights-Dumbells_Hero-Web-300x169.png 300w"
                  sizes="(max-width: 500px) 100vw, 500px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/urethane-dumbbells/"
                  data-wpel-link="internal"
                >
                  Urethane Dumbbells
                </a>
              </div>
            </div>
          </div>
        </div>
        {/*/ .row */}
      </div>
      {/*/ .container */}
    </section>
    <section id="request-a-quote-form" className="section request-a-quote-form quote-form">
      <div className="container">
        <h2>
          <span>Start Your Consultation Today</span>
        </h2>
        <div className="row center-xs">
          <div className="col-md-6 offset-md-6">
            {/*[if lte IE 8]>

<![endif]*/}
          </div>
        </div>
      </div>
    </section>
    <a className="sticky-cta" href="#request-a-quote-form">
      <img src="/wp-content/uploads/2022/04/Icon_map-gym.png" />
      Start Consultation
    </a>{" "}
  </main>
  {/* /.main */}
</>

        </>
    )
}
export default FreeWeights;