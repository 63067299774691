import React  from "react";




function About(){
    return(
        <>
      
  
      <main className="main" id="main">
     <section className="breadcrumbs">
    <ul>
      {/*li><a href="#">Home</a></li>
          <li class="current">Star Trac</li*/}
      <li>
        <span>
          <span>
            <a href="https://corehandf.com/" data-wpel-link="internal">
              Home
            </a>
          </span>{" "}
          »{" "}
          <span className="breadcrumb_last" aria-current="page">
            ABOUT US
          </span>
        </span>
      </li>
    </ul>
  </section>
  <section className="section banner">
    <div
      className="about-usbanner"
      style={{
        background:
          '#2e2e2e url("https://corehandf.com/wp-content/uploads/2020/10/About_Us-Page_Header_cyan-1-scaled.jpg") center center no-repeat !important'
      }}
    >
      <div className="single-banner-content">
        <h1 className="banner-title largest">ABOUT US</h1>
      </div>
    </div>
  </section>
  <section className="section single-content">
    <div className="container">
      <div className="row center-xs">
        <div className="col-xs-2">
          <div className="single-share">
            {/*ul class="list share-list">
							<li>Share</li>
							<li>
								<a href="#" class="facebook">
									<i class="fa fa-facebook"></i>
								</a>
							</li>
							<li>
								<a href="#" class="twitter">
									<i class="fa fa-twitter"></i>
								</a>
							</li>
							<li>
								<a href="#" class="google-plus">
									<i class="fa fa-google-plus"></i>
								</a>
							</li>
							<li>
								<a href="#" class="linkedin">
									<i class="fa fa-linkedin"></i>
								</a>
							</li>
							<li class="seperator"></li>
							<li>
								<a href="#">
									<i class="fa fa-envelope"></i>
								</a>
							</li>
						</ul*/}
          </div>
        </div>
        <div className="contanier">
          <div className="row  center-xs">
          <div className="col-xs-10">
          <p>
            <span style={{ color: "#00aeef" }}>
              <strong>A GLOBAL FITNESS COMPANY</strong>
            </span>
          </p>
          <p>
            Core Health &amp; Fitness is the global partner you’ve been looking
            for. A vertically integrated company, we design, sell, install and
            support high quality commercial fitness equipment and digital
            solutions for our iconic brands; StairMaster, Schwinn, Nautilus,
            Star Trac, Throwdown and Wexer.
          </p>
          <p>
            We understand the importance of a worldwide presence and recognize
            the benefits of well networked partnerships. From space design and
            equipment selection, to introducing technology, flooring and
            recovery space partners; Core has a global network to meet your
            every need from concept to installation and through fitness
            programming and maintenance.
          </p>
          <p>
            Our promise is to maximize customer delight and trust. It is our job
            to go above and beyond to create memorable customer experiences and
            surpass your expectations. We endeavor to create strong
            relationships and lifelong brand advocates by delivering a
            world-class customer experience.
          </p>
          <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
            <iframe
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%"
              }}
              title="Core Health & Fitness Your Solutions Partner"
              src="https://player.vimeo.com/video/664391406?title=0&byline=0&portrait=0"
              frameBorder={0}
              allowFullScreen="allowfullscreen"
            />
          </div>
          <p></p>
          <p>
            <span style={{ color: "#00aeef" }}>
              <strong>VERTICAL INTEGRATION</strong>
            </span>
          </p>
          <p>
            As a vertically integrated company, our manufacturing partner
            facility in Xiamen, Fujian, China produces high-end commercial
            fitness products and is OEM for many consumer fitness brands like
            MYX and Bowflex.
          </p>
          <p>
            With a focus on financial consolidation, quality control and supply
            chain integration, we are continually improving operations with the
            future of the fitness industry at the focal point of our planning.
          </p>
          <p>
            In addition to designing our own products, Core Health &amp; Fitness
            is the OEM provider for many top tier brands in the fitness
            industry. This diverse portfolio has allowed us to expand on
            manufacturing capabilities while also mitigating risk.
          </p>
          <p>
            When faced with the COVID-19 pandemic, Core Health &amp; Fitness was
            able to increase manufacturing partner volume by pivoting with the
            increased production of home fitness equipment. This agility, backed
            by a dedicated and skilled work force, further exemplifies our
            continued dedication to producing the best equipment in the
            industry.
          </p>
          <p>
            Built in 2003, our partner facility features over 1M square feet of
            manufacturing space, research and development laboratories as well
            as executive and administrative offices. A leading-edge
            manufacturing area boasts continuous-flow assembly lines, electronic
            assembly, metal fabrication and weld shops.
          </p>
          <p>
            The ongoing expansion of development and manufacturing partnership
            capabilities emphasizes our commitment to continually raise the
            benchmark for value and quality in fitness and health products and
            services. Our manufacturing facility implements Continuous-Flow
            Manufacturing (CFM) and Quality Improvement Programs (QIP),
            providing continuous product and process improvements.
          </p>
          <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
            <iframe
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%"
              }}
              title="Core Health & Fitness Manufacturing"
              src="https://player.vimeo.com/video/686834762?title=0&byline=0&portrait=0"
              frameBorder={0}
              allowFullScreen="allowfullscreen"
            >
              &lt;span data-mce-type="bookmark" style="display: inline-block;
              width: 0px; overflow: hidden; line-height: 0;"
              class="mce_SELRES_start"&gt;&lt;/span&gt;
            </iframe>
          </div>
          <p></p>
          <p>
            Image Gallery:
            <br />
            <a href="/LA-Reception_MG_5290-HDR.jpg" data-wpel-link="internal">
              <img
                decoding="async"
                className="alignnone size-thumbnail wp-image-41537"
                src="/LA-Reception_MG_5290-HDR-150x150.jpg"
                alt="Manufacturing Facility"
                width={150}
                height={150}
                srcSet="/LA-Reception_MG_5290-HDR-150x150.jpg 150w, /LA-Reception_MG_5290-HDR-300x300.jpg 300w, /LA-Reception_MG_5290-HDR-100x100.jpg 100w"
                sizes="(max-width: 150px) 100vw, 150px"
              />
            </a>{" "}
            <a href="/ConveyorLine_MG_5066-HDR.jpg" data-wpel-link="internal">
              <img
                decoding="async"
                className="alignnone size-thumbnail wp-image-41542"
                src="/ConveyorLine_MG_5066-HDR-150x150.jpg"
                alt="Manufacturing Facility"
                width={150}
                height={150}
                srcSet="/ConveyorLine_MG_5066-HDR-150x150.jpg 150w, /ConveyorLine_MG_5066-HDR-300x300.jpg 300w, /ConveyorLine_MG_5066-HDR-100x100.jpg 100w"
                sizes="(max-width: 150px) 100vw, 150px"
              />
            </a>{" "}
            <a href="/Assembly-3_MG_5255.jpg" data-wpel-link="internal">
              <img
                decoding="async"
                className="alignnone size-thumbnail wp-image-41547"
                src="/Assembly-3_MG_5255-150x150.jpg"
                alt="Manufacturing Facility"
                width={150}
                height={150}
                srcSet="/Assembly-3_MG_5255-150x150.jpg 150w, /Assembly-3_MG_5255-300x300.jpg 300w, /Assembly-3_MG_5255-100x100.jpg 100w"
                sizes="(max-width: 150px) 100vw, 150px"
              />
            </a>
            <a href="/Packaging-2_MG_5237.jpg" data-wpel-link="internal">
              {" "}
              <img
                loading="lazy"
                decoding="async"
                className="alignnone size-thumbnail wp-image-41532"
                src="/Packaging-2_MG_5237-150x150.jpg"
                alt="Manufacturing Facility"
                width={150}
                height={150}
                srcSet="/Packaging-2_MG_5237-150x150.jpg 150w, /Packaging-2_MG_5237-300x300.jpg 300w, /Packaging-2_MG_5237-100x100.jpg 100w"
                sizes="(max-width: 150px) 100vw, 150px"
              />
            </a>{" "}
            <a href="/ZMat_MG_5169-scaled.jpg" data-wpel-link="internal">
              <img
                loading="lazy"
                decoding="async"
                className="alignnone size-thumbnail wp-image-41512"
                src="/ZMat_MG_5169-150x150.jpg"
                alt="Manufacturing Facility"
                width={150}
                height={150}
                srcSet="/ZMat_MG_5169-150x150.jpg 150w, /ZMat_MG_5169-scaled-300x300.jpg 300w, /ZMat_MG_5169-scaled-100x100.jpg 100w"
                sizes="(max-width: 150px) 100vw, 150px"
              />
            </a>{" "}
            <a href="/Warehouse-7_MG_5127.jpg" data-wpel-link="internal">
              <img
                loading="lazy"
                decoding="async"
                className="alignnone size-thumbnail wp-image-41517"
                src="/Warehouse-7_MG_5127-150x150.jpg"
                alt="Manufacturing Facility"
                width={150}
                height={150}
                srcSet="/Warehouse-7_MG_5127-150x150.jpg 150w, /Warehouse-7_MG_5127-300x300.jpg 300w, /Warehouse-7_MG_5127-100x100.jpg 100w"
                sizes="(max-width: 150px) 100vw, 150px"
              />
            </a>{" "}
            <a href="/Warehouse-3_MG_5039-HDR.jpg" data-wpel-link="internal">
              <img
                loading="lazy"
                decoding="async"
                className="alignnone size-thumbnail wp-image-41522"
                src="/Warehouse-3_MG_5039-HDR-150x150.jpg"
                alt="Manufacturing Facility"
                width={150}
                height={150}
                srcSet="/Warehouse-3_MG_5039-HDR-150x150.jpg 150w, /Warehouse-3_MG_5039-HDR-300x300.jpg 300w, /Warehouse-3_MG_5039-HDR-100x100.jpg 100w"
                sizes="(max-width: 150px) 100vw, 150px"
              />
            </a>{" "}
            <a
              href="/ShippingReceiving-2_MG_5192.jpg"
              data-wpel-link="internal"
            >
              <img
                loading="lazy"
                decoding="async"
                className="alignnone size-thumbnail wp-image-41527"
                src="/ShippingReceiving-2_MG_5192-150x150.jpg"
                alt="Manufacturing Facility"
                width={150}
                height={150}
                srcSet="/ShippingReceiving-2_MG_5192-150x150.jpg 150w, /ShippingReceiving-2_MG_5192-300x300.jpg 300w, /ShippingReceiving-2_MG_5192-100x100.jpg 100w"
                sizes="(max-width: 150px) 100vw, 150px"
              />
            </a>
          </p>
          <p>
            <span style={{ color: "#00aeef" }}>
              <strong>ADVANTAGES AND STRENGTHS</strong>
            </span>
          </p>
          <p>
            • ISO 9001 Certified
            <br /> • 1M Square foot Facility, utilizing the latest in metal
            processing technology
            <br /> • Vertically Integrated
            <br /> • Local to Port of Xiamen
            <br /> • Onsite R&amp;D Capabilities
            <br /> • Seasoned, long term management team
            <br /> • Mature Supply Chain
          </p>
          <p>
            Our partner manufacturing facility is located on the South Eastern
            shore in Xiamen, Fujian Province, China.​
          </p>
          <p>
            Xiamen is centrally located with direct access to International
            Shipping Lines providing shorter lead times when shipping to major
            ports worldwide. The Free Trade Ports of Huli and Hai Cang are also
            located in Xiamen.​
          </p>
          <p>
            Additionally, the International Xiamen Gao Qi Airport is within 20
            minutes of our facility; expedited Air Shipping can be arranged if
            needed for international air shipping routes.
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>
              <a
                href="https://corehandf.com/core-about-us"
                rel="noopener noreferrer"
                data-wpel-link="internal"
              >
                ABOUT{" "}
              </a>
              |&nbsp;
              <a
                href="/Pages/about-us/about-us.html"
                rel="noopener noreferrer"
                data-wpel-link="internal"
              >
                CULTURE
              </a>{" "}
              |&nbsp;
              <a href="" rel="noopener noreferrer" data-wpel-link="internal">
                SOCIAL RESPONSIBILITY
              </a>
              &nbsp;|&nbsp;
              <a href="" rel="noopener noreferrer" data-wpel-link="internal">
                BRANDS
              </a>
              &nbsp;|&nbsp;
              <a href="" rel="noopener noreferrer" data-wpel-link="internal">
                PARTNERS
              </a>
            </strong>
            <strong>
              |&nbsp;
              <a href="" rel="noopener noreferrer" data-wpel-link="internal">
                CAREERS
              </a>
            </strong>
          </p>
        </div>
          </div>
        </div>
       
      </div>
    </div>
  </section>
</main>



        </>
    )
}

export default About;