import React from "react";
import Navbar from "../../CommonCompounds/Nav/Nav.jsx"
import Home from "../../Compounds/Home/Home";
import Footer from "../../CommonCompounds/Footer/Footer.jsx";


function home(){
    return(
        <>

       <Navbar />
       <Home />
       <Footer />
        </>
    )
}
 export default home;