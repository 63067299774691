import React from "react";
import ALLCordio from "../ProductCategory/type/main-floor-cardio/AllCardio";



function Fourseries(){
    return(

   <>
  <>
  <main className="main" id="main">
    <section className="breadcrumbs">
      <ul>
        {/*li><a href="#">Home</a></li>
          <li class="current">Star Trac</li*/}
        <li>
          <span>
            <span>
              <a href="https://corehandf.com/" data-wpel-link="internal">
                Home
              </a>
            </span>{" "}
            »{" "}
            <span>
              <a
                href="https://corehandf.com/product_line/"
                data-wpel-link="internal"
              >
                Product Lines
              </a>
            </span>{" "}
            »{" "}
            <span>
              <a
                href="https://corehandf.com/product_line/star-trac-3/"
                data-wpel-link="internal"
              >
                Star Trac®
              </a>
            </span>{" "}
            »{" "}
            <span className="breadcrumb_last" aria-current="page">
              4 Series
            </span>
          </span>
        </li>{" "}
      </ul>
    </section>
    <section
      className="section product-line-banner-section"
      style={{
        background:
          "url(https://corehandf.com/wp-content/uploads/2023/04/Image-Lifestyle-4-Series-Hero-Shot.webp) top center no-repeat",
        backgroundSize: "cover"
      }}
    >
      <div className="product-line-banner">
        <div className="content-wrap">
          <img src="https://corehandf.com/wp-content/uploads/2023/04/logo-starimaster-start-trac-trans-white-1.png" />
          <h1 className="banner-title largest">4 Series</h1>
        </div>
      </div>
    </section>
    <section className="section product-line-intro-section container"></section>
    <section className="section full-width-column-section container image-left">
      <div className="row">
        <div className="col-md-5">
          <div className="img-video-wrap">
            <img src="https://corehandf.com/wp-content/uploads/2023/04/Star-Trac-4-Series-4-TR-Treadmill-Product-Image.webp" />
          </div>
        </div>
        <div className="col-md-7">
          <div className="content-wrap">
            <h2>COMPLETE LINE OF CARDIO WITH AN EFFICIENT FOOTPRINT</h2>
            <p>
              The 4 Series cardio line from Star Trac is the perfect solution
              for the hospitality and multi-housing markets. With a variety of
              premium displays, quick select adjustment keys, and optional
              personal viewing screen,the 4 series provides smaller facilities
              with the look and performance of a full commercial product.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className="section fifty-fifty-column-section container">
      <div className="row gx-5">
        <div className="col-md-6">
          <div className="content-wrap">
            <img src="https://corehandf.com/wp-content/uploads/2023/04/Ecofit_WhiteBG_SM.webp" />
            <h2>MANAGEMENT SOLUTIONS</h2>
            <p>
              ECOFIT® asset management solutions let you see beyond the basics
              of which machines are most used – you’ll get insights that show
              you if you should rotate machine placements to improve maintenance
              scheduling, identify and plan preventative maintenance to ensure
              equipment is available to members and much more.
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="content-wrap">
            <img src="https://corehandf.com/wp-content/uploads/2023/04/Star-Trac-Consoles-OpenHub-15_-Embedded-Console-2019-Product-Image.webp" />
            <h2>CONSOLES FOR EVERY BUDGET</h2>
            <p>
              The 4 Series cardio line of products offer three console options;
              15” Capacitive Touch, 10” Touch or 10”. With so many options
              you’re sure to find the right solution for your space.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className="series-prods-section">
      <div className="container">
        <h2>Browse 4 Series Products</h2>
        <div className="row">
          <div className="col-md-3">
            <a
              href="https://corehandf.com/product/4ct-cross-trainer/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/4ct-cross-trainer/"
                data-wpel-link="internal"
              >
                <img
                  width={500}
                  height={500}
                  src="https://corehandf.com/wp-content/uploads/2018/12/4CT_OpenHub.png"
                  className="attachment-large size-large wp-post-image"
                  alt="4CT_OpenHub"
                  decoding="async"
                  fetchpriority="high"
                  srcSet="https://corehandf.com/wp-content/uploads/2018/12/4CT_OpenHub.png 500w, https://corehandf.com/wp-content/uploads/2018/12/4CT_OpenHub-300x300.png 300w, https://corehandf.com/wp-content/uploads/2018/12/4CT_OpenHub-100x100.png 100w, https://corehandf.com/wp-content/uploads/2018/12/4CT_OpenHub-150x150.png 150w"
                  sizes="(max-width: 500px) 100vw, 500px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/4ct-cross-trainer/"
                  data-wpel-link="internal"
                >
                  4CT Cross Trainer
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <a
              href="https://corehandf.com/product/4tr-treadmill/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/4tr-treadmill/"
                data-wpel-link="internal"
              >
                <img
                  width={500}
                  height={500}
                  src="https://corehandf.com/wp-content/uploads/2018/12/4TR_OpenHub.png"
                  className="attachment-large size-large wp-post-image"
                  alt="4TR_OpenHub"
                  decoding="async"
                  srcSet="https://corehandf.com/wp-content/uploads/2018/12/4TR_OpenHub.png 500w, https://corehandf.com/wp-content/uploads/2018/12/4TR_OpenHub-300x300.png 300w, https://corehandf.com/wp-content/uploads/2018/12/4TR_OpenHub-100x100.png 100w, https://corehandf.com/wp-content/uploads/2018/12/4TR_OpenHub-150x150.png 150w"
                  sizes="(max-width: 500px) 100vw, 500px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/4tr-treadmill/"
                  data-wpel-link="internal"
                >
                  4TR Treadmill
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <a
              href="https://corehandf.com/product/4rb-recumbent-bike/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/4rb-recumbent-bike/"
                data-wpel-link="internal"
              >
                <img
                  width={500}
                  height={500}
                  src="https://corehandf.com/wp-content/uploads/2018/12/4RB_OpenHub.png"
                  className="attachment-large size-large wp-post-image"
                  alt="4RB_OpenHub"
                  decoding="async"
                  srcSet="https://corehandf.com/wp-content/uploads/2018/12/4RB_OpenHub.png 500w, https://corehandf.com/wp-content/uploads/2018/12/4RB_OpenHub-300x300.png 300w, https://corehandf.com/wp-content/uploads/2018/12/4RB_OpenHub-100x100.png 100w, https://corehandf.com/wp-content/uploads/2018/12/4RB_OpenHub-150x150.png 150w"
                  sizes="(max-width: 500px) 100vw, 500px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/4rb-recumbent-bike/"
                  data-wpel-link="internal"
                >
                  4RB Recumbent Bike
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <a
              href="/product-category/brand/star-trac/4-series-line/"
              data-wpel-link="internal"
            >
              <div className="card">
                <div className="card-body">
                  <h3>
                    View All
                    <br />4 Series
                  </h3>
                </div>
              </div>
            </a>
          </div>
        </div>
        {/*/ .row */}
      </div>
      {/*/ .container */}
    </section>
    <section className="frequently-purchased-with-prods-section">
      <div className="container">
        <h2>Frequently Purchased With</h2>
        <div className="row">
          <div className="col-md-3">
            <a
              href="https://corehandf.com/product/nautilus-instinct-biceps-curl/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/nautilus-instinct-biceps-curl/"
                data-wpel-link="internal"
              >
                <img
                  width={500}
                  height={500}
                  src="https://corehandf.com/wp-content/uploads/2017/02/Instinct_BicepsCurl_-60AR.png"
                  className="attachment-large size-large wp-post-image"
                  alt="Instinct_BicepsCurl_-60AR"
                  decoding="async"
                  loading="lazy"
                  srcSet="https://corehandf.com/wp-content/uploads/2017/02/Instinct_BicepsCurl_-60AR.png 500w, https://corehandf.com/wp-content/uploads/2017/02/Instinct_BicepsCurl_-60AR-300x300.png 300w, https://corehandf.com/wp-content/uploads/2017/02/Instinct_BicepsCurl_-60AR-100x100.png 100w, https://corehandf.com/wp-content/uploads/2017/02/Instinct_BicepsCurl_-60AR-150x150.png 150w"
                  sizes="(max-width: 500px) 100vw, 500px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/nautilus-instinct-biceps-curl/"
                  data-wpel-link="internal"
                >
                  Instinct® Biceps Curl
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <a
              href="https://corehandf.com/product/throwdown-xtc-2x4-compact-rig/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/throwdown-xtc-2x4-compact-rig/"
                data-wpel-link="internal"
              >
                <img
                  width={1024}
                  height={576}
                  src="https://corehandf.com/wp-content/uploads/2020/08/Throwdown-XTC-Compact-Rig-2x4-9-XTC-204C-60-Matte-Black-Product-Image-1024x576.webp"
                  className="attachment-large size-large wp-post-image"
                  alt=""
                  decoding="async"
                  loading="lazy"
                  srcSet="https://corehandf.com/wp-content/uploads/2020/08/Throwdown-XTC-Compact-Rig-2x4-9-XTC-204C-60-Matte-Black-Product-Image-1024x576.webp 1024w, https://corehandf.com/wp-content/uploads/2020/08/Throwdown-XTC-Compact-Rig-2x4-9-XTC-204C-60-Matte-Black-Product-Image-300x169.webp 300w, https://corehandf.com/wp-content/uploads/2020/08/Throwdown-XTC-Compact-Rig-2x4-9-XTC-204C-60-Matte-Black-Product-Image-768x432.webp 768w, https://corehandf.com/wp-content/uploads/2020/08/Throwdown-XTC-Compact-Rig-2x4-9-XTC-204C-60-Matte-Black-Product-Image-1536x864.webp 1536w, https://corehandf.com/wp-content/uploads/2020/08/Throwdown-XTC-Compact-Rig-2x4-9-XTC-204C-60-Matte-Black-Product-Image-2048x1152.webp 2048w, https://corehandf.com/wp-content/uploads/2020/08/Throwdown-XTC-Compact-Rig-2x4-9-XTC-204C-60-Matte-Black-Product-Image-600x338.webp 600w"
                  sizes="(max-width: 1024px) 100vw, 1024px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/throwdown-xtc-2x4-compact-rig/"
                  data-wpel-link="internal"
                >
                  2 x 4 Compact XTC Rig
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <a
              href="https://corehandf.com/product/throwdown-fxd-bench/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/throwdown-fxd-bench/"
                data-wpel-link="internal"
              >
                <img
                  width={1024}
                  height={1024}
                  src="https://corehandf.com/wp-content/uploads/2021/04/FXD-Bench-60-Degree-1024x1024.png"
                  className="attachment-large size-large wp-post-image"
                  alt="FXD Bench 60 Degree"
                  decoding="async"
                  loading="lazy"
                  srcSet="https://corehandf.com/wp-content/uploads/2021/04/FXD-Bench-60-Degree-1024x1024.png 1024w, https://corehandf.com/wp-content/uploads/2021/04/FXD-Bench-60-Degree-300x300.png 300w, https://corehandf.com/wp-content/uploads/2021/04/FXD-Bench-60-Degree-100x100.png 100w, https://corehandf.com/wp-content/uploads/2021/04/FXD-Bench-60-Degree-600x600.png 600w, https://corehandf.com/wp-content/uploads/2021/04/FXD-Bench-60-Degree-150x150.png 150w, https://corehandf.com/wp-content/uploads/2021/04/FXD-Bench-60-Degree-768x768.png 768w, https://corehandf.com/wp-content/uploads/2021/04/FXD-Bench-60-Degree-1536x1536.png 1536w, https://corehandf.com/wp-content/uploads/2021/04/FXD-Bench-60-Degree-2048x2048.png 2048w"
                  sizes="(max-width: 1024px) 100vw, 1024px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/throwdown-fxd-bench/"
                  data-wpel-link="internal"
                >
                  FXD Bench
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <a
              href="https://corehandf.com/product/stairmaster-4g/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/stairmaster-4g/"
                data-wpel-link="internal"
              >
                <img
                  width={1024}
                  height={1024}
                  src="https://corehandf.com/wp-content/uploads/2023/03/StairMaster-4G-15in-OpenHub-Console-Product-Image-WEB-1024x1024.png"
                  className="attachment-large size-large wp-post-image"
                  alt=""
                  decoding="async"
                  loading="lazy"
                  srcSet="https://corehandf.com/wp-content/uploads/2023/03/StairMaster-4G-15in-OpenHub-Console-Product-Image-WEB-1024x1024.png 1024w, https://corehandf.com/wp-content/uploads/2023/03/StairMaster-4G-15in-OpenHub-Console-Product-Image-WEB-300x300.png 300w, https://corehandf.com/wp-content/uploads/2023/03/StairMaster-4G-15in-OpenHub-Console-Product-Image-WEB-150x150.png 150w, https://corehandf.com/wp-content/uploads/2023/03/StairMaster-4G-15in-OpenHub-Console-Product-Image-WEB-768x768.png 768w, https://corehandf.com/wp-content/uploads/2023/03/StairMaster-4G-15in-OpenHub-Console-Product-Image-WEB-70x70.png 70w, https://corehandf.com/wp-content/uploads/2023/03/StairMaster-4G-15in-OpenHub-Console-Product-Image-WEB-600x600.png 600w, https://corehandf.com/wp-content/uploads/2023/03/StairMaster-4G-15in-OpenHub-Console-Product-Image-WEB-100x100.png 100w, https://corehandf.com/wp-content/uploads/2023/03/StairMaster-4G-15in-OpenHub-Console-Product-Image-WEB.png 1500w"
                  sizes="(max-width: 1024px) 100vw, 1024px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/stairmaster-4g/"
                  data-wpel-link="internal"
                >
                  4G
                </a>
              </div>
            </div>
          </div>
        </div>
        {/*/ .row */}
      </div>
      {/*/ .container */}
    </section>
    <section id="request-a-quote-form" className="section request-a-quote-form">
      <div className="container">
        <h2>
          <span>Start Your Consultation Today</span>
        </h2>
        <div className="row center-xs">
          <div className="col-md-6 offset-md-6">
            {/*[if lte IE 8]>

<![endif]*/}
          </div>
        </div>
      </div>
    </section>
    <a className="sticky-cta" href="#request-a-quote-form">
      <img src="/wp-content/uploads/2022/04/Icon_map-gym.png" />
      Start Consultation
    </a>{" "}
  </main>
  {/* /.main */}
</>

   </> 

    )    
};
export default Fourseries;



