import React  from "react";
import ALLCordio from "../ProductCategory/type/main-floor-cardio/AllCardio";


function Rigs(){
    return (
        <>
        <ALLCordio />
        </>
    )
}

export default Rigs ;