import React from "react";
import images from "../../images/img";
import Form from "../../CommonCompounds/Form/Form";

function Oneseries() {
  return (
    <>
      <>
        <main className="main" id="main">
          <section className="breadcrumbs">
            <ul>
              {/*li><a href="#">Home</a></li>
          <li class="current">Star Trac</li*/}
              <li>
                <span>
                  <span>
                    <a href="https://corehandf.com/" data-wpel-link="internal">
                      Home
                    </a>
                  </span>{" "}
                  »{" "}
                  <span>
                    <a
                      href="https://corehandf.com/product_line/"
                      data-wpel-link="internal"
                    >
                      Product Lines
                    </a>
                  </span>{" "}
                  »{" "}
                  <span>
                    <a href="" data-wpel-link="internal">
                      Nautilus
                    </a>
                  </span>{" "}
                  »{" "}
                  <span className="breadcrumb_last" aria-current="page">
                    One Series
                  </span>
                </span>
              </li>
            </ul>
          </section>
          <section
            className="section product-line-banner-section"
            style={{
              background:
                "url(https://corehandf.com/wp-content/uploads/2023/04/Image-Lifestyle-One-Hero-Shot-Wide-1.webp) top center no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div className="product-line-banner">
              <div className="content-wrap">
                <img src="https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Logo-all-white.png" />
                <h1 className="banner-title largest">One Series</h1>
              </div>
            </div>
          </section>
          <section className="section product-line-intro-section container">
            <h2>FOLLOW YOUR INTUITION</h2>
            <p>
              Nautilus One equipment combines revolutionary weight stack
              technology with the most intuitive and user-friendly selection
              method ever created: a dial. Our unique 4-bar linkage and
              specialized cam design provide smooth resistance and proper muscle
              loading through the complete range of motion, while fewer guide
              rods, cams, and pulleys extend the life of this product well past
              other machines. For a unique, safe, and effective strength
              training experience, there is only ONE choice that will ensure
              your facility stands out from your competition.
            </p>
          </section>
          <section className="section full-width-column-section container image-left">
            <div className="row">
              <div className="col-md-5">
                <div className="img-video-wrap">
                  <img src="https://corehandf.com/wp-content/uploads/2023/04/Image-Lifestyle-One-Hero-Shot-Wide-2.webp" />
                </div>
              </div>
              <div className="col-md-7">
                <div className="content-wrap">
                  <h2>INNOVATIVE AND UNIFIED TOWERS</h2>
                  <p>
                    Genius in its simplicity, the Nautilus One line features
                    compact unified towers, each with patented weight stack
                    technology and the unique weight selection dial. Plates are
                    stacked side-by-side with minimal use of pulleys and belts
                    to allow for natural movement mechanics that give a smooth,
                    plate-loaded workout feel from a selectorized machine.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="section full-width-column-section container image-right">
            <div className="row">
              <div className="col-md-7">
                <div className="content-wrap">
                  <h2>INNOVATIVE AND UNIFIED TOWERS</h2>
                  <p>
                    Now weight selection is as easy as turning a dial. The
                    unique weight selection dial is easily recognizable and
                    always within reach from the seated position—no more bending
                    down, reaching behind, or standing up to adjust weight
                    selection. Nautilus One® has no guide rods and no weight
                    stack pins, which means less maintenance and downtime.
                  </p>
                </div>
              </div>
              <div className="col-md-5">
                <div className="img-video-wrap">
                  <div style={{ padding: "100% 0 0 0", position: "relative" }}>
                    <iframe
                      src="https://player.vimeo.com/video/791218148?h=582105914e&badge=0&autopause=0&player_id=0&app_id=58479"
                      frameBorder={0}
                      allow="autoplay; fullscreen; picture-in-picture"
                      allowFullScreen=""
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                      }}
                      title="Nautilus One Dial Animation"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section full-width-column-section container image-left">
            <div className="row">
              <div className="col-md-5">
                <div className="img-video-wrap">
                  <img src="https://corehandf.com/wp-content/uploads/2023/04/Nautilus-One-Leg-Press-S6LP-32-Product-Image.webp" />
                </div>
              </div>
              <div className="col-md-7">
                <div className="content-wrap">
                  <h2>DESIGNED FOR ALL LEVELS</h2>
                  <p>
                    Nautilus One uses a unique smart arm linkage and Ergo Grip
                    technology as standard on select machines. These features
                    help promote proper biomechanics as the machines follow the
                    body’s natural strength curve. The One line is for everyone,
                    allowing for micro-adjustments with 1 lb increment weight
                    changes and providing easy-to-use features for a
                    user-friendly experience such as hydraulic seats, a range of
                    motion limitor options, low-profile towers,
                    non-language-based placards with QR codes, axis indicators,
                    and entry/exit handles.
                  </p>
                  <p>&nbsp;</p>
                </div>
              </div>
            </div>
          </section>
          <section className="series-prods-section">
            <div className="container">
              <h2>Browse One Series Products</h2>
              <div className="row">
                <div className="col-md-3 px-4">
                  <a href="" data-wpel-link="internal"></a>
                  <div className="card">
                    <a href="" data-wpel-link="internal">
                      <img
                        width={500}
                        height={500}
                        src=""
                        className="attachment-large size-large wp-post-image"
                        alt=""
                        decoding="async"
                        fetchpriority="high"
                        srcSet="https://corehandf.com/wp-content/uploads/2017/02/S6AB-Abdominal.png 500w, https://corehandf.com/wp-content/uploads/2017/02/S6AB-Abdominal-300x300.png 300w, https://corehandf.com/wp-content/uploads/2017/02/S6AB-Abdominal-100x100.png 100w, https://corehandf.com/wp-content/uploads/2017/02/S6AB-Abdominal-150x150.png 150w"
                        sizes="(max-width: 500px) 100vw, 500px"
                      />
                    </a>
                    <div className="card-body test">
                      <a href="" data-wpel-link="internal">
                        Nautilus One™ Abdominal Crunch
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 px-4">
                  <a href="" data-wpel-link="internal"></a>
                  <div className="card">
                    <a href="" data-wpel-link="internal">
                      <img
                        width={500}
                        height={500}
                        src="https://corehandf.com/wp-content/uploads/2017/02/S6BC-Biceps-Curl.png"
                        className="attachment-large size-large wp-post-image"
                        alt=""
                        decoding="async"
                        srcSet="https://corehandf.com/wp-content/uploads/2017/02/S6BC-Biceps-Curl.png 500w, https://corehandf.com/wp-content/uploads/2017/02/S6BC-Biceps-Curl-300x300.png 300w, https://corehandf.com/wp-content/uploads/2017/02/S6BC-Biceps-Curl-100x100.png 100w, https://corehandf.com/wp-content/uploads/2017/02/S6BC-Biceps-Curl-150x150.png 150w"
                        sizes="(max-width: 500px) 100vw, 500px"
                      />
                    </a>
                    <div className="card-body test">
                      <a href="" data-wpel-link="internal">
                        Nautilus One™ Biceps Curl
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-3  px-4">
                  <a href="" data-wpel-link="internal"></a>
                  <div className="card">
                    <a href="" data-wpel-link="internal">
                      <img
                        width={500}
                        height={500}
                        src="https://corehandf.com/wp-content/uploads/2017/02/S6FWN-Four-Way-Neck.png"
                        className="attachment-large size-large wp-post-image"
                        alt=""
                        decoding="async"
                        srcSet="https://corehandf.com/wp-content/uploads/2017/02/S6FWN-Four-Way-Neck.png 500w, https://corehandf.com/wp-content/uploads/2017/02/S6FWN-Four-Way-Neck-300x300.png 300w, https://corehandf.com/wp-content/uploads/2017/02/S6FWN-Four-Way-Neck-100x100.png 100w, https://corehandf.com/wp-content/uploads/2017/02/S6FWN-Four-Way-Neck-150x150.png 150w"
                        sizes="(max-width: 500px) 100vw, 500px"
                      />
                    </a>
                    <div className="card-body test">
                      <a href="" data-wpel-link="internal">
                        Nautilus One™ Four-Way Neck Machine
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-3  px-4">
                  <a
                    href="/product-category/brand/nautilus/one-line/"
                    data-wpel-link="internal"
                  >
                    <div className="card">
                      <div className="card-body">
                        <h3>
                          View All
                          <br />
                          One Series
                        </h3>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              {/*/ .row */}
            </div>
            {/*/ .container */}
          </section>
          <section className="frequently-purchased-with-prods-section">
            <div className="container">
              <h2>Frequently Purchased With</h2>
              <div className="row ">
                <div className="col-md-3 px-4">
                  <a href="" data-wpel-link="internal"></a>
                  <div className="card">
                    <a href="" data-wpel-link="internal">
                      <img
                        width={1024}
                        height={1024}
                        src="https://corehandf.com/wp-content/uploads/2018/12/Startrac-10-TRx-Product-Image-scaled-e1706729713642-1024x1024.webp"
                        className="attachment-large size-large wp-post-image"
                        alt=""
                        decoding="async"
                        loading="lazy"
                        srcSet="https://corehandf.com/wp-content/uploads/2018/12/Startrac-10-TRx-Product-Image-scaled-e1706729713642-1024x1024.webp 1024w, https://corehandf.com/wp-content/uploads/2018/12/Startrac-10-TRx-Product-Image-scaled-e1706729713642-300x300.webp 300w, https://corehandf.com/wp-content/uploads/2018/12/Startrac-10-TRx-Product-Image-scaled-e1706729713642-150x150.webp 150w, https://corehandf.com/wp-content/uploads/2018/12/Startrac-10-TRx-Product-Image-scaled-e1706729713642-768x768.webp 768w, https://corehandf.com/wp-content/uploads/2018/12/Startrac-10-TRx-Product-Image-scaled-e1706729713642-70x70.webp 70w, https://corehandf.com/wp-content/uploads/2018/12/Startrac-10-TRx-Product-Image-scaled-e1706729713642-600x600.webp 600w, https://corehandf.com/wp-content/uploads/2018/12/Startrac-10-TRx-Product-Image-scaled-e1706729713642-100x100.webp 100w, https://corehandf.com/wp-content/uploads/2018/12/Startrac-10-TRx-Product-Image-scaled-e1706729713642.webp 1536w"
                        sizes="(max-width: 1024px) 100vw, 1024px"
                      />
                    </a>
                    <div className="card-body test">
                      <a href="" data-wpel-link="internal">
                        10TRx FreeRunner™ Treadmill
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 px-4">
                  <a href="" data-wpel-link="internal"></a>
                  <div className="card">
                    <a href="" data-wpel-link="internal">
                      <img
                        width={500}
                        height={500}
                        src="https://corehandf.com/wp-content/uploads/2017/02/Benches__Racks_Half_Rack-60.png"
                        className="attachment-large size-large wp-post-image"
                        alt="Nautilus - Benches & Racks - Half Rack - 60"
                        decoding="async"
                        loading="lazy"
                        srcSet="https://corehandf.com/wp-content/uploads/2017/02/Benches__Racks_Half_Rack-60.png 500w, https://corehandf.com/wp-content/uploads/2017/02/Benches__Racks_Half_Rack-60-300x300.png 300w, https://corehandf.com/wp-content/uploads/2017/02/Benches__Racks_Half_Rack-60-100x100.png 100w, https://corehandf.com/wp-content/uploads/2017/02/Benches__Racks_Half_Rack-60-150x150.png 150w"
                        sizes="(max-width: 500px) 100vw, 500px"
                      />
                    </a>
                    <div className="card-body test">
                      <a href="" data-wpel-link="internal">
                        Half Rack
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 px-4">
                  <a href="" data-wpel-link="internal"></a>
                  <div className="card">
                    <a href="" data-wpel-link="internal">
                      <img
                        width={1024}
                        height={1024}
                        src="https://corehandf.com/wp-content/uploads/2021/09/Throwdown-Alpha-XTC-PTC-20ft-Bridge-1024x1024.png"
                        className="attachment-large size-large wp-post-image"
                        alt="Throwdown-Alpha-XTC-PTC-20ft-Bridge"
                        decoding="async"
                        loading="lazy"
                        srcSet="https://corehandf.com/wp-content/uploads/2021/09/Throwdown-Alpha-XTC-PTC-20ft-Bridge-1024x1024.png 1024w, https://corehandf.com/wp-content/uploads/2021/09/Throwdown-Alpha-XTC-PTC-20ft-Bridge-300x300.png 300w, https://corehandf.com/wp-content/uploads/2021/09/Throwdown-Alpha-XTC-PTC-20ft-Bridge-100x100.png 100w, https://corehandf.com/wp-content/uploads/2021/09/Throwdown-Alpha-XTC-PTC-20ft-Bridge-600x600.png 600w, https://corehandf.com/wp-content/uploads/2021/09/Throwdown-Alpha-XTC-PTC-20ft-Bridge-150x150.png 150w, https://corehandf.com/wp-content/uploads/2021/09/Throwdown-Alpha-XTC-PTC-20ft-Bridge-768x768.png 768w, https://corehandf.com/wp-content/uploads/2021/09/Throwdown-Alpha-XTC-PTC-20ft-Bridge.png 1500w"
                        sizes="(max-width: 1024px) 100vw, 1024px"
                      />
                    </a>
                    <div className="card-body test">
                      <a href="" data-wpel-link="internal">
                        Alpha XTC Personal Training Center
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/*/ .row */}
            </div>
            {/*/ .container */}
          </section>
          <section
            id="request-a-quote-form"
            className="section request-a-quote-form  quote-form"
          >
            <div className="container">
              <h2>
                <span>Start Your Consultation Today</span>
              </h2>
              <div className="row center-xs">
                <div className="col-md-6 offset-md-6">
                  <Form />
                </div>
              </div>
            </div>
          </section>
          <a className="sticky-cta" href="#request-a-quote-form">
            <img src={images.mapgym} />
            Start Consultation
          </a>{" "}
        </main>
        {/* /.main */}
      </>
    </>
  );
}

export default Oneseries;
