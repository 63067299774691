import React from "react";
import ALLCordio from "../ProductCategory/type/main-floor-cardio/AllCardio";



function TenSerive(){
    return(
   <>
 <>
  <main className="main" id="main">
    <section className="breadcrumbs">
      <ul>
        {/*li><a href="#">Home</a></li>
          <li class="current">Star Trac</li*/}
        <li>
          <span>
            <span>
              <a href="https://corehandf.com/" data-wpel-link="internal">
                Home
              </a>
            </span>{" "}
            »{" "}
            <span>
              <a
                href="https://corehandf.com/product_line/"
                data-wpel-link="internal"
              >
                Product Lines
              </a>
            </span>{" "}
            »{" "}
            <span>
              <a
                href="https://corehandf.com/product_line/star-trac-3/"
                data-wpel-link="internal"
              >
                Star Trac®
              </a>
            </span>{" "}
            »{" "}
            <span className="breadcrumb_last" aria-current="page">
              10 Series
            </span>
          </span>
        </li>{" "}
      </ul>
    </section>
    <section
      className="section product-line-banner-section"
      style={{
        background:
          "url(https://corehandf.com/wp-content/uploads/2023/04/Star-Trac-FreeRunner-Hero-Shot.webp) top center no-repeat",
        backgroundSize: "cover"
      }}
    >
      <div className="product-line-banner">
        <div className="content-wrap">
          <img src="https://corehandf.com/wp-content/uploads/2023/04/logo-starimaster-start-trac-trans-white-1.png" />
          <h1 className="banner-title largest">10 Series</h1>
        </div>
      </div>
    </section>
    <section className="section product-line-intro-section container"></section>
    <section className="section full-width-column-section container image-left">
      <div className="row">
        <div className="col-md-5">
          <div className="img-video-wrap">
            <img src="https://corehandf.com/wp-content/uploads/2019/10/10-TRx_HexDeck_Close_Up_2-web-copy.jpg" />
          </div>
        </div>
        <div className="col-md-7">
          <div className="content-wrap">
            <h2>HEXDECK TECHNOLOGY</h2>
            <p>
              Our HexDeck System features an aluminum deck supported by a
              proprietary, hexagonal polymer suspension system. The deck
              dissipates heat, keeping a cooler surface for the belt and
              reducing wear. The deck panel will last many times longer than MDF
              – only a thin phenolic layer needs replaced when worn,
              significantly reducing the labor and material long-term cost of
              ownership.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className="section fifty-fifty-column-section container">
      <div className="row gx-5">
        <div className="col-md-6">
          <div className="content-wrap">
            <img src="" />
            <h2>WEARABLE DEVICE CONNECTIVITY</h2>
            <p>
              NFC Technology in the OpenHub LCD and 16/20” consoles allow simple
              tap to pair connectivity with Apple Watch and Samsung Galaxy
              watches, for real-time data sharing.
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="content-wrap">
            <img src="https://corehandf.com/wp-content/uploads/2019/01/Star-Trac-20in-Console-Embedded-1-scaled.webp" />
            <h2>OPENHUB CONSOLE</h2>
            <p>
              Embedded Console powered by Android&lt;sup&gt;TM&lt;/sup&gt;: the
              8 and 10 Series Cardio embedded consoles boast clear HD
              resolution, high-speed functionality, and familiar user-interface
              for workouts and entertainment. With on-board apps, IPTV,
              hassle-free maintenance, and brand customization options, our
              embedded consoles are the solution for modern fitness technology.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className="section full-width-column-section container image-left">
      <div className="row">
        <div className="col-md-5">
          <div className="img-video-wrap">
            <img src="https://corehandf.com/wp-content/uploads/2017/01/Star-Trac-Console-Openhub-LCD-Quick-Keys-Deadon.jpg" />
          </div>
        </div>
        <div className="col-md-7">
          <div className="content-wrap">
            <h2>QUICK KEY SELECTION</h2>
            <p>
              Quickly adjust speed and incline with the easy Quick Key selection
              on the 8-TRx and FreeRunner treadmill LCD displays. Members can
              quickly navigate the treadmill controls and adjust their workout
              with a row of large buttons on the display. This user-friendly
              feature allows for easy adjustment to the workout while in motion.
              The embedded OpenHub displays include a customizable Quick Key
              view for every type of equipment.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className="series-prods-section">
      <div className="container">
        <h2>Browse 10 Series Products</h2>
        <div className="row">
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/10trx-freerunner-treadmill/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/10trx-freerunner-treadmill/"
                data-wpel-link="internal"
              >
                <img
                  width={1024}
                  height={1024}
                  src="https://corehandf.com/wp-content/uploads/2018/12/Startrac-10-TRx-Product-Image-scaled-e1706729713642-1024x1024.webp"
                  className="attachment-large size-large wp-post-image"
                  alt=""
                  decoding="async"
                  fetchpriority="high"
                  srcSet="https://corehandf.com/wp-content/uploads/2018/12/Startrac-10-TRx-Product-Image-scaled-e1706729713642-1024x1024.webp 1024w, https://corehandf.com/wp-content/uploads/2018/12/Startrac-10-TRx-Product-Image-scaled-e1706729713642-300x300.webp 300w, https://corehandf.com/wp-content/uploads/2018/12/Startrac-10-TRx-Product-Image-scaled-e1706729713642-150x150.webp 150w, https://corehandf.com/wp-content/uploads/2018/12/Startrac-10-TRx-Product-Image-scaled-e1706729713642-768x768.webp 768w, https://corehandf.com/wp-content/uploads/2018/12/Startrac-10-TRx-Product-Image-scaled-e1706729713642-70x70.webp 70w, https://corehandf.com/wp-content/uploads/2018/12/Startrac-10-TRx-Product-Image-scaled-e1706729713642-600x600.webp 600w, https://corehandf.com/wp-content/uploads/2018/12/Startrac-10-TRx-Product-Image-scaled-e1706729713642-100x100.webp 100w, https://corehandf.com/wp-content/uploads/2018/12/Startrac-10-TRx-Product-Image-scaled-e1706729713642.webp 1536w"
                  sizes="(max-width: 1024px) 100vw, 1024px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/10trx-freerunner-treadmill/"
                  data-wpel-link="internal"
                >
                  10TRx FreeRunner™ Treadmill
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/stairmaster-10g/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/stairmaster-10g/"
                data-wpel-link="internal"
              >
                <img
                  width={1024}
                  height={1024}
                  src="https://corehandf.com/wp-content/uploads/2019/09/Stairmaster-10-G-Hero-Shot-Transparent-1024x1024.webp"
                  className="attachment-large size-large wp-post-image"
                  alt=""
                  decoding="async"
                  srcSet="https://corehandf.com/wp-content/uploads/2019/09/Stairmaster-10-G-Hero-Shot-Transparent-1024x1024.webp 1024w, https://corehandf.com/wp-content/uploads/2019/09/Stairmaster-10-G-Hero-Shot-Transparent-300x300.webp 300w, https://corehandf.com/wp-content/uploads/2019/09/Stairmaster-10-G-Hero-Shot-Transparent-150x150.webp 150w, https://corehandf.com/wp-content/uploads/2019/09/Stairmaster-10-G-Hero-Shot-Transparent-768x768.webp 768w, https://corehandf.com/wp-content/uploads/2019/09/Stairmaster-10-G-Hero-Shot-Transparent-1536x1536.webp 1536w, https://corehandf.com/wp-content/uploads/2019/09/Stairmaster-10-G-Hero-Shot-Transparent-2048x2048.webp 2048w, https://corehandf.com/wp-content/uploads/2019/09/Stairmaster-10-G-Hero-Shot-Transparent-70x70.webp 70w, https://corehandf.com/wp-content/uploads/2019/09/Stairmaster-10-G-Hero-Shot-Transparent-600x600.webp 600w, https://corehandf.com/wp-content/uploads/2019/09/Stairmaster-10-G-Hero-Shot-Transparent-100x100.webp 100w"
                  sizes="(max-width: 1024px) 100vw, 1024px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/stairmaster-10g/"
                  data-wpel-link="internal"
                >
                  10G
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="/product-category/brand/star-trac/10-series-line/"
              data-wpel-link="internal"
            >
              <div className="card">
                <div className="card-body">
                  <h3>
                    View All
                    <br />
                    10 Series
                  </h3>
                </div>
              </div>
            </a>
          </div>
        </div>
        {/*/ .row */}
      </div>
      {/*/ .container */}
    </section>
    <section className="frequently-purchased-with-prods-section">
      <div className="container">
        <h2>Frequently Purchased With</h2>
        <div className="row">
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/stairmaster-10g/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/stairmaster-10g/"
                data-wpel-link="internal"
              >
                <img
                  width={1024}
                  height={1024}
                  src="https://corehandf.com/wp-content/uploads/2019/09/Stairmaster-10-G-Hero-Shot-Transparent-1024x1024.webp"
                  className="attachment-large size-large wp-post-image"
                  alt=""
                  decoding="async"
                  srcSet="https://corehandf.com/wp-content/uploads/2019/09/Stairmaster-10-G-Hero-Shot-Transparent-1024x1024.webp 1024w, https://corehandf.com/wp-content/uploads/2019/09/Stairmaster-10-G-Hero-Shot-Transparent-300x300.webp 300w, https://corehandf.com/wp-content/uploads/2019/09/Stairmaster-10-G-Hero-Shot-Transparent-150x150.webp 150w, https://corehandf.com/wp-content/uploads/2019/09/Stairmaster-10-G-Hero-Shot-Transparent-768x768.webp 768w, https://corehandf.com/wp-content/uploads/2019/09/Stairmaster-10-G-Hero-Shot-Transparent-1536x1536.webp 1536w, https://corehandf.com/wp-content/uploads/2019/09/Stairmaster-10-G-Hero-Shot-Transparent-2048x2048.webp 2048w, https://corehandf.com/wp-content/uploads/2019/09/Stairmaster-10-G-Hero-Shot-Transparent-70x70.webp 70w, https://corehandf.com/wp-content/uploads/2019/09/Stairmaster-10-G-Hero-Shot-Transparent-600x600.webp 600w, https://corehandf.com/wp-content/uploads/2019/09/Stairmaster-10-G-Hero-Shot-Transparent-100x100.webp 100w"
                  sizes="(max-width: 1024px) 100vw, 1024px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/stairmaster-10g/"
                  data-wpel-link="internal"
                >
                  10G
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/throwdown-personal-training-center/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/throwdown-personal-training-center/"
                data-wpel-link="internal"
              >
                <img
                  width={1024}
                  height={1024}
                  src="https://corehandf.com/wp-content/uploads/2021/09/Throwdown-Alpha-XTC-PTC-20ft-Bridge-1024x1024.png"
                  className="attachment-large size-large wp-post-image"
                  alt="Throwdown-Alpha-XTC-PTC-20ft-Bridge"
                  decoding="async"
                  loading="lazy"
                  srcSet="https://corehandf.com/wp-content/uploads/2021/09/Throwdown-Alpha-XTC-PTC-20ft-Bridge-1024x1024.png 1024w, https://corehandf.com/wp-content/uploads/2021/09/Throwdown-Alpha-XTC-PTC-20ft-Bridge-300x300.png 300w, https://corehandf.com/wp-content/uploads/2021/09/Throwdown-Alpha-XTC-PTC-20ft-Bridge-100x100.png 100w, https://corehandf.com/wp-content/uploads/2021/09/Throwdown-Alpha-XTC-PTC-20ft-Bridge-600x600.png 600w, https://corehandf.com/wp-content/uploads/2021/09/Throwdown-Alpha-XTC-PTC-20ft-Bridge-150x150.png 150w, https://corehandf.com/wp-content/uploads/2021/09/Throwdown-Alpha-XTC-PTC-20ft-Bridge-768x768.png 768w, https://corehandf.com/wp-content/uploads/2021/09/Throwdown-Alpha-XTC-PTC-20ft-Bridge.png 1500w"
                  sizes="(max-width: 1024px) 100vw, 1024px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/throwdown-personal-training-center/"
                  data-wpel-link="internal"
                >
                  Alpha XTC Personal Training Center
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/schwinn-z-bike/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/schwinn-z-bike/"
                data-wpel-link="internal"
              >
                <img
                  width={1001}
                  height={1024}
                  src="https://corehandf.com/wp-content/uploads/2021/02/Schwinn-Z-Bike-01-Product-Image-1001x1024.webp"
                  className="attachment-large size-large wp-post-image"
                  alt=""
                  decoding="async"
                  loading="lazy"
                  srcSet="https://corehandf.com/wp-content/uploads/2021/02/Schwinn-Z-Bike-01-Product-Image-1001x1024.webp 1001w, https://corehandf.com/wp-content/uploads/2021/02/Schwinn-Z-Bike-01-Product-Image-293x300.webp 293w, https://corehandf.com/wp-content/uploads/2021/02/Schwinn-Z-Bike-01-Product-Image-768x786.webp 768w, https://corehandf.com/wp-content/uploads/2021/02/Schwinn-Z-Bike-01-Product-Image-1501x1536.webp 1501w, https://corehandf.com/wp-content/uploads/2021/02/Schwinn-Z-Bike-01-Product-Image-2002x2048.webp 2002w, https://corehandf.com/wp-content/uploads/2021/02/Schwinn-Z-Bike-01-Product-Image-600x614.webp 600w"
                  sizes="(max-width: 1001px) 100vw, 1001px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/schwinn-z-bike/"
                  data-wpel-link="internal"
                >
                  Z Bike
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/schwinn-x-bike/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/schwinn-x-bike/"
                data-wpel-link="internal"
              >
                <img
                  width={1001}
                  height={1024}
                  src="https://corehandf.com/wp-content/uploads/2021/02/Schwinn-X-Bike-01-Product-Image-1001x1024.webp"
                  className="attachment-large size-large wp-post-image"
                  alt=""
                  decoding="async"
                  loading="lazy"
                  srcSet="https://corehandf.com/wp-content/uploads/2021/02/Schwinn-X-Bike-01-Product-Image-1001x1024.webp 1001w, https://corehandf.com/wp-content/uploads/2021/02/Schwinn-X-Bike-01-Product-Image-293x300.webp 293w, https://corehandf.com/wp-content/uploads/2021/02/Schwinn-X-Bike-01-Product-Image-768x786.webp 768w, https://corehandf.com/wp-content/uploads/2021/02/Schwinn-X-Bike-01-Product-Image-1501x1536.webp 1501w, https://corehandf.com/wp-content/uploads/2021/02/Schwinn-X-Bike-01-Product-Image-2002x2048.webp 2002w, https://corehandf.com/wp-content/uploads/2021/02/Schwinn-X-Bike-01-Product-Image-600x614.webp 600w"
                  sizes="(max-width: 1001px) 100vw, 1001px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/schwinn-x-bike/"
                  data-wpel-link="internal"
                >
                  X Bike
                </a>
              </div>
            </div>
          </div>
        </div>
        {/*/ .row */}
      </div>
      {/*/ .container */}
    </section>
    <section id="request-a-quote-form" className="section request-a-quote-form  quote-form">
      <div className="container">
        <h2>
          <span>Start Your Consultation Today</span>
        </h2>
        <div className="row center-xs">
          <div className="col-md-6 offset-md-6">
            {/*[if lte IE 8]>

<![endif]*/}
          </div>
        </div>
      </div>
    </section>
    <a className="sticky-cta" href="#request-a-quote-form">
      <img src="/wp-content/uploads/2022/04/Icon_map-gym.png" />
      Start Consultation
    </a>{" "}
  </main>
  {/* /.main */}
</>

  </>
    )
};
export default TenSerive;




