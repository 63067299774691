import React  from "react";
import Navber from "../../CommonCompounds/Nav/Nav";
import Footer from "../../CommonCompounds/Footer/Footer";

import Blog1 from "../../Compounds/Blog1/Blog1";


function Blog1Page(){
    return (
        <>
         <Navber />
         <Blog1 />
         <Footer />
        </>
    )
}

export default Blog1Page;