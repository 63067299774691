import React from "react";
import Navbar from "../../CommonCompounds/Nav/Nav.jsx"
import Footer from "../../CommonCompounds/Footer/Footer.jsx";
import AthleticRecCenter from "../../Compounds/AthleticRecCenters/AthleticRecCenters.jsx"


function AthleticRecCenterPage(){
    return(
        <>

       <Navbar />
       <AthleticRecCenter />
       <Footer />
        </>
    )
}
 export default AthleticRecCenterPage;