import React from "react";
import Navbar from "../../CommonCompounds/Nav/Nav.jsx";
import Footer from "../../CommonCompounds/Footer/Footer.jsx";
import Nautilus from "../../Compounds/Nautilus/Nautilus.jsx";

function NautilusPage() {
    return (
        <>
            <Navbar />
            <Nautilus />
           
            <Footer />
        </>
    );
}

export default NautilusPage;
