import React from "react";
import Navbar from "../../CommonCompounds/Nav/Nav.jsx"
import Footer from "../../CommonCompounds/Footer/Footer.jsx";
import GovernmentMilitary from "../../Compounds/GovernmentMilitary/GovernmentMilitary.jsx"



function GovernmentMilitaryPage(){
    return(
        <>

       <Navbar />
       <GovernmentMilitary /> 
       <Footer />
        </>
    )
}
 export default GovernmentMilitaryPage;