import React from "react";
import Navbar from "../../CommonCompounds/Nav/Nav.jsx"
import Footer from "../../CommonCompounds/Footer/Footer.jsx";
import Fitness from "../../Compounds/FitnessCenters/Fitness.jsx";



function FitnessPage(){
    return(
        <>

       <Navbar />
       <Fitness />
       <Footer />
        </>
    )
}
 export default FitnessPage;