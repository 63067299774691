import React from "react";
import Navbar from "../../CommonCompounds/Nav/Nav.jsx";
import Footer from "../../CommonCompounds/Footer/Footer.jsx";
import StartracComponent from "../../Compounds/Star-trac/Star-trac.jsx";

function StartracPage() {
    return (
        <>
            <Navbar />
            <StartracComponent />
            <Footer />
        </>
    );
}

export default StartracPage;
