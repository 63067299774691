import { Swiper, SwiperSlide } from 'swiper/react';
import images from '../../images/img';
import { Autoplay, Pagination } from 'swiper/modules';


function Home() {
  return (
    <div>
      <main className="main" id="main">
        <section className="section_slide">
          <div className="home-slider_wrapper">
            <Swiper
              className="swiper-container swipper--banner--home"
              modules={[Autoplay, Pagination]}
              autoplay={{ delay: 5000 }}
              pagination={{ clickable: true, el: ".swiper-pagination" }}
            >
              <SwiperSlide>
                <div className="banner-image">
                  <img
                    src={images.banner2}
                    alt="Banner 1"
                  />
                </div>
                <div className="text-slider banner-title">
                  <div className="wrapp--content--slider">
                    <h1>Innovative Brands in Motion</h1>
                    <p>Ready to build the perfect gym?</p>
                    <a
                      className="btn btn--line-white btn-white"
                      href=""
                      target="_blank"
                      rel="nofollow external noopener noreferrer"
                    >
                      Request a consultation
                    </a>
                    <a className="btn btn--arrow btn-white" href="">
                      Learn Why Core Health &amp; Fitness
                    </a>
                  </div>
                </div>
              </SwiperSlide>
              {/* Repeat SwiperSlide for other slides */}
              <SwiperSlide>
                <div className="banner-image">
                  <img
                    src={images.banner1}
                    alt="Banner 2"
                  />
                </div>
                <div className="text-slider banner-title">
                  <div className="wrapp--content--slider">
                    <h1>Complete Cardio</h1>
                    <ul className="logos-brands d-flex">
                      <li className="logo-brand">
                        <img
                          width="200px"
                          src="https://corehandf.com/wp-content/uploads/2021/10/startrac-all-white-logo.png"
                          alt="StarTrac Logo"
                        />
                      </li>
                      <li className="logo-brand">
                        <img
                          width="200px"
                          src="https://corehandf.com/wp-content/uploads/2021/10/stairmaster-all-white-logo.png"
                          alt="StairMaster Logo"
                        />
                      </li>
                    </ul>
                    <a
                      className="btn btn--line-white btn-white"
                      href=""
                    >
                      Learn More
                    </a>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="banner-image">
                  <img
                    src={images.banner4}
                    alt="Banner 2"
                  />
                </div>
                <div className="text-slider banner-title">
                  <div className="wrapp--content--slider">
                    <h1>Innovative strength</h1>
                    <ul className="logos-brands d-flex">
                      <li className="logo-brand">
                        <img
                          width="200px"
                          src="https://corehandf.com/wp-content/uploads/2021/10/nautilus-all-white-logo.png"
                          alt="StarTrac Logo"
                        />
                      </li>
                      <li className="logo-brand">
                        <img
                          width="200px"
                          src="https://corehandf.com/wp-content/uploads/2021/10/throwdown-all-white-logo.png"
                          alt="StairMaster Logo"
                        />
                      </li>
                    </ul>
                    <a
                      className="btn btn--line-white btn-white"
                      href=""
                    >
                      Learn More
                    </a>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="banner-image">
                  <img
                    src={images.Banner4}
                    alt="Banner 2"
                  />
                </div>
                <div className="text-slider banner-title">
                  <div className="wrapp--content--slider">
                    <h1> Group Training</h1>
                    <ul className="logos-brands d-flex">
                      <li className="logo-brand">
                        <img
                          width="200px"
                          src="https://corehandf.com/wp-content/uploads/2021/10/stairmaster-all-white-logo.png"
                          alt="StarTrac Logo"
                        />
                      </li>
                      <li className="logo-brand">
                        <img
                          width="200px"
                          src="https://corehandf.com/wp-content/uploads/2021/10/schwinn-all-white-logo.png"
                          alt="StairMaster Logo"
                        />
                      </li>
                      <li className="logo-brand">
                        <img
                          width="200px"
                              src="https://corehandf.com/wp-content/uploads/2021/10/throwdown-all-white-logo.png"
                          alt="StairMaster Logo"
                        />
                      </li>
                    </ul>
                    <a
                      className="btn btn--line-white btn-white"
                      href=""
                    >
                      Learn More
                    </a>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="banner-image">
                  <img
                    src={images.banner5}
                    alt="Banner 2"
                  />
                </div>
                <div className="text-slider banner-title">
                  <div className="wrapp--content--slider">
                    <h1>Impact sports</h1>
                    <ul className="logos-brands d-flex">
                      <li className="logo-brand">
                        <img
                          width="200px"
                          src="https://corehandf.com/wp-content/uploads/2021/10/throwdown-all-white-logo.png"
                          alt="StarTrac Logo"
                        />
                      </li>
                      <li className="logo-brand">
                        <img
                          width="200px"
                          src="https://corehandf.com/wp-content/uploads/2021/10/stairmaster-all-white-logo.png"
                          alt="StairMaster Logo"
                        />
                      </li>
                    </ul>
                    <a
                      className="btn btn--line-white btn-white"
                      href=""
                    >
                      Learn More
                    </a>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
            <div className="swiper-pagination"></div>
          </div>
        </section>
        <section id="section-our-brand" className="section padd-80">
          <div className="container">
            <div className="row between-lg middle-xs">
              <div className="col-sm-12 col-md-12 col-lg-5 center-xs item-col-1 padd-40 m-auto">
                <div className="mw-85">
                  <h3 className="title-section">Our Brands</h3>
                  <p>
                    <span style={{ verticalAlign: "inherit" }}>
                      <span style={{ verticalAlign: "inherit" }}>
                        Core Health &amp; Fitness is more than gym equipment, we
                        offer innovative solutions for all your facility needs.​
                        This is why we’ve brought together the most recognizable
                        fitness brands to ensure our customers can offer their
                        members an authentic fitness experience.{" "}
                      </span>
                    </span>
                  </p>
                  <a
                    className="btn btn-line--blue btn-black"
                    href="https://corehandf.com/our-brands/"
                    data-wpel-link="internal"
                  >
                    {" "}
                    Get To Know Our Brands
                  </a>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6 item-col-2">
                <ul className="wrapper-logos-our-brand">
                  <li className="image-hidden">
                    <a
                      href=""
                      data-wpel-link="internal"
                    >
                      <img
                        src="https://corehandf.com/wp-content/uploads/2021/10/logoasociate_StairMaster-1.png"
                        alt=""
                      />
                    </a>
                  </li>
                  <li className="image-hidden">
                    <a
                      href=""
                      data-wpel-link="internal"
                    >
                      <img
                        src="https://corehandf.com/wp-content/uploads/2021/10/logoasociate_schwinn-1.png"
                        alt=""
                      />
                    </a>
                  </li>
                  <li className="image-hidden">
                    <a
                      href=""
                      data-wpel-link="internal"
                    >
                      <img
                        src="https://corehandf.com/wp-content/uploads/2021/10/logoasociate_Nautilus-1-1.png"
                        alt=""
                      />
                    </a>
                  </li>
                  <li className="image-hidden">
                    <a
                      href=""
                      data-wpel-link="internal"
                    >
                      <img
                        src="https://corehandf.com/wp-content/uploads/2021/10/logoasociate_startrac-1.png"
                        alt=""
                      />
                    </a>
                  </li>
                
                  <li className="image-hidden ">
                    <a
                      href=""
                      data-wpel-link="internal"
                    >
                      <img
                        src="https://corehandf.com/wp-content/uploads/2021/10/logoasociate_throwoown-1.png"
                        alt=""
                      />
                    </a>
                  </li>
                  
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section id="section-learn-more-about" className="section">
          <div className="px-0">
            {/* <div class="row middle-xs"> */}
            <div className="row">
              <div
                className="col-sm-12 col-md-7 col-lg-8"
                style={{
                  backgroundImage:
                    "url(https://corehandf.com/wp-content/uploads/2021/10/Core-Health-Fitness-Solution-Story-1.webp)",
                }}
              >
                <img
                  className="img-mobile"
                  src="https://corehandf.com/wp-content/uploads/2021/10/Core-Health-Fitness-Solution-Story-1.webp"
                />
              </div>
              <div className="col-sm-12 col-md-5 col-lg-4 center-xs mw-70">
                <div className="wrapper-item-content padd-40 text-left">
                  <img
                    className="logo"
                    src="https://corehandf.com/wp-content/uploads/2021/10/core_health_fitness-1.png"
                  />
                  <p>
                    At Core, we know that a passion for fitness can move us
                    through life with great purpose. With that purpose comes
                    goals; to build your dream space, to create a community, to
                    share your fitness journey. Our team of professionals can
                    make your dreams a reality. From space design and equipment
                    selection to introducing technology, flooring, and recovery
                    space partners; Core has a global network to meet your every
                    need from concept to installation and through fitness
                    programming and maintenance.
                  </p>
                  <p>
                    Partner with us and see how our Core Values drive our every
                    decision.
                  </p>
                  <a
                    className="btn btn-line--blue btn-black"
                    href=""
                    data-wpel-link="internal"
                  >
                    Learn More About Core Health &amp; Fitness
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="section-customer-promise" className="padd-80">
          <div className="container">
            <div className="row between-lg middle-xs">
              <div className="col-sm-12 col-md-12 col-lg-4 item-col-1 padd-40 m-auto">
                <h2 className="text-uppercase">Customer Promise</h2>
                <p></p>
                <p>
                  Our promise is to maximize customer delight and trust. It is
                  our job to go above and beyond to create memorable customer
                  experiences and surpass our customers’ expectations, creating
                  strong relationships and lifelong brand advocates.
                </p>
                <p>
                  <span
                    className="TextRun SCXW220248299 BCX0"
                    lang="EN-US"
                    xmlLang="EN-US"
                    data-contrast="auto"
                  >
                    <span className="NormalTextRun SCXW220248299 BCX0">
                      Work with us and find out why our customers are giving us
                      the highest NPS rating in the industry.
                    </span>
                  </span>
                  <span
                    className="EOP SCXW220248299 BCX0"
                    data-ccp-props='{"134233279":true,"201341983":0,"335559685":1440,"335559739":160,"335559740":259}'
                  >
                    &nbsp;
                  </span>
                </p>
                <p />
                <p>
                  <strong>NET PROMOTER SCORE + 71</strong>
                </p>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6 p-relative iframe-medium">
                <iframe
                  title="Core Health & Fitness: Your Fitness Solutions Partner"
                  src="https://player.vimeo.com/video/664391406?dnt=1&app_id=122963"
                  width={640}
                  height={360}
                  frameBorder={0}
                  allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                />{" "}
              </div>
            </div>
          </div>
        </section>
        <section className="post-grid section-product-highlight">
          <div className="row">
            <div
              className="col-sm-12 col-md-7 col-lg-8"
              style={{
                backgroundImage:
                  "url(https://corehandf.com/wp-content/uploads/2022/09/StairMaster-Jacobs-Ladder-JLX-1-Lifestyle-Image-scaled-e1673905354921.jpg)",
              }}
            >
              <div className="image_post">
                <img
                  className="img-mobile"
                  src={images.AtCore}
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-5 col-lg-4 item-col-1 bg_gray_blue mw-70">
              <div className="wrapper-item-content padd-80 text-left">
                <h2 className="text-uppercase">PRODUCT HIGHLIGHT</h2>
                <img src="https://corehandf.com/wp-content/uploads/2022/07/StairMaster-252x75-1.png" />
                <div className="content-product">
                  <h3 className="text-uppercase title_post">JLX</h3>
                  <div className="descripcion_post">
                    <p></p>
                    <p>
                      The Jacobs Ladder X ladder climbing exercise machine is
                      designed specifically for training fitness enthusiasts,
                      athletes, and professionals. JLX marks the first major
                      redesign to Jacobs Ladder in over 20 years, and offers
                      four different climbing positions to train different
                      muscle groups at different intensity levels. The JLX also
                      features a redesigned display and dashboard, providing
                      more feedback and information on workout intensity levels
                      based on speed
                    </p>
                    <p />
                  </div>
                  <a
                    className="btn btn-white btn--arrow btn-right"
                    href=""
                    data-wpel-link="internal"
                  >
                    Learn More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg_gray padd-bottom-80">
          <div className="container">
            <div className="row middle-xs">
              <div className="feature--products">
                <div className="white--space shadow">
                  <h2 className="text-uppercase">Featured Products</h2>
                  <p />
                  <ul>
                    <li className="text-center">
                      <div className="image-featuredproduct">
                        <img
                          width={300}
                          height={300}
                          src="https://corehandf.com/wp-content/uploads/2023/04/Star-Trac-8-Series-Virtual-Bike-8VB-Bike-21-Product-Image-300x300.webp"
                          className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail"
                          alt=""
                          decoding="async"
                          srcSet="https://corehandf.com/wp-content/uploads/2023/04/Star-Trac-8-Series-Virtual-Bike-8VB-Bike-21-Product-Image-300x300.webp 300w, https://corehandf.com/wp-content/uploads/2023/04/Star-Trac-8-Series-Virtual-Bike-8VB-Bike-21-Product-Image-150x150.webp 150w, https://corehandf.com/wp-content/uploads/2023/04/Star-Trac-8-Series-Virtual-Bike-8VB-Bike-21-Product-Image-70x70.webp 70w, https://corehandf.com/wp-content/uploads/2023/04/Star-Trac-8-Series-Virtual-Bike-8VB-Bike-21-Product-Image-100x100.webp 100w, https://corehandf.com/wp-content/uploads/2023/04/Star-Trac-8-Series-Virtual-Bike-8VB-Bike-21-Product-Image-scaled-400x400.webp 400w"
                          sizes="(max-width: 300px) 100vw, 300px"
                        />{" "}
                      </div>
                      <div className="content-featuredproduct">
                        <h3>Star Trac Virtual Bike</h3>
                        <a
                          className="btn btn-black btn--arrow"
                          href=""
                          data-wpel-link="internal"
                        >
                          Learn more
                        </a>
                      </div>
                    </li>
                    <li className="text-center">
                      <div className="image-featuredproduct">
                        <img
                          width={300}
                          height={300}
                          src="https://corehandf.com/wp-content/uploads/2020/08/Throwdown-XTC-Compact-Rig-2x8-9-XTC-208C-60-Matte-Black-Product-Image-300x300.webp"
                          className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail"
                          alt=""
                          decoding="async"
                          srcSet="https://corehandf.com/wp-content/uploads/2020/08/Throwdown-XTC-Compact-Rig-2x8-9-XTC-208C-60-Matte-Black-Product-Image-300x300.webp 300w, https://corehandf.com/wp-content/uploads/2020/08/Throwdown-XTC-Compact-Rig-2x8-9-XTC-208C-60-Matte-Black-Product-Image-150x150.webp 150w, https://corehandf.com/wp-content/uploads/2020/08/Throwdown-XTC-Compact-Rig-2x8-9-XTC-208C-60-Matte-Black-Product-Image-70x70.webp 70w, https://corehandf.com/wp-content/uploads/2020/08/Throwdown-XTC-Compact-Rig-2x8-9-XTC-208C-60-Matte-Black-Product-Image-100x100.webp 100w"
                          sizes="(max-width: 300px) 100vw, 300px"
                        />{" "}
                      </div>
                      <div className="content-featuredproduct">
                        <h3>HIIT Rope</h3>
                        <a
                          className="btn btn-black btn--arrow"
                          href=""
                          data-wpel-link="internal"
                        >
                          Learn more
                        </a>
                      </div>
                    </li>
                    <li className="text-center">
                      <div className="image-featuredproduct">
                        <img
                          width={300}
                          height={300}
                          src="https://corehandf.com/wp-content/uploads/2021/04/FXD-Bench-60-Degree-300x300.png"
                          className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail"
                          alt="FXD Bench 60 Degree"
                          decoding="async"
                          srcSet="https://corehandf.com/wp-content/uploads/2021/04/FXD-Bench-60-Degree-300x300.png 300w, https://corehandf.com/wp-content/uploads/2021/04/FXD-Bench-60-Degree-100x100.png 100w, https://corehandf.com/wp-content/uploads/2021/04/FXD-Bench-60-Degree-600x600.png 600w, https://corehandf.com/wp-content/uploads/2021/04/FXD-Bench-60-Degree-1024x1024.png 1024w, https://corehandf.com/wp-content/uploads/2021/04/FXD-Bench-60-Degree-150x150.png 150w, https://corehandf.com/wp-content/uploads/2021/04/FXD-Bench-60-Degree-768x768.png 768w, https://corehandf.com/wp-content/uploads/2021/04/FXD-Bench-60-Degree-1536x1536.png 1536w, https://corehandf.com/wp-content/uploads/2021/04/FXD-Bench-60-Degree-2048x2048.png 2048w"
                          sizes="(max-width: 300px) 100vw, 300px"
                        />{" "}
                      </div>
                      <div className="content-featuredproduct">
                        <h3>FXD Bench</h3>
                        <a
                          className="btn btn-black btn--arrow"
                          href=""
                          data-wpel-link="internal"
                        >
                          Learn more
                        </a>
                      </div>
                    </li>
                    <li className="text-center">
                      <div className="image-featuredproduct">
                        <img
                          width={300}
                          height={300}
                          src="https://corehandf.com/wp-content/uploads/2021/02/Schwinn-Z-Bike-01-Product-Image-300x300.webp"
                          className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail"
                          alt=""
                          decoding="async"
                          srcSet="https://corehandf.com/wp-content/uploads/2021/02/Schwinn-Z-Bike-01-Product-Image-300x300.webp 300w, https://corehandf.com/wp-content/uploads/2021/02/Schwinn-Z-Bike-01-Product-Image-150x150.webp 150w, https://corehandf.com/wp-content/uploads/2021/02/Schwinn-Z-Bike-01-Product-Image-70x70.webp 70w, https://corehandf.com/wp-content/uploads/2021/02/Schwinn-Z-Bike-01-Product-Image-100x100.webp 100w"
                          sizes="(max-width: 300px) 100vw, 300px"
                        />{" "}
                      </div>
                      <div className="content-featuredproduct">
                        <h3>Z Bike</h3>
                        <a
                          className="btn btn-black btn--arrow"
                          href=""
                          data-wpel-link="internal"
                        >
                          Learn more
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id="section-shop-all-products"
          style={{ background: "#2e2e38" }}
          className="featured-text p-relative"
        >
          <div className="row">
            <div className="col-sm-12 col-md-5 col-lg-4 mw-70 featured-content">
              <div className="wrapper-item-content text-uppercase text-center text-white padd-80 text-left">
                <h3>Learn more about our Instructor Education Programs</h3>
                <a
                  className="btn btn--arrow btn-white"
                  href="/"
                  data-wpel-link="internal"
                >
                  {" "}
                  Learn More
                </a>
              </div>
            </div>
            <div
              className="col-sm-12 col-md-7 col-lg-8 image-feature"
              style={{
                backgroundImage:
                  "url(https://corehandf.com/wp-content/uploads/2021/10/Learn-More-Instructor-Education-CTA-scaled-1.webp)",
              }}
            >
       
              <img
                className="img-mobile"
                src="https://corehandf.com/wp-content/uploads/2021/10/Learn-More-Instructor-Education-CTA-scaled-1.webp"
              />
            </div>
          </div>
        </section>
        <section
          style={{
            background:
              "url(https://corehandf.com/wp-content/uploads/2021/10/blog-backg.jpg)no-repeat",
            backgroundSize: "cover",
            paddingBottom: 50,
          }}
        >
          <div className="wrapp--blog padd-80">
            <h2 className="title-section-post">
              {" "}
              Core Health &amp; Fitness Updates{" "}
            </h2>
            <ul>
              <li className="wrapp-items-blog">
                <div className="item-post d-flex">
                  <div className="col-sm-12 col-md-4 col-lg-4">
                    <div className="image-item-post">
                      <img
                        width={1024}
                        height={768}
                        src="https://corehandf.com/wp-content/uploads/2024/07/core_customized_branding_blogpic.png"
                        className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                        alt="customized branding"
                        decoding="async"
                        srcSet="https://corehandf.com/wp-content/uploads/2024/07/core_customized_branding_blogpic.png 1024w, https://corehandf.com/wp-content/uploads/2024/07/core_customized_branding_blogpic-300x225.png 300w, https://corehandf.com/wp-content/uploads/2024/07/core_customized_branding_blogpic-768x576.png 768w, https://corehandf.com/wp-content/uploads/2024/07/core_customized_branding_blogpic-600x450.png 600w"
                        sizes="(max-width: 1024px) 100vw, 1024px"
                      />{" "}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 content-post-blog">
                    <h3>
                      Customized Branding Strategies Elevate Fitness Experiences
                    </h3>
                    <ul className="date-info-post d-flex">
                      <li>07.19.24</li>
                      <li>Blog</li>
                    </ul>
                  </div>
                  <div className="col-sm-12 col-md-7 col-lg-2 content-post-blog">
                    <a
                      href=""
                      className="btn btn--arrow btn-white"
                      data-wpel-link="internal"
                    >
                      Read More{" "}
                    </a>{" "}
                  </div>
                </div>
              </li>
              <li className="wrapp-items-blog">
                <div className="item-post d-flex">
                  <div className="col-sm-12 col-md-4 col-lg-4">
                    <div className="image-item-post">
                      <img
                        width={1920}
                        height={1080}
                        src="https://corehandf.com/wp-content/uploads/2024/07/uk-trends-blog-header-.png"
                        className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                        alt="uk fitness trends"
                        decoding="async"
                        srcSet="https://corehandf.com/wp-content/uploads/2024/07/uk-trends-blog-header-.png 1920w, https://corehandf.com/wp-content/uploads/2024/07/uk-trends-blog-header--300x169.png 300w, https://corehandf.com/wp-content/uploads/2024/07/uk-trends-blog-header--1024x576.png 1024w, https://corehandf.com/wp-content/uploads/2024/07/uk-trends-blog-header--768x432.png 768w, https://corehandf.com/wp-content/uploads/2024/07/uk-trends-blog-header--1536x864.png 1536w, https://corehandf.com/wp-content/uploads/2024/07/uk-trends-blog-header--600x338.png 600w"
                        sizes="(max-width: 1920px) 100vw, 1920px"
                      />{" "}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 content-post-blog">
                    <h3>
                      Unlock Top UK Fitness Trends Transforming Member
                      Experiences
                    </h3>
                    <ul className="date-info-post d-flex">
                      <li>07.14.24</li>
                      <li>Blog</li>
                    </ul>
                  </div>
                  <div className="col-sm-12 col-md-7 col-lg-2 content-post-blog">
                    <a
                      href=""
                      className="btn btn--arrow btn-white"
                      data-wpel-link="internal"
                    >
                      Read More{" "}
                    </a>{" "}
                  </div>
                </div>
              </li>
              <li className="wrapp-items-blog">
                <div className="item-post d-flex">
                  <div className="col-sm-12 col-md-4 col-lg-4">
                    <div className="image-item-post">
                      <img
                        width={1080}
                        height={600}
                        src="https://corehandf.com/wp-content/uploads/2024/06/new-finance-partner-header-3-1080-x-600-px.png"
                        className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                        alt="new core financial partnership with franklin square"
                        decoding="async"
                        srcSet="https://corehandf.com/wp-content/uploads/2024/06/new-finance-partner-header-3-1080-x-600-px.png 1080w, https://corehandf.com/wp-content/uploads/2024/06/new-finance-partner-header-3-1080-x-600-px-300x167.png 300w, https://corehandf.com/wp-content/uploads/2024/06/new-finance-partner-header-3-1080-x-600-px-1024x569.png 1024w, https://corehandf.com/wp-content/uploads/2024/06/new-finance-partner-header-3-1080-x-600-px-768x427.png 768w, https://corehandf.com/wp-content/uploads/2024/06/new-finance-partner-header-3-1080-x-600-px-600x333.png 600w"
                        sizes="(max-width: 1080px) 100vw, 1080px"
                      />{" "}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 content-post-blog">
                    <h3>
                      New Core Financial Partnership Fuels Strategic Growth and
                      Innovation
                    </h3>
                    <ul className="date-info-post d-flex">
                      <li>06.24.24</li>
                      <li>Blog</li>
                    </ul>
                  </div>
                  <div className="col-sm-12 col-md-7 col-lg-2 content-post-blog">
                    <a
                      href=""
                      className="btn btn--arrow btn-white"
                      data-wpel-link="internal"
                    >
                      Read More{" "}
                    </a>{" "}
                  </div>
                </div>
              </li>
              <li className="wrapp-items-blog">
                <div className="item-post d-flex">
                  <div className="col-sm-12 col-md-4 col-lg-4">
                    <div className="image-item-post">
                      <img
                        width={1024}
                        height={768}
                        src="https://corehandf.com/wp-content/uploads/2024/06/core_zbike_return-to-cycling-blogpic.png"
                        className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                        alt="indoor cycling recovery with schwinn z bike"
                        decoding="async"
                        srcSet="https://corehandf.com/wp-content/uploads/2024/06/core_zbike_return-to-cycling-blogpic.png 1024w, https://corehandf.com/wp-content/uploads/2024/06/core_zbike_return-to-cycling-blogpic-300x225.png 300w, https://corehandf.com/wp-content/uploads/2024/06/core_zbike_return-to-cycling-blogpic-768x576.png 768w, https://corehandf.com/wp-content/uploads/2024/06/core_zbike_return-to-cycling-blogpic-600x450.png 600w"
                        sizes="(max-width: 1024px) 100vw, 1024px"
                      />{" "}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 content-post-blog">
                    <h3>
                      Z Bike Indoor Cycling Recovery: Getting Back in the Saddle
                    </h3>
                    <ul className="date-info-post d-flex">
                      <li>06.04.24</li>
                      <li>Blog</li>
                    </ul>
                  </div>
                  <div className="col-sm-12 col-md-7 col-lg-2 content-post-blog">
                    <a
                      href=""
                      className="btn btn--arrow btn-white"
                      data-wpel-link="internal"
                    >
                      Read More{" "}
                    </a>{" "}
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <a
            className="btn btn--arrow btn-white"
            style={{ margin: "50px auto" }}
            href="/blog/"
            data-wpel-link="internal"
          >
            View All Blog Posts
          </a>{" "}
        </section>
        <section style={{ background: "#ffffff" }} className="featured-text">
          <div className="padd-80  text-uppercase text-center">
            <h3>Join Our Mailing List.</h3>
            <a
              className="btn btn-line--blue btn-black"
              href=""
              data-wpel-link="external"
              target="_blank"
              rel="nofollow external noopener noreferrer"
            >
              {" "}
              Sign Up
            </a>
          </div>
        </section>
      </main>
    </div>
  );
}

export default Home;
