import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { API_URL } from '../../../../const';

function ALLCordio() {
  const [filters, setFilters] = useState({});
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [brandFilters, setBrandFilters] = useState([]);
  const [exerciseFilters, setExerciseFilters] = useState([]);

  const [selectedBrandIds, setSelectedBrandIds] = useState([]);
  const [selectedExerciseIds, setSelectedExerciseIds] = useState([]);

  useEffect(() => {
    getProductFilters();
    getProductsListByCatId();
  }, []);

  const getProductFilters = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}getProductFilters`);
      const filterData = response.data.result_Arr;

      const brandFilterArray = filterData.brand.map((brand) => ({
        id: brand.product_brand_category_id,
        key: brand.product_brand_category_name.toLowerCase().replace(/\s+/g, "_"),
        label: brand.product_brand_category_name,
      }));

      const exerciseFilterArray = filterData.exercise.map((exercise) => ({
        id: exercise.product_exercise_category_id,
        key: exercise.product_exercise_category_name.toLowerCase().replace(/\s+/g, "_"),
        label: exercise.product_exercise_category_name,
      }));

      setBrandFilters(brandFilterArray);
      setExerciseFilters(exerciseFilterArray);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };


  const handleFilterChange = (e, filterType, id, categoryType) => {
    const { checked } = e.target;
    setFilters((prevFilters) => ({ ...prevFilters, [filterType]: checked }));
  
    if (categoryType === "brand") {
      updateSelectedIds(checked, id, setSelectedBrandIds);
    } else if (categoryType === "exercise") {
      updateSelectedIds(checked, id, setSelectedExerciseIds);
    }
  
    getProductsListByFilters();
  };
  

  

const updateSelectedIds = (checked, id, setSelectedIds) => {
  setSelectedIds((prevIds) => {
    if (checked) {
      return [...prevIds, id];
    } else {
      return prevIds.filter((itemId) => itemId !== id);
    }
  });
};


useEffect(() => {
  getProductsListByFilters();
}, [selectedBrandIds, selectedExerciseIds]);

const getProductsListByFilters = () => {
  var subCatId = window.location.href.split('/getProductsListById/')[1];
  const brandIds = selectedBrandIds.join(',');
  const exerciseIds = selectedExerciseIds.join(',');

  axios.post(`${API_URL}getFilteredProducts`, {
    brand: brandIds,
    exercise: exerciseIds,
    subCatId: subCatId
  })
  .then(response => {
    console.log("Filtered products: ", response.data);
    if(response.data){
      setItems(response.data.result_Arr);
    } else {
      setItems([]);
    }
    
  })
  .catch(error => {
    console.error("Error fetching filtered products: ", error);
  });
};

const getProductsListByCatId = async () => {
  setLoading(true);
  try {
    var subCatId = window.location.href.split('/getProductsListById/')[1];
    const response = await axios.get(`${API_URL}getAllActiveProductsBySubCatId/` + subCatId);
    console.log(response.data.result_Arr);
    if(response.data){
      setItems(response.data.result_Arr);
    } else {
      setItems([]);
    }
    setLoading(false);
  } catch (error) {
    console.log(error);
    setLoading(false);
  }
};




  const filterItems = (items) => {
    if (!items) return []; 
    return items
      .filter((item) => {
        return Object.keys(filters).every((filterKey) => {
          if (!filters[filterKey]) return true;
          if (filterKey.includes("type"))
            return item.type === filterKey.replace("type_", "").replace(/_/g, " ");
          if (filterKey.includes("exercise"))
            return item.exerciseType === filterKey.replace("exercise_", "").replace(/_/g, " ");
          if (filterKey.includes("brand"))
            return item.brand === filterKey.replace("brand_", "");
          return true;
        });
      })
      .slice(0, 318);
  };

  const filteredItems = filterItems(items);

  return (
    <main className="main" id="main">
      <section className="breadcrumbs">
        <ul>
          <li>
            <span>
              <a href="#" data-wpel-link="internal">Home</a> » 
              <a href="#" data-wpel-link="internal">TYPE</a> » 
              <span className="breadcrumb_last" aria-current="page">MAIN FLOOR CARDIO</span>
            </span>
          </li>
        </ul>
      </section>
      <div className="container">
        <div className="row">
          <div className="col-xs-3 sidebar-col">
            <aside className="sidebar" id="sidebar-filters">
              <header className="sidebar-header" id="results-total">
                <h2>All Products <span>({filteredItems.length})</span></h2>
              </header>
              <div className="filters">
                <ul id="filter-results"></ul>
                <a
                  className="reset"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setFilters({});
                    setSelectedBrandIds([]);
                    setSelectedExerciseIds([]);
                  }}
                  data-wpel-link="internal"
                >
                  Reset Filters
                </a>
              </div>

              {/* Brand Filter */}
              <div id="main-filters-wrapper">
                <div className="sidebar-group">
                  <header className="sidebar-group-header">
                    <h4> Brand <span className="ico" /> </h4>
                  </header>
                  <ul className="filter-this" id="product_brand">
                    {brandFilters.map((brand) => (
                      <li key={brand.key}>
                        <input
                          type="checkbox"
                          id={brand.key}
                          checked={filters[brand.key] || false}
                          onChange={(e) => handleFilterChange(e, brand.key, brand.id, "brand")} // Pass 'brand' as categoryType
                        />
                        <label htmlFor={brand.key}>{brand.label}</label>
                      </li>
                    ))}
                  </ul>

                </div>

                {/* Exercise Filter */}
                <div className="sidebar-group">
                  <header className="sidebar-group-header">
                    <h4> Exercise <span className="ico" /> </h4>
                  </header>
                  <ul className="filter-this" id="product_exercise">
                    {exerciseFilters.map((exercise) => (
                      <li key={exercise.key}>
                        <input
                          type="checkbox"
                          id={exercise.key}
                          checked={filters[exercise.key] || false}
                          onChange={(e) => handleFilterChange(e, exercise.key, exercise.id, "exercise")} // Pass 'exercise' as categoryType
                        />
                        <label htmlFor={exercise.key}>{exercise.label}</label>
                      </li>
                    ))}
                  </ul>

                </div>
              </div>
            </aside>
          </div>
          <div className="col-xs-9 archive-content" id="products-wrapper">
            <div className="row" id="products-inner-wrapper">
              {filteredItems.map((item) => {
                const imageUrl = API_URL + item.product_primary_image;
                return (
                  <div className="col-xs-4" key={item.product_id}>
                    <div className="card">
                      <Link to={`/product/${item.product_id}`}>
                        <img src={imageUrl} alt={item.product_name} />
                        <div className="card-info">
                          <p className="name">{item.product_name}</p>
                          <p className="number">{item.product_model}</p>
                        </div>
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
            {/* Pagination */}
            <ul className="pagination" id="product-pagination" style={{ display: "none" }}></ul>
          </div>
        </div>
      </div>
    </main>
  );
}

export default ALLCordio;
