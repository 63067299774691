import React from "react";
import Navbar from "../../CommonCompounds/Nav/Nav.jsx"
import OurCulture from "../../Compounds/OurCulture/OurCulture.jsx";
import Footer from "../../CommonCompounds/Footer/Footer.jsx";



function OurCulturePage(){
    return(
        <>

       <Navbar />
       <OurCulture />
       <Footer />
        </>
    )
}
 export default OurCulturePage;