import React from "react";


function RacksandPlatforms (){
    return (
        <>

<>
  <main className="main" id="main">
    <section className="breadcrumbs">
      <ul>
        {/*li><a href="#">Home</a></li>
          <li class="current">Star Trac</li*/}
        <li>
          <span>
            <span>
              <a href="https://corehandf.com/" data-wpel-link="internal">
                Home
              </a>
            </span>{" "}
            »{" "}
            <span>
              <a
                href="https://corehandf.com/product_line/"
                data-wpel-link="internal"
              >
                Product Lines
              </a>
            </span>{" "}
            »{" "}
            <span>
              <a
                href="https://corehandf.com/product_line/nautilus-brand/"
                data-wpel-link="internal"
              >
                Nautilus
              </a>
            </span>{" "}
            »{" "}
            <span className="breadcrumb_last" aria-current="page">
              Racks and Platforms
            </span>
          </span>
        </li>{" "}
      </ul>
    </section>
    <section
      className="section product-line-banner-section"
      style={{
        background:
          "url(https://corehandf.com/wp-content/uploads/2023/04/Nautilus_Benches_Racks.webp) top center no-repeat",
        backgroundSize: "cover"
      }}
    >
      <div className="product-line-banner">
        <div className="content-wrap">
          <img src="https://corehandf.com/wp-content/uploads/2019/04/Nautilus-Logo-all-white1.png" />
          <h1 className="banner-title largest">Racks and Platforms</h1>
        </div>
      </div>
    </section>
    <section className="section product-line-intro-section container">
      <h2>SUSTAINABILITY WITH A PURPOSE!</h2>
      <p>
        Nautilus Platforms are the best in class, built environmentally
        conscious with solid construction for extreme durability and
        high-performance training. Nautilus Platforms protect floors, reduce the
        force required to stop dropped weight plates, dampen sound, and create a
        stable lifting station for free weight zones. The durability of the
        bamboo surface handles the toughest workouts and maintains outstanding
        longevity. Bamboo is a renewable resource—a fast-growing crop that does
        not need replanting. Nautilus Platforms are a smart investment that
        return long-lasting benefits.
      </p>
    </section>
    <section className="section full-width-column-section container image-left">
      <div className="row">
        <div className="col-md-5">
          <div className="img-video-wrap">
            <img src="https://corehandf.com/wp-content/uploads/2023/04/Nautilus-Half-Rack-With-SVA-Platform-Bamboo-II-9-HDHR2-9-HDP6D-BB2-Product-Image-Product-Image.webp" />
          </div>
        </div>
        <div className="col-md-7">
          <div className="content-wrap">
            <h2>STURDY. VERSATILE. SOLUTIONS.</h2>
            <p>
              Throwdown Free Weights pair perfectly with Nautilus Strength
              products allowing you to get everything in one place. You can even
              find free weight sets crafted specifically for popular Nautilus
              Benches &amp; Racks, Leverage and Plate Loaded products.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className="section fifty-fifty-column-section container">
      <div className="row gx-5">
        <div className="col-md-6">
          <div className="content-wrap">
            <img src="https://corehandf.com/wp-content/uploads/2023/04/Nautilus-Power-Cage-SVA-Kit-1-Product-Image.webp" />
            <h2>INTEGRATED STANDARDS PLUS, OPTIONAL ACCESSORIES</h2>
            <p>
              The free weight areas are growing; don’t be left behind! As
              functional fitness and strength training remain a staple in the
              gym, facility operators need to have solutions for their
              customers. With the Nautilus Half Rack and Power Cage, you know
              you’ve got the best-in-class solutions. Both come standard with an
              integrated multi-grip pull-up station, adjustable protective deep
              j-hook style bar catches, and chrome angled weight storage. Plus,
              options for add-ons: including bumper plate storage, bridge
              adaptors for our Half Racks, bamboo platforms and band pegs for
              variable resistance.
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="content-wrap">
            <img src="https://corehandf.com/wp-content/uploads/2023/04/Nautilus-Stand-Alone-SVA-Platform-Bamboo-II-9-HDP6D-BB2-SA-Product-Image.webp" />
            <h2>BEST IN CLASS PLATFORMS</h2>
            <p>
              Built to out perform the competition, the Nautilus Platform has
              evolved! Whether you’re in the need to protect your floors to
              allow for correct performance characteristics while lifting or,
              looking at ways to reduce sound and vibration caused from users
              dropping barbells while performing Olympic and power lifting, we
              have your solution. Whether you chose a 4′ or 6′ length platform,
              sound vibration absorption (SVA) or standard bamboo wood, Nautilus
              has your back!
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className="series-prods-section">
      <div className="container">
        <h2>Browse Racks and Platforms Products</h2>
        <div className="row">
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/throwdown-fbr-12x24/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/throwdown-fbr-12x24/"
                data-wpel-link="internal"
              >
                <img
                  width={500}
                  height={500}
                  src="https://corehandf.com/wp-content/uploads/2020/12/Image-Throwdown-FBR-1224-XX-1.png"
                  className="attachment-large size-large wp-post-image"
                  alt="Throwdown - FBR - 1224"
                  decoding="async"
                  fetchpriority="high"
                  srcSet="https://corehandf.com/wp-content/uploads/2020/12/Image-Throwdown-FBR-1224-XX-1.png 500w, https://corehandf.com/wp-content/uploads/2020/12/Image-Throwdown-FBR-1224-XX-1-300x300.png 300w, https://corehandf.com/wp-content/uploads/2020/12/Image-Throwdown-FBR-1224-XX-1-100x100.png 100w, https://corehandf.com/wp-content/uploads/2020/12/Image-Throwdown-FBR-1224-XX-1-150x150.png 150w"
                  sizes="(max-width: 500px) 100vw, 500px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/throwdown-fbr-12x24/"
                  data-wpel-link="internal"
                >
                  12 x 24 Freestanding Bag Rack
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/throwdown-fbr-6x24/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/throwdown-fbr-6x24/"
                data-wpel-link="internal"
              >
                <img
                  width={500}
                  height={500}
                  src="https://corehandf.com/wp-content/uploads/2020/12/Image-Throwdown-FBR-0624-XX-1.png"
                  className="attachment-large size-large wp-post-image"
                  alt="Throwdown - FBR - 0624"
                  decoding="async"
                  srcSet="https://corehandf.com/wp-content/uploads/2020/12/Image-Throwdown-FBR-0624-XX-1.png 500w, https://corehandf.com/wp-content/uploads/2020/12/Image-Throwdown-FBR-0624-XX-1-300x300.png 300w, https://corehandf.com/wp-content/uploads/2020/12/Image-Throwdown-FBR-0624-XX-1-100x100.png 100w, https://corehandf.com/wp-content/uploads/2020/12/Image-Throwdown-FBR-0624-XX-1-150x150.png 150w"
                  sizes="(max-width: 500px) 100vw, 500px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/throwdown-fbr-6x24/"
                  data-wpel-link="internal"
                >
                  6 x 24 Freestanding Bag Rack
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/throwdown-abr-9x36-20-bag/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/throwdown-abr-9x36-20-bag/"
                data-wpel-link="internal"
              >
                <img
                  width={500}
                  height={500}
                  src="https://corehandf.com/wp-content/uploads/2020/12/Image-Throwdown-ABR-3620-60-Web.jpg"
                  className="attachment-large size-large wp-post-image"
                  alt="Throwdown - ABR- 3620 - 60"
                  decoding="async"
                  srcSet="https://corehandf.com/wp-content/uploads/2020/12/Image-Throwdown-ABR-3620-60-Web.jpg 500w, https://corehandf.com/wp-content/uploads/2020/12/Image-Throwdown-ABR-3620-60-Web-300x300.jpg 300w, https://corehandf.com/wp-content/uploads/2020/12/Image-Throwdown-ABR-3620-60-Web-100x100.jpg 100w, https://corehandf.com/wp-content/uploads/2020/12/Image-Throwdown-ABR-3620-60-Web-150x150.jpg 150w"
                  sizes="(max-width: 500px) 100vw, 500px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/throwdown-abr-9x36-20-bag/"
                  data-wpel-link="internal"
                >
                  9 x 36 Alpha Bag Rack 20-Bag
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="/product-category/brand/nautilus/platforms-racks-line/"
              data-wpel-link="internal"
            >
              <div className="card">
                <div className="card-body">
                  <h3>
                    View All
                    <br />
                    Racks and Platforms
                  </h3>
                </div>
              </div>
            </a>
          </div>
        </div>
        {/*/ .row */}
      </div>
      {/*/ .container */}
    </section>
    <section className="frequently-purchased-with-prods-section">
      <div className="container">
        <h2>Frequently Purchased With</h2>
        <div className="row">
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/nautilus-benches-racks-adjustable-abdominal-bench/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/nautilus-benches-racks-adjustable-abdominal-bench/"
                data-wpel-link="internal"
              >
                <img
                  width={500}
                  height={500}
                  src="https://corehandf.com/wp-content/uploads/2017/02/Benches__Racks__Adjustable_Abdominal_Decline_Bench-60-1.png"
                  className="attachment-large size-large wp-post-image"
                  alt="Nautilus - Benches & Racks - Adjustable Abdominal Bench - 60"
                  decoding="async"
                  loading="lazy"
                  srcSet="https://corehandf.com/wp-content/uploads/2017/02/Benches__Racks__Adjustable_Abdominal_Decline_Bench-60-1.png 500w, https://corehandf.com/wp-content/uploads/2017/02/Benches__Racks__Adjustable_Abdominal_Decline_Bench-60-1-300x300.png 300w, https://corehandf.com/wp-content/uploads/2017/02/Benches__Racks__Adjustable_Abdominal_Decline_Bench-60-1-100x100.png 100w, https://corehandf.com/wp-content/uploads/2017/02/Benches__Racks__Adjustable_Abdominal_Decline_Bench-60-1-150x150.png 150w"
                  sizes="(max-width: 500px) 100vw, 500px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/nautilus-benches-racks-adjustable-abdominal-bench/"
                  data-wpel-link="internal"
                >
                  Adjustable Abdominal Bench
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/throwdown-hiit-hex-dumbbells/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/throwdown-hiit-hex-dumbbells/"
                data-wpel-link="internal"
              >
                <img
                  width={500}
                  height={500}
                  src="https://corehandf.com/wp-content/uploads/2020/09/Image-Throwdown-HIIT-Hex-Dumbbells-Imperial-TD-HDB-XX-web.png"
                  className="attachment-large size-large wp-post-image"
                  alt="Image - Throwdown - HIIT Hex Dumbbells - Imperial"
                  decoding="async"
                  loading="lazy"
                  srcSet="https://corehandf.com/wp-content/uploads/2020/09/Image-Throwdown-HIIT-Hex-Dumbbells-Imperial-TD-HDB-XX-web.png 500w, https://corehandf.com/wp-content/uploads/2020/09/Image-Throwdown-HIIT-Hex-Dumbbells-Imperial-TD-HDB-XX-web-300x300.png 300w, https://corehandf.com/wp-content/uploads/2020/09/Image-Throwdown-HIIT-Hex-Dumbbells-Imperial-TD-HDB-XX-web-100x100.png 100w, https://corehandf.com/wp-content/uploads/2020/09/Image-Throwdown-HIIT-Hex-Dumbbells-Imperial-TD-HDB-XX-web-150x150.png 150w"
                  sizes="(max-width: 500px) 100vw, 500px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/throwdown-hiit-hex-dumbbells/"
                  data-wpel-link="internal"
                >
                  HIIT Hex Dumbbells
                </a>
              </div>
            </div>
          </div>
        </div>
        {/*/ .row */}
      </div>
      {/*/ .container */}
    </section>
    <section id="request-a-quote-form" className="section request-a-quote-form ">
      <div className="container">
        <h2>
          <span>Start Your Consultation Today</span>
        </h2>
        <div className="row center-xs">
          <div className="col-md-6 offset-md-6">
            {/*[if lte IE 8]>

<![endif]*/}
          </div>
        </div>
      </div>
    </section>
    <a className="sticky-cta" href="#request-a-quote-form">
      <img src="/wp-content/uploads/2022/04/Icon_map-gym.png" />
      Start Consultation
    </a>{" "}
  </main>
  {/* /.main */}
</>

        </>
    )
}

export default RacksandPlatforms;