import React from "react";
import Navbar from "../../CommonCompounds/Nav/Nav.jsx"

import Footer from "../../CommonCompounds/Footer/Footer.jsx";
import OurPartners from "../../Compounds/OurPartners/OurPartners.jsx"


function OurPartnersPage(){
    return(
        <>

       <Navbar />
       <OurPartners />
       <Footer />
        </>
    )
}
 export default OurPartnersPage;