import React  from "react";


function PrivacyPolicy(){
    return(
<div className="container ">
<div className="iub_header">
    <h1>
      Privacy Policy of <strong>corehandf.com/</strong>
    </h1>
    <p>This Application collects some Personal Data from its Users.</p>
  </div>{" "}
  {/* /header */}
  <h2 id="purposes_data">
    Personal Data processed for the following purposes and using the following
    services:
  </h2>
  <ul className="for_boxes cf">
    <li className="one_line_col">
      <ul className="for_boxes">
        <li>
          <div className="iconed policyicon_purpose_16">
            <h3>Access to third-party accounts</h3>
            <ul className="unstyled">
              <li>
                <h3>Linkedin account access</h3>
                <p>Personal Data: Trackers; Usage Data</p>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <div className="iconed policyicon_purpose_7">
            <h3>Advertising</h3>
            <ul className="unstyled">
              <li>
                <h3>LinkedIn Ads</h3>
                <p>Personal Data: Trackers; Usage Data</p>
              </li>
              <li>
                <h3>LinkedIn conversion tracking (LinkedIn Insight Tag)</h3>
                <p>Personal Data: device information; Trackers; Usage Data</p>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <div className="iconed policyicon_purpose_5">
            <h3>Analytics</h3>
            <ul className="unstyled">
              <li>
                <h3>Google Analytics 4</h3>
                <p>
                  Personal Data: browser information; city; device information;
                  latitude (of city); longitude (of city); number of Users;
                  session statistics; Trackers; Usage Data
                </p>
              </li>
              <li>
                <h3>Matomo Cloud</h3>
                <p>Personal Data: Trackers; Usage Data</p>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <div className="iconed policyicon_purpose_69">
            <h3>Building and running this Application</h3>
            <ul className="unstyled">
              <li>
                <h3>WordPress (self-hosted)</h3>
                <p>Personal Data: Usage Data</p>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <div className="iconed policyicon_purpose_10">
            <h3>Contacting the User</h3>
            <ul className="unstyled">
              <li>
                <h3>Contact form</h3>
                <p>
                  Personal Data: city; company name; country; county; date of
                  birth; email address; fax number; field of activity; first
                  name; gender; last name; number of employees; phone number;
                  physical address; profession; state; Trackers; Usage Data;
                  User ID; various types of Data; website; ZIP/Postal code
                </p>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <div className="iconed policyicon_purpose_25">
            <h3>Displaying content from external platforms</h3>
            <ul className="unstyled">
              <li>
                <h3>Font Awesome, Google Fonts and Vimeo video</h3>
                <p>Personal Data: Trackers; Usage Data</p>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <div className="iconed policyicon_purpose_42">
            <h3>Hosting and backend infrastructure</h3>
            <ul className="unstyled">
              <li>
                <h3>accessiBe accessWidget</h3>
                <p>
                  Personal Data: browser information; clicks; device
                  information; interaction events; IP address; operating
                  systems; page views; referral URL; Usage Data
                </p>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <div className="iconed policyicon_purpose_64">
            <h3>
              Registration and authentication provided directly by this
              Application
            </h3>
            <ul className="unstyled">
              <li>
                <h3>Direct registration</h3>
                <p>Personal Data: academic background; billing address</p>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <div className="iconed policyicon_purpose_38">
            <h3>SPAM protection</h3>
            <ul className="unstyled">
              <li>
                <h3>Google reCAPTCHA</h3>
                <p>
                  Personal Data: answers to questions; clicks; keypress events;
                  motion sensor events; mouse movements; scroll position; touch
                  events; Trackers; Usage Data
                </p>
              </li>
              <li>
                <h3>Cloudflare Bot Management</h3>
                <p>
                  Personal Data: app information; Application opens; browser
                  information; browsing history; city; clicks; country; county;
                  custom events; device information; device logs;
                  geography/region; interaction events; IP address; keypress
                  events; language; latitude (of city); launches; longitude (of
                  city); metro area; motion sensor events; mouse movements;
                  number of sessions; operating systems; page events; page
                  views; province; scroll position; scroll-to-page interactions;
                  search history; session duration; session statistics; state;
                  touch events; Trackers; Usage Data; video views; ZIP/Postal
                  code
                </p>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <div className="iconed policyicon_purpose_48">
            <h3>Tag Management</h3>
            <ul className="unstyled">
              <li>
                <h3>Google Tag Manager</h3>
                <p>Personal Data: Trackers; Usage Data</p>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <div className="iconed policyicon_purpose_29">
            <h3>Traffic optimization and distribution</h3>
            <ul className="unstyled">
              <li>
                <h3>Cloudflare</h3>
                <p>
                  Personal Data: Trackers; various types of Data as specified in
                  the privacy policy of the service
                </p>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <div className="iconed policyicon_purpose_35">
            <h3>User database management</h3>
            <ul className="unstyled">
              <li>
                <h3>HubSpot CRM</h3>
                <p>
                  Personal Data: email address; first name; last name; phone
                  number; various types of Data as specified in the privacy
                  policy of the service
                </p>
              </li>
              <li>
                <h3>HubSpot Lead Management</h3>
                <p>
                  Personal Data: email address; first name; last name; phone
                  number; Usage Data; various types of Data as specified in the
                  privacy policy of the service
                </p>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </li>
  </ul>
  <h2 id="opt_out">Information on opting out of interest-based advertising</h2>
  <p>
    In addition to any opt-out feature provided by any of the services listed in
    this document, Users may learn more on how to generally opt out of
    interest-based advertising within the dedicated section of the Cookie
    Policy.
  </p>
  <h2 id="contact_information">Contact information</h2>
  <ul className="for_boxes cf">
    <li className="one_line_col">
      <ul className="for_boxes">
        <li>
          <div className="iconed icon_owner">
            {/* Owner start */}
            <h3>Owner and Data Controller</h3>
            <p>
              17800 SE Mill Plain Blvd. Suite 190
              <br />
              Vancouver, WA 98683
              <br />
              (888) 678-2476
            </p>
            <p>
              <strong>Owner contact email:</strong> Sales@corehandf.com
            </p>
            {/* Owner end */}
          </div>
        </li>
      </ul>
    </li>
  </ul>
</div>

    )
}
export default PrivacyPolicy ;