import React  from "react";
import Navbar from "../../CommonCompounds/Nav/Nav.jsx";
import Footer from "../../CommonCompounds/Footer/Footer.jsx";
import Leverage from "../../Compounds/Leverage/Leverage.jsx"

function LeveragePage (){
    return (
        <>  
        <Navbar />
        <Leverage />
        <Footer />


        </>
    )
}

export default LeveragePage;