import React from "react";
import { NavLink } from "react-router-dom";

function Customization() {
  return (
    <>
      <>
        <main className="main" id="main">
          <section className="breadcrumbs">
            <ul>
              {/*li><a href="#">Home</a></li>
          <li class="current">Star Trac</li*/}
              <li>
                <span>
                  <span>
                    <a href="https://corehandf.com/" data-wpel-link="internal">
                      Home
                    </a>
                  </span>{" "}
                  »{" "}
                  <span className="breadcrumb_last" aria-current="page">
                    CUSTOMIZATION
                  </span>
                </span>
              </li>{" "}
            </ul>
          </section>
          <section className="section banner">
            <div
              className="single-banner"
              style={{
                background:
                  '#2e2e2e url("") center center no-repeat !important',
              }}
            >
              <div className="single-banner-content">
                <h1 className="banner-title largest">CUSTOMIZATION</h1>
              </div>
            </div>
          </section>
          <section className="section single-content">
            <div className="container">
              <div className="row center-xs">
                <div className="col-xs-2">
                  <div className="single-share">
                    {/*ul class="list share-list">
							<li>Share</li>
							<li>
								<a href="#" class="facebook">
									<i class="fa fa-facebook"></i>
								</a>
							</li>
							<li>
								<a href="#" class="twitter">
									<i class="fa fa-twitter"></i>
								</a>
							</li>
							<li>
								<a href="#" class="google-plus">
									<i class="fa fa-google-plus"></i>
								</a>
							</li>
							<li>
								<a href="#" class="linkedin">
									<i class="fa fa-linkedin"></i>
								</a>
							</li>
							<li class="seperator"></li>
							<li>
								<a href="#">
									<i class="fa fa-envelope"></i>
								</a>
							</li>
						</ul*/}
                  </div>
                </div>

                <div className="container">
                  <div className="row  center-xs">
                    <div className="col-xs-10">
                      <p style={{ textAlign: "left" }}>
                        We know what it takes to build an authentic and
                        meaningful brand with a clear purpose. Our customization
                        program is the perfect way to leverage all that hard
                        work into creating an amazing member experience. Every
                        product offered through Core Health &amp; Fitness has
                        some customizable features; from frame color and finish
                        to upholstery and, in some cases, the ability to add
                        your logo.
                      </p>
                      <h3 style={{ textAlign: "left" }}>
                        <span style={{ color: "#00aeef" }}>
                          <strong>CUSTOMIZATION POLICY</strong>
                        </span>
                      </h3>
                      <p style={{ textAlign: "left" }}>
                        Core Health &amp; Fitness requires a non-refundable 50%
                        down payment for all custom products. Custom orders have
                        a minimum order quantity of 7 units, orders of less than
                        7 units will incur a minimum charge. A purchase order
                        for a custom product contractually obligates the Buyer
                        to take delivery. Customers have opportunity to cancel
                        within 7 business days of purchase, any cancellations
                        outside of the 7-business day window will be subject to
                        a 25% cancellation fee. Core, in its sole discretion,
                        may waive the 50% down payment. If Core has waived the
                        down payment, and Buyer thereafter cancels the order,
                        Buyer agrees to pay a 25% cancellation fee related to
                        the custom portion of the canceled order plus an
                        additional amount of 25% of the total value of the
                        canceled order.
                      </p>
                      <h3 style={{ textAlign: "left" }}>
                        <span style={{ color: "#00aeef" }}>
                          <strong>
                            DIFFERENT CATEGORIES OFFER DIFFERENT OPTIONS
                          </strong>
                        </span>
                      </h3>
                      <p style={{ textAlign: "left" }}>
                        <strong>Standard Stocked Paint Colors</strong>
                      </p>
                      <p>
                        <img
                          fetchpriority="high"
                          decoding="async"
                          className="alignnone wp-image-62970 size-full"
                          src="https://corehandf.com/wp-content/uploads/2022/05/Customization-Table-1.png"
                          alt=""
                          width={600}
                          height={157}
                          srcSet="https://corehandf.com/wp-content/uploads/2022/05/Customization-Table-1.png 600w, https://corehandf.com/wp-content/uploads/2022/05/Customization-Table-1-300x79.png 300w"
                          sizes="(max-width: 600px) 100vw, 600px"
                        />
                      </p>
                      <p>
                        <img
                          decoding="async"
                          className="alignnone wp-image-63090"
                          src="https://corehandf.com/wp-content/uploads/2022/05/60-150x150.png"
                          alt={-60}
                          width={120}
                          height={120}
                          srcSet="https://corehandf.com/wp-content/uploads/2022/05/60-150x150.png 150w, https://corehandf.com/wp-content/uploads/2022/05/60-300x300.png 300w, https://corehandf.com/wp-content/uploads/2022/05/60-100x100.png 100w, https://corehandf.com/wp-content/uploads/2022/05/60.png 597w"
                          sizes="(max-width: 120px) 100vw, 120px"
                        />{" "}
                        <img
                          decoding="async"
                          className="alignnone wp-image-63094"
                          src="https://corehandf.com/wp-content/uploads/2022/05/79-150x150.png"
                          alt={-79}
                          width={120}
                          height={120}
                          srcSet="https://corehandf.com/wp-content/uploads/2022/05/79-150x150.png 150w, https://corehandf.com/wp-content/uploads/2022/05/79-300x300.png 300w, https://corehandf.com/wp-content/uploads/2022/05/79-600x599.png 600w, https://corehandf.com/wp-content/uploads/2022/05/79-100x100.png 100w, https://corehandf.com/wp-content/uploads/2022/05/79.png 625w"
                          sizes="(max-width: 120px) 100vw, 120px"
                        />
                      </p>
                      <p>
                        All other paint colors are subject to our Custom Product
                        Policy. Please contact your Core Health &amp; Fitness
                        representative for details on the customization process
                        and any additional questions regarding product
                        customization not covered in the outline above.
                      </p>
                      <p>
                        <em>
                          Preview our group cycling and strength equipment
                          customization options:
                        </em>
                      </p>
                      <p>
                        {/*HubSpot Call-to-Action Code */}
                        <span
                          id="hs-cta-wrapper-ab436699-5392-4c10-b445-66a47d6049d6"
                          className="hs-cta-wrapper"
                        >
                          <span
                            id="hs-cta-ab436699-5392-4c10-b445-66a47d6049d6"
                            className="hs-cta-node hs-cta-ab436699-5392-4c10-b445-66a47d6049d6"
                          >
                            {/* [if lte IE 8]>


<div id="hs-cta-ie-element"></div>


<![endif]*/}
                           <NavLink
  to="/bikeCustomization"
 
>
  <img
   
    className="hs-cta-img"
    style={{ borderWidth: 0 }}
    src="https://no-cache.hubspot.com/cta/default/2450902/ab436699-5392-4c10-b445-66a47d6049d6.png"
    alt="BUILD YOUR SCHWINN BIKE →"
  />
</NavLink>

                          </span>
                        </span>
                        {/* end HubSpot Call-to-Action Code */}
                      </p>
                      <p>
                        {/*HubSpot Call-to-Action Code */}
                        <span
                          id="hs-cta-wrapper-08aab1d7-005e-4e6d-985e-1410993f0da1"
                          className="hs-cta-wrapper"
                        >
                          <span
                            id="hs-cta-08aab1d7-005e-4e6d-985e-1410993f0da1"
                            className="hs-cta-node hs-cta-08aab1d7-005e-4e6d-985e-1410993f0da1"
                          >
                            {/* [if lte IE 8]>


<div id="hs-cta-ie-element"></div>


<![endif]*/}
                            <NavLink
                              to=""
                              target="_blank"
                              rel="noopener noreferrer nofollow external"
                              data-wpel-link="external"
                            >
                              <img
                                decoding="async"
                                id="hs-cta-img-08aab1d7-005e-4e6d-985e-1410993f0da1"
                                className="hs-cta-img"
                                style={{ borderWidth: 0 }}
                                src="https://no-cache.hubspot.com/cta/default/2450902/08aab1d7-005e-4e6d-985e-1410993f0da1.png"
                                alt="BUILD YOUR NAUTILUS STRENGTH EQUIPMENT →"
                              />
                            </NavLink>
                          </span>
                        </span>
                        {/* end HubSpot Call-to-Action Code */}
                      </p>
                      <h3 />
                      <h3 style={{ textAlign: "left" }}>
                        <span style={{ color: "#00aeef" }}>
                          <strong>
                            BUT CUSTOMIZATION CAN BE MORE THAN BRAND IDENTITY
                          </strong>
                        </span>
                      </h3>
                      <p style={{ textAlign: "left" }}>
                        Used thoughtfully, customized products paired with
                        facility layout can create a progressive flow, helping
                        your members plan their fitness journey and build
                        community.
                      </p>
                      <p style={{ textAlign: "left" }}>
                        From concept to install, Core Health &amp; Fitness’
                        consultative approach can help you reach your facility
                        goals.
                      </p>
                      <p>
                        <a href="" data-wpel-link="internal">
                          <img
                            loading="lazy"
                            decoding="async"
                            className="aligncenter wp-image-63077 size-thumbnail"
                            src="https://corehandf.com/wp-content/uploads/2022/05/Custom-Bike-150x150.png"
                            alt="Z Bike Custom"
                            width={150}
                            height={150}
                            srcSet="https://corehandf.com/wp-content/uploads/2022/05/Custom-Bike-150x150.png 150w, https://corehandf.com/wp-content/uploads/2022/05/Custom-Bike-300x300.png 300w, https://corehandf.com/wp-content/uploads/2022/05/Custom-Bike-768x768.png 768w, https://corehandf.com/wp-content/uploads/2022/05/Custom-Bike-600x600.png 600w, https://corehandf.com/wp-content/uploads/2022/05/Custom-Bike-100x100.png 100w, https://corehandf.com/wp-content/uploads/2022/05/Custom-Bike.png 852w"
                            sizes="(max-width: 150px) 100vw, 150px"
                          />
                        </a>{" "}
                        <a
                          href=""
                          data-wpel-link="internal"
                        >
                          <img
                            loading="lazy"
                            decoding="async"
                            className="aligncenter size-thumbnail wp-image-45986"
                            src=""
                            alt="Nautilus_Customization"
                            width={150}
                            height={150}
                            srcSet="https://corehandf.com/wp-content/uploads/2021/04/Nautilus_Customization-150x150.jpg 150w, https://corehandf.com/wp-content/uploads/2021/04/Nautilus_Customization-300x300.jpg 300w, https://corehandf.com/wp-content/uploads/2021/04/Nautilus_Customization-100x100.jpg 100w, https://corehandf.com/wp-content/uploads/2021/04/Nautilus_Customization.jpg 500w"
                            sizes="(max-width: 150px) 100vw, 150px"
                          />
                        </a>{" "}
                        <a
                          href="https://corehandf.com/wp-content/uploads/2021/04/Throwdown_Custom-Dumbbell.jpg"
                          data-wpel-link="internal"
                        >
                          <img
                            loading="lazy"
                            decoding="async"
                            className="aligncenter size-thumbnail wp-image-45991"
                            src="https://corehandf.com/wp-content/uploads/2021/04/Throwdown_Custom-Dumbbell-150x150.jpg"
                            alt="Throwdown_Custom-Dumbbell"
                            width={150}
                            height={150}
                            srcSet="https://corehandf.com/wp-content/uploads/2021/04/Throwdown_Custom-Dumbbell-150x150.jpg 150w, https://corehandf.com/wp-content/uploads/2021/04/Throwdown_Custom-Dumbbell-300x300.jpg 300w, https://corehandf.com/wp-content/uploads/2021/04/Throwdown_Custom-Dumbbell-100x100.jpg 100w, https://corehandf.com/wp-content/uploads/2021/04/Throwdown_Custom-Dumbbell.jpg 500w"
                            sizes="(max-width: 150px) 100vw, 150px"
                          />
                        </a>{" "}
                        <a
                          href="https://corehandf.com/wp-content/uploads/2021/04/UFC-FiT-Custom-Platform-Rendering.jpg"
                          data-wpel-link="internal"
                        >
                          <img
                            loading="lazy"
                            decoding="async"
                            className="aligncenter size-thumbnail wp-image-45996"
                            src="https://corehandf.com/wp-content/uploads/2021/04/UFC-FiT-Custom-Platform-Rendering-150x150.jpg"
                            alt="UFC-FiT-Custom-Platform-Rendering"
                            width={150}
                            height={150}
                            srcSet="https://corehandf.com/wp-content/uploads/2021/04/UFC-FiT-Custom-Platform-Rendering-150x150.jpg 150w, https://corehandf.com/wp-content/uploads/2021/04/UFC-FiT-Custom-Platform-Rendering-300x300.jpg 300w, https://corehandf.com/wp-content/uploads/2021/04/UFC-FiT-Custom-Platform-Rendering-100x100.jpg 100w, https://corehandf.com/wp-content/uploads/2021/04/UFC-FiT-Custom-Platform-Rendering.jpg 500w"
                            sizes="(max-width: 150px) 100vw, 150px"
                          />
                        </a>{" "}
                        <a
                          href="https://corehandf.com/wp-content/uploads/2021/04/UFC-Gym-6-Heavy-Bag-Customization.jpg"
                          data-wpel-link="internal"
                        >
                          <img
                            loading="lazy"
                            decoding="async"
                            className="aligncenter size-thumbnail wp-image-46001"
                            src="https://corehandf.com/wp-content/uploads/2021/04/UFC-Gym-6-Heavy-Bag-Customization-150x150.jpg"
                            alt="UFC-Gym---6'-Heavy-Bag-Customization"
                            width={150}
                            height={150}
                            srcSet="https://corehandf.com/wp-content/uploads/2021/04/UFC-Gym-6-Heavy-Bag-Customization-150x150.jpg 150w, https://corehandf.com/wp-content/uploads/2021/04/UFC-Gym-6-Heavy-Bag-Customization-300x300.jpg 300w, https://corehandf.com/wp-content/uploads/2021/04/UFC-Gym-6-Heavy-Bag-Customization-100x100.jpg 100w, https://corehandf.com/wp-content/uploads/2021/04/UFC-Gym-6-Heavy-Bag-Customization.jpg 500w"
                            sizes="(max-width: 150px) 100vw, 150px"
                          />
                        </a>{" "}
                        <a
                          href="https://corehandf.com/wp-content/uploads/2021/04/Vitesse-Custom-Impact_Yellow.jpg"
                          data-wpel-link="internal"
                        >
                          <img
                            loading="lazy"
                            decoding="async"
                            className="aligncenter size-thumbnail wp-image-46006"
                            src="https://corehandf.com/wp-content/uploads/2021/04/Vitesse-Custom-Impact_Yellow-150x150.jpg"
                            alt="Vitesse-Custom-Impact_Yellow"
                            width={150}
                            height={150}
                            srcSet="https://corehandf.com/wp-content/uploads/2021/04/Vitesse-Custom-Impact_Yellow-150x150.jpg 150w, https://corehandf.com/wp-content/uploads/2021/04/Vitesse-Custom-Impact_Yellow-300x300.jpg 300w, https://corehandf.com/wp-content/uploads/2021/04/Vitesse-Custom-Impact_Yellow-100x100.jpg 100w, https://corehandf.com/wp-content/uploads/2021/04/Vitesse-Custom-Impact_Yellow.jpg 500w"
                            sizes="(max-width: 150px) 100vw, 150px"
                          />
                        </a>{" "}
                        <a
                          href=""
                          data-wpel-link="internal"
                        >
                          <img
                            loading="lazy"
                            decoding="async"
                            className="aligncenter size-thumbnail wp-image-46020"
                            src="https://corehandf.com/wp-content/uploads/2021/04/Install-2-150x150.png"
                            alt="Crunch Dumbbells"
                            width={150}
                            height={150}
                            srcSet="https://corehandf.com/wp-content/uploads/2021/04/Install-2-150x150.png 150w, https://corehandf.com/wp-content/uploads/2021/04/Install-2-300x300.png 300w, https://corehandf.com/wp-content/uploads/2021/04/Install-2-100x100.png 100w, https://corehandf.com/wp-content/uploads/2021/04/Install-2.png 500w"
                            sizes="(max-width: 150px) 100vw, 150px"
                          />
                        </a>{" "}
                        <a
                          href=""
                          data-wpel-link="internal"
                        >
                          <img
                            loading="lazy"
                            decoding="async"
                            className="aligncenter wp-image-63044 size-thumbnail"
                            src="https://corehandf.com/wp-content/uploads/2022/05/Crunch-Nautilus-SVA-Platform-2-e1652216903829-150x150.jpg"
                            alt="Crunch Nautilus SVA Platform"
                            width={150}
                            height={150}
                            srcSet="https://corehandf.com/wp-content/uploads/2022/05/Crunch-Nautilus-SVA-Platform-2-e1652216903829-150x150.jpg 150w, https://corehandf.com/wp-content/uploads/2022/05/Crunch-Nautilus-SVA-Platform-2-e1652216903829-300x300.jpg 300w, https://corehandf.com/wp-content/uploads/2022/05/Crunch-Nautilus-SVA-Platform-2-e1652216903829-768x767.jpg 768w, https://corehandf.com/wp-content/uploads/2022/05/Crunch-Nautilus-SVA-Platform-2-e1652216903829-600x599.jpg 600w, https://corehandf.com/wp-content/uploads/2022/05/Crunch-Nautilus-SVA-Platform-2-e1652216903829-100x100.jpg 100w, https://corehandf.com/wp-content/uploads/2022/05/Crunch-Nautilus-SVA-Platform-2-e1652216903829.jpg 988w"
                            sizes="(max-width: 150px) 100vw, 150px"
                          />
                        </a>
                      </p>
                      <p>
                        <a className="cta_button" href="#request-a-quote-form">
                          Start Your Consultation
                        </a>
                      </p>
                      <div
                        id="request-a-quote-form"
                        className="request-a-quote-form"
                      >
                        {/* [if lte IE 8]>

<![endif]*/}
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        {/* /.main */}
      </>
    </>
  );
}

export default Customization;
