import React  from "react";
import Form from "../../CommonCompounds/Form/Form";
import ProductGallery from "../../CommonCompounds/CarourselProduct/CarourselProduct";


function MultiUnitHousing(){
    return(
        <>
        <main className="main" id="main">
  <section className="breadcrumbs">
    <ul>
      {/*li><a href="#">Home</a></li>
          <li class="current">Star Trac</li*/}
      <li>
        <span>
          <span>
            <a href="https://corehandf.com/" data-wpel-link="internal">
              Home
            </a>
          </span>{" "}
          »{" "}
          <span className="breadcrumb_last" aria-current="page">
            Multi-Unit Housing Market
          </span>
        </span>
      </li>
    </ul>
  </section>
  <section
    className="section_slide solutions-banner row"
    style={{
      backgroundImage:
        "url(https://corehandf.com/wp-content/uploads/2021/12/Core-Hospitality-Facility-7-Lifestyle-Image.png)"
    }}
  >
    <div className="col-xs-12 col-sm-8 col-md-8 col-lg-5">
      <h1>
        <strong>Multi-Unit</strong> Housing Market
      </h1>
    </div>
  </section>
  <section className="section single-content">
    <div className="container small">
      <div className="row">
        <div className="col-xs-12">
          <p>
            Residents of Multi-Family Housing properties have begun to seek out
            those that have a fitness space that not only has a substantial
            footprint, but also mirrors the quality they are seeking within
            their potential residents. With this in mind, just as you worked
            with your facility’s designer to select the exact materials to give
            your property the feel your residents will expect, so too will our
            industry experts guide you through selecting the pieces of equipment
            that reflect your facility’s quality. When you request a
            consultation, the expert that you speak with will get to know your
            goals and the expectations of your residents, as well as guide you
            through the following items:
          </p>
          <p>
            • Layout and Flow
            <br /> • Product Selection
            <br /> • Technology &amp; Connected Fitness
            <br /> • Budget
            <br /> • Financing
          </p>
          <p>
            As industry leaders, we know that residents of Student Housing are
            expecting many of the same amenities as those within Multi-Family
            Housing. Your residents are eager to move in, make connections and
            build a routine that sets themselves up for success. Our equipment
            is not only set up to provide the workout your residents have come
            to expect, but it also lends itself to the wearable technology of
            their choice so they can stay connected with friends both new and
            old. Start a consultation and your local representative will get to
            know your unique facility and the needs of your residents so that
            you can provide an atmosphere that builds on your campus and school
            pride.{" "}
          </p>
          <a className="cta-button" href="#embed-form">
            Request a Consultation
          </a>
          <div
            id="metaslider-id-57831"
            style={{ width: "100%" }}
            className="ml-slider-3-90-1 metaslider metaslider-flex metaslider-57831 ml-slider ms-theme-cubic has-dots-nav"
            role="region"
            aria-roledescription="Slideshow"
            aria-label="Industry: Multi-Unit Housing"
          >
            <div id="metaslider_container_57831">
              <div id="metaslider_57831">
                <ul className="slides">
                  <li
                    style={{ display: "block", width: "100%" }}
                    className="slide-57834 ms-image "
                    aria-roledescription="slide"
                    aria-label="slide-57834"
                  >
                    <img
                      src="https://corehandf.com/wp-content/uploads/2021/10/Waterside-Apartments-Greenville-SC-Star-Trac-4-Series-2-770x500.webp"
                      height={500}
                      width={770}
                      alt=""
                      className="slider-57831 slide-57834"
                      title="Waterside Apartments Greenville, SC Star Trac 4 Series 2"
                    />
                  </li>
                  <li
                    style={{ display: "none", width: "100%" }}
                    className="slide-58129 ms-image "
                    aria-roledescription="slide"
                    aria-label="slide-58129"
                  >
                    <img
                      src="https://corehandf.com/wp-content/uploads/2021/12/Harbor-Landing-Glen-Cove-NY-Nautilus-Instinct-770x500.jpg"
                      height={500}
                      width={770}
                      alt="Harbor Landing Glen Cove, NY Nautilus Instinct"
                      className="slider-57831 slide-58129"
                      title="Harbor Landing Glen Cove, NY Nautilus Instinct"
                    />
                  </li>
                  <li
                    style={{ display: "none", width: "100%" }}
                    className="slide-61164 ms-image "
                    aria-roledescription="slide"
                    aria-label="slide-61164"
                  >
                    <img
                      src="https://corehandf.com/wp-content/uploads/2021/11/Multi-Unit-Housing-scaled-770x500.jpg"
                      height={500}
                      width={770}
                      alt="Multi-Unit Housing"
                      className="slider-57831 slide-61164"
                      title="Multi-Unit Housing"
                    />
                  </li>
                  <li
                    style={{ display: "none", width: "100%" }}
                    className="slide-57832 ms-image "
                    aria-roledescription="slide"
                    aria-label="slide-57832"
                  >
                    <img
                      src="https://corehandf.com/wp-content/uploads/2020/09/Image-Install-Multi-Family-Renheng-Residential-Club-Star-Trac-4-Series-4TR-scaled-770x500.jpeg"
                      height={500}
                      width={770}
                      alt="Renheng Residential Club | Zhijian, China"
                      className="slider-57831 slide-57832"
                      title="Image - Install - Multi Family - Renheng Residential Club - Star Trac - 4 Series - 4TR"
                    />
                  </li>
                  <li
                    style={{ display: "none", width: "100%" }}
                    className="slide-58127 ms-image "
                    aria-roledescription="slide"
                    aria-label="slide-58127"
                  >
                    <img
                      src="https://corehandf.com/wp-content/uploads/2021/12/The-Cottenham-Exchange-at-Waterways-Schwinn-AC-Power-770x500.jpg"
                      height={500}
                      width={770}
                      alt="The Cottenham Exchange at Waterways Schwinn AC Power"
                      className="slider-57831 slide-58127"
                      title="The Cottenham Exchange at Waterways Schwinn AC Power"
                    />
                  </li>
                  <li
                    style={{ display: "none", width: "100%" }}
                    className="slide-58128 ms-image "
                    aria-roledescription="slide"
                    aria-label="slide-58128"
                  >
                    <img
                      src="https://corehandf.com/wp-content/uploads/2021/12/Sharon-Green-Menlo-CA-Star-Trac-2-770x500.jpg"
                      height={500}
                      width={770}
                      alt="Sharon Green Menlo, CA Star Trac 2"
                      className="slider-57831 slide-58128"
                      title="Sharon Green Menlo, CA Star Trac 2"
                    />
                  </li>
                  <li
                    style={{ display: "none", width: "100%" }}
                    className="slide-57833 ms-image "
                    aria-roledescription="slide"
                    aria-label="slide-57833"
                  >
                    <img
                      src="https://corehandf.com/wp-content/uploads/2021/10/Harbor-Landing-Glen-Cove-NY-Star-Trac-770x500.webp"
                      height={500}
                      width={770}
                      alt=""
                      className="slider-57831 slide-57833"
                      title="Harbor Landing Glen Cove, NY Star Trac"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="centers-section" style={{ display: "none" }}>
    <div className="swiper">
      <div className="swiper-container">
        <div className="swiper-wrapper">
          <div
            className="swiper-slide"
            style={{
              backgroundImage:
                'url("https://corehandf.com/wp-content/uploads/2021/09/Renheng.png")'
            }}
          >
            <div className="caption"></div>
          </div>
          <div
            className="swiper-slide"
            style={{
              backgroundImage:
                'url("https://corehandf.com/wp-content/uploads/2021/09/Harbor-Landing.png")'
            }}
          >
            <div className="caption"></div>
          </div>
          <div
            className="swiper-slide"
            style={{
              backgroundImage:
                'url("https://corehandf.com/wp-content/uploads/2021/09/Waterside.png")'
            }}
          >
            <div className="caption"></div>
          </div>
        </div>
      </div>
      <a href="#" className="swiper-button-prev  3" />
      <a href="#" className="swiper-button-next  3" />
    </div>
  </section>
<section >
   <ProductGallery />
</section>
<section className="request-a-quote-form">
    <div className="container">
      <h2 id="request-a-quote-form" className="section-title"><span>Start Your Consultation Today</span></h2>
      <div className="row center-xs">
        <div className="col-md-6 offset-md-6">
        <Form />
        </div>
      </div>
    </div>
  </section>
</main>

        </>
    )
}

export default MultiUnitHousing;