import React from "react";



function NotFound (){
    return(
        <>

 <div className="contanier">
 <h1 className="text-center" style={{ paddingBottom: '200px', paddingTop:"200px", color: "red"}}>
  Exit Page NotFound
</h1>

 </div>
        </>
    )
}

export default NotFound;