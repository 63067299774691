import React from "react";


function Warranty(){
    return(
        <>
  <main className="main" id="main">
        {/* <!-- Breadcrumbs Section --> */}
        <section class="breadcrumbs">
            <ul>
                <li><a href="#">Home</a></li>
                <li class="current">
                    <a href="https://corehandf.com/" data-wpel-link="internal">Home</a> »
                    <span class="breadcrumb_last" aria-current="page">Warranty</span>
                </li>
            </ul>
        </section>

        {/* <!-- Banner Section --> */}
        <section class="section banner">
            <div class="single-banner">
                <div class="single-banner-content ">
                    <h1 class="banner-title largest">Warranty</h1>
                </div>
            </div>
        </section>

        {/* <!-- Content Section --> */}
        <section class="section single-content">
            <div class="container">
                <div class="row center-xs">
                <div className="col-xs-10">
  <p>
    Each Core Health &amp; Fitness product will carry its own limited warranty
    as set forth on the official Core Health &amp; Fitness website. Such
    warranty will be buyer’s sole and exclusive remedy for any breach of
    warranty. Core Health &amp; Fitness disclaims all other warranties expressed
    or implied or statutory, including any warranty of merchantability, any
    warranty of fitness for a particular purpose and any implied warranties
    arising from a course of dealing or usage of trade. This warranty supersedes
    all other warranties, including any warranties based on oral
    representations.
  </p>
  <p>
    This warranty extends only to the original end user customer and is not
    transferable. This warranty does not cover defects caused by negligence;
    improper maintenance; improper storage; misuse; installation not in
    accordance with Core Health &amp; Fitness’ printed instructions; abuse;
    normal wear and tear; contact with liquids; application other than intended
    use; or installation of unapproved third party products.
  </p>
  <p>
    Core Health &amp; Fitness’ sole liability under this or any other warranty
    expressed or implied is limited to repair or replacement or refund as
    determined solely by Core Health &amp; Fitness. Repair, replacement or
    refund as determined solely by core health &amp; fitness will be the sole
    and exclusive remedies for breach of warranty or any other legal theory
    including theories for the recovery of consequential or incidental damages.
    Some states do not allow the exclusion or limitation of incidental and
    consequential damages, so the above limitation may not apply.
  </p>
  <h3>
    <a
      href="https://share.pickit.com/file/documents/64f89b8de8e4f31befa80b28"
      data-wpel-link="external"
      target="_blank"
      rel="nofollow external noopener noreferrer"
    >
      <span style={{ color: "#00ccff" }}>
        <strong>DOWNLOAD WARRANTY MATRIX</strong>
      </span>
    </a>
  </h3>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
</div>

                </div>
            </div>
        </section>
    </main>

        </>
    )
}

export default Warranty;