import React  from "react";
import ProductGallery from "../../CommonCompounds/CarourselProduct/CarourselProduct";
import Form from "../../CommonCompounds/Form/Form";




function GovernmentMilitary(){
    return(
        <>
        <main className="main" id="main">
  <section className="breadcrumbs">
    <ul>
      {/*li><a href="#">Home</a></li>
          <li class="current">Star Trac</li*/}
      <li>
        <span>
          <span>
            <a href="https://corehandf.com/" data-wpel-link="internal">
              Home
            </a>
          </span>{" "}
          »{" "}
          <span className="breadcrumb_last" aria-current="page">
            Government &amp; Military
          </span>
        </span>
      </li>
    </ul>
  </section>
  <section
    className="section_slide solutions-banner row"
    style={{
      backgroundImage:
        "url(https://corehandf.com/wp-content/uploads/2022/03/Core-Military-Install-001-Lifestyle-Image-scaled.jpg)"
    }}
  >
    <div className="col-xs-12 col-sm-8 col-md-8 col-lg-5">
      <h1>
        <strong>Government</strong> &amp; Military
      </h1>
    </div>
  </section>
  <section className="section single-content">
    <div className="container small">
      <div className="row">
        <div className="col-xs-12">
          <p>
            To those of you who serve around the world, we at Core Health &amp;
            Fitness thank you.
          </p>
          <p>
            When it comes to selecting the equipment that’s right for your
            needs, there is no better partner than the one that can provide the
            fitness and conditioning solutions your mission requires. Core
            Health &amp; Fitness’ portfolio of commercial brands – StairMaster,
            Nautilus, Star Trac, Throwdown and Schwinn – are your single source
            for fitness equipment, instructor education and support delivered
            Globally. Below are a few of the items you’ll be guided through:
          </p>
          <p>
            • 2D Rendering
            <br /> • 3D Layout
            <br /> • Budget
            <br /> • Financing
          </p>
          <p>
            When you request a consultation, your regional rep will get to know
            the requirements of your facility and guide you through selecting
            the solutions that will keep your members in peak condition. Below
            are just a few of the spaces we’ve been able to collaborate with and
            share our passion for fitness.
          </p>
          <a className="cta-button" href="#embed-form">
            Request A Consultation
          </a>
          <div
            id="metaslider-id-57849"
            style={{ width: "100%" }}
            className="ml-slider-3-90-1 metaslider metaslider-flex metaslider-57849 ml-slider ms-theme-cubic has-dots-nav"
            role="region"
            aria-roledescription="Slideshow"
            aria-label="Industry: Government & Military"
          >
            <div id="metaslider_container_57849">
              <div id="metaslider_57849" className="flexslider">
                <ul className="slides">
                  <li
                    style={{ display: "block", width: "100%" }}
                    className="slide-77236 ms-image "
                    aria-roledescription="slide"
                    aria-label="slide-77236"
                  >
                    <img
                      src="https://corehandf.com/wp-content/uploads/2022/11/JL-Int-at-Ft-Drum-1.jpg"
                      height={500}
                      width={770}
                      alt="Stairmaster Jacob's Ladder"
                      className="slider-57849 slide-77236"
                      title="JL Int at Ft Drum"
                    />
                  </li>
                  <li
                    style={{ display: "none", width: "100%" }}
                    className="slide-60980 ms-image "
                    aria-roledescription="slide"
                    aria-label="slide-60980"
                  >
                    <img
                      src="https://corehandf.com/wp-content/uploads/2022/02/MicrosoftTeams-image-7-770x500.jpg"
                      height={500}
                      width={770}
                      alt="Government Military"
                      className="slider-57849 slide-60980"
                      title="Government Military"
                    />
                  </li>
                  <li
                    style={{ display: "none", width: "100%" }}
                    className="slide-60979 ms-image "
                    aria-roledescription="slide"
                    aria-label="slide-60979"
                  >
                    <img
                      src="https://corehandf.com/wp-content/uploads/2022/02/MicrosoftTeams-image-8-770x500.jpg"
                      height={500}
                      width={770}
                      alt="Government Military"
                      className="slider-57849 slide-60979"
                      title="Government Military"
                    />
                  </li>
                  <li
                    style={{ display: "none", width: "100%" }}
                    className="slide-57852 ms-image "
                    aria-roledescription="slide"
                    aria-label="slide-57852"
                  >
                    <img
                      src="https://corehandf.com/wp-content/uploads/2021/11/Government-and-Military-770x500.webp"
                      height={500}
                      width={770}
                      alt="Government and Military"
                      className="slider-57849 slide-57852"
                      title="Government and Military"
                    />
                  </li>
                  <li
                    style={{ display: "none", width: "100%" }}
                    className="slide-57980 ms-image "
                    aria-roledescription="slide"
                    aria-label="slide-57980"
                  >
                    <img
                      src="https://corehandf.com/wp-content/uploads/2021/12/US-CBP-Field-Office-Nautilus-Throwdown-770x500.jpg"
                      height={500}
                      width={770}
                      alt="US CBP Field Office Nautilus Throwdown"
                      className="slider-57849 slide-57980"
                      title="US CBP Field Office Nautilus Throwdown"
                    />
                  </li>
                  <li
                    style={{ display: "none", width: "100%" }}
                    className="slide-60982 ms-image "
                    aria-roledescription="slide"
                    aria-label="slide-60982"
                  >
                    <img
                      src="https://corehandf.com/wp-content/uploads/2022/02/MicrosoftTeams-image-5-1-770x500.jpg"
                      height={500}
                      width={770}
                      alt="Government Military"
                      className="slider-57849 slide-60982"
                      title="Government Military"
                    />
                  </li>
                  <li
                    style={{ display: "none", width: "100%" }}
                    className="slide-58002 ms-image "
                    aria-roledescription="slide"
                    aria-label="slide-58002"
                  >
                    <img
                      src="https://corehandf.com/wp-content/uploads/2021/12/Stairmaster-HIITMILLX-770x500.jpg"
                      height={500}
                      width={770}
                      alt="HIITMill X Military"
                      className="slider-57849 slide-58002"
                      title="Stairmaster HIITMILLX"
                    />
                  </li>
                  <li
                    style={{ display: "none", width: "100%" }}
                    className="slide-58007 ms-image "
                    aria-roledescription="slide"
                    aria-label="slide-58007"
                  >
                    <img
                      src="https://corehandf.com/wp-content/uploads/2021/12/Boxmaster-Offutt-AFB-770x500.jpg"
                      height={500}
                      width={770}
                      alt="Boxmaster Offutt AFB"
                      className="slider-57849 slide-58007"
                      title="Boxmaster Offutt AFB"
                    />
                  </li>
                  <li
                    style={{ display: "none", width: "100%" }}
                    className="slide-60981 ms-image "
                    aria-roledescription="slide"
                    aria-label="slide-60981"
                  >
                    <img
                      src="https://corehandf.com/wp-content/uploads/2022/02/MicrosoftTeams-image-6-770x500.jpg"
                      height={500}
                      width={770}
                      alt="Government Military"
                      className="slider-57849 slide-60981"
                      title="Government Military"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="centers-section" style={{ display: "none" }}>
    <div className="swiper">
      <div className="swiper-container">
        <div className="swiper-wrapper">
          <div
            className="swiper-slide"
            style={{
              backgroundImage:
                'url("https://corehandf.com/wp-content/uploads/2021/11/Government-and-Military.webp")'
            }}
          >
            <div className="caption"></div>
          </div>
        </div>
      </div>
      <a href="#" className="swiper-button-prev just-one 1" />
      <a href="#" className="swiper-button-next just-one 1" />
    </div>
  </section>
  <section>
    <ProductGallery />
     </section>
     <section className="request-a-quote-form">
    <div className="container">
      <h2 id="request-a-quote-form" className="section-title"><span>Start Your Consultation Today</span></h2>
      <div className="row center-xs">
        <div className="col-md-6 offset-md-6">
        <Form />
        </div>
      </div>
    </div>
  </section>
 
</main>


        </>
    )
}

export default GovernmentMilitary ;