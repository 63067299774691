import React from "react";
import ProductGallery from "../../CommonCompounds/CarourselProduct/CarourselProduct";
import Form from "../../CommonCompounds/Form/Form";

function Hospitality(){
    return (
        <>
        <main className="main" id="main">
  <section className="breadcrumbs">
    <ul>
      {/*li><a href="#">Home</a></li>
          <li class="current">Star Trac</li*/}
      <li>
        <span>
          <span>
            <a href="https://corehandf.com/" data-wpel-link="internal">
              Home
            </a>
          </span>{" "}
          »{" "}
          <span className="breadcrumb_last" aria-current="page">
            Hospitality
          </span>
        </span>
      </li>
    </ul>
  </section>
  <section
    className="section_slide solutions-banner row"
    style={{
      backgroundImage:
        "url(https://corehandf.com/wp-content/uploads/2022/03/Core-Hospitailty-Resort-1-Lifestyle-Image.png)"
    }}
  >
    <div className="col-xs-12 col-sm-8 col-md-8 col-lg-5">
      <h1>
        <strong>Hospitality</strong>
      </h1>
    </div>
  </section>
  <section className="section single-content">
    <div className="container small">
      <div className="row">
        <div className="col-xs-12">
          <p>
            When it comes to designing a brand-new property or upgrading your
            fitness offerings within an existing one, Core has the solutions to
            suit your guest’s every need. With a One Team mentality, we go above
            and beyond to make sure the flow and quality of your fitness center
            is another reason your guests come back time and time again. Core’s
            portfolio of commercial brands are installed worldwide, catering to
            properties spanning from premium resorts to local hospitality
            chains.
          </p>
          <p>
            With a global network of partners, we’re able to guide you through
            space design, equipment selection, the importance of flooring and so
            much more. When you request a consultation, your regional rep will
            get to know your goals and understand the guests that your property
            expects to attract. Below are a few of the items you’ll be guided
            through:
          </p>
          <p>
            • 2D Rendering
            <br /> • 3D Layout
            <br /> • Budget
            <br /> • Financing
          </p>
          <p>
            Our brands – Nautilus, Star Trac, StairMaster Throwdown and Schwinn
            – allow us to tailor the experience of your facility so we can meet
            your exact needs. Your regional rep will utilize years of industry
            knowledge along with the latest FISA data to ensure you’re
            maximizing your space and are on trend with what’s being installed
            in your market and around the world. Below are just a few of the
            fitness spaces we’ve been able to collaborate with and share our
            passion for fitness.
          </p>
          <a className="cta-button" href="#embed-form">
            Request a Consultation
          </a>
          <div
            id="metaslider-id-57835"
            style={{ width: "100%" }}
            className="ml-slider-3-90-1 metaslider metaslider-flex metaslider-57835 ml-slider ms-theme-cubic has-dots-nav"
            role="region"
            aria-roledescription="Slideshow"
            aria-label="Industry: Hospitality"
          >
            <div id="metaslider_container_57835">
              <div id="metaslider_57835">
                <ul className="slides">
                  <li
                    style={{ display: "block", width: "100%" }}
                    className="slide-57837 ms-image "
                    aria-roledescription="slide"
                    aria-label="slide-57837"
                  >
                    <img
                      src="https://corehandf.com/wp-content/uploads/2021/10/Hotel-Stay-Denmark-Nautilus-Multi-2-648x421.jpg"
                      height={500}
                      width={770}
                      alt="Hotel Stay Denmark"
                      className="slider-57835 slide-57837"
                      title="Hotel Stay Denmark Nautilus Multi 2"
                    />
                  </li>
                  <li
                    style={{ display: "none", width: "100%" }}
                    className="slide-58112 ms-image "
                    aria-roledescription="slide"
                    aria-label="slide-58112"
                  >
                    <img
                      src="https://corehandf.com/wp-content/uploads/2021/12/Marriott-Nautilus-Benches-770x500.jpg"
                      height={500}
                      width={770}
                      alt="Marriott Nautilus Benches"
                      className="slider-57835 slide-58112"
                      title="Marriott Nautilus Benches"
                    />
                  </li>
                  <li
                    style={{ display: "none", width: "100%" }}
                    className="slide-57838 ms-image "
                    aria-roledescription="slide"
                    aria-label="slide-57838"
                  >
                    <img
                      src="https://corehandf.com/wp-content/uploads/2021/10/Intercontinental-Hotel-Star-Trac-4TR-3-616x400.jpg"
                      height={500}
                      width={770}
                      alt=""
                      className="slider-57835 slide-57838"
                      title="Intercontinental Hotel"
                    />
                  </li>
                  <li
                    style={{ display: "none", width: "100%" }}
                    className="slide-57839 ms-image "
                    aria-roledescription="slide"
                    aria-label="slide-57839"
                  >
                    <img
                      src="https://corehandf.com/wp-content/uploads/2021/10/Holiday-Inn-Star-Trac-770x500.jpg"
                      height={500}
                      width={770}
                      alt="Holiday Inn"
                      className="slider-57835 slide-57839"
                      title="Holiday Inn Star Trac"
                    />
                  </li>
                  <li
                    style={{ display: "none", width: "100%" }}
                    className="slide-58113 ms-image "
                    aria-roledescription="slide"
                    aria-label="slide-58113"
                  >
                    <img
                      src="https://corehandf.com/wp-content/uploads/2021/12/Intercontinental-Hotel-Star-Trac-4-Series-2-770x500.jpg"
                      height={500}
                      width={770}
                      alt="Intercontinental Hotel Star Trac 4 Series 2"
                      className="slider-57835 slide-58113"
                      title="Intercontinental Hotel Star Trac 4 Series 2"
                    />
                  </li>
                  <li
                    style={{ display: "none", width: "100%" }}
                    className="slide-58114 ms-image "
                    aria-roledescription="slide"
                    aria-label="slide-58114"
                  >
                    <img
                      src="https://corehandf.com/wp-content/uploads/2021/12/Bristol-Hotel-Star-Trac-e1638813149221-770x500.jpg"
                      height={500}
                      width={770}
                      alt="Bristol Hotel Star Trac"
                      className="slider-57835 slide-58114"
                      title="Bristol Hotel Star Trac"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="centers-section" style={{ display: "none" }}>
    <div className="swiper">
      <div className="swiper-container">
        <div className="swiper-wrapper">
          <div
            className="swiper-slide"
            style={{
              backgroundImage:
                'url("https://corehandf.com/wp-content/uploads/2021/10/Hotel-Stay-Denmark-Nautilus-Multi-2.jpg")'
            }}
          >
            <div className="caption"></div>
          </div>
          <div
            className="swiper-slide"
            style={{
              backgroundImage:
                'url("https://corehandf.com/wp-content/uploads/2021/10/Intercontinental-Hotel-Star-Trac-4TR-3.jpg")'
            }}
          >
            <div className="caption"></div>
          </div>
          <div
            className="swiper-slide"
            style={{
              backgroundImage:
                'url("https://corehandf.com/wp-content/uploads/2021/10/Holiday-Inn-Star-Trac.jpg")'
            }}
          >
            <div className="caption"></div>
          </div>
        </div>
      </div>
      <a href="#" className="swiper-button-prev  3" />
      <a href="#" className="swiper-button-next  3" />
    </div>
  </section>
 <section>
 <ProductGallery />
 </section>
 <section className="request-a-quote-form">
    <div className="container">
      <h2 id="request-a-quote-form" className="section-title"><span>Start Your Consultation Today</span></h2>
      <div className="row center-xs">
        <div className="col-md-6 offset-md-6">
        <Form />
        </div>
      </div>
    </div>
  </section>
</main>

        </>
    )
}

export default Hospitality ;