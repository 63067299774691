import React from "react";
import Blog from "../../Compounds/Blog/Blog";
import Navbar from "../../CommonCompounds/Nav/Nav.jsx"
import Footer from "../../CommonCompounds/Footer/Footer.jsx";

function BlogPage(){
    return(
   
    <>

    <Navbar />
    <Blog />
    <Footer />
    
    </>
    )
}

export default BlogPage;