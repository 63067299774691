import React from "react";
import Navbar from "../../CommonCompounds/Nav/Nav.jsx"
import Footer from "../../CommonCompounds/Footer/Footer.jsx";

import PrivacyPolicy from "../../Compounds/PrivacyPolicy/PrivacyPolicy.jsx";


function RacksplatformsPage(){
    return(
        <>

       <Navbar />
       <PrivacyPolicy />
       <Footer />
        </>
    )
}
 export default RacksplatformsPage;