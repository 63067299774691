import React  from "react";


function OurCulture(){
    return(

       
        <>
  <main className="main" id="main">
    <section className="breadcrumbs">
      <ul>
        {/*li><a href="#">Home</a></li>
          <li class="current">Star Trac</li*/}
        <li>
          <span>
            <span>
              <a href="https://corehandf.com/" data-wpel-link="internal">
                Home
              </a>
            </span>{" "}
            »{" "}
            <span className="breadcrumb_last" aria-current="page">
              OUR CULTURE
            </span>
          </span>
        </li>
      </ul>
    </section>
    <section className="section banner">
      <div
        className="CULTURE"
        style={{
          background:
            '#2e2e2e url("//corehandf.com/wp-content/uploads/2020/10/Our-Culture_Page_Header_cyan-1-scaled.jpg") center center no-repeat !important ' 
        }}
      >
        <div className="single-banner-content">
          <h1 className="banner-title largest">OUR CULTURE</h1>
        </div>
      </div>
    </section>
    <section className="section single-content">
      <div className="container">
        <div className="row center-xs">
          <div className="col-xs-2">
            <div className="single-share">
              {/*ul class="list share-list">
							<li>Share</li>
							<li>
								<a href="#" class="facebook">
									<i class="fa fa-facebook"></i>
								</a>
							</li>
							<li>
								<a href="#" class="twitter">
									<i class="fa fa-twitter"></i>
								</a>
							</li>
							<li>
								<a href="#" class="google-plus">
									<i class="fa fa-google-plus"></i>
								</a>
							</li>
							<li>
								<a href="#" class="linkedin">
									<i class="fa fa-linkedin"></i>
								</a>
							</li>
							<li class="seperator"></li>
							<li>
								<a href="#">
									<i class="fa fa-envelope"></i>
								</a>
							</li>
						</ul*/}
            </div>
          </div>
          <div className="container">
            <div className="row center-xs">
            <div className="col-xs-10 ">
            <h3 data-ccp-props='{"335551550":1,"335551620":1,"335559683":0,"335559685":0,"335559731":0,"335559737":0,"335562764":2,"335562765":1,"335562766":4,"335562767":10,"335562768":4,"335562769":0}'>
              <span style={{ color: "#00aeef" }}>
                <b>OUR PURPOSE IS TO LIVE AND SHARE OUR PASSION FOR FITNESS</b>​
              </span>
            </h3>
            <p data-ccp-props='{"335551550":1,"335551620":1,"335559683":0,"335559685":0,"335559731":0,"335559737":0,"335562764":2,"335562765":1,"335562766":4,"335562767":10,"335562768":4,"335562769":0}'>
              Fitness is something we do, and we lead by the actions we take
              towards Fitness, for ourselves and for those around us​.
            </p>
            <p data-ccp-props='{"335551550":1,"335551620":1,"335559683":0,"335559685":0,"335559731":0,"335559737":0,"335562764":2,"335562765":1,"335562766":4,"335562767":10,"335562768":4,"335562769":0}'>
              Fitness benefits the masses, and we are examples, advocates,
              coaches, and influencers for a Fitness lifestyle​.
            </p>
            <p data-ccp-props='{"335551550":1,"335551620":1,"335559683":0,"335559685":0,"335559731":0,"335559737":0,"335562764":2,"335562765":1,"335562766":4,"335562767":10,"335562768":4,"335562769":0}'>
              We are&nbsp;passionate about Fitness.&nbsp;It is&nbsp;in our DNA,
              and it drives us to perform in life, in&nbsp;our jobs, in all that
              makes us who we are.
            </p>
            <h3>
              <span style={{ color: "#00aeef" }}>
                <strong>CORE VALUES</strong>
              </span>
            </h3>
            <p>
              <strong>PLAY TO WIN</strong>
              <br />
              <em>
                <strong>
                  We compete as a team. We have a bias towards positive action.
                </strong>
              </em>
              <br /> We cultivate employees who want to be part of a team; that
              are passionate about our industry, our products and our success.
              We value employees who excel in a fast-paced team environment and
              want to play to win.
            </p>
            <p>
              <strong>KEEP SCORE</strong>
              <br />
              <em>
                <strong>
                  To know how to win, we need to know where we stand.{" "}
                </strong>
              </em>
              <br /> Do the math, ensuring we are using our time and resources
              effectively towards a common goal. We believe financial rigor
              drives our efforts.
            </p>
            <p>
              <strong>OWN IT</strong>
              <br />
              <em>
                <strong>
                  We display and are accountable in everything we do.{" "}
                </strong>
              </em>
              <br /> We own it from start to finish; we do what we say we will
              do. If a commitment cannot be met, we communicate and find
              alternative solutions. We ultimately earn the trust of our
              partners, achieving the right results.
            </p>
            <p>
              <strong>CHANGE IT UP</strong>
              <br />
              <em>
                <strong>Plan and prepare to be nimble.</strong>
              </em>
              <br /> We are empowered to change it up. Dynamic environments call
              for dynamic solutions. We will look for “what’s possible”, we do
              not settle for “what is”, and we keep moving forward.
            </p>
            <p>
              <strong>ALL IN</strong>
              <br />
              <em>
                <strong>We act as one team.</strong>
              </em>
              <br /> We know greatness is not achieved with less than everything
              we have. As one team, all in together, we strive for breakthrough
              results and push ourselves to be the best. We are motivated and
              passionate about our actions and the results.
            </p>
            <p>
              <strong>FIND A WAY</strong>
              <br />
              <em>
                <strong>
                  Do what it takes. Think big for Core. No excuses.
                </strong>
              </em>
              <br /> We find a way to create results where others cannot; the
              words “no” and “failure” are not a part of our vocabulary. We get
              the job done in spite of any obstacles, and our success is defined
              by the value we deliver, to our people and our customers.
            </p>
            <p>
              <strong>SPEAK UP</strong>
              <br />
              <em>
                <strong>
                  Let’s hear it. Practice respectful, unfiltered dialogue.
                </strong>
              </em>
              <br /> We value your input – Speak up. No idea will ever
              materialize into success if it remains inside someone’s head.All
              ideas qualify; best idea wins. No silent suffering.
            </p>
            <h3>
              <strong>
                <span
                  className="TextRun SCXP240198581 BCX0"
                  lang="EN-US"
                  style={{ color: "#00aeef" }}
                  xmlLang="EN-US"
                  data-scheme-color="@00B0F0,,"
                  data-usefontface="false"
                  data-contrast="none"
                >
                  <span className="NormalTextRun SCXP240198581 BCX0">
                    CORPORATE SOCIAL RESPONSIBILITY
                  </span>
                </span>
              </strong>
            </h3>
            <p>CREATING POSITIVE IMPACT THROUGH THOUGHTFUL ACTION</p>
            <p data-ccp-props='{"335551550":1,"335551620":1,"335559683":0,"335559685":0,"335559731":0,"335559737":0,"335562764":2,"335562765":1,"335562766":4,"335562767":10,"335562768":4,"335562769":0}'>
              <span data-usefontface="false" data-contrast="none">
                At Core Health &amp; Fitness our values go beyond a list of
                ideals. We embrace our&nbsp;
              </span>
              <span data-usefontface="false" data-contrast="none">
                humanity and focus on those things we can do to make a positive
                impact in our local&nbsp;
              </span>
              <span data-usefontface="false" data-contrast="none">
                and global communities. We know the past, present and future
                hold equal weight so we&nbsp;
              </span>
              <span data-usefontface="false" data-contrast="none">
                assess our actions and make important choices to assure we leave
                the world a better&nbsp;
              </span>
              <span data-usefontface="false" data-contrast="none">
                place than when we entered.{" "}
              </span>
            </p>
            <p data-ccp-props='{"335551550":1,"335551620":1,"335559683":0,"335559685":0,"335559731":0,"335559737":0,"335562764":2,"335562765":1,"335562766":4,"335562767":10,"335562768":4,"335562769":0}'>
              <span data-usefontface="false" data-contrast="none">
                <span
                  className="TextRun BCX0 SCXW105339944"
                  lang="EN-US"
                  xmlLang="EN-US"
                  data-contrast="auto"
                >
                  <span className="NormalTextRun BCX0 SCXW105339944">
                    With focus on sustainability, wellbeing (both mental and
                    physical) and paid days off to focus on volunteering
                    projects, Core Health &amp; Fitness is continually finding
                    ways to give back to our communities on a global scale.
                  </span>
                </span>
              </span>
              ​
              <strong>
                <a
                  href=""
                >
                  Learn More →
                </a>
              </strong>
            </p>
            <h3>
              <strong>
                <span style={{ color: "#00aeef" }}>
                  <span
                    className="TextRun SCXP15410483 BCX0"
                    lang="EN-US"
                    xmlLang="EN-US"
                    data-scheme-color="@00B0F0,,"
                    data-usefontface="false"
                    data-contrast="none"
                  >
                    <span className="NormalTextRun SCXP15410483 BCX0">
                      THE PURSUIT OF WELLNESS
                    </span>
                  </span>
                  <span className="EOP SCXP15410483 BCX0">​</span>
                </span>
              </strong>
            </h3>
            <p data-ccp-props='{"335551550":1,"335551620":1,"335559683":0,"335559685":0,"335559731":0,"335559737":0,"335562764":2,"335562765":1,"335562766":4,"335562767":10,"335562768":4,"335562769":0}'>
              <span data-usefontface="false" data-contrast="none">
                Wellness is more than a state of mind,&nbsp;it is&nbsp;the
                pursuit of activities and lifestyle choices&nbsp;
              </span>
              <span data-usefontface="false" data-contrast="none">
                that make us physically and mentally healthier. At Core Health
                &amp; Fitness we live by this&nbsp;
              </span>
              <span data-usefontface="false" data-contrast="none">
                truth daily and make it our business to create fitness
                opportunities for our employees, no&nbsp;
              </span>
              <span data-usefontface="false" data-contrast="none">
                matter their fitness level or location. With 50%
                subsidized&nbsp;
              </span>
              <i>
                <span data-usefontface="false" data-contrast="none">
                  Fuel for Life&nbsp;
                </span>
              </i>
              <span data-usefontface="false" data-contrast="none">
                subscription, fun&nbsp;
              </span>
              <span data-usefontface="false" data-contrast="none">
                Myzone
              </span>
              <span data-usefontface="false" data-contrast="none">
                &nbsp;Fitness challenges (with company provided&nbsp;
              </span>
              <span data-usefontface="false" data-contrast="none">
                Myzone
              </span>
              <span data-usefontface="false" data-contrast="none">
                &nbsp;belt) and access to&nbsp;
              </span>
              <span data-usefontface="false" data-contrast="none">
                discounted fitness equipment,&nbsp;we’ve&nbsp;created a global
                team who are active, engaged&nbsp;
              </span>
              <span data-usefontface="false" data-contrast="none">
                and love to take on a challenge.
              </span>
            </p>
            <p data-ccp-props='{"335551550":1,"335551620":1,"335559683":0,"335559685":0,"335559731":0,"335559737":0,"335562764":2,"335562765":1,"335562766":4,"335562767":10,"335562768":4,"335562769":0}'>
              <span data-usefontface="false" data-contrast="none">
                &nbsp;
              </span>
              ​
            </p>
            <p>
              {/*HubSpot Call-to-Action Code */}
              <span
                id="hs-cta-wrapper-a18cc603-7723-457b-9859-182195841ca7"
                className="hs-cta-wrapper"
              >
                <span
                  id="hs-cta-a18cc603-7723-457b-9859-182195841ca7"
                  className="hs-cta-node hs-cta-a18cc603-7723-457b-9859-182195841ca7"
                >
                  {/* [if lte IE 8]>


<div id="hs-cta-ie-element"></div>


<![endif]*/}
                  <a
                    href=""
                    target="_blank"
                    rel="noopener noreferrer nofollow external"
                    data-wpel-link="external"
                  >
                    <img
                      decoding="async"
                      id="hs-cta-img-a18cc603-7723-457b-9859-182195841ca7"
                      className="hs-cta-img"
                      style={{ borderWidth: 0 }}
                      src="https://no-cache.hubspot.com/cta/default/2450902/a18cc603-7723-457b-9859-182195841ca7.png"
                      alt="Join The Team!"
                    />
                  </a>
                </span>
              </span>
            </p>
            <p>
              {/* end HubSpot Call-to-Action Code */}
              <br /> Image Gallery:
            </p>
            <p>
              <a
                href=""
                data-wpel-link="internal"
              >
                <img
                  decoding="async"
                  className="alignnone size-thumbnail wp-image-42033"
                  src="https://corehandf.com/wp-content/uploads/2020/11/Bill-Kazmeier-and-the-Dallas-YMCA-Group-150x150.jpg"
                  alt=""
                  width={150}
                  height={150}
                  srcSet="https://corehandf.com/wp-content/uploads/2020/11/Bill-Kazmeier-and-the-Dallas-YMCA-Group-150x150.jpg 150w, https://corehandf.com/wp-content/uploads/2020/11/Bill-Kazmeier-and-the-Dallas-YMCA-Group-300x300.jpg 300w, https://corehandf.com/wp-content/uploads/2020/11/Bill-Kazmeier-and-the-Dallas-YMCA-Group-100x100.jpg 100w"
                  sizes="(max-width: 150px) 100vw, 150px"
                />
              </a>{" "}
              <a
                href=""
                data-wpel-link="internal"
              >
                <img
                  decoding="async"
                  className="alignnone size-thumbnail wp-image-41665"
                  src="https://corehandf.com/wp-content/uploads/2020/10/Company-Culture-1-150x150.jpg"
                  alt="Company Culture"
                  width={150}
                  height={150}
                  srcSet="https://corehandf.com/wp-content/uploads/2020/10/Company-Culture-1-150x150.jpg 150w, https://corehandf.com/wp-content/uploads/2020/10/Company-Culture-1-300x300.jpg 300w, https://corehandf.com/wp-content/uploads/2020/10/Company-Culture-1-100x100.jpg 100w"
                  sizes="(max-width: 150px) 100vw, 150px"
                />
              </a>
              <a
                href=""
                data-wpel-link="internal"
              >
                <img
                  decoding="async"
                  className="wp-image-42008 size-thumbnail alignnone"
                  src="https://corehandf.com/wp-content/uploads/2020/11/Core-takes-CycleBar-Class-150x150.jpg"
                  alt=""
                  width={150}
                  height={150}
                  srcSet="https://corehandf.com/wp-content/uploads/2020/11/Core-takes-CycleBar-Class-150x150.jpg 150w, https://corehandf.com/wp-content/uploads/2020/11/Core-takes-CycleBar-Class-scaled-300x300.jpg 300w, https://corehandf.com/wp-content/uploads/2020/11/Core-takes-CycleBar-Class-scaled-100x100.jpg 100w"
                  sizes="(max-width: 150px) 100vw, 150px"
                />
              </a>{" "}
              <a
                style={{ backgroundColor: "#ffffff" }}
                href=""
                data-wpel-link="internal"
              >
                <img
                  loading="lazy"
                  decoding="async"
                  className="alignnone size-thumbnail wp-image-41670"
                  src="https://corehandf.com/wp-content/uploads/2020/10/Company-Culture-2-150x150.jpg"
                  alt="Company Culture"
                  width={150}
                  height={150}
                  srcSet="https://corehandf.com/wp-content/uploads/2020/10/Company-Culture-2-150x150.jpg 150w, https://corehandf.com/wp-content/uploads/2020/10/Company-Culture-2-300x300.jpg 300w, https://corehandf.com/wp-content/uploads/2020/10/Company-Culture-2-100x100.jpg 100w"
                  sizes="(max-width: 150px) 100vw, 150px"
                />{" "}
              </a>
              <a
                href=""
                data-wpel-link="internal"
              >
                <img
                  loading="lazy"
                  decoding="async"
                  className="alignnone wp-image-42018 size-thumbnail"
                  src="https://corehandf.com/wp-content/uploads/2020/11/STAND-PHOTO-2018-150x150.jpg"
                  alt=""
                  width={150}
                  height={150}
                  srcSet="https://corehandf.com/wp-content/uploads/2020/11/STAND-PHOTO-2018-150x150.jpg 150w, https://corehandf.com/wp-content/uploads/2020/11/STAND-PHOTO-2018-300x300.jpg 300w, https://corehandf.com/wp-content/uploads/2020/11/STAND-PHOTO-2018-100x100.jpg 100w"
                  sizes="(max-width: 150px) 100vw, 150px"
                />
              </a>
              <a
                href=""
                data-wpel-link="internal"
              >
                {" "}
                <img
                  loading="lazy"
                  decoding="async"
                  className="alignnone size-thumbnail wp-image-42038"
                  src="https://corehandf.com/wp-content/uploads/2020/11/Alida-awards-24E-Pelham-Nautilus-Trusted-Strength-Center-150x150.jpg"
                  alt=""
                  width={150}
                  height={150}
                  srcSet="https://corehandf.com/wp-content/uploads/2020/11/Alida-awards-24E-Pelham-Nautilus-Trusted-Strength-Center-150x150.jpg 150w, https://corehandf.com/wp-content/uploads/2020/11/Alida-awards-24E-Pelham-Nautilus-Trusted-Strength-Center-scaled-300x300.jpg 300w, https://corehandf.com/wp-content/uploads/2020/11/Alida-awards-24E-Pelham-Nautilus-Trusted-Strength-Center-scaled-100x100.jpg 100w"
                  sizes="(max-width: 150px) 100vw, 150px"
                />
              </a>{" "}
              <a
                href=""
                data-wpel-link="internal"
              >
                <img
                  loading="lazy"
                  decoding="async"
                  className="alignnone size-thumbnail wp-image-42028"
                  src="https://corehandf.com/wp-content/uploads/2020/11/Education-Training-in-Club-Campestre-San-Luis-Potosi-150x150.jpg"
                  alt=""
                  width={150}
                  height={150}
                  srcSet="https://corehandf.com/wp-content/uploads/2020/11/Education-Training-in-Club-Campestre-San-Luis-Potosi-150x150.jpg 150w, https://corehandf.com/wp-content/uploads/2020/11/Education-Training-in-Club-Campestre-San-Luis-Potosi-rotated-300x300.jpg 300w, https://corehandf.com/wp-content/uploads/2020/11/Education-Training-in-Club-Campestre-San-Luis-Potosi-rotated-100x100.jpg 100w"
                  sizes="(max-width: 150px) 100vw, 150px"
                />
              </a>{" "}
              <a
                href=""
                data-wpel-link="internal"
              >
                <img
                  loading="lazy"
                  decoding="async"
                  className="alignnone size-thumbnail wp-image-42023"
                  src="https://corehandf.com/wp-content/uploads/2020/11/Schwinn-Master-Class-in-Le-Parc-Club-in-San-Luis-Potosi-Mexico-150x150.jpg"
                  alt=""
                  width={150}
                  height={150}
                  srcSet="https://corehandf.com/wp-content/uploads/2020/11/Schwinn-Master-Class-in-Le-Parc-Club-in-San-Luis-Potosi-Mexico-150x150.jpg 150w, https://corehandf.com/wp-content/uploads/2020/11/Schwinn-Master-Class-in-Le-Parc-Club-in-San-Luis-Potosi-Mexico-300x300.jpg 300w, https://corehandf.com/wp-content/uploads/2020/11/Schwinn-Master-Class-in-Le-Parc-Club-in-San-Luis-Potosi-Mexico-100x100.jpg 100w"
                  sizes="(max-width: 150px) 100vw, 150px"
                />{" "}
              </a>{" "}
              <a
                style={{ backgroundColor: "#ffffff" }}
                href=""
                data-wpel-link="internal"
              >
                <img
                  loading="lazy"
                  decoding="async"
                  className="alignnone size-thumbnail wp-image-41680"
                  src="https://corehandf.com/wp-content/uploads/2020/10/Company-Culture-4-150x150.jpg"
                  alt="Company Culture"
                  width={150}
                  height={150}
                  srcSet="https://corehandf.com/wp-content/uploads/2020/10/Company-Culture-4-150x150.jpg 150w, https://corehandf.com/wp-content/uploads/2020/10/Company-Culture-4-300x300.jpg 300w, https://corehandf.com/wp-content/uploads/2020/10/Company-Culture-4-100x100.jpg 100w"
                  sizes="(max-width: 150px) 100vw, 150px"
                />
              </a>
              <a
                href=""
                data-wpel-link="internal"
              >
                <img
                  loading="lazy"
                  decoding="async"
                  className="alignnone wp-image-42013 size-thumbnail"
                  src="https://corehandf.com/wp-content/uploads/2020/11/IMG_1733-150x150.jpg"
                  alt=""
                  width={150}
                  height={150}
                  srcSet="https://corehandf.com/wp-content/uploads/2020/11/IMG_1733-150x150.jpg 150w, https://corehandf.com/wp-content/uploads/2020/11/IMG_1733-300x300.jpg 300w, https://corehandf.com/wp-content/uploads/2020/11/IMG_1733-100x100.jpg 100w"
                  sizes="(max-width: 150px) 100vw, 150px"
                />
              </a>{" "}
              <a
                href=""
                data-wpel-link="internal"
              >
                <img
                  loading="lazy"
                  decoding="async"
                  className="alignnone size-thumbnail wp-image-42043"
                  src="https://corehandf.com/wp-content/uploads/2020/11/Club-Visit-YMCA-Advisory-Board-150x150.jpg"
                  alt=""
                  width={150}
                  height={150}
                  srcSet="https://corehandf.com/wp-content/uploads/2020/11/Club-Visit-YMCA-Advisory-Board-150x150.jpg 150w, https://corehandf.com/wp-content/uploads/2020/11/Club-Visit-YMCA-Advisory-Board-300x300.jpg 300w, https://corehandf.com/wp-content/uploads/2020/11/Club-Visit-YMCA-Advisory-Board-100x100.jpg 100w"
                  sizes="(max-width: 150px) 100vw, 150px"
                />
              </a>{" "}
              <a
                href="g"
                data-wpel-link="internal"
              >
                <img
                  loading="lazy"
                  decoding="async"
                  className="alignnone size-thumbnail wp-image-42048"
                  src="https://corehandf.com/wp-content/uploads/2020/11/Hibner-Discussing-Facility-Design-and-Layout-150x150.jpg"
                  alt=""
                  width={150}
                  height={150}
                  srcSet="https://corehandf.com/wp-content/uploads/2020/11/Hibner-Discussing-Facility-Design-and-Layout-150x150.jpg 150w, https://corehandf.com/wp-content/uploads/2020/11/Hibner-Discussing-Facility-Design-and-Layout-300x300.jpg 300w, https://corehandf.com/wp-content/uploads/2020/11/Hibner-Discussing-Facility-Design-and-Layout-100x100.jpg 100w"
                  sizes="(max-width: 150px) 100vw, 150px"
                />
              </a>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>
                <a
                  href=""
                  rel="noopener noreferrer"
                  data-wpel-link="internal"
                >
                  ABOUT{" "}
                </a>
                |&nbsp;
                <a
                  href=""
                  rel="noopener noreferrer"
                  data-wpel-link="internal"
                >
                  CULTURE
                </a>{" "}
                |&nbsp;
                <a
                  href=""
                  rel="noopener noreferrer"
                  data-wpel-link="internal"
                >
                  SOCIAL RESPONSIBILITY
                </a>
                &nbsp;|&nbsp;
                <a
                  href=""
                  rel="noopener noreferrer"
                  data-wpel-link="internal"
                >
                  BRANDS
                </a>
                &nbsp;|&nbsp;
                <a
                  href=""
                  rel="noopener noreferrer"
                  data-wpel-link="internal"
                >
                  PARTNERS
                </a>{" "}
                |&nbsp;
                <a
                  href=""
                  rel="noopener noreferrer"
                  data-wpel-link="internal"
                >
                  CAREERS
                </a>
              </strong>
            </p>
          </div>
            </div>
          </div>
        
        </div>
      </div>
    </section>
  </main>
  {/* /.main */}
</>

       
    )
   

    
}

export default  OurCulture;