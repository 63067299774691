import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';



const ProductGallery = () => {
  return (
    <>
      <style>
        {`
        .swiper-container {
          width: 80%;
          padding-top: 20px;
          padding-bottom: 50px;
        }

        .gallery-section {
          padding: 20px;
        
        }

        .section-subtitle {
         
          font-size: 24px;
          margin-bottom: 30px;
        }

        .card {
          display: flex;
          flex-direction: column;
          align-items: center;
          background-color: #fff;
          border-radius: 4px;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
          overflow: hidden;
        }

        .card-image {
          width: 100%;
   
          padding-top: 105%;
          background-size: cover;
          background-position: center;
        }

        .card-info {
          padding: 15px;
          text-align: start;
        }

        .card-info .name {
          font-size: 18px;
          font-weight: bold;
          margin: 10px 0;
        }

        .card-info .number {
          font-size: 14px;
          color: #666;
        }

        @media (max-width: 1024px) {
  
        }

        @media (max-width: 768px) {
          .swiper-container {
            padding-bottom: 30px;
          }

          .section-subtitle {
            font-size: 20px;
          }
        }
      `}
      </style>

      <section id="gallery" className="gallery-section">
        <h2 className="section-subtitle">
          <span>
            <strong>Product</strong> Gallery
          </span>
        </h2>
        <Swiper
          spaceBetween={30}
          slidesPerView={4}
          navigation
          breakpoints={{
            1440: {
              slidesPerView: 4,
            },
            768: {
              slidesPerView: 2,
            },
            576: {
              slidesPerView: 1,
            },
          }}
          className="swiper-container"
        >
          {/* Slide 1 */}
          <SwiperSlide>
            <div className="card">
              <a href="">
                <div
                  className="card-image"
                  style={{
                    backgroundImage:
                      "url('https://corehandf.com/wp-content/uploads/2018/12/Startrac-10-TRx-Product-Image-scaled-e1706729713642.webp')",
                  }}
                >
                 
                </div>
                <div className="card-info">
                  <p className="name">10TRx FreeRunner™ Treadmill</p>
                  <p className="number">Model 10TRx</p>
                </div>
              </a>
            </div>
          </SwiperSlide>

          {/* Slide 2 */}
          <SwiperSlide>
            <div className="card">
              <a href="">
                <div
                  className="card-image"
                  style={{
                    backgroundImage:
                      "url('https://corehandf.com/wp-content/uploads/2020/08/Star-Trac-8-TRx-Hero-Shot-Transparent.png')",
                  }}
                >
                  
                </div>
                <div className="card-info">
                  <p className="name">8TRx Treadmill</p>
                  <p className="number">Model 8TRx</p>
                </div>
              </a>
            </div>
          </SwiperSlide>

          {/* Slide 3 */}
          <SwiperSlide>
            <div className="card">
              <a href="">
                <div
                  className="card-image"
                  style={{
                    backgroundImage:
                      "url('https://corehandf.com/wp-content/uploads/2019/09/Stairmaster-10-G-Hero-Shot-Transparent.webp')",
                  }}
                >
                
                </div>
                <div className="card-info">
                  <p className="name">10G</p>
                  <p className="number">Model 10G</p>
                </div>
              </a>
            </div>
          </SwiperSlide>

          {/* Slide 4 */}
          <SwiperSlide>
            <div className="card">
              <a href="">
                <div
                  className="card-image"
                  style={{
                    backgroundImage:
                      "url('https://corehandf.com/wp-content/uploads/2022/07/Stairmaster-8-Gx-Hero-Shot-Transparent.webp')",
                  }}
                >
                 
                </div>
                <div className="card-info">
                  <p className="name">8Gx</p>
                  <p className="number">Model 8Gx</p>
                </div>
              </a>
            </div>
          </SwiperSlide>
        </Swiper>
      </section>
    </>
  );
};

export default ProductGallery;
