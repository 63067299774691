import React from "react";
import Navbar from "../../CommonCompounds/Nav/Nav.jsx"
import Footer from "../../CommonCompounds/Footer/Footer.jsx";
import CorporateFitness from "../../Compounds/CorporateFitness/CorporateFitness.jsx";



function Contact(){
    return(
        <>

       <Navbar />
       <CorporateFitness />
       <Footer />
        </>
    )
}
 export default Contact;