import img1 from "../assets/images/Schwinn-Logo-Black-1-1024x287-4.webp"
import img2 from "../assets/images/Wexer_logo_true-blk-ft.png";
import img3 from "../assets/images/logoasociate_Nautilus-3.png";
import img4 from "../assets/images/Core-Main-60-Image-Web.jpg"
import mapgym  from "../assets/images/Icon_map-gym.png";
import Logo from "../assets/images/Home/Logo.png";
import banner1 from"../assets/images/Home/Banner 1.jpg";
import banner2 from"../assets/images/Home/banner 2.jpg";
import banner3 from"../assets/images/Home/Banner 3.jpg";
import banner4 from"../assets/images/Home/banner 4.jpg";
import banner5 from"../assets/images/Home/banner 5.jpg";
import  stairmaster from "../assets/images/Home/logoasociate_StairMaster-3 1.png"
import  schwinn from "../assets/images/Home/Schwinn-Logo-Black-1-1024x287-4 2.webp"
import  nautilus from "../assets/images/Home/logoasociate_Nautilus-3 3.png"
import  startrac from "../assets/images/Home/logoasociate_startrac-3 4.png"
import  throwdown from "../assets/images/Home/logoasociate_throwoown-3 5.png"
import AtCore from "../assets/images/Home/new-finance-partner-header-3-1080-x-600-px.png"
import PRODUCTHIGHLIGHT  from "../assets/images/Home/Product Hight.jpg";
import Featured1 from  "../assets/images/Home/feature 1 (1).png";
import Featured2 from  "../assets/images/Home/feature 1 (1).webp";
import Featured3 from  "../assets/images/Home/feature 1 (2).webp";
import Featured4 from  "../assets/images/Home/feature 1 (3).webp";
import whycore1 from "../assets/images/Home/Why Core 1 (1).jpg";
import whycore2 from "../assets/images/Home/Why Core 1 (1).webp";
import whycore3 from "../assets/images/Home/Why Core 1 (2).webp";
import whycore4 from "../assets/images/Home/Why Core 1 (3).webp";
import whycore5 from "../assets/images/Home/Why Core 1 (4).webp";
import Banner4 from  "../assets/images/Home/WhatsApp Image 2024-08-07 at 16.14.01_d77e111b.jpg"
 



const images = {
  ima1:img1,
  ima2:img2,
  ima3:img3,
  ima4:img4,
  mapgym:mapgym,
  Logo:Logo,
  banner1:banner1,
  banner2:banner2,
  banner3:banner3,
  banner4:banner4,
  Banner4:Banner4,
  banner5:banner5,
  stairmaster:stairmaster,
  schwinn:schwinn,
  nautilus:nautilus,
  startrac:startrac,
  throwdown:throwdown,
  AtCore:AtCore,
  PRODUCTHIGHLIGHT:PRODUCTHIGHLIGHT,
  Featured1:Featured1,
  Featured2:Featured2,
  Featured3:Featured3,
  Featured4:Featured4, 
  whycore1:whycore1,
  whycore2:whycore2,
  whycore3:whycore3,
  whycore4:whycore4,
  whycore5:whycore5,
}

export default images;