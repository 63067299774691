import React, { useState } from 'react';
import './BikeBuilder.css';

import xBike from '../../images/customization/Bikes/X Bike.png';
import zBike from '../../images/customization/Bikes/Z Bike.png';

import Black60 from '../../images/customization/Frame/Black 60.png';
import BlackGray83 from '../../images/customization/Frame/Black Gray 83.png';
import CandyBlue65 from '../../images/customization/Frame/Candy Blue 65.png';
import CandyRed27 from '../../images/customization/Frame/Candy Red 27.png';
import EagleWhite25 from '../../images/customization/Frame/Eagle White 25.png';
import Karminrot30 from '../../images/customization/Frame/Karminrot 30.png';
import MossGreen56 from '../../images/customization/Frame/Moss Green- 56.png';
import PastelOrange77 from '../../images/customization/Frame/Pastel Orange 77.png';
import PearlNightBlue80 from '../../images/customization/Frame/Pearl Night Blue 80.png';
import PeelWhite61 from '../../images/customization/Frame/Peel White 61.png';
import PowerhouseGranite62 from '../../images/customization/Frame/Powerhouse Granite 62.png';
import Purple49 from '../../images/customization/Frame/Purple 49.png';
import QuantumSilver79 from '../../images/customization/Frame/Quantum Silver 79.png';
import Silver13 from '../../images/customization/Frame/Silver 13.png';
import SpeckledRed82 from '../../images/customization/Frame/Speckled Red 82.png';
import TrafficGrey57 from '../../images/customization/Frame/Traffic Grey 57.png';
import UltramarineBlue73 from '../../images/customization/Frame/Ultramarine Blue 73.png';
import WrinkleBlack26 from '../../images/customization/Frame/Wrinkle Black 26.png';
import ZincYellow32 from '../../images/customization/Frame/Zinc Yellow 32.png';

import X_Z_BIKE_TRANSPARENT from '../../images/customization/Pedals/X_Z_BIKE_TRANSPARENT.png';
import X_Z_BIKE_PEDALS_TRIPLE_LINK from '../../images/customization/Pedals/X_Z_BIKE_PEDALS_TRIPLE_LINK.png';
import X_Z_BIKE_PEDALS_TRIPLE_LINK1 from '../../images/customization/Pedals/X_Z_BIKE_PEDALS_TRIPLE_LINK (1).png';

import ComfortSaddle from '../../images/customization/Seats/Comfort Saddle.png';
import RoadSaddle from '../../images/customization/Seats/Road Saddle.png';

const BikeCustomizer = () => {
  const [bikeImage, setBikeImage] = useState('select');
  const [frameColor, setFrameColor] = useState('select');
  const [pedals, setPedals] = useState('select');
  const [saddle, setSaddle] = useState('select');

  const handleBikeChange = (e) => {
    setBikeImage(e.target.value);
    // Reset other dropdowns when bike changes
    setFrameColor('select');
    setPedals('select');
    setSaddle('select');
  };

  const handleFrameColorChange = (e) => {
    setFrameColor(e.target.value);
    setPedals('select'); // Reset pedals when frame color changes
    setSaddle('select'); // Reset saddle when frame color changes
  };

  const handlePedalsChange = (e) => {
    setPedals(e.target.value);
    setSaddle('select'); // Reset saddle when pedals change
  };

  return (
    <div className="bike-customizer my-4">
      <div className="line-customizer-left">
        <h2 className="custom-ttl">
          <p>
            <span style={{ color: '#00aeef' }}>
              <strong>Build Your Bike</strong>
            </span>
          </p>
        </h2>

        {/* Bike Type Dropdown */}
        <select className="sel_line" value={bikeImage} onChange={handleBikeChange}>
          <option value="select">Bikes</option>
          <option value={xBike}>X Bike</option>
          <option value={zBike}>Z Bike</option>
        </select>

        {/* Conditionally render Frame Color Dropdown */}
        {bikeImage !== 'select' && (
          <select className="sel_line" value={frameColor} onChange={handleFrameColorChange}>
            <option value="select">Frame Color</option>
            <option value={SpeckledRed82}>Speckled Red 82</option>
            <option value={Karminrot30}>Karminrot 30</option>
            <option value={CandyRed27}>Candy Red 27</option>
            <option value={ZincYellow32}>Zinc Yellow 32</option>
            <option value={MossGreen56}>Moss Green 56</option>
            <option value={Purple49}>Purple 49</option>
            <option value={CandyBlue65}>Candy Blue 65</option>
            <option value={PearlNightBlue80}>Pearl Night Blue 80</option>
            <option value={UltramarineBlue73}>Ultramarine Blue 73</option>
            <option value={PastelOrange77}>Pastel Orange 77</option>
            <option value={EagleWhite25}>Eagle White 25</option>
            <option value={PeelWhite61}>Peel White 61</option>
            <option value={Silver13}>Silver 13</option>
            <option value={PowerhouseGranite62}>Powerhouse Granite 62</option>
            <option value={QuantumSilver79}>Quantum Silver 79</option>
            <option value={TrafficGrey57}>Traffic Grey 57</option>
            <option value={BlackGray83}>Black Gray 83</option>
            <option value={WrinkleBlack26}>Wrinkle Black 26</option>
            <option value={Black60}>Black 60</option>
          </select>
        )}

        {/* Conditionally render Pedals Dropdown */}
        {frameColor !== 'select' && (
          <select className="sel_line" value={pedals} onChange={handlePedalsChange}>
            <option value="select">Pedals</option>
            <option value={X_Z_BIKE_TRANSPARENT}>Standard Morse Taper Double Link™ pedals</option>
            <option value={X_Z_BIKE_PEDALS_TRIPLE_LINK}>Morse Taper Triple Link™ Keo-compatible pedals</option>
            <option value={X_Z_BIKE_PEDALS_TRIPLE_LINK1}>Morse Taper Triple Link™ Delta-compatible pedals</option>
          </select>
        )}

        {/* Conditionally render Saddle Dropdown */}
        {pedals !== 'select' && (
          <select className="sel_line" value={saddle} onChange={(e) => setSaddle(e.target.value)}>
            <option value="select">Seats</option>
            <option value={RoadSaddle}>Road Saddle</option>
            <option value={ComfortSaddle}>Comfort Saddle</option>
          </select>
        )}
      </div>

      {/* Right side where the image changes */}
      <div className="line-customizer-right">
        {/* Image container with relative positioning */}
        <div style={{ position: 'relative', width: '300px', height: 'auto' }}>
          {/* Base bike image */}
          {bikeImage !== 'select' && (
            <img
              src={bikeImage}
              alt="Bike Image"
              style={{ position: 'absolute', top: 0, left: 0, zIndex: 1, width: '100%' }}
            />
          )}

          {/* Frame color overlay */}
          {frameColor !== 'select' && (
            <img
              src={frameColor}
              alt="Frame Color"
              style={{ position: 'absolute', top: 0, left: 0, zIndex: 2, width: '100%' }}
            />
          )}

          {/* Pedals overlay */}
          {pedals !== 'select' && (
            <img
              src={pedals}
              alt="Pedals"
              style={{ position: 'absolute', top: 0, left: 0, zIndex: 3, width: '100%' }}
            />
          )}

          {/* Saddle overlay */}
          {saddle !== 'select' && (
            <img
              src={saddle}
              alt="Saddle"
              style={{ position: 'absolute', top: 0, left: 0, zIndex: 4, width: '100%' }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default BikeCustomizer;
