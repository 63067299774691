import React from "react";
import { NavLink } from "react-router-dom";

function Footer() {
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="wrapp--footer">
              <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                <div className="wrapper--logos">
                  <ul>
                    <li>
                      <NavLink
                        href="/"
                        id="StairMaster"
                        data-wpel-link="internal"
                      >
                        <img src="https://corehandf.com/wp-content/uploads/2021/10/logoasociate_StairMaster-3.png" />
                      </NavLink>
                    </li>
                    <li>
                      <NavLink href="/" id="SCHWINN" data-wpel-link="internal">
                        <img src="https://corehandf.com/wp-content/uploads/2021/10/Schwinn-Logo-Black-1-1024x287-4.webp" />
                      </NavLink>
                    </li>
                    <li>
                      <NavLink href="" id="Nautilus" data-wpel-link="internal">
                        <img src="https://corehandf.com/wp-content/uploads/2021/10/logoasociate_Nautilus-3.png" />
                      </NavLink>
                    </li>
                    <li>
                      <NavLink href="" id="Star Trac" data-wpel-link="internal">
                        <img src="https://corehandf.com/wp-content/uploads/2021/10/logoasociate_startrac-3.png" />
                      </NavLink>
                    </li>
                    <li>
                      <NavLink href="" id="ThrowDown" data-wpel-link="internal">
                        <img src="https://corehandf.com/wp-content/uploads/2021/10/logoasociate_throwoown-3.png" />
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-2 col-lg-2 main-menu-footer col-1">
                <div className="menu-footer-customer-support-container">
                  <ul id="menu-footer-customer-support" className="menu">
                    <li
                      id="menu-item-50435"
                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-50435"
                    >
                      <a href="#">Customer Support</a>
                      <ul className="sub-menu">
                        <li
                          id="menu-item-50436"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-50436"
                        >
                          <NavLink
                            to="/contact"
                            data-wpel-link="external"
                            target="_blank"
                            rel="nofollow external noopener noreferrer"
                          >
                            Contact Support
                          </NavLink>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-2 col-lg-2 main-menu-footer col-2">
                <div className="menu-footer-product-resources-container">
                  <ul id="menu-footer-product-resources" className="menu">
                    <li
                      id="menu-item-50407"
                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-50407"
                    >
                      <a href="#">Product Resources</a>
                      <ul className="sub-menu">
                        <li
                          id="menu-item-50408"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-50408"
                        >
                          <a href="" data-wpel-link="internal"></a>
                        </li>
                        <li
                          id="menu-item-50410"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-50410"
                        >
                          <a
                            href="https://app.pickit.com/#/board/6580c489889b1f5f629fc730"
                            data-wpel-link="external"
                            target="_blank"
                            rel="nofollow external noopener noreferrer"
                          >
                            Product Sheets
                          </a>
                        </li>
                        <li
                          id="menu-item-57918"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-57918"
                        >
                          <NavLink to="/Catalogue">Catalog</NavLink>
                        </li>
                        <li
                          id="menu-item-50411"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-50411"
                        >
                          <a
                            href=""
                            data-wpel-link="external"
                            target="_blank"
                            rel="nofollow external noopener noreferrer"
                          ></a>
                        </li>

                        <li
                          id="menu-item-52536"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-52536"
                        >
                          <NavLink to="/warranty">Warranty</NavLink>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-2 col-lg-2 main-menu-footer col-3">
                <div className="menu-footer-company-container">
                  <ul id="menu-footer-company" className="menu">
                    <li
                      id="menu-item-50416"
                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-50416"
                    >
                      <a href="#">Company</a>
                      <ul className="sub-menu">
                        <li
                          id="menu-item-50531"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-50531"
                        >
                          <NavLink to="/About" activeClassName="active">
                            ABOUT US
                          </NavLink>
                        </li>

                        <li
                          id="menu-item-86722"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-86722"
                        >
                          <NavLink
                            to="/PrivacyPolicy"
                            data-wpel-link="external"
                            target="_blank"
                            rel="nofollow external noopener noreferrer"
                          >
                            Privacy Policy
                          </NavLink>
                        </li>
                        <li
                          id="menu-item-86723"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-86723"
                        >
                          <a
                            href=""
                            data-wpel-link="external"
                            target="_blank"
                            rel="nofollow external noopener noreferrer"
                          >
                            Cookie Policy
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 just-content-betw f_colum d-flex col-4">
                <div className="wrapper--logo-initial">
                  <a href="" data-wpel-link="internal">
                    <img
                      width="200px"
                      alt=""
                      src="https://corehandf.com/wp-content/uploads/2021/10/core_health_fitness-1.png"
                    />
                  </a>
                </div>
                <div className="wrapper--social-media">
                  <span className="title-widget text-footer ">
                    Join Our Community
                  </span>
                  <ul>
                    <li>
                      <a
                        target="_blank"
                        href=""
                        className="ico-socialmedia Likedin"
                        style={{ display: "inline-flex" }}
                        data-wpel-link="external"
                        rel="nofollow external noopener noreferrer"
                      >
                        <img width="30px" heigth="30px" src="" />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href=""
                        className="ico-socialmedia Facebook"
                        style={{ display: "inline-flex" }}
                        data-wpel-link="external"
                        rel="nofollow external noopener noreferrer"
                      >
                        <img
                          width="30px"
                          heigth="30px"
                          src="https://corehandf.com/wp-content/mu-plugins/assets/img/acf/facebook-circle.svg"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href=""
                        className="ico-socialmedia Instagram"
                        style={{ display: "inline-flex" }}
                        data-wpel-link="external"
                        rel="nofollow external noopener noreferrer"
                      >
                        <img
                          width="30px"
                          heigth="30px"
                          src="https://corehandf.com/wp-content/mu-plugins/assets/img/acf/instagram-circle.svg"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href=""
                        className="ico-socialmedia YouTube"
                        style={{ display: "inline-flex" }}
                        data-wpel-link="external"
                        rel="nofollow external noopener noreferrer"
                      >
                        <img
                          width="30px"
                          heigth="30px"
                          src="https://corehandf.com/wp-content/mu-plugins/assets/img/acf/youtube-circle.svg"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href=""
                        className="ico-socialmedia TikTok"
                        style={{ display: "inline-flex" }}
                        data-wpel-link="external"
                        rel="nofollow external noopener noreferrer"
                      >
                        <img
                          width="30px"
                          heigth="30px"
                          src="https://corehandf.com/wp-content/mu-plugins/assets/img/acf/tiktok-circle.svg"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="container"></div>
        </div>
        <div id="copyright">
          <div className="container">
            <p>
              © Core Health &amp; Fitness, LLC. <br className="mobile-only" />
              All rights reserved.
              <br className="mobile-only" />
              &nbsp; &nbsp;{" "}
              <a
                href=""
                target="_blank"
                dat-wpel-link="external"
                rel="nofollow external noopener noreferrer"
              >
                Privacy Policy
              </a>
              &nbsp;| &nbsp;
              <a href="" data-wpel-link="internal">
                Recall Alert&nbsp;
              </a>{" "}
              |{" "}
              <a href="" data-wpel-link="internal">
                Terms and Conditions
              </a>
              &nbsp;|{" "}
              <a href="" data-wpel-link="internal">
                Accessibility
              </a>{" "}
              |{" "}
              <a href="" data-wpel-link="internal">
                Patents
              </a>{" "}
              |&nbsp;
              <a href="" data-wpel-link="internal">
                Site Map
              </a>
            </p>
          </div>
        </div>
        <style
          dangerouslySetInnerHTML={{
            __html:
              "\n            .box address a {\n                color: white;\n            }\n\n            @media only screen and (min-width: 1200px) and (max-width: 1273px) {\n                body.de .nav-middle .col-right ul.nav-middle-left>li>a {\n                    padding-right: 5px;\n                }\n            }\n\n            .search-field {\n                -webkit-appearance: none;\n            }\n\n            .nav-middle .col-right ul.nav-middle-left>li.parent {\n                padding-left: 40px;\n            }\n\n            .nav-middle .col-right ul.nav-middle-left>li.small-menu {\n                padding-left: 0;\n            }\n\n            .nav-middle .col-right ul.nav-middle-left>li .submenu ul.brand-new-layout li>ul.sub-menu>li>ul>li.menu-item-6124>a,\n            .nav-middle .col-right ul.nav-middle-left>li .submenu ul.brand-new-layout li>ul.sub-menu>li>ul>li.menu-item-6126>a,\n            .nav-middle .col-right ul.nav-middle-left>li .submenu ul.brand-new-layout li>ul.sub-menu>li>ul>li.menu-item-6098>a {\n                white-space: nowrap;\n            }\n        ",
          }}
        />
      </footer>
    </>
  );
}

export default Footer;
