import React from "react";

function SocialResponsibility(){
    return(
     <>
   <>
  <main className="main" id="main">
    <section className="breadcrumbs">
      <ul>
        {/*li><a href="#">Home</a></li>
          <li class="current">Star Trac</li*/}
        <li>
          <span>
            <span>
              <a href="https://corehandf.com/" data-wpel-link="internal">
                Home
              </a>
            </span>{" "}
            »{" "}
            <span className="breadcrumb_last" aria-current="page">
              SOCIAL RESPONSIBILITY
            </span>
          </span>
        </li>
      </ul>
    </section>
    <section className="section banner">
      <div
        className="RESPONSIBILITY"
        style={{
          background:
            '#2e2e2e url("https://corehandf.com/wp-content/uploads/2021/04/CSR-Page_Header-scaled.jpg") center center no-repeat !important'
        }}
      >
        <div className="single-banner-content">
          <h1 className="banner-title largest">SOCIAL RESPONSIBILITY</h1>
        </div>
      </div>
    </section>
    <section className="section single-content">
      <div className="container">
        <div className="row center-xs">
          <div className="col-xs-2">
            <div className="single-share">
              {/*ul class="list share-list">
							<li>Share</li>
							<li>
								<a href="#" class="facebook">
									<i class="fa fa-facebook"></i>
								</a>
							</li>
							<li>
								<a href="#" class="twitter">
									<i class="fa fa-twitter"></i>
								</a>
							</li>
							<li>
								<a href="#" class="google-plus">
									<i class="fa fa-google-plus"></i>
								</a>
							</li>
							<li>
								<a href="#" class="linkedin">
									<i class="fa fa-linkedin"></i>
								</a>
							</li>
							<li class="seperator"></li>
							<li>
								<a href="#">
									<i class="fa fa-envelope"></i>
								</a>
							</li>
						</ul*/}
            </div>
          </div>
          <div className="contanier">
            <div className="row  center-xs">
            <div className="col-xs-10 ">
            <p>
              <span style={{ color: "#00aeef" }}>
                <strong>
                  CREATING POSITIVE IMPACT THROUGH THOUGHTFUL ACTION
                </strong>
              </span>
            </p>
            <p>
              At Core Health &amp; Fitness, we embrace our humanity and focus on
              those things we can do to make a positive impact in our local and
              global communities. We assess our actions and make important
              choices to assure we leave the world a better place than when we
              entered.
            </p>
            <p>
              When matched with the heritage of our brands, the Social
              Responsibility pillars of WELLBEING, COMMUNITY, and SUSTAINABILITY
              strengthen our foundation to share our passion for fitness and our
              sense of responsibility to protect the future of it.
            </p>
            <p>
              <img
                decoding="async"
                className="alignnone wp-image-45841"
                src="https://corehandf.com/wp-content/uploads/2021/04/wellbeing-300x160.jpg"
                alt=""
                width={200}
                height={107}
                srcSet="https://corehandf.com/wp-content/uploads/2021/04/wellbeing-300x160.jpg 300w, https://corehandf.com/wp-content/uploads/2021/04/wellbeing.jpg 529w"
                sizes="(max-width: 200px) 100vw, 200px"
              />
            </p>
            <p>
              <strong>
                <span style={{ color: "#00aeef" }}>
                  THE PURSUIT OF WELLNESS
                </span>
              </strong>
            </p>
            <p>
              Wellness is the pursuit of activities and lifestyle choices that
              make us physically and mentally healthier, which is both integral
              to the successful business models of our customers, as well as
              part of the necessary foundation for our ability as a global team
              to support those customers effectively. ​
            </p>
            <p>
              Our goal is to support a safe and healthy workplace internally in
              order to support wellness in the fitness industry externally. ​
            </p>
            <p>
              <strong>Highlights:​</strong>
            </p>
            <p>
              • MyZone Challenges​
              <br /> • Fuel For Life​
              <br /> • Daily Workouts
            </p>
            <p>
              <img
                decoding="async"
                className="alignnone wp-image-45851"
                src="https://corehandf.com/wp-content/uploads/2021/04/community-300x160.jpg"
                alt=""
                width={200}
                height={107}
                srcSet="https://corehandf.com/wp-content/uploads/2021/04/community-300x160.jpg 300w, https://corehandf.com/wp-content/uploads/2021/04/community.jpg 529w"
                sizes="(max-width: 200px) 100vw, 200px"
              />
            </p>
            <p>
              <span style={{ color: "#00aeef" }}>
                <strong>CORE IN THE COMMUNITY</strong>
              </span>
            </p>
            <p>
              Communities are powered by the human need to connect, and they
              empower their members to lift each other up. This support
              particularly from a fitness community crosses all social and
              physical boundaries.
            </p>
            <p>
              Our goal is to be the catalyst that brings people together through
              fitness to accomplish more than they could individually.​​
            </p>
            <p>
              <strong>Highlights:​</strong>
            </p>
            <p>
              • Together, We Ride Virtual Marathon
              <br /> • Cycle for Survival
              <br /> • First Tee YMCA Support
              <br /> • Master Ride for Guanajuato &amp; Ashland, OR Fire
              Departments
            </p>
            <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
              <iframe
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%"
                }}
                title="Core Health & Fitness Service Sector"
                src="https://player.vimeo.com/video/489468408?title=0&byline=0&portrait=0"
                frameBorder={0}
                allowFullScreen="allowfullscreen"
              />
            </div>
            <p></p>
            <p>
              <img
                decoding="async"
                className="alignnone wp-image-45856"
                src="https://corehandf.com/wp-content/uploads/2021/04/sustainability-300x160.jpg"
                alt="sustainability"
                width={200}
                height={107}
                srcSet="https://corehandf.com/wp-content/uploads/2021/04/sustainability-300x160.jpg 300w, https://corehandf.com/wp-content/uploads/2021/04/sustainability.jpg 529w"
                sizes="(max-width: 200px) 100vw, 200px"
              />
            </p>
            <p>
              <span style={{ color: "#00aeef" }}>
                <strong>PROTECTING THE FUTURE OF FITNESS​</strong>
              </span>
            </p>
            <p>
              Gym members trust that their equipment can take a beating, but
              they also want to trust that the environment has not taken a
              beating at its expense. The successful growth and resilience of
              the fitness industry is dependent on meeting the needs of today
              without compromising the needs of tomorrow.
            </p>
            <p>
              Our goal is to answer the United Nations’ call to action to
              protect the planet through responsible consumption and production.
              Work to improve environmental control and efficiency in all
              aspects of our global operations and product development.​
            </p>
            <p>
              <strong>Highlights:</strong>
            </p>
            <p>
              • ISO 14001 Environmental Management System
              <br /> • Integrate Design for Environment principles into our
              Innovation Process
              <br /> • ZeroLandfill at US Distribution Facilities
              <br /> • EPS-free product packaging to reduce ocean pollution
              <br /> • Reduce environmental impact by extending product life
              through the promotion of Preventative Maintenance and
              refurbishment
              <br /> • Our trucking transportation partners are Green 75
              Members; one partner has won EPA’s SmartWay Excellence Award for
              freight efficiency initiatives that help drive reduced emissions
              and fuel savings.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>
                <a
                  href="https://corehandf.com/core-about-us"
                  rel="noopener noreferrer"
                  data-wpel-link="internal"
                >
                  ABOUT{" "}
                </a>
                |&nbsp;
                <a
                  href="https://corehandf.com/our-culture/"
                  rel="noopener noreferrer"
                  data-wpel-link="internal"
                >
                  CULTURE
                </a>{" "}
                |&nbsp;
                <a
                  href="https://corehandf.com/social-responsibility/"
                  rel="noopener noreferrer"
                  data-wpel-link="internal"
                >
                  SOCIAL RESPONSIBILITY
                </a>
                &nbsp;|&nbsp;
                <a
                  href="https://corehandf.com/our-brands/"
                  rel="noopener noreferrer"
                  data-wpel-link="internal"
                >
                  BRANDS
                </a>
                &nbsp;|&nbsp;
                <a
                  href="https://corehandf.com/our-partners/"
                  rel="noopener noreferrer"
                  data-wpel-link="internal"
                >
                  PARTNERS
                </a>
              </strong>{" "}
              <strong>|</strong>&nbsp;
              <strong>
                <a
                  href="https://corehandf.com/our-careers/"
                  rel="noopener noreferrer"
                  data-wpel-link="internal"
                >
                  CAREERS
                </a>
              </strong>
            </p>
          </div>
            </div>
          </div>
         
        </div>
      </div>
    </section>
  </main>
  {/* /.main */}
</>



     </>
    )
}

export default SocialResponsibility;