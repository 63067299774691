import React from "react";
import Navbar from "../../CommonCompounds/Nav/Nav.jsx"
import Footer from "../../CommonCompounds/Footer/Footer.jsx";
import Highintensity from "../../Compounds/Highintensity/Highintensity.jsx";


function GroupcyclingPage(){
    return(
        <>

       <Navbar />
       <Highintensity />
       <Footer />
        </>
    )
}
 export default GroupcyclingPage;