import React from "react";
import Navbar from "../../CommonCompounds/Nav/Nav.jsx";
import Footer from "../../CommonCompounds/Footer/Footer.jsx";
import Inspiration from "../../Compounds/Inspiration/Inspiration.jsx";




function InspirationPage() {
    return (
        <>
            <Navbar />
            <Inspiration />
            <Footer />
        </>
    );
}

export default InspirationPage;