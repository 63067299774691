import React  from "react";


function Contact(){
    return (
       <>
  <main>
  <div
    className="contanier contact-contanier"
    style={{ marginTop: 100, marginBottom: 200 }}
  >
    <div className="contact-info">
      <h2>Get in touch</h2>
      <p>
        Use our contact form for all information requests or  contact us  
        <br />  directly using the contact information below.
      </p>
      <p>Feel free to get in touch with us via email or phone</p>
      <div className="office-info">
        <p>
          <strong>Our Office Location</strong>
        </p>
        <p>
          The Interior Design Studio Company
          <br />
          The Courtyard, Al Quoz 1, Colorado, USA
        </p>
      </div>
      <div className="phone-info">
        <p>
          <strong>Phone (Landline)</strong>
        </p>
        <p>+912 3 567 8987</p>
      </div>
    </div>
    <div className="contact-form">
      <h2>Get started with a free quotation</h2>
      <form>
        <label htmlFor="name">
          {" "}
          <p>Name</p>
        </label>
        <input
          type="text"
          id="name"
          name="name"
          placeholder="Enter your Name"
          required=""
        />
            <label htmlFor="email">
          {" "}
          <p>Contact Number </p>
        </label>
        <input
          type="email"
          id="email"
          name="email"
          placeholder="Enter a Contact Number"
          required=""
        />
            <label htmlFor="email">
          {" "}
          <p>Location </p>
        </label>
        <input
          type="email"
          id="email"
          name="email"
          placeholder="Enter a Location"
          required=""
        />
        <label htmlFor="email">
          {" "}
          <p>Email </p>
        </label>
        <input
          type="email"
          id="email"
          name="email"
          placeholder="Enter a valid email address"
          required=""
        />
        <label htmlFor="service">
          {" "}
          <p>Brands</p>
        </label>
        <select id="service" name="service" required="">
          <option value="">Select a Brands</option>
          <option value="interior-design">Stratrac </option>
          <option value="furniture-design">Nautilus </option>
          <option value="space-planning"> Schwinn</option>
          <option value="consultation">Stairmaster</option>
          <option value="consultation">Throwdown&nbsp;</option>
        </select>
        <label htmlFor="message">
          {" "}
          <p>Message</p>
        </label>
        <textarea
          id="message"
          name="message"
          placeholder="Enter your message"
          required=""
          defaultValue={""}
        />
        <a
          className="btn btn-line--blue btn-black"
          href=""
          data-wpel-link="internal"
        >
          Submit{" "}
        </a>
      </form>
    </div>
  </div>
  <style
    dangerouslySetInnerHTML={{
      __html:
        "\n     .contact-form {\n    background-color: white;\n  padding: 50px 50px 50px 50px;\n\n    border-radius: 5px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n}\n.contact-form input, .contact-form textarea, .contact-form select {\n    width: 100%;\n    padding: 10px;\n    margin-bottom: 15px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n}\n\n\n.contact-info {\n    max-width: 50%;\n}\n\n.contact-info h2, .contact-form h2 {\n    margin-top: 0;\n    color: #333;\n}\n\n.office-info, .phone-info {\n    margin-top: 20px;\n}\n\n.contact-form {\n    max-width: 500px;\n    flex: 1;\n}\n\n.contact-form label {\n    display: block;\n    margin-bottom: 5px;\n    color: #333;\n}\n\n.contact-form input, .contact-form textarea {\n    width: 100%;\n    padding: 10px;\n    margin-bottom: 15px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n}\n\n.contact-form .terms {\n    display: flex;\n    align-items: center;\n}\n\n.contact-form .terms input {\n    margin-right: 10px;\n}\n\n.contact-form button {\n   \n\n    padding: 10px 20px;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n}\n.contact-contanier {\n    display: flex;\n    justify-content: center;\n    align-items: flex-start;\n\n  align-items: center;\n    margin: 0 auto;\n}\n.contact-form button:hover {\n   background-color: #0aadf0;\n   color: white;\n}\n\n        "
    }}
  />
</main>

       </> 
    )
}

export default Contact;