import React from "react";
import Navbar from "../../CommonCompounds/Nav/Nav.jsx"
import MultiStations   from "../../Compounds/MultiStations/MultiStations.jsx"
import Footer from "../../CommonCompounds/Footer/Footer.jsx";


function  MultiStationsPage(){
    return(
        <>

       <Navbar />
       <MultiStations />
       <Footer />
        </>
    )
}
 export default MultiStationsPage;