import React from "react";
import Navbar from "../../CommonCompounds/Nav/Nav.jsx";
import Footer from "../../CommonCompounds/Footer/Footer.jsx";
import StairmasterComponent from "../../Compounds/Stairmaster/Stairmaster.jsx";

function StairmasterPage() {
    return (
        <>
            <Navbar />
            <StairmasterComponent />
            <Footer />
        </>
    );
}

export default StairmasterPage;
