import React  from "react";
import images from "../../images/img";
import Form from "../../CommonCompounds/Form/Form";


function Throwdown (){
    return (
        <>
        <main className="main" id="main">
  <section className="breadcrumbs">
    <ul>
      {/*li><a href="#">Home</a></li>
          <li class="current">Star Trac</li*/}
      <li>
        <span>
          <span>
            <a href="https://corehandf.com/" data-wpel-link="internal">
              Home
            </a>
          </span>{" "}
          »{" "}
          <span className="breadcrumb_last" aria-current="page">
            Throwdown®
          </span>
        </span>
      </li>
    </ul>
  </section>
  {/*  Conditional for Industry page style layout */}
  <section id="build" className="section banner">
    <div className="row brand-banner">
      <div className="col-xs-12 brand-banner-left">
        <img
          src={images.brands2}
          alt=""
        />
        <div
          className="bg"
          style={{
            background:
              "url(https://corehandf.com/wp-content/uploads/2020/05/Throwdown-Website-1.jpg) center center no-repeat"
          }}
        />
      </div>
    </div>
  </section>
  <section>
    <div className="container">
      <div className="cnt">
        <h1 className="banner-title">Pushing Boundaries</h1>
        <p>
          For 20 years, Throwdown has offered unique workout opportunities that
          add diversity and challenge to any exercise routine. From functional
          training zones to boxing, mixed martials arts and more, Throwdown
          products are the differentiator in any facility.
        </p>
      </div>
    </div>
  </section>
  <section id="featured" className="section cards">
    <div className="container wide">
      <div className="row card-row">
        <div className="col-xs-3">
          <div className="card">
            <a
              href="/product-category/type/weightroom/rigs/"
              data-wpel-link="internal"
            >
              <img src="https://corehandf.com/wp-content/uploads/2023/10/throwdown-rigs.jpg" />
              <div className="card-info">
                <p className="name">Rigs</p>
              </div>
            </a>
          </div>
        </div>
        <div className="col-xs-3">
          <div className="card">
            <a
              href="/product-category/type/weightroom/accessories/"
              data-wpel-link="internal"
            >
              <img src="https://corehandf.com/wp-content/uploads/2023/10/throwdown-fit-accessories.jpg" />
              <div className="card-info">
                <p className="name">Fit Accessories</p>
              </div>
            </a>
          </div>
        </div>
        <div className="col-xs-3">
          <div className="card">
            <a
              href="/product-category/type/weightroom/storage-racks/"
              data-wpel-link="internal"
            >
              <img src="https://corehandf.com/wp-content/uploads/2023/10/throwdown-storage-racks.jpg" />
              <div className="card-info">
                <p className="name">Storage Racks</p>
              </div>
            </a>
          </div>
        </div>
        <div className="col-xs-3">
          <div className="card view-all">
            <a
              href="/product-category/brand/throwdown"
              data-wpel-link="internal"
            >
              <img src="" />
              <div className="card-info">
                <p className="name">
                  View All Throwdown <i className="fas fa-arrow-right fa-2x" />
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div className="container video-slider-wrap"></div>
  <section className="section view-other-brands">
    <div className="container">
      <header className="block-header">
        <h3>View Other Brands</h3>
      </header>
      <ul className="brand-logos">
        <li className="">
          <a href="/product-brand/nautilus" data-wpel-link="internal">
            <img src="https://corehandf.com/wp-content/uploads/2021/10/logoasociate_Nautilus-3.png" />
          </a>
        </li>
        <li className="">
          <a href="/product-brand/schwinn" data-wpel-link="internal">
            <img src="https://corehandf.com/wp-content/uploads/2021/10/Schwinn-Logo-Black-1-1024x287-3.webp" />
          </a>
        </li>
        <li className="">
          <a href="/product-brand/stairmaster" data-wpel-link="internal">
            <img src="https://corehandf.com/wp-content/uploads/2021/10/logoasociate_StairMaster-3.png" />
          </a>
        </li>
        <li className="">
          <a href="/product-brand/star-trac" data-wpel-link="internal">
            <img src="https://corehandf.com/wp-content/uploads/2021/10/logoasociate_startrac-3.png" />
          </a>
        </li>
      </ul>
    </div>
  </section>
  <section className="section brand-page-bottom">
    <div className="container">
      <div className="row center-xs">
        <div className="col-xs-4 offset-xs-3">
          <p>Follow Us</p>
          <ul className="list flex-center flex-middle">
            <li>
              <a
                href="https://www.facebook.com/throwdownfitness/"
                target="_blank"
                data-wpel-link="external"
                rel="nofollow external noopener noreferrer"
              >
                <i className="fa fa-facebook" />
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/CoreHandF"
                target="_blank"
                data-wpel-link="external"
                rel="nofollow external noopener noreferrer"
              >
                <i className="fa fa-twitter" />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/throwdownfitness/"
                target="_blank"
                data-wpel-link="external"
                rel="nofollow external noopener noreferrer"
              >
                <i className="fa fa-instagram" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <section className="request-a-quote-form">
    <div className="container">
      <h2 id="request-a-quote-form" className="section-title">
        <span>Start Your Consultation Today</span>
      </h2>
      <div className="row center-xs">
        <div className="col-md-6 offset-md-6">
 <Form />
        </div>
      </div>
    </div>
  </section>
  <a className="sticky-cta" href="#request-a-quote-form">
  <img src={images.mapgym} />
    Start Consultation
  </a>


  {/*  Conditional for Industry page style layout */}
</main>

        </>
    )
}

export default Throwdown;