import React  from "react";
import Navbar from "../../CommonCompounds/Nav/Nav.jsx";
import Footer from "../../CommonCompounds/Footer/Footer.jsx";
import HumanSport from "../../Compounds/HumanSport/HumanSport.jsx"

function HumansportPage (){
    return (
        <>  
        <Navbar />
        <HumanSport />
        <Footer />


        </>
    )
}

export default HumansportPage;