import React from "react";
import Navbar from "../../CommonCompounds/Nav/Nav.jsx"
import Footer from "../../CommonCompounds/Footer/Footer.jsx";
import TenSerive from "../../Compounds/10Series/10Series.jsx";


function TenseriesPage(){
    return(
        <>

       <Navbar />
       <TenSerive />
       <Footer />
        </>
    )
}
 export default TenseriesPage;