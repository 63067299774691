import React from "react";
import Form from "../../CommonCompounds/Form/Form";
import ProductGallery from "../../CommonCompounds/CarourselProduct/CarourselProduct";
import '../../assets/Style/style.css';

function Fitness(){
    return(
        <>
        <>
  <main className="main" id="main">
    <section className="breadcrumbs">
      <ul>
        {/*li><a href="#">Home</a></li>
          <li class="current">Star Trac</li*/}
        <li>
          <span>
            <span>
              <a href="" data-wpel-link="internal">
                Home
              </a>
            </span>{" "}
            »{" "}
            <span className="breadcrumb_last" aria-current="page">
              Fitness Centers
            </span>
          </span>
        </li>
      </ul>
    </section>
    <section
      className="section_slide solutions-banner row"
      style={{
        backgroundImage:
          "url(https://corehandf.com/wp-content/uploads/2022/02/Core-Innovative-Strength-Lifestyle-Image.jpg)"
      }}
    >
      <div className="col-xs-12 col-sm-8 col-md-8 col-lg-5">
        <h1>
          <strong>Fitness</strong> Centers
        </h1>
      </div>
    </section>
    <section className="section single-content">
      <div className="container small">
        <div className="row">
          <div className="col-xs-12">
            <p>
              Whether you’re looking to refresh your facility or open a brand
              new one, our team of industry experts take a consultative approach
              to understand your unique goals. This approach allows us to take
              you from concept to installation and industry leading support that
              keeps you in the know about your equipment.
            </p>
            <p>
              With a global network of partners we’re able to guide you through
              space design and equipment selection, the importance of flooring
              and asset management and so much more. When you request a
              consultation, your regional rep will get to know your goals and
              understand the fitness training styles your members have come to
              expect.
            </p>
            <p>Below are a few of the items you’ll be guided through:</p>
            <p>
              • Layout and Flow
              <br /> • Product Selection
              <br /> • Group Training &amp; Maximizing ROI
              <br /> • Technology &amp; Connected Fitness
              <br /> • Budget
              <br /> • Financing
            </p>
            <p>
              <img
                fetchpriority="high"
                decoding="async"
                className="alignnone wp-image-61152 size-full"
                src="https://corehandf.com/wp-content/uploads/2022/02/Laptop-Render-Mockup2.jpg"
                alt="Concept To Fruition"
                width={1910}
                height={521}
                srcSet="https://corehandf.com/wp-content/uploads/2022/02/Laptop-Render-Mockup2.jpg 1910w, https://corehandf.com/wp-content/uploads/2022/02/Laptop-Render-Mockup2-600x164.jpg 600w, https://corehandf.com/wp-content/uploads/2022/02/Laptop-Render-Mockup2-300x82.jpg 300w, https://corehandf.com/wp-content/uploads/2022/02/Laptop-Render-Mockup2-1024x279.jpg 1024w, https://corehandf.com/wp-content/uploads/2022/02/Laptop-Render-Mockup2-768x209.jpg 768w, https://corehandf.com/wp-content/uploads/2022/02/Laptop-Render-Mockup2-1536x419.jpg 1536w"
                sizes="(max-width: 1910px) 100vw, 1910px"
              />
            </p>
            <p>
              Our brands – StairMaster, Schwinn, Nautilus, Star Trac, Throwdown,
              and Wexer – allow us to customize the experience you’re looking to
              bring to your members. If you’re looking to refresh a section of
              your facility, your regional rep will utilize years of industry
              knowledge along with the latest FISA data to ensure you’re
              maximizing your space and are on trend with what new and existing
              members are expecting. Below are just a few of the fitness centers
              we’ve been able to collaborate with and share our passion for
              fitness.
            </p>
            <a className="cta-button" href="#embed-form">
              Request a Consultation
            </a>
            <div
              id="metaslider-id-57824"
              style={{ width: "100%" }}
              className="ml-slider-3-90-1 metaslider metaslider-flex metaslider-57824 ml-slider ms-theme-cubic has-dots-nav"
              role="region"
              aria-roledescription="Slideshow"
              aria-label="Industry: Fitness Centers"
            >
              <div id="metaslider_container_57824">
                <div id="metaslider_57824">
                  <ul className="slides">
                    <li
                      style={{ display: "block", width: "100%" }}
                      className="slide-57825 ms-image "
                      aria-roledescription="slide"
                      aria-label="slide-57825"
                    >
                      <img
                        src="https://corehandf.com/wp-content/uploads/2021/09/FitBerry-Cropped-770x500.png"
                        height={500}
                        width={770}
                        alt="FitBerry"
                        className="slider-57824 slide-57825"
                        title="FitBerry"
                      />
                    </li>
                    <li
                      style={{ display: "none", width: "100%" }}
                      className="slide-57826 ms-image "
                      aria-roledescription="slide"
                      aria-label="slide-57826"
                    >
                      <img
                        src="https://corehandf.com/wp-content/uploads/2021/09/Snail-Fitness-Cropped-770x500.png"
                        height={500}
                        width={770}
                        alt="Snail Fitness - Hangzhou, China"
                        className="slider-57824 slide-57826"
                        title="Snail-Fitness-Cropped"
                      />
                    </li>
                    <li
                      style={{ display: "none", width: "100%" }}
                      className="slide-57827 ms-image "
                      aria-roledescription="slide"
                      aria-label="slide-57827"
                    >
                      <img
                        src="https://corehandf.com/wp-content/uploads/2021/09/TX-Fam-Fit-Cropped-770x500.png"
                        height={500}
                        width={770}
                        alt="Texas Family Fitness"
                        className="slider-57824 slide-57827"
                        title="TX-Fam-Fit-Cropped"
                      />
                    </li>
                    <li
                      style={{ display: "none", width: "100%" }}
                      className="slide-57828 ms-image "
                      aria-roledescription="slide"
                      aria-label="slide-57828"
                    >
                      <img
                        src="https://corehandf.com/wp-content/uploads/2021/09/Banner-Size-770x500.png"
                        height={500}
                        width={770}
                        alt="Just Jym"
                        className="slider-57824 slide-57828"
                        title="Just Jym"
                      />
                    </li>
                    <li
                      style={{ display: "none", width: "100%" }}
                      className="slide-57829 ms-image "
                      aria-roledescription="slide"
                      aria-label="slide-57829"
                    >
                      <img
                        src="https://corehandf.com/wp-content/uploads/2021/09/Duet-Fit-Cropped-770x500.png"
                        height={500}
                        width={770}
                        alt="Duet Fit Spain"
                        className="slider-57824 slide-57829"
                        title="Duet-Fit---Cropped"
                      />
                    </li>
                    <li
                      style={{ display: "none", width: "100%" }}
                      className="slide-57830 ms-image "
                      aria-roledescription="slide"
                      aria-label="slide-57830"
                    >
                      <img
                        src="https://corehandf.com/wp-content/uploads/2021/10/Golds-Gym-Glendale-Schwinn-AC-Performance-770x500.webp"
                        height={500}
                        width={770}
                        alt=""
                        className="slider-57824 slide-57830"
                        title="Gold's Gym Glendale Schwinn AC Performance"
                      />
                    </li>
                    <li
                      style={{ display: "none", width: "100%" }}
                      className="slide-61165 ms-image "
                      aria-roledescription="slide"
                      aria-label="slide-61165"
                    >
                      <img
                        src="https://corehandf.com/wp-content/uploads/2021/11/Fitness-Centers-scaled-770x500.jpg"
                        height={500}
                        width={770}
                        alt="Fitness Centers"
                        className="slider-57824 slide-61165"
                        title="Fitness Centers"
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="centers-section" style={{ display: "none" }}>
      <div className="swiper">
        <div className="swiper-container">
          <div className="swiper-wrapper">
            <div
              className="swiper-slide"
              style={{
                backgroundImage:
                  'url("https://corehandf.com/wp-content/uploads/2021/09/FitBerry-Cropped.png")'
              }}
            >
              <div className="caption">
                <p>FitBerry | Bucha, Ukraine</p>
              </div>
            </div>
            <div
              className="swiper-slide"
              style={{
                backgroundImage:
                  'url("https://corehandf.com/wp-content/uploads/2021/09/Snail-Fitness-Cropped.png")'
              }}
            >
              <div className="caption">
                <p>
                  <span
                    data-sheets-value='{"1":2,"2":"Life1 Fitness Club opened in the middle of the City, Hungary in February 2014. Life1 offers more than 1200sqft of floor space and is fully air-conditioned throughout. The gym is equipped with 42 state of the art pieces of Star Trac cardio equipment providing modern machines with interactive entertainment. Life1 also offers highly qualified trainers, who prepare special training plans and programs, focussing on guests individual fitness goals."}'
                    data-sheets-userformat='{"2":14782,"4":{"1":2,"2":16777215},"5":{"1":[{"1":2,"2":0,"5":{"1":2,"2":0}},{"1":0,"2":0,"3":3},{"1":1,"2":0,"4":1}]},"6":{"1":[{"1":2,"2":0,"5":{"1":2,"2":0}},{"1":0,"2":0,"3":3},{"1":1,"2":0,"4":1}]},"7":{"1":[{"1":2,"2":0,"5":{"1":2,"2":0}},{"1":0,"2":0,"3":3},{"1":1,"2":0,"4":1}]},"8":{"1":[{"1":2,"2":0,"5":{"1":2,"2":0}},{"1":0,"2":0,"3":3},{"1":1,"2":0,"4":1}]},"10":0,"11":4,"14":{"1":2,"2":0},"15":"Arial","16":10}'
                  >
                    Snail Fitness |{" "}
                  </span>
                  Hangzhou, China
                </p>
              </div>
            </div>
            <div
              className="swiper-slide"
              style={{
                backgroundImage:
                  'url("https://corehandf.com/wp-content/uploads/2021/09/TX-Fam-Fit-Cropped.png")'
              }}
            >
              <div className="caption">
                <p>Texas Family Fitness | Texas, United States</p>
              </div>
            </div>
            <div
              className="swiper-slide"
              style={{
                backgroundImage:
                  'url("https://corehandf.com/wp-content/uploads/2021/09/Banner-Size.png")'
              }}
            >
              <div className="caption">
                <p>Just Jym | Singapore</p>
              </div>
            </div>
            <div
              className="swiper-slide"
              style={{
                backgroundImage:
                  'url("https://corehandf.com/wp-content/uploads/2021/09/Duet-Fit-Cropped.png")'
              }}
            >
              <div className="caption">
                <p>Duet Fit | Barcelona, Spain</p>
              </div>
            </div>
            <div
              className="swiper-slide"
              style={{
                backgroundImage:
                  'url("https://corehandf.com/wp-content/uploads/2021/09/Golds-gym.png")'
              }}
            >
              <div className="caption">
                <p>Gold’s Gym | California, United States</p>
              </div>
            </div>
          </div>
        </div>
        <a href="#" className="swiper-button-prev  6" />
        <a href="#" className="swiper-button-next  6" />
      </div>
    </section>
    <section>
      <ProductGallery />
    </section>
   
    <section className="request-a-quote-form">
    <div className="container">
      <h2 id="request-a-quote-form" className="section-title"><span>Start Your Consultation Today</span></h2>
      <div className="row center-xs">
        <div className="col-md-6 offset-md-6">
        <Form />
        </div>
      </div>
    </div>
  </section>

  </main>
  {/* /.main */}
</>

        </>
    )
}

export default Fitness;