import React  from "react";
import Form from "../../CommonCompounds/Form/Form";
import ProductGallery from "../../CommonCompounds/CarourselProduct/CarourselProduct";


function AthleticRecCenter(){
    return(
        <>
        <>
  <main className="main" id="main">
    <section className="breadcrumbs">
      <ul>
        {/*li><a href="#">Home</a></li>
          <li class="current">Star Trac</li*/}
        <li>
          <span>
            <span>
              <a href="" data-wpel-link="internal">
                Home
              </a>
            </span>{" "}
            »{" "}
            <span className="breadcrumb_last" aria-current="page">
              Athletic &amp; Rec Centers
            </span>
          </span>
        </li>
      </ul>
    </section>
    <section
      className="section_slide solutions-banner row"
      style={{
        backgroundImage:
          "url(https://corehandf.com/wp-content/uploads/2022/03/Core-Impact-Training-Lifestyle-Image.jpg)"
      }}
    >
      <div className="col-xs-12 col-sm-8 col-md-8 col-lg-5">
        <h1>
          <strong>Athletic</strong> &amp; Rec Centers
        </h1>
      </div>
    </section>
    <section className="section single-content">
      <div className="container small">
        <div className="row">
          <div className="col-xs-12">
            <p>
              Whether you’re breaking ground on a new facility, remodeling an
              existing space, or simply replacing existing equipment our One
              Team approach ensures we’re aligned from start to finish. When it
              comes to designing your fitness center, we understand that it’s as
              much a recruiting tool for your student body as it is for your
              student athletes. With this in mind we’ve created a wide range of
              custom options that allows you to showcase your school colors as
              well as your logos so your facility is able to have as much or as
              little branding as you desire. Below are a few of the items you’ll
              be guided through:{" "}
            </p>
            <p>
              • 2D Rendering
              <br /> • 3D Layout
              <br /> • Customization
              <br /> • Budget
              <br /> • Financing{" "}
            </p>
            <p>
              There is no better partner than Core Health &amp; Fitness because
              our portfolio of commercial brands – StairMaster, Schwinn,
              Nautilus, Star Trac, Throwdown, and Wexer – not only accommodate a
              wide range of aesthetics, but also provide a multitude of training
              options to keep pace with the changes your coaches know are
              coming. When you start your consultation, you’ll be speaking with
              an industry expert focused on understanding the goals of your
              facility. We understand the unique requirements of outfitting a
              space that meets the needs of your steady state cross country
              team, your explosive volleyball team as well as your dynamically
              powerful football team. Below are just a few of the facilities
              we’ve been able to collaborate with and go from concept to
              completion.{" "}
            </p>
            <a className="cta-button" href="#embed-form">
              Request a Consultation
            </a>
            <div
              id="metaslider-id-57843"
              style={{ width: "100%" }}
              className="ml-slider-3-90-1 metaslider metaslider-flex metaslider-57843 ml-slider ms-theme-cubic has-dots-nav"
              role="region"
              aria-roledescription="Slideshow"
              aria-label="Industry: Athletic & Rec Centers"
            >
              <div id="metaslider_container_57843">
                <div id="metaslider_57843">
                  <ul className="slides">
                    <li
                      style={{ display: "block", width: "100%" }}
                      className="slide-58072 ms-image "
                      aria-roledescription="slide"
                      aria-label="slide-58072"
                    >
                      <img
                        src="https://corehandf.com/wp-content/uploads/2021/12/Cowboys-Fit-Star-Trac-8RB-770x500.jpg"
                        height={500}
                        width={770}
                        alt="Cowboys Fit Star Trac 8RB"
                        className="slider-57843 slide-58072"
                        title="Cowboys Fit Star Trac 8RB"
                      />
                    </li>
                    <li
                      style={{ display: "none", width: "100%" }}
                      className="slide-61171 ms-image "
                      aria-roledescription="slide"
                      aria-label="slide-61171"
                    >
                      <img
                        src="https://corehandf.com/wp-content/uploads/2021/11/Athletic-and-Recreation-Centers-scaled-e1638314372111-770x500.webp"
                        height={500}
                        width={770}
                        alt="Athletic and Recreation Centers"
                        className="slider-57843 slide-61171"
                        title="Athletic and Recreation Centers"
                      />
                    </li>
                    <li
                      style={{ display: "none", width: "100%" }}
                      className="slide-57845 ms-image "
                      aria-roledescription="slide"
                      aria-label="slide-57845"
                    >
                      <img
                        src="https://corehandf.com/wp-content/uploads/2021/10/Bears-Fit-StairMaster-8G-2-636x413.jpg"
                        height={500}
                        width={770}
                        alt="Bears Fit"
                        className="slider-57843 slide-57845"
                        title="Bears Fit StairMaster 8G 2"
                      />
                    </li>
                    <li
                      style={{ display: "none", width: "100%" }}
                      className="slide-58071 ms-image "
                      aria-roledescription="slide"
                      aria-label="slide-58071"
                    >
                      <img
                        src="https://corehandf.com/wp-content/uploads/2021/12/Texans-Fit-Throwdown-770x500.jpg"
                        height={500}
                        width={770}
                        alt="Texans Fit Throwdown"
                        className="slider-57843 slide-58071"
                        title="Texans Fit Throwdown"
                      />
                    </li>
                    <li
                      style={{ display: "none", width: "100%" }}
                      className="slide-58073 ms-image "
                      aria-roledescription="slide"
                      aria-label="slide-58073"
                    >
                      <img
                        src="https://corehandf.com/wp-content/uploads/2021/12/Clemson-University-Star-Trac-8RB-scaled-770x500.jpg"
                        height={500}
                        width={770}
                        alt="Clemson University Star Trac 8RB"
                        className="slider-57843 slide-58073"
                        title="Clemson University Star Trac 8RB"
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
<section >
  <ProductGallery />
</section>
   
    <section className="request-a-quote-form">
    <div className="container">
      <h2 id="request-a-quote-form" className="section-title"><span>Start Your Consultation Today</span></h2>
      <div className="row center-xs">
        <div className="col-md-6 offset-md-6">
        <Form />
        </div>
      </div>
    </div>
  </section>
  </main>
  {/* /.main */}
</>

        </>
    )
}

export default AthleticRecCenter;