import React  from "react";


function PlateLoaded (){
    return(
        <>
<>
  <main className="main" id="main">
    <section className="breadcrumbs">
      <ul>
        {/*li><a href="#">Home</a></li>
          <li class="current">Star Trac</li*/}
        <li>
          <span>
            <span>
              <a href="https://corehandf.com/" data-wpel-link="internal">
                Home
              </a>
            </span>{" "}
            »{" "}
            <span>
              <a
                href="https://corehandf.com/product_line/"
                data-wpel-link="internal"
              >
                Product Lines
              </a>
            </span>{" "}
            »{" "}
            <span>
              <a
                href="https://corehandf.com/product_line/nautilus-brand/"
                data-wpel-link="internal"
              >
                Nautilus
              </a>
            </span>{" "}
            »{" "}
            <span className="breadcrumb_last" aria-current="page">
              Plate Loaded
            </span>
          </span>
        </li>{" "}
      </ul>
    </section>
    <section
      className="section product-line-banner-section"
      style={{
        background:
          "url(https://corehandf.com/wp-content/uploads/2023/04/Nautilus_PlateLoaded_HeroShot.webp) top center no-repeat",
        backgroundSize: "cover"
      }}
    >
      <div className="product-line-banner">
        <div className="content-wrap">
          <img src="https://corehandf.com/wp-content/uploads/2019/04/Nautilus-Logo-all-white1.png" />
          <h1 className="banner-title largest">Plate Loaded</h1>
        </div>
      </div>
    </section>
    <section className="section product-line-intro-section container">
      <p>
        It’s no secret that working out with plates is one of the most effective
        ways to train and condition, but it’s what you do with those weights
        that makes all the difference. Designed to make working out with plates
        as effective, rewarding, and safe as possible, our Plate Loaded line
        occupies its own unique place in training and on your facility floor.
        With industry standard designs like our Hack Squat, we helped set the
        benchmark for traditional plate loaded machines. When it comes to
        innovation, we’ve led the way with our 7-degree angled Smith Machine and
        the Freedom RackTM, which delivers the safety of a Smith Machine and the
        versatility of a Power Rack.
      </p>
    </section>
    <section className="section full-width-column-section container image-left">
      <div className="row">
        <div className="col-md-5">
          <div className="img-video-wrap">
            <img src="https://corehandf.com/wp-content/uploads/2023/04/deadlift-shrug-60.webp" />
          </div>
        </div>
        <div className="col-md-7">
          <div className="content-wrap">
            <h2>A PERFECT PARTNERSHIP</h2>
            <p>
              Throwdown Free Weights pair perfectly with Nautilus Strength
              products allowing you to get everything in one place. You can even
              find free weight sets crafted specifically for popular Nautilus
              Benches &amp; Racks, Leverage, and Plate-Loaded products.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className="section full-width-column-section container image-right">
      <div className="row">
        <div className="col-md-7">
          <div className="content-wrap">
            <h2>TREND SETTING INNOVATION</h2>
            <p>
              The Nautilus Glute Drive safely and smartly isolates your glutes,
              building power through a strong hip bridge motion, creating sexy
              glutes, and improving hip and core stability. The Glute Drive
              gives you the full benefits of the hip thrust exercise simply,
              safely, and with good form. A comfortable padded belt secures the
              user to the back pad, which supports the full length of the spine
              for added safety. Users can load up to four plates on each side,
              which gives the machine a max weight load of 360 lbs.
            </p>
          </div>
        </div>
        <div className="col-md-5">
          <div className="img-video-wrap">
            <img src="https://corehandf.com/wp-content/uploads/2023/04/Glute-Drive-60.webp" />
          </div>
        </div>
      </div>
    </section>
    <section className="series-prods-section">
      <div className="container">
        <h2>Browse Plate Loaded Products</h2>
        <div className="row">
          <div className="col-md-3">
            <a
              href="https://corehandf.com/product/nautilus-plate-loaded-angled-leg-press/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/nautilus-plate-loaded-angled-leg-press/"
                data-wpel-link="internal"
              >
                <img
                  width={500}
                  height={500}
                  src="https://corehandf.com/wp-content/uploads/2017/02/Leverage_Angled_Leg_Press-60.png"
                  className="attachment-large size-large wp-post-image"
                  alt="Nautilus - Leverage - Angled Leg Press - 60"
                  decoding="async"
                  fetchpriority="high"
                  srcSet="https://corehandf.com/wp-content/uploads/2017/02/Leverage_Angled_Leg_Press-60.png 500w, https://corehandf.com/wp-content/uploads/2017/02/Leverage_Angled_Leg_Press-60-300x300.png 300w, https://corehandf.com/wp-content/uploads/2017/02/Leverage_Angled_Leg_Press-60-100x100.png 100w, https://corehandf.com/wp-content/uploads/2017/02/Leverage_Angled_Leg_Press-60-150x150.png 150w"
                  sizes="(max-width: 500px) 100vw, 500px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/nautilus-plate-loaded-angled-leg-press/"
                  data-wpel-link="internal"
                >
                  Angled Leg Press
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <a
              href="https://corehandf.com/product/nautilus-plate-loaded-incline-lever-row/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/nautilus-plate-loaded-incline-lever-row/"
                data-wpel-link="internal"
              >
                <img
                  width={500}
                  height={500}
                  src="https://corehandf.com/wp-content/uploads/2017/03/Leverag_Incline_Lever_Row-60.png"
                  className="attachment-large size-large wp-post-image"
                  alt="Nautilus - Leverage - Incline Lever Row - 60"
                  decoding="async"
                  srcSet="https://corehandf.com/wp-content/uploads/2017/03/Leverag_Incline_Lever_Row-60.png 500w, https://corehandf.com/wp-content/uploads/2017/03/Leverag_Incline_Lever_Row-60-300x300.png 300w, https://corehandf.com/wp-content/uploads/2017/03/Leverag_Incline_Lever_Row-60-100x100.png 100w, https://corehandf.com/wp-content/uploads/2017/03/Leverag_Incline_Lever_Row-60-150x150.png 150w"
                  sizes="(max-width: 500px) 100vw, 500px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/nautilus-plate-loaded-incline-lever-row/"
                  data-wpel-link="internal"
                >
                  Incline Lever Row
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <a
              href="https://corehandf.com/product/nautilus-plate-loaded-hack-squat/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/nautilus-plate-loaded-hack-squat/"
                data-wpel-link="internal"
              >
                <img
                  width={500}
                  height={500}
                  src="https://corehandf.com/wp-content/uploads/2017/02/Leverage_Hack_Squat-60.png"
                  className="attachment-large size-large wp-post-image"
                  alt=""
                  decoding="async"
                  srcSet="https://corehandf.com/wp-content/uploads/2017/02/Leverage_Hack_Squat-60.png 500w, https://corehandf.com/wp-content/uploads/2017/02/Leverage_Hack_Squat-60-300x300.png 300w, https://corehandf.com/wp-content/uploads/2017/02/Leverage_Hack_Squat-60-100x100.png 100w, https://corehandf.com/wp-content/uploads/2017/02/Leverage_Hack_Squat-60-150x150.png 150w"
                  sizes="(max-width: 500px) 100vw, 500px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/nautilus-plate-loaded-hack-squat/"
                  data-wpel-link="internal"
                >
                  Hack Squat
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <a
              href="/product-category/brand/nautilus/plate-loaded-line/"
              data-wpel-link="internal"
            >
              <div className="card">
                <div className="card-body">
                  <h3>
                    View All
                    <br />
                    Plate Loaded
                  </h3>
                </div>
              </div>
            </a>
          </div>
        </div>
        {/*/ .row */}
      </div>
      {/*/ .container */}
    </section>
    <section className="frequently-purchased-with-prods-section">
      <div className="container">
        <h2>Frequently Purchased With</h2>
        <div className="row">
          <div className="col-md-3">
            <a
              href="https://corehandf.com/product/nautilus-leverage-chest-press/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/nautilus-leverage-chest-press/"
                data-wpel-link="internal"
              >
                <img
                  width={500}
                  height={500}
                  src="https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Leverage-Chest-Press-60-9NP-L2002-Web-Image.png"
                  className="attachment-large size-large wp-post-image"
                  alt="Nautilus Leverage Chest Press -60 9NP-L2002"
                  decoding="async"
                  loading="lazy"
                  srcSet="https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Leverage-Chest-Press-60-9NP-L2002-Web-Image.png 500w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Leverage-Chest-Press-60-9NP-L2002-Web-Image-300x300.png 300w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Leverage-Chest-Press-60-9NP-L2002-Web-Image-100x100.png 100w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Leverage-Chest-Press-60-9NP-L2002-Web-Image-150x150.png 150w"
                  sizes="(max-width: 500px) 100vw, 500px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/nautilus-leverage-chest-press/"
                  data-wpel-link="internal"
                >
                  Leverage® Chest Press
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <a
              href="https://corehandf.com/product/nautilus-benches-racks-incline-bench-press/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/nautilus-benches-racks-incline-bench-press/"
                data-wpel-link="internal"
              >
                <img
                  width={500}
                  height={500}
                  src="https://corehandf.com/wp-content/uploads/2017/02/Benches__Racks_Incline_Press-60.png"
                  className="attachment-large size-large wp-post-image"
                  alt=""
                  decoding="async"
                  loading="lazy"
                  srcSet="https://corehandf.com/wp-content/uploads/2017/02/Benches__Racks_Incline_Press-60.png 500w, https://corehandf.com/wp-content/uploads/2017/02/Benches__Racks_Incline_Press-60-300x300.png 300w, https://corehandf.com/wp-content/uploads/2017/02/Benches__Racks_Incline_Press-60-100x100.png 100w, https://corehandf.com/wp-content/uploads/2017/02/Benches__Racks_Incline_Press-60-150x150.png 150w"
                  sizes="(max-width: 500px) 100vw, 500px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/nautilus-benches-racks-incline-bench-press/"
                  data-wpel-link="internal"
                >
                  Incline Bench Press
                </a>
              </div>
            </div>
          </div>
        </div>
        {/*/ .row */}
      </div>
      {/*/ .container */}
    </section>
    <section id="request-a-quote-form" className="section request-a-quote-form">
      <div className="container">
        <h2>
          <span>Start Your Consultation Form</span>
        </h2>
        <div className="row center-xs">
          <div className="col-md-6 offset-md-6">
            {/*[if lte IE 8]>

<![endif]*/}
          </div>
        </div>
      </div>
    </section>
    <a className="sticky-cta" href="#request-a-quote-form">
      <img src="/wp-content/uploads/2022/04/Icon_map-gym.png" />
      Start Consultation
    </a>{" "}
  </main>
  {/* /.main */}
</>

        
        </>
    )
}

export default PlateLoaded ;