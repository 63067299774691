import React from "react";
import Navbar from "../../CommonCompounds/Nav/Nav.jsx"
import Footer from "../../CommonCompounds/Footer/Footer.jsx";
import Rigs from "../../Compounds/Rigs/Rigs.jsx"
import Storageracks from "../../Compounds/StorageRacks/StorageRacks.jsx"



function StorageracksPage(){
    return(
        <>

       <Navbar />
       <Storageracks />
       <Footer />
        </>
    )
}
 export default StorageracksPage;