import React from "react";
import Navbar from "../../CommonCompounds/Nav/Nav.jsx"
import Contact from "../../Compounds/Contact/Contact.jsx";
import Footer from "../../CommonCompounds/Footer/Footer.jsx";



function Contactpage(){
    return(
        <>

       <Navbar />
       <Contact />
       <Footer />
        </>
    )
}
 export default Contactpage;