import React from "react";
import Navbar from "../../CommonCompounds/Nav/Nav.jsx";
import Footer from "../../CommonCompounds/Footer/Footer.jsx";
import Throwdown from "../../Compounds/Throwdown/Throwdown.jsx";

function ThrowdownPage() {
    return (
        <>
            <Navbar />
            <Throwdown />
            <Footer />
        </>
    );
}

export default ThrowdownPage;
