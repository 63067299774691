import React from "react";
import Navbar from "../../CommonCompounds/Nav/Nav.jsx";
import Footer from "../../CommonCompounds/Footer/Footer.jsx";
import Ymca from "../../Compounds/Ymca/Ymca.jsx";

function YmcaPage() {
    return (
        <>
            <Navbar />
            <Ymca />
            <Footer />
        </>
    );
}

export default YmcaPage;

