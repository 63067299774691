import { NavLink } from "react-router-dom";
import images from "../../images/img";
import React, { useState, useEffect } from "react";
import axios from 'axios'
import { API_URL } from '../../const';

const Nav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [homePageData, setHomePageData] = useState(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    getHomePageNavData();
  }, []);

  const getHomePageNavData = async () => {
    try {
      const response = await axios.get(`${API_URL}getHomePageMenus`);
      setHomePageData(response.data.result_Arr);
      console.log(response.data.result_Arr);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <header className="header" id="header-navbar">
        <div className="row">
          <div className="wrapper--header desktop-header">
            <div className="wrapper--logo-initial">
              <NavLink to="/home" data-wpel-link="internal">
                <img width="200px" alt="" src={images.Logo} />
              </NavLink>
            </div>
            <div className="wrapper--logos">
              <ul>
                <li>
                  <NavLink to="/StairMaster" id="StairMaster">
                    <img src={images.stairmaster} alt="StairMaster Logo" />
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/schwinn" id="SCHWINN">
                    <img src={images.schwinn} alt="Schwinn Logo" />
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/nautilus" id="Nautilus">
                    <img src={images.nautilus} alt="Nautilus Logo" />
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/startrac" id="StarTrac">
                    <img src={images.startrac} alt="Star Trac Logo" />
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/throwdown" id="ThrowDown">
                    <img src={images.throwdown} alt="ThrowDown Logo" />
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="wrapper--menu-header">
              <nav
                className="navbar navbar-expand-md navbar-light bg-light"
                role="navigation"
              >
                <div className="menu">
                  <nav id="site-navigation" className="main-navigation navbar">
                    <div id="navbar" className="navbar-collapse collapse">
                      <ul id="primary-menu" className="nav navbar-nav">
                        <li className="  dropdown">
                          <a
                            className="dropdown-toggle"
                            data-toggle="dropdown"
                            role="button"
                            aria-haspopup="true"
                            aria-expanded="false"
                            href="#"
                          >
                            Products
                            <span className="caret" />{" "}
                          </a>
                          <div className="dropdown-menu mega-menu nav">
                            <div className=" ">
                              <a className="section-label" href="#">
                                Categories{" "}
                              </a>
                              <ul className="nav sub-menu">
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                {/* First Column: Cardio */}
                                <div style={{ flex: '1' }}>
                                  <ul>
                                    {
                                      homePageData ?
                                        homePageData
                                          .filter(menu => menu.category_name === 'Cardio' || menu.category_name === 'Indoor Cycling' || menu.category_name === 'HIIT')
                                          .map((menu, index1) => (
                                            <React.Fragment key={`cardio-${index1}`}>
                                              <li className="menu-item-type-taxonomy menu-item-object-product_exercise">
                                                <span className="section-label">{menu.category_name}</span>
                                              </li>
                                              <ul className="nav sub-menu">
                                                {
                                                  menu.sub_category ?
                                                    menu.sub_category.map((subMenu, index2) => (
                                                      <li key={`subMenu-${index2}`}>
                                                        <a href={`/getProductsListById/${subMenu.sub_category_id}`} onClick={() => window.location.href = `/getProductsListById/${subMenu.sub_category_id}`}>
                                                          {subMenu.sub_category_name}
                                                        </a>
                                                      </li>
                                                    ))
                                                  : null
                                                }
                                              </ul>
                                            </React.Fragment>
                                          ))
                                        : null
                                    }
                                  </ul>
                                </div>

                                {/* Second Column: Strength */}
                                <div style={{ flex: '1' }}>
                                  <ul>
                                    {
                                      homePageData ?
                                        homePageData
                                          .filter(menu => menu.category_name === 'Strength' || menu.category_name === 'Impact Sports')
                                          .map((menu, index1) => (
                                            <React.Fragment key={`strength-${index1}`}>
                                              <li className="menu-item-type-taxonomy menu-item-object-product_exercise">
                                                <span className="section-label">{menu.category_name}</span>
                                              </li>
                                              <ul className="nav sub-menu">
                                                {
                                                  menu.sub_category ?
                                                    menu.sub_category.map((subMenu, index2) => (
                                                      <li key={`subMenu-${index2}`}>
                                                        <a href={`/getProductsListById/${subMenu.sub_category_id}`} onClick={() => window.location.href = `/startrac/getProductsListById/${subMenu.sub_category_id}`}>
                                                          {subMenu.sub_category_name}
                                                        </a>
                                                      </li>
                                                    ))
                                                  : null
                                                }
                                              </ul>
                                            </React.Fragment>
                                          ))
                                        : null
                                    }
                                  </ul>
                                </div>

                                {/* Third Column: Exercise */}
                                <div style={{ flex: '1' }}>
                                  <ul>
                                    {
                                      homePageData ?
                                        homePageData
                                          .filter(menu => menu.category_name === 'Exercise' || menu.category_name === 'Connected Fitness' || menu.category_name === 'Resources')
                                          .map((menu, index1) => (
                                            <React.Fragment key={`exercise-${index1}`}>
                                              <li className="menu-item-type-taxonomy menu-item-object-product_exercise">
                                                <span className="section-label">{menu.category_name}</span>
                                              </li>
                                              <ul className="nav sub-menu">
                                              {
  menu.sub_category ?
    menu.sub_category.map((subMenu, index2) => (
      <li key={`subMenu-${index2}`}>
        {
          <NavLink
            to={subMenu.sub_category_name === 'Customization' 
              ? '/Customization' 
              : `/getProductsListById/${subMenu.sub_category_id}`}
            onClick={() => {
              if (subMenu.sub_category_name === 'Customization') {
                window.location.href = '/startrac/Customization';
              } else {
                window.location.href = `/getProductsListById/${subMenu.sub_category_id}`;
              }
            }}
          >
            {subMenu.sub_category_name}
          </NavLink>
        }
      </li>
    ))
  : null
}

                                              </ul>
                                            </React.Fragment>
                                          ))
                                        : null
                                    }
                                  </ul>
                                </div>
                              </div>

                              </ul>
                            </div>
                            <div className=" menu-item-type-taxonomy menu-item-object-product_cat ">
                              <a className="section-label" href="#">
                                Brands{" "}
                              </a>
                              {/* if have image */}
                              <ul className="nav sub-menu">
                                <li className=" ">
                                  <a href="" data-wpel-link="internal">
                                    StairMaster{" "}
                                  </a>
                                  {/* if is section label */}
                                  {/* if have image */}
                                </li>
                                <li className=" ">
                                  <a href="" data-wpel-link="internal">
                                    Schwinn{" "}
                                  </a>
                                  {/* if is section label */}
                                  {/* if have image */}
                                </li>
                                <li className=" ">
                                  <a href="" data-wpel-link="internal">
                                    Nautilus{" "}
                                  </a>
                                  {/* if is section label */}
                                  {/* if have image */}
                                </li>
                                <li className=" ">
                                  <a href="" data-wpel-link="internal">
                                    Star Trac{" "}
                                  </a>
                                  {/* if is section label */}
                                  {/* if have image */}
                                </li>
                                <li className=" ">
                                  <a href="" data-wpel-link="internal">
                                    Throwdown{" "}
                                  </a>
                                  {/* if is section label */}
                                  {/* if have image */}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>

                        <li className="  dropdown">
                          <a
                            className="dropdown-toggle"
                            data-toggle="dropdown"
                            role="button"
                            aria-haspopup="true"
                            aria-expanded="false"
                            href="#"
                          >
                            Industries
                            <span className="caret" />{" "}
                          </a>
                          <div className="dropdown-menu mega-menu nav">
                            <div className="">
                              <span className="section-label">Industries</span>
                              <div className="row">
                                <div className="col-md-6">
                                  <ul className="nav sub">
                                    <li className="menu-item-type-taxonomy menu-item-object-product_market">
                                      <NavLink to="/Fitness" data-wpel-link="internal">
                                        Fitness Centers
                                      </NavLink>
                                    </li>
                                    <li className="menu-item-type-taxonomy menu-item-object-product_market">
                                      <NavLink to="/MultiUnitHousing" data-wpel-link="internal">
                                        Multi-Unit Housing Market
                                      </NavLink>
                                    </li>
                                    <li className="menu-item-type-taxonomy menu-item-object-product_market">
                                      <NavLink to="/Hospitality" data-wpel-link="internal">
                                        Hospitality
                                      </NavLink>
                                    </li>
                                    <li className="menu-item-type-taxonomy menu-item-object-product_market">
                                      <NavLink to="/CorporateFitness" data-wpel-link="internal">
                                        Corporate Fitness
                                      </NavLink>
                                    </li>
                                    <li className="menu-item-type-taxonomy menu-item-object-product_market">
                                      <NavLink to="/MunicipalitieSrecreation" data-wpel-link="internal">
                                        Municipalities, Parks & Recreation
                                      </NavLink>
                                    </li>
                                  </ul>
                                </div>
                                <div className="col-md-6">
                                  <ul className="nav sub">
                                    <li className="menu-item-type-taxonomy menu-item-object-product_market">
                                      <NavLink to="/AthleticRecCenter" data-wpel-link="internal">
                                        Athletic & Rec Centers
                                      </NavLink>
                                    </li>
                                    <li className="menu-item-type-taxonomy menu-item-object-product_market">
                                      <NavLink href="/Ymca" data-wpel-link="internal">
                                        YMCA
                                      </NavLink>
                                    </li>
                                    <li className="menu-item-type-taxonomy menu-item-object-product_market">
                                      <NavLink href="/RehabFacility" data-wpel-link="internal">
                                        Rehab Facility
                                      </NavLink>
                                    </li>
                                    <li className="menu-item-type-taxonomy menu-item-object-product_market">
                                      <NavLink href="/GovernmentMilitary" data-wpel-link="internal">
                                        Government & Military
                                      </NavLink>
                                    </li>
                                    <li className="menu-item-type-taxonomy menu-item-object-product_market">
                                      <NavLink href="/BuilderDeveloper" data-wpel-link="internal">
                                        Builder / Developer
                                      </NavLink>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>

                        <li className=" dropdown">
                          <a
                            className="dropdown-toggle"
                            data-toggle="dropdown"
                            role="button"
                            aria-haspopup="true"
                            aria-expanded="false"
                            href="#"
                          >
                            Why Core
                            <span className="caret" />{" "}
                          </a>
                          <div className="dropdown-menu mega-menu nav">
                            <div className=" ">
                              {/* if have image */}
                              <NavLink to="/OurCulture" data-wpel-link="internal" activeClassName="active">
                                <span className="section-label">
                                Our Culture

                                </span>
                                <div className="image-hidden-menu">
                                <img src={images.whycore2} />
                                </div>
                                <p>
                                Driven by our Core Values, we strive to be the greatest company you’ll ever partner with or work for!{" "}
                                </p>
                                <span className="p-relative btn btn--arrow  btn-black">
                                  Learn more
                                </span>
                              </NavLink>
                              {/* if is section label */}
                              {/* if have image */}
                            </div>
                            
                            <div className=" ">
                              {/* if have image */}
                              <NavLink to="/SocialResponsibility" activeClassName="active" data-wpel-link="internal">
                                <span className="section-label">
                                  Social Responsibility
                                </span>
                                <div className="image-hidden-menu">
                                <img src={images.whycore3} />
                                </div>
                                <p>
                                At Core we share a passion for wellbeing, community and sustainability.{" "}
                                </p>
                                <span className="p-relative btn btn--arrow  btn-black">
                                  Learn more
                                </span>
                              </NavLink>
                              {/* if is section label */}
                              {/* if have image */}
                            </div>
                            <div className=" ">
                              {/* if have image */}
                       
                              <NavLink to="/About" activeClassName="active" data-wpel-link="internal">
                                <span className="section-label">
                                Why Core 
                                </span>
                                <div className="image-hidden-menu">
                                <img src={images.whycore4} />
                                </div>
                                <p>
                                Find a Core Product Certification Training near you.{" "}
                                </p>
                                <span className="p-relative btn btn--arrow  btn-black">
                                  Learn more
                                </span>
                              </NavLink>
                              {/* if is section label */}
                              {/* if have image */}
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
                {/* menu */}
              </nav>
            </div>
            <div className="wrapper--search">
              <form
                role="search"
                className="search-form"
                action=""
                method=""
              >
                <label>
                  <span className="screen-reader-text">Search for:</span>
                  <input
                    type="search"
                    className="search-field"
                    placeholder="Search …"
                    defaultValue=""
                    name="s"
                  />
                  <input
                    type="hidden"
                    className="search-field"
                    defaultValue="product"
                    name="post_type"
                  />
                </label>
                <button type="submit" className="search-submit button">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="20px"
                    version="1.1"
                    id="Capa_1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 56.966 56.966"
                    style={{ enableBackground: "new 0 0 56.966 56.966" }}
                    xmlSpace="preserve"
                    height="18px"
                  >
                    <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
                  </svg>
                </button>
              </form>
              <div className="wrapper--language">
                <span>EN</span>
                <ul className="sub-menu">
                  <li>
                    <a href="" data-wpel-link="internal">
                      PL
                    </a>
                  </li>
                  <li>
                    <a href="" data-wpel-link="internal">
                      ES
                    </a>
                  </li>
                  <li>
                    <a href="" data-wpel-link="internal">
                      DE
                    </a>
                  </li>
                </ul>
                <span className="ico" />{" "}
              </div>
            </div>
            <div className="wrapper--main">
              <div className="menu-contact-container">
                <ul id="menu-contact" className="menu">
                  <li
                    id="menu-item-81183"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-81183"
                  >
                    <a href="" data-wpel-link="internal">
                      Blog
                    </a>
                  </li>
                  <li
                    id="menu-item-50536"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-50536"
                  >
                    <NavLink to="/contact" activeClassName="active">
                      Contact
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="nav-middle mobile-header">
          <div className="row">
            <div className="col-xs-6 col-left logo-header-mobile">
              <div className="wrapper--logo-initial">
              <NavLink to="/home" data-wpel-link="internal">
                <img width="200px" alt="" src={images.Logo} />
              </NavLink>
              </div>
              <div className="wrapper--logos">
                <ul>
                  <li>
                    <NavLink to="/StairMaster" id="StairMaster">
                      <img
                        src="https://corehandf.com/wp-content/uploads/2021/10/logoasociate_StairMaster-3.png"
                        alt="StairMaster Logo"
                      />
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/schwinn" id="SCHWINN">
                      <img
                        src="https://corehandf.com/wp-content/uploads/2021/10/Schwinn-Logo-Black-1-1024x287-4.webp"
                        alt="Schwinn Logo"
                      />
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/nautilus" id="Nautilus">
                      <img
                        src="https://corehandf.com/wp-content/uploads/2021/10/logoasociate_Nautilus-3.png"
                        alt="Nautilus Logo"
                      />
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/startrac" id="StarTrac">
                      <img
                        src="https://corehandf.com/wp-content/uploads/2021/10/logoasociate_startrac-3.png"
                        alt="Star Trac Logo"
                      />
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/throwdown" id="ThrowDown">
                      <img
                        src="https://corehandf.com/wp-content/uploads/2021/10/logoasociate_throwoown-3.png"
                        alt="ThrowDown Logo"
                      />
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xs-  col-right">
              <div className="mobile-redirect">
                <a className="mobile-search" href="#">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="20px"
                    version="1.1"
                    id="Capa_1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 56.966 56.966"
                    style={{ enableBackground: "new 0 0 56.966 56.966" }}
                    xmlSpace="preserve"
                    height="20px"
                    fill="#0AADF0"
                  >
                    <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
                  </svg>
                </a>
                <button className="hamburger hamburger--spin js-hamburger">
                  <span className="hamburger-box">
                    <span className="hamburger-inner" />
                  </span>
                </button>
              </div>
              <div className="mobile-search-cnt">
                <form
                  role="search"
                  className="mobile-search-form"
                  action=""
                  method="GET"
                >
                  <label>
                    <span className="screen-reader-text">Search for:</span>
                    <input
                      type="search"
                      className="search-field"
                      placeholder="Search …"
                      defaultValue=""
                      name="s"
                    />
                    <input
                      type="hidden"
                      className="search-field"
                      defaultValue="product"
                      name="post_type"
                    />
                  </label>
                  <button type="submit" className="search-submit button">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink=""
                      width="20px"
                      version="1.1"
                      id="Capa_1"
                      x="0px"
                      y="0px"
                      viewBox="0 0 56.966 56.966"
                      style={{ enableBackground: "new 0 0 56.966 56.966" }}
                      xmlSpace="preserve"
                      height="20px"
                      fill="#0AADF0"
                    >
                      <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
                    </svg>
                  </button>
                </form>
              </div>
              <div className="mobile-topnav-hidden">
                <div className="wrapper--language">
                  <span>EN</span>
                  <ul className="sub-menu">
                    <li>
                      <a href="" data-wpel-link="internal">
                        PL
                      </a>
                    </li>
                    <li>
                      <a href="" data-wpel-link="internal">
                        ES
                      </a>
                    </li>
                    <li>
                      <a href="" data-wpel-link="internal">
                        DE
                      </a>
                    </li>
                  </ul>
                  <span className="ico" />{" "}
                </div>
                <ul id="menu-main-header" className="menu-header">
                  <li
                    id="menu-item-50423"
                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-50423"
                  >
                    <a href="#">Products</a>
                    <ul className="sub-menu">
                      <li
                        id="menu-item-50424"
                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-50424"
                      >
                        <a href="#">Categories</a>
                        <ul className="sub-menu">
                          <li
                            id="menu-item-50429"
                            className="menu-item menu-item-type-taxonomy menu-item-object-product_exercise menu-item-has-children menu-item-50429"
                          >
                            <a href="" data-wpel-link="internal">
                              Cardio
                            </a>
                            <ul className="sub-menu">
                              <li
                                id="menu-item-53453"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-53453"
                              >
                                <a
                                  href="/product-category/type/main-floor-cardio/"
                                  data-wpel-link="internal"
                                >
                                  All Cardio
                                </a>
                              </li>
                              <li
                                id="menu-item-86478"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-86478"
                              >
                                <a href="" data-wpel-link="internal">
                                  Virtual Bike
                                </a>
                              </li>
                              <li
                                id="menu-item-50431"
                                className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-50431"
                              >
                                <a href="" data-wpel-link="internal">
                                  Treadmills
                                </a>
                              </li>
                              <li
                                id="menu-item-85977"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-85977"
                              >
                                <a href="" data-wpel-link="internal">
                                  Climbing
                                </a>
                              </li>
                              <li
                                id="menu-item-50434"
                                className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-50434"
                              >
                                <a href="" data-wpel-link="internal">
                                  Ellipticals
                                </a>
                              </li>
                              <li
                                id="menu-item-50443"
                                className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-50443"
                              >
                                <a href="" data-wpel-link="internal">
                                  Upright &amp; Recumbent Bikes
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li
                            id="menu-item-50444"
                            className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-has-children menu-item-50444"
                          >
                            <a href="" data-wpel-link="internal">
                              Indoor Cycling
                            </a>
                            <ul className="sub-menu">
                              <li
                                id="menu-item-53475"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-53475"
                              >
                                <a href="" data-wpel-link="internal">
                                  All Indoor Cycling
                                </a>
                              </li>
                              <li
                                id="menu-item-50446"
                                className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-50446"
                              >
                                <a href="" data-wpel-link="internal">
                                  Bike Accessories
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li
                            id="menu-item-50451"
                            className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-50451"
                          >
                            <a href="" data-wpel-link="internal">
                              HIIT
                            </a>
                            <ul className="sub-menu">
                              <li
                                id="menu-item-53476"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-53476"
                              >
                                <a href="" data-wpel-link="internal">
                                  All HIIT
                                </a>
                              </li>
                              <li
                                id="menu-item-58500"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-58500"
                              >
                                <a href="" data-wpel-link="internal">
                                  HIIT UBE
                                </a>
                              </li>
                              <li
                                id="menu-item-86689"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-86689"
                              >
                                <a href="" data-wpel-link="internal">
                                  HIIT Line
                                </a>
                              </li>
                              <li
                                id="menu-item-86690"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-86690"
                              >
                                <a href="" data-wpel-link="internal">
                                  HIITMill &amp; HIITMill X
                                </a>
                              </li>
                              <li
                                id="menu-item-86692"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-86692"
                              >
                                <a href="" data-wpel-link="internal">
                                  HIIT Rower
                                </a>
                              </li>
                              <li
                                id="menu-item-87115"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-87115"
                              >
                                <a href="" data-wpel-link="internal">
                                  HIIT Ski
                                </a>
                              </li>
                              <li
                                id="menu-item-87116"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-87116"
                              >
                                <a href="" data-wpel-link="internal">
                                  HIIT Rope
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li
                            id="menu-item-50452"
                            className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-has-children menu-item-50452"
                          >
                            <a href="" data-wpel-link="internal">
                              Strength
                            </a>
                            <ul className="sub-menu">
                              <li
                                id="menu-item-85964"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-85964"
                              >
                                <a href="" data-wpel-link="internal">
                                  One
                                </a>
                              </li>
                              <li
                                id="menu-item-85965"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-85965"
                              >
                                <a href="" data-wpel-link="internal">
                                  Inspiration&nbsp;
                                </a>
                              </li>
                              <li
                                id="menu-item-86136"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-86136"
                              >
                                <a href="" data-wpel-link="internal">
                                  Instinct
                                </a>
                              </li>
                              <li
                                id="menu-item-85966"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-85966"
                              >
                                <a href="" data-wpel-link="internal">
                                  Impact
                                </a>
                              </li>
                              <li
                                id="menu-item-85967"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-85967"
                              >
                                <a href="" data-wpel-link="internal">
                                  Human Sport
                                </a>
                              </li>
                              <li
                                id="menu-item-85968"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-85968"
                              >
                                <a href="" data-wpel-link="internal">
                                  Leverage
                                </a>
                              </li>
                              <li
                                id="menu-item-85969"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-85969"
                              >
                                <a href="" data-wpel-link="internal">
                                  Plate Loaded
                                </a>
                              </li>
                              <li
                                id="menu-item-85970"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-85970"
                              >
                                <a href="" data-wpel-link="internal">
                                  Multi-Stations
                                </a>
                              </li>
                              <li
                                id="menu-item-85971"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-85971"
                              >
                                <a href="" data-wpel-link="internal">
                                  Benches
                                </a>
                              </li>
                              <li
                                id="menu-item-85972"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-85972"
                              >
                                <a href="" data-wpel-link="internal">
                                  Racks &amp; Platforms
                                </a>
                              </li>
                              <li
                                id="menu-item-50459"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-50459"
                              >
                                <a href="" data-wpel-link="internal">
                                  Free Weights
                                </a>
                              </li>
                              <li
                                id="menu-item-50460"
                                className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-50460"
                              >
                                <a href="" data-wpel-link="internal">
                                  Rigs
                                </a>
                              </li>
                              <li
                                id="menu-item-50461"
                                className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-50461"
                              >
                                <a href="" data-wpel-link="internal">
                                  Storage Racks
                                </a>
                              </li>
                              <li
                                id="menu-item-50467"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-50467"
                              >
                                <a href="" data-wpel-link="internal">
                                  FIT Accessories
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li
                            id="menu-item-50468"
                            className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-50468"
                          >
                            <a href="#">Impact Sports</a>
                            <ul className="sub-menu">
                              <li
                                id="menu-item-50462"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-50462"
                              >
                                <a href="" data-wpel-link="internal">
                                  Bag Racks
                                </a>
                              </li>
                              <li
                                id="menu-item-50466"
                                className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-50466"
                              >
                                <a href="" data-wpel-link="internal">
                                  Accessories
                                </a>
                              </li>
                              <li
                                id="menu-item-87752"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-87752"
                              >
                                <a href="" data-wpel-link="internal">
                                  Kevlar
                                </a>
                              </li>
                              <li
                                id="menu-item-50465"
                                className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-50465"
                              >
                                <a href="" data-wpel-link="internal">
                                  BoxMaster
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li
                            id="menu-item-50475"
                            className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-50475"
                          >
                            <a href="#">Exercise</a>
                            <ul className="sub-menu">
                              <li
                                id="menu-item-84997"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-84997"
                              >
                                <a href="" data-wpel-link="internal">
                                  Pilates
                                </a>
                              </li>
                              <li
                                id="menu-item-50476"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-50476"
                              >
                                <a href="" data-wpel-link="internal">
                                  Cardio
                                </a>
                              </li>
                              <li
                                id="menu-item-50477"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-50477"
                              >
                                <a href="" data-wpel-link="internal">
                                  10 Series
                                </a>
                              </li>
                              <li
                                id="menu-item-50478"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-50478"
                              >
                                <a href="" data-wpel-link="internal">
                                  8 Series
                                </a>
                              </li>
                              <li
                                id="menu-item-50479"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-50479"
                              >
                                <a href="" data-wpel-link="internal">
                                  4 Series
                                </a>
                              </li>
                              <li
                                id="menu-item-50480"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-50480"
                              >
                                <a href="" data-wpel-link="internal">
                                  S Series
                                </a>
                              </li>
                              <li
                                id="menu-item-50481"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-50481"
                              >
                                <a href="" data-wpel-link="internal">
                                  High Intensity
                                </a>
                              </li>
                              <li
                                id="menu-item-50482"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-50482"
                              >
                                <a href="" data-wpel-link="internal">
                                  Strength Training
                                </a>
                              </li>
                              <li
                                id="menu-item-52426"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-52426"
                              >
                                <a href="" data-wpel-link="internal">
                                  Group Cycling
                                </a>
                              </li>
                              <li
                                id="menu-item-69337"
                                className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-69337"
                              >
                                <a href="" data-wpel-link="internal">
                                  Jacobs Ladder
                                </a>
                              </li>
                              <li
                                id="menu-item-50455"
                                className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-50455"
                              >
                                <a href="" data-wpel-link="internal">
                                  Functional
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li
                            id="menu-item-50428"
                            className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-50428"
                          >
                            <a href="" data-wpel-link="internal">
                              Connected Fitness
                            </a>
                            <ul className="sub-menu">
                              <li
                                id="menu-item-50425"
                                className="menu-item menu-item-type-taxonomy menu-item-object-product_types menu-item-50425"
                              >
                                <a href="" data-wpel-link="internal">
                                  Cardio Consoles
                                </a>
                              </li>
                              <li
                                id="menu-item-50426"
                                className="menu-item menu-item-type-taxonomy menu-item-object-product_types menu-item-50426"
                              >
                                <a href="" data-wpel-link="internal">
                                  Cycling Consoles
                                </a>
                              </li>
                              <li
                                id="menu-item-52429"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-52429"
                              >
                                <a href="" data-wpel-link="internal">
                                  HIIT Consoles
                                </a>
                              </li>
                              <li
                                id="menu-item-50427"
                                className="menu-item menu-item-type-taxonomy menu-item-object-product_types menu-item-50427"
                              >
                                <a href="" data-wpel-link="internal">
                                  Personal Viewing Screens
                                </a>
                              </li>
                              <li
                                id="menu-item-52430"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-52430"
                              >
                                <a href="" data-wpel-link="internal">
                                  Partners
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li
                            id="menu-item-50469"
                            className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-50469"
                          >
                            <a href="#">Resources</a>
                            <ul className="sub-menu">
                              <li
                                id="menu-item-50470"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-50470"
                              >
                                <a
                                  href=""
                                  data-wpel-link="external"
                                  target="_blank"
                                  rel="nofollow external noopener noreferrer"
                                >
                                  Core Content Hub
                                </a>
                              </li>
                              <li
                                id="menu-item-50471"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-50471"
                              >
                                <a
                                  href=""
                                  data-wpel-link="external"
                                  target="_blank"
                                  rel="nofollow external noopener noreferrer"
                                >
                                  Product Sheets
                                </a>
                              </li>
                              <li
                                id="menu-item-50472"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-50472"
                              >
                                <a href="" data-wpel-link="internal">
                                  Catalog
                                </a>
                              </li>
                              <li
                                id="menu-item-52425"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-52425"
                              >
                                <a href="" data-wpel-link="internal">
                                  Customization
                                </a>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                      <li
                        id="menu-item-50473"
                        className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-has-children menu-item-50473"
                      >
                        <a href="" data-wpel-link="internal">
                          Brands
                        </a>
                        <ul className="sub-menu">
                          <li
                            id="menu-item-81339"
                            className="menu-item menu-item-type-custom menu-item-object-custom menu-item-81339"
                          >
                            <a href="" data-wpel-link="internal">
                              StairMaster
                            </a>
                          </li>
                          <li
                            id="menu-item-81340"
                            className="menu-item menu-item-type-custom menu-item-object-custom menu-item-81340"
                          >
                            <a href="" data-wpel-link="internal">
                              Schwinn
                            </a>
                          </li>
                          <li
                            id="menu-item-81341"
                            className="menu-item menu-item-type-custom menu-item-object-custom menu-item-81341"
                          >
                            <a href="" data-wpel-link="internal">
                              Nautilus
                            </a>
                          </li>
                          <li
                            id="menu-item-81342"
                            className="menu-item menu-item-type-custom menu-item-object-custom menu-item-81342"
                          >
                            <a href="" data-wpel-link="internal">
                              Star Trac
                            </a>
                          </li>
                          <li
                            id="menu-item-81343"
                            className="menu-item menu-item-type-custom menu-item-object-custom menu-item-81343"
                          >
                            <a href="" data-wpel-link="internal">
                              Throwdown
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li
                        id="menu-item-50487"
                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-50487"
                      >
                        <a href="#">Shops</a>
                        <ul className="sub-menu"></ul>
                      </li>
                    </ul>
                  </li>

                  <li
                    id="menu-item-50464"
                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-50464"
                  >
                    <a href="#">Industries</a>
                    <ul className="sub-menu">
                      <li
                        id="menu-item-50493"
                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-50493"
                      >
                        <a href="#">Industries</a>
                        <ul className="sub-menu">
                          <li
                            id="menu-item-50513"
                            className="menu-item menu-item-type-taxonomy menu-item-object-product_market menu-item-50513"
                          >
                            <a href="" data-wpel-link="internal">
                              Fitness Centers
                            </a>
                          </li>
                          <li
                            id="menu-item-52538"
                            className="menu-item menu-item-type-taxonomy menu-item-object-product_market menu-item-52538"
                          >
                            <a href="" data-wpel-link="internal">
                              Multi-Unit Housing Market
                            </a>
                          </li>
                          <li
                            id="menu-item-50494"
                            className="menu-item menu-item-type-taxonomy menu-item-object-product_market menu-item-50494"
                          >
                            <a href="" data-wpel-link="internal">
                              Hospitality
                            </a>
                          </li>
                          <li
                            id="menu-item-50495"
                            className="menu-item menu-item-type-taxonomy menu-item-object-product_market menu-item-50495"
                          >
                            <a href="" data-wpel-link="internal">
                              Corporate Fitness
                            </a>
                          </li>
                          <li
                            id="menu-item-50496"
                            className="menu-item menu-item-type-taxonomy menu-item-object-product_market menu-item-50496"
                          >
                            <a href="" data-wpel-link="internal">
                              Municipalities, Parks &amp; Recreation
                            </a>
                          </li>
                          <li
                            id="menu-item-52539"
                            className="menu-item menu-item-type-taxonomy menu-item-object-product_market menu-item-52539"
                          >
                            <a href="" data-wpel-link="internal">
                              Athletic &amp; Rec Centers
                            </a>
                          </li>
                          <li
                            id="menu-item-52540"
                            className="menu-item menu-item-type-taxonomy menu-item-object-product_market menu-item-52540"
                          >
                            <a href="" data-wpel-link="internal">
                              YMCA
                            </a>
                          </li>
                          <li
                            id="menu-item-52541"
                            className="menu-item menu-item-type-taxonomy menu-item-object-product_market menu-item-52541"
                          >
                            <a href="" data-wpel-link="internal">
                              Rehab Facility
                            </a>
                          </li>
                          <li
                            id="menu-item-50500"
                            className="menu-item menu-item-type-taxonomy menu-item-object-product_market menu-item-50500"
                          >
                            <a href="" data-wpel-link="internal">
                              Government &amp; Military
                            </a>
                          </li>
                          <li
                            id="menu-item-50511"
                            className="menu-item menu-item-type-custom menu-item-object-custom menu-item-50511"
                          >
                            <a href="" data-wpel-link="internal">
                              Builder / Developer
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li
                    id="menu-item-50503"
                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-50503"
                  >
                    <a href="#">Support</a>
                    <ul className="sub-menu">
                      <li
                        id="menu-item-50506"
                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-50506"
                      >
                        <a
                          target="_blank"
                          rel="noopener nofollow external noreferrer"
                          href=""
                          data-wpel-link="external"
                        >
                          Core Connect
                        </a>
                      </li>
                      <li
                        id="menu-item-50505"
                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-50505"
                      >
                        <a
                          href=""
                          data-wpel-link="external"
                          target="_blank"
                          rel="nofollow external noopener noreferrer"
                        >
                          Customer Service
                        </a>
                      </li>
                      <li
                        id="menu-item-50507"
                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-50507"
                      >
                        <a
                          href=""
                          data-wpel-link="external"
                          target="_blank"
                          rel="nofollow external noopener noreferrer"
                        >
                          Service Training Opportunities
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li
                    id="menu-item-50504"
                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-50504"
                  >
                    <a href="#">Why Core</a>
                    <ul className="sub-menu">
                      <li
                        id="menu-item-50510"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-50510"
                      >
                        <a href="" data-wpel-link="internal">
                          Our Culture
                        </a>
                      </li>
                      <li
                        id="menu-item-50508"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-50508"
                      >
                        <a href="" data-wpel-link="internal">
                          Careers
                        </a>
                      </li>
                      <li
                        id="menu-item-50509"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-50509"
                      >
                        <a href="" data-wpel-link="internal">
                          Social Responsibility
                        </a>
                      </li>
                      <li
                        id="menu-item-50512"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-50512"
                      >
                        <a href="" data-wpel-link="internal">
                          Why Core
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Nav;
