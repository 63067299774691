import React  from "react";
import Navber from "../../CommonCompounds/Nav/Nav";
import Footer from "../../CommonCompounds/Footer/Footer";
import ALLCordio from "../../Compounds/ProductCategory/type/main-floor-cardio/AllCardio";


function BoxmasterPage(){
    return (
        <>
         <Navber />
         <ALLCordio />
         <Footer />
        </>
    )
}

export default BoxmasterPage;