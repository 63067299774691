import React from "react";
import Form from "../../CommonCompounds/Form/Form";
import ProductGallery from "../../CommonCompounds/CarourselProduct/CarourselProduct";


function MunicipalitieSrecreation(){
    return (
  <> 
  <main className="main" id="main">
    <section className="breadcrumbs">
      <ul>
        {/*li><a href="#">Home</a></li>
          <li class="current">Star Trac</li*/}
        <li>
          <span>
            <span>
              <a href="https://corehandf.com/" data-wpel-link="internal">
                Home
              </a>
            </span>{" "}
            »{" "}
            <span className="breadcrumb_last" aria-current="page">
              Municipalities, Parks &amp; Recreation
            </span>
          </span>
        </li>
      </ul>
    </section>
    <section
      className="section_slide solutions-banner row"
      style={{
        backgroundImage:
          "url(https://corehandf.com/wp-content/uploads/2022/03/Core-Parks-And-Rac-Install-001-Lifestyle-Image-scaled.jpg)"
      }}
    >
      <div className="col-xs-12 col-sm-8 col-md-8 col-lg-5">
        <h1>
          <strong>Municipalities,</strong> Parks &amp; Recreation
        </h1>
      </div>
    </section>
    <section className="section single-content">
      <div className="container small">
        <div className="row">
          <div className="col-xs-12">
            <p>
              Whether you’re breaking ground on a new facility, remodeling an
              existing space, or simply replacing existing equipment our One
              Team approach ensures we’re aligned from start to finish. When it
              comes to your fitness space, we understand that it’s as much a
              space for members to achieve their fitness goals as it is a space
              for the community to come together.{" "}
            </p>
            <p>
              With a global network of partners, we’re able to guide you through
              space design, equipment selection, the importance of flooring and
              so much more. When you request a consultation, your regional rep
              will get to know your goals and understand the vision you have for
              your facility so they can provide the solutions that blend
              seamlessly with your overall design. Below are a few of the items
              you’ll be guided through:{" "}
            </p>
            <p>
              • 2D Rendering
              <br /> • 3D Layout
              <br /> • Budget
              <br /> • Financing{" "}
            </p>
            <p>
              When you start a consultation, your regional rep will utilize
              years of industry knowledge along with the latest FISA data to
              ensure you’re maximizing your space and are on trend with what’s
              being installed in your market and around the world. Below are
              just a few of the properties we’ve been able to collaborate with
              and go from concept to completion.{" "}
            </p>
            <a className="cta-button" href="#embed-form">
              Request a Consultation
            </a>
            <div
              id="metaslider-id-57841"
              style={{ width: "100%" }}
              className="ml-slider-3-90-1 metaslider metaslider-flex metaslider-57841 ml-slider ms-theme-cubic has-dots-nav"
              role="region"
              aria-roledescription="Slideshow"
              aria-label="Industry: Municipalities, Parks and Recreation"
            >
              <div id="metaslider_container_57841">
                <div id="metaslider_57841">
                  <ul className="slides">
                    <li
                      style={{ display: "block", width: "100%" }}
                      className="slide-58084 ms-image "
                      aria-roledescription="slide"
                      aria-label="slide-58084"
                    >
                      <img
                        src="https://corehandf.com/wp-content/uploads/2021/12/Club-Police-Education-School-Star-Trac-2-scaled-770x500.jpg"
                        height={500}
                        width={770}
                        alt="Club Police Education School Star Trac 2"
                        className="slider-57841 slide-58084"
                        title="Club Police Education School Star Trac 2"
                      />
                    </li>
                    <li
                      style={{ display: "none", width: "100%" }}
                      className="slide-60962 ms-image "
                      aria-roledescription="slide"
                      aria-label="slide-60962"
                    >
                      <img
                        src="https://corehandf.com/wp-content/uploads/2022/02/MicrosoftTeams-image-10-770x500.jpg"
                        height={500}
                        width={770}
                        alt="Parks & Rec"
                        className="slider-57841 slide-60962"
                        title="Parks & Rec"
                      />
                    </li>
                    <li
                      style={{ display: "none", width: "100%" }}
                      className="slide-58089 ms-image "
                      aria-roledescription="slide"
                      aria-label="slide-58089"
                    >
                      <img
                        src="https://corehandf.com/wp-content/uploads/2021/12/Club-Police-Education-School-Nautilus-2-scaled-770x500.jpg"
                        height={500}
                        width={770}
                        alt="Club Police Education School Nautilus 2"
                        className="slider-57841 slide-58089"
                        title="Club Police Education School Nautilus 2"
                      />
                    </li>
                    <li
                      style={{ display: "none", width: "100%" }}
                      className="slide-57950 ms-image "
                      aria-roledescription="slide"
                      aria-label="slide-57950"
                    >
                      <img
                        src="https://corehandf.com/wp-content/uploads/2021/11/Municipalities-Parks-and-Rec-640x415.webp"
                        height={500}
                        width={770}
                        alt="Municipalities Parks and Rec"
                        className="slider-57841 slide-57950"
                        title="Municipalities Parks and Rec"
                      />
                    </li>
                    <li
                      style={{ display: "none", width: "100%" }}
                      className="slide-57842 ms-image "
                      aria-roledescription="slide"
                      aria-label="slide-57842"
                    >
                      <img
                        src="https://corehandf.com/wp-content/uploads/2019/03/1440x600-middleburg-heights-11-770x500.jpg"
                        height={500}
                        width={770}
                        alt=""
                        className="slider-57841 slide-57842"
                        title="1440x600-middleburg-heights-1[1]"
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="centers-section" style={{ display: "none" }}>
      <div className="swiper">
        <div className="swiper-container">
          <div className="swiper-wrapper">
            <div
              className="swiper-slide"
              style={{
                backgroundImage:
                  'url("https://corehandf.com/wp-content/uploads/2017/10/1440x600-middleburg-heights-1.jpg")'
              }}
            >
              <div className="caption"></div>
            </div>
            <div
              className="swiper-slide"
              style={{
                backgroundImage:
                  'url("https://corehandf.com/wp-content/uploads/2021/11/Municipalities-Parks-and-Rec.webp")'
              }}
            >
              <div className="caption"></div>
            </div>
          </div>
        </div>
        <a href="#" className="swiper-button-prev  2" />
        <a href="#" className="swiper-button-next  2" />
      </div>
    </section>
   <section>
    <ProductGallery />
   </section>
    <section className="request-a-quote-form">
    <div className="container">
      <h2 id="request-a-quote-form" className="section-title"><span>Start Your Consultation Today</span></h2>
      <div className="row center-xs">
        <div className="col-md-6 offset-md-6">
        <Form />
        </div>
      </div>
    </div>
  </section>
  </main>
  {/* /.main */}
</>

    )
}

export default MunicipalitieSrecreation;