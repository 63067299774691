import React from "react";
import Navbar from "../../CommonCompounds/Nav/Nav.jsx"
import Footer from "../../CommonCompounds/Footer/Footer.jsx";
import Functional from "../../Compounds/Functional/Functional.jsx";


function FunctionalPage(){
    return(
        <>

       <Navbar />
       <Functional />
       <Footer />
        </>
    )
}
 export default FunctionalPage;

 