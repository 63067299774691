import React  from "react";
import ProductGallery from "../../CommonCompounds/CarourselProduct/CarourselProduct";
import Form from "../../CommonCompounds/Form/Form";


function CorporateFitness(){
    return (
        <>
<main className="main" id="main">
  <section className="breadcrumbs">
    <ul>
      {/*li><a href="#">Home</a></li>
          <li class="current">Star Trac</li*/}
      <li>
        <span>
          <span>
            <a href="https://corehandf.com/" data-wpel-link="internal">
              Home
            </a>
          </span>{" "}
          »{" "}
          <span className="breadcrumb_last" aria-current="page">
            Corporate Fitness
          </span>
        </span>
      </li>
    </ul>
  </section>
  <section
    className="section_slide solutions-banner row"
    style={{
      backgroundImage:
        "url(https://corehandf.com/wp-content/uploads/2022/03/Core-Corprate-Fitness-001-Lifestyle-Image.jpg)"
    }}
  >
    <div className="col-xs-12 col-sm-8 col-md-8 col-lg-5">
      <h1>
        <strong>Corporate</strong> Fitness
      </h1>
    </div>
  </section>
  <section className="section single-content">
    <div className="container small">
      <div className="row">
        <div className="col-xs-12">
          <p>
            At Core Health &amp; Fitness we know a thing or two about the
            importance of fitness and overall health for our employees. With a
            network of global partners, we’re able to ensure facilities like
            ours and yours can connect to the latest in wearable technology so
            that each employee is able to track their workout their way. Whether
            you’re breaking ground on a new facility, remodeling an existing
            space, or simply replacing existing equipment our One Team approach
            ensures we’re aligned from start to finish.{" "}
          </p>
          <p>
            Our portfolio of commercial brands – StairMaster, Schwinn, Nautilus,
            Star Trac and Throwdown – not only accommodate a wide range of
            aesthetics, but also provide a multitude of training options to keep
            pace with the trends and match the expectations you’ve set for your
            organization. When you request a consultation, your regional rep
            will get to know your goals and understand your company culture to
            ensure we provide the solutions that are right for your employees.
            Below are a few of the items you’ll be guided through:{" "}
          </p>
          <p>
            • 2D Rendering
            <br /> • 3D Layout
            <br /> • Budget
            <br /> • Financing{" "}
          </p>
          <p>
            Since you’re here, you already know the positive impact of fitness
            on productivity and all around mental and physical health. The next
            step is to share your vision with one of our industry experts and
            allow them to guide you through the process of brining your space to
            life. Below are just a few of the properties we’ve been able to
            collaborate with and go from concept to completion.{" "}
          </p>
          <a className="cta-button" href="#embed-form">
            Request a Consultation
          </a>
          <div
            id="metaslider-id-57836"
            style={{ width: "100%" }}
            className="ml-slider-3-90-1 metaslider metaslider-flex metaslider-57836 ml-slider ms-theme-cubic has-dots-nav"
            role="region"
            aria-roledescription="Slideshow"
            aria-label="Industry: Corporate Fitness"
          >
            <div id="metaslider_container_57836">
              <div id="metaslider_57836">
                <ul className="slides">
                  <li
                    style={{ display: "block", width: "100%" }}
                    className="slide-57840 ms-image "
                    aria-roledescription="slide"
                    aria-label="slide-57840"
                  >
                    <img
                      src="https://corehandf.com/wp-content/uploads/2021/09/Corporate-770x500.png"
                      height={500}
                      width={770}
                      alt="Corporate Fitness Star Trac 4 Series"
                      className="slider-57836 slide-57840"
                      title="Corporate"
                    />
                  </li>
                  <li
                    style={{ display: "none", width: "100%" }}
                    className="slide-58099 ms-image "
                    aria-roledescription="slide"
                    aria-label="slide-58099"
                  >
                    <img
                      src="https://corehandf.com/wp-content/uploads/2021/12/Ardent-Fitness-Star-Trac-4-Series-770x500.jpg"
                      height={500}
                      width={770}
                      alt="Ardent Fitness Star Trac 4 Series"
                      className="slider-57836 slide-58099"
                      title="Ardent Fitness Star Trac 4 Series"
                    />
                  </li>
                  <li
                    style={{ display: "none", width: "100%" }}
                    className="slide-60987 ms-image "
                    aria-roledescription="slide"
                    aria-label="slide-60987"
                  >
                    <img
                      src="https://corehandf.com/wp-content/uploads/2022/02/MicrosoftTeams-image-2-770x500.jpg"
                      height={500}
                      width={770}
                      alt="Corporate Fitness"
                      className="slider-57836 slide-60987"
                      title="Corporate Fitness"
                    />
                  </li>
                  <li
                    style={{ display: "none", width: "100%" }}
                    className="slide-57949 ms-image "
                    aria-roledescription="slide"
                    aria-label="slide-57949"
                  >
                    <img
                      src="https://corehandf.com/wp-content/uploads/2021/11/Corporate-Fitness-770x500.webp"
                      height={500}
                      width={770}
                      alt="Corporate Fitness"
                      className="slider-57836 slide-57949"
                      title="Corporate Fitness"
                    />
                  </li>
                  <li
                    style={{ display: "none", width: "100%" }}
                    className="slide-58098 ms-image "
                    aria-roledescription="slide"
                    aria-label="slide-58098"
                  >
                    <img
                      src="https://corehandf.com/wp-content/uploads/2021/12/Hub-Schwinn-SC-Power-e1638812930212-770x500.jpg"
                      height={500}
                      width={770}
                      alt="Hub Schwinn SC Power"
                      className="slider-57836 slide-58098"
                      title="Hub Schwinn SC Power"
                    />
                  </li>
                  <li
                    style={{ display: "none", width: "100%" }}
                    className="slide-58173 ms-image "
                    aria-roledescription="slide"
                    aria-label="slide-58173"
                  >
                    <img
                      src="https://corehandf.com/wp-content/uploads/2021/12/Rise-and-Grind-Nautilus-Multistation-2-1-700x454.jpg"
                      height={500}
                      width={770}
                      alt="Rise and Grind Nautilus Multistation 2"
                      className="slider-57836 slide-58173"
                      title="Rise and Grind Nautilus Multistation 2"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="centers-section" style={{ display: "none" }}>
    <div className="swiper">
      <div className="swiper-container">
        <div className="swiper-wrapper">
          <div
            className="swiper-slide"
            style={{
              backgroundImage:
                'url("https://corehandf.com/wp-content/uploads/2021/09/Corporate.png")'
            }}
          >
            <div className="caption"></div>
          </div>
          <div
            className="swiper-slide"
            style={{
              backgroundImage:
                'url("https://corehandf.com/wp-content/uploads/2021/11/Corporate-Fitness.webp")'
            }}
          >
            <div className="caption"></div>
          </div>
        </div>
      </div>
      <a href="#" className="swiper-button-prev  2" />
      <a href="#" className="swiper-button-next  2" />
    </div>
  </section>
 <section>
  <ProductGallery />
 </section>
  <section className="request-a-quote-form">
    <div className="container">
      <h2 id="request-a-quote-form" className="section-title"><span>Start Your Consultation Today</span></h2>
      <div className="row center-xs">
        <div className="col-md-6 offset-md-6">
        <Form />
        </div>
      </div>
    </div>
  </section>
</main>

         
        </>
    )
}
export default CorporateFitness;