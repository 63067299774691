import React from "react";
import Navbar from "../../CommonCompounds/Nav/Nav.jsx"
import Footer from "../../CommonCompounds/Footer/Footer.jsx";
import JacobsLadder from "../../Compounds/JacobsLadder/JacobsLadder.jsx";


function JacobsladderPage(){
    return(
        <>

       <Navbar />
       <JacobsLadder />
       <Footer />
        </>
    )
}
 export default JacobsladderPage;