import React from "react";


function Catalogue() {
  return (
    <>
      <main className="main  mb-250" id="main">
        <section className="breadcrumbs">
          <ul>
            <li>
              <span>
                <span>
                  <a href="https://corehandf.com/" data-wpel-link="internal">Home</a>
                </span>
                » <span className="breadcrumb_last" aria-current="page">Core Health &amp; Fitness Catalogue</span>
              </span>
            </li>
          </ul>
        </section>
        <section className="section_slide solutions-banner row" style={{ backgroundImage: "url()" }}>
          <div className="col-xs-12 col-sm-8 col-md-8 col-lg-5">
            <h1>Core Health &amp; Fitness Catalogue</h1>
          </div>
        </section>
        <div id="content">
          <div className="content-area" id="primary">
            <div id="tabs">
              <div className="container">
                <div className="row">
                  <div className="col">
                    <ul role="tablist">
                      {/* <li>
                        <a href="#tabs-1">English Catalogue</a>
                      </li> */}
                      <span className="drop-sel" />
                    </ul>
                  </div>
                </div>
              </div>
              <div id="tabs-1">
                <div className="container s1-wrap">
                  <div className="row">
                    <div className="col-xs-12 mt-4">
                      <h2>English Catalogue</h2>
                      <div className="s-content">
                        <div id="ypembedcontainer" className="embed-container" data-page-width={495} data-page-height={640}>
                          <iframe title="Core Brand Catalog" src="https://www.yumpu.com/en/embed/view/OM73dyInfqQp9RPC" frameBorder={0} allowFullScreen="allowfullscreen" />
                        </div>
                        <p></p>
                      </div>
                      <div className="red-btn-spacing" />
                      <a
          className="btn btn-line--blue btn-black mb-4 btn-blue"
          href=""
          data-wpel-link="internal"
        >
          Download {" "}
        </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* #primary */}
        </div>
        {/* Container end */}
      </main>
    </>
  );
}

export default Catalogue;
