import React  from "react";
import Navbar from "../../CommonCompounds/Nav/Nav.jsx";
import Footer from "../../CommonCompounds/Footer/Footer.jsx";

import Impact from "../../Compounds/Impact/Impact.jsx";

function HumansportPage (){
    return (
        <>  
        <Navbar />
        <Impact />
        <Footer />


        </>
    )
}

export default HumansportPage;