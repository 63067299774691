import React from "react";
import Navbar from "../../CommonCompounds/Nav/Nav.jsx";
import Footer from "../../CommonCompounds/Footer/Footer.jsx";
import Catalogue from "../../Compounds/Catalogue/Catalogue.jsx";  

function CataloguePage() {
    return (
        <>
            <Navbar />
            <Catalogue />
            <Footer />
        </>
    );
}

export default CataloguePage;
