import React  from "react";
import Form from "../../CommonCompounds/Form/Form";


function Instinct (){
    return(
        <>
<>
  <main className="main" id="main">
    <section className="breadcrumbs">
      <ul>
        {/*li><a href="#">Home</a></li>
          <li class="current">Star Trac</li*/}
        <li>
          <span>
            <span>
              <a href="https://corehandf.com/" data-wpel-link="internal">
                Home
              </a>
            </span>{" "}
            »{" "}
            <span>
              <a
                href="https://corehandf.com/product_line/"
                data-wpel-link="internal"
              >
                Product Lines
              </a>
            </span>{" "}
            »{" "}
            <span>
              <a
                href="https://corehandf.com/product_line/nautilus-brand/"
                data-wpel-link="internal"
              >
                Nautilus
              </a>
            </span>{" "}
            »{" "}
            <span className="breadcrumb_last" aria-current="page">
              Instinct
            </span>
          </span>
        </li>
      </ul>
    </section>
    <section
      className="section product-line-banner-section"
      style={{
        background:
          "url(https://corehandf.com/wp-content/uploads/2023/04/Image-Lifestyle-Inspiration-Hero-Shot-Wide.webp) top center no-repeat",
        backgroundSize: "cover"
      }}
    >
      <div className="product-line-banner">
        <div className="content-wrap">
          <img src="https://corehandf.com/wp-content/uploads/2019/04/Nautilus-Logo-all-white1.png" />
          <h1 className="banner-title largest">Instinct</h1>
        </div>
      </div>
    </section>
    <section className="section product-line-intro-section container">
      <h2>EASY TO USE. EASY ON SPACE.</h2>
      <p>
        Welcoming to users of all levels, Instinct Strength is a full line of
        intuitive machines with easy adjustments, offering users a quick and
        complete workout. Perfectly suited to be a basic strength line or
        circuit system, Instinct effectively complements more specialized lines.
        Users can complete a circuit on their own or with the assistance of an
        instructor in just 30 minutes.
      </p>
      <p>
        Instinct Strength offers both single and dual-function units to meet the
        needs of any space or facility. The versatility of Instinct’s small
        footprint also lets you get the most from minimal space while blending
        seamlessly with our cardio line for a cohesive facility look.
      </p>
    </section>
    <section className="section full-width-column-section container image-left">
      <div className="row">
        <div className="col-md-5">
          <div className="img-video-wrap">
            <div style={{ padding: "100% 0 0 0", position: "relative" }}>
              <iframe
                src="https://player.vimeo.com/video/791206580?h=02b66042f1&badge=0&autopause=0&player_id=0&app_id=58479"
                frameBorder={0}
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen=""
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%"
                }}
                title="Lock N Load"
              />
            </div>
          </div>
        </div>
        <div className="col-md-7">
          <div className="content-wrap">
            <h2>LOCK N LOAD WEIGHT SELECTION</h2>
            <p>
              Selecting a weight using the intuitive Lock N Load design is as
              easy as flicking a switch. Universally color-coded switches engage
              and disengage the weight via an internal pin, providing a safer,
              more durable and maintenance-free operation. Lock N Load prevents
              unnecessary maintenance headaches since there are no pins to lose
              or replace
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className="section fifty-fifty-column-section container">
      <div className="row gx-5">
        <div className="col-md-6">
          <div className="content-wrap">
            <div style={{ padding: "100% 0 0 0", position: "relative" }}>
              <iframe
                src="https://player.vimeo.com/video/791211635?h=a491e331eb&badge=0&autopause=0&player_id=0&app_id=58479"
                frameBorder={0}
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen=""
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%"
                }}
                title="Nautilus Inspiration Tower Lenticular Animated"
              />
            </div>
            <h2>DUAL USE MACHINES</h2>
            <p>
              The versatile dual purpose machines from Nautilus Instinct have a
              modest footprint and allow you to switch between two different
              exercises with a simple adjustment. This is ideal for smaller
              facilities, giving them the ability to fit a full fitness circuit
              in a much smaller space.
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="content-wrap">
            <img src="https://corehandf.com/wp-content/uploads/2023/04/Instinct-Triceps-Extension-scaled.webp" />
            <h2>DESIGNED FOR ALL LEVELS</h2>
            <p>
              Designed for the needs of the vertical market, for light
              commercial and users of all levels
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className="section fifty-fifty-column-section container">
      <div className="row gx-5">
        <div className="col-md-6">
          <div className="content-wrap">
            <img src="https://corehandf.com/wp-content/uploads/2023/04/Nautilus-INSTINCT-Dual-Pectoral-Fly-Rear-Deltoid-9NL-D2110-03-Product-Image.webp" />
            <h2>LOW PROFILE TOWERS</h2>
            <p>
              Low profile towers &lt;55” tall with transparent, smoked
              plexiglass shrouds Low profile towers &lt;55” tall with
              transparent, smoked plexiglass shrouds
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="content-wrap">
            <img src="https://corehandf.com/wp-content/uploads/2023/04/Image-Nautilus-Instinct-Lat-Pull-Down-web.webp" />
            <h2>A COMPLETE SOLUTION</h2>
            <p>
              Instinct offers a full line of Dual and Single-functioning
              selectorized strength machines for complete circuit training or
              complementing other specialized lines.
            </p>
            <p>
              Plus, a complete line of benches and racks and a light-commercial
              Dual Adjustable Pulley cable machine. All together, Nautilus
              Instinct is positioned as the price conscious solution for your
              fitness needs.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className="series-prods-section">
      <div className="container">
        <h2>Browse Instinct Products</h2>
        <div className="row">
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/nautilus-instinct-dual-abdominal-lower-back/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/nautilus-instinct-dual-abdominal-lower-back/"
                data-wpel-link="internal"
              >
                <img
                  width={500}
                  height={500}
                  src="https://corehandf.com/wp-content/uploads/2017/02/Instinct_AbCrunch_-60AR.png"
                  className="attachment-large size-large wp-post-image"
                  alt="Instinct_AbCrunch_-60AR"
                  decoding="async"
                  fetchpriority="high"
                  srcSet="https://corehandf.com/wp-content/uploads/2017/02/Instinct_AbCrunch_-60AR.png 500w, https://corehandf.com/wp-content/uploads/2017/02/Instinct_AbCrunch_-60AR-300x300.png 300w, https://corehandf.com/wp-content/uploads/2017/02/Instinct_AbCrunch_-60AR-100x100.png 100w, https://corehandf.com/wp-content/uploads/2017/02/Instinct_AbCrunch_-60AR-150x150.png 150w"
                  sizes="(max-width: 500px) 100vw, 500px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/nautilus-instinct-dual-abdominal-lower-back/"
                  data-wpel-link="internal"
                >
                  Instinct® Dual Abdominal/Lower Back
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/nautilus-instinct-dual-biceps-curl-triceps-extension/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/nautilus-instinct-dual-biceps-curl-triceps-extension/"
                data-wpel-link="internal"
              >
                <img
                  width={500}
                  height={500}
                  src="https://corehandf.com/wp-content/uploads/2017/02/Instinct_Dual_Biceps_Curl_Triceps_Extension_-60AR.png"
                  className="attachment-large size-large wp-post-image"
                  alt="Instinct_Dual_Biceps_Curl_Triceps_Extension_-60AR"
                  decoding="async"
                  srcSet="https://corehandf.com/wp-content/uploads/2017/02/Instinct_Dual_Biceps_Curl_Triceps_Extension_-60AR.png 500w, https://corehandf.com/wp-content/uploads/2017/02/Instinct_Dual_Biceps_Curl_Triceps_Extension_-60AR-300x300.png 300w, https://corehandf.com/wp-content/uploads/2017/02/Instinct_Dual_Biceps_Curl_Triceps_Extension_-60AR-100x100.png 100w, https://corehandf.com/wp-content/uploads/2017/02/Instinct_Dual_Biceps_Curl_Triceps_Extension_-60AR-150x150.png 150w"
                  sizes="(max-width: 500px) 100vw, 500px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/nautilus-instinct-dual-biceps-curl-triceps-extension/"
                  data-wpel-link="internal"
                >
                  Instinct® Dual Biceps Curl/Triceps Extension
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/nautilus-instinct-biceps-curl/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/nautilus-instinct-biceps-curl/"
                data-wpel-link="internal"
              >
                <img
                  width={500}
                  height={500}
                  src="https://corehandf.com/wp-content/uploads/2017/02/Instinct_BicepsCurl_-60AR.png"
                  className="attachment-large size-large wp-post-image"
                  alt="Instinct_BicepsCurl_-60AR"
                  decoding="async"
                  srcSet="https://corehandf.com/wp-content/uploads/2017/02/Instinct_BicepsCurl_-60AR.png 500w, https://corehandf.com/wp-content/uploads/2017/02/Instinct_BicepsCurl_-60AR-300x300.png 300w, https://corehandf.com/wp-content/uploads/2017/02/Instinct_BicepsCurl_-60AR-100x100.png 100w, https://corehandf.com/wp-content/uploads/2017/02/Instinct_BicepsCurl_-60AR-150x150.png 150w"
                  sizes="(max-width: 500px) 100vw, 500px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/nautilus-instinct-biceps-curl/"
                  data-wpel-link="internal"
                >
                  Instinct® Biceps Curl
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="/product-category/brand/nautilus/instinct-line/"
              data-wpel-link="internal"
            >
              <div className="card">
                <div className="card-body">
                  <h3>
                    View All
                    <br />
                    Instinct
                  </h3>
                </div>
              </div>
            </a>
          </div>
        </div>
        {/*/ .row */}
      </div>
      {/*/ .container */}
    </section>
    <section className="frequently-purchased-with-prods-section">
      <div className="container">
        <h2>Frequently Purchased With</h2>
        <div className="row">
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/stairmaster-4g/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/stairmaster-4g/"
                data-wpel-link="internal"
              >
                <img
                  width={1024}
                  height={1024}
                  src="https://corehandf.com/wp-content/uploads/2023/03/StairMaster-4G-15in-OpenHub-Console-Product-Image-WEB-1024x1024.png"
                  className="attachment-large size-large wp-post-image"
                  alt=""
                  decoding="async"
                  loading="lazy"
                  srcSet="https://corehandf.com/wp-content/uploads/2023/03/StairMaster-4G-15in-OpenHub-Console-Product-Image-WEB-1024x1024.png 1024w, https://corehandf.com/wp-content/uploads/2023/03/StairMaster-4G-15in-OpenHub-Console-Product-Image-WEB-300x300.png 300w, https://corehandf.com/wp-content/uploads/2023/03/StairMaster-4G-15in-OpenHub-Console-Product-Image-WEB-150x150.png 150w, https://corehandf.com/wp-content/uploads/2023/03/StairMaster-4G-15in-OpenHub-Console-Product-Image-WEB-768x768.png 768w, https://corehandf.com/wp-content/uploads/2023/03/StairMaster-4G-15in-OpenHub-Console-Product-Image-WEB-70x70.png 70w, https://corehandf.com/wp-content/uploads/2023/03/StairMaster-4G-15in-OpenHub-Console-Product-Image-WEB-600x600.png 600w, https://corehandf.com/wp-content/uploads/2023/03/StairMaster-4G-15in-OpenHub-Console-Product-Image-WEB-100x100.png 100w, https://corehandf.com/wp-content/uploads/2023/03/StairMaster-4G-15in-OpenHub-Console-Product-Image-WEB.png 1500w"
                  sizes="(max-width: 1024px) 100vw, 1024px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/stairmaster-4g/"
                  data-wpel-link="internal"
                >
                  4G
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/4tr-treadmill/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/4tr-treadmill/"
                data-wpel-link="internal"
              >
                <img
                  width={500}
                  height={500}
                  src="https://corehandf.com/wp-content/uploads/2018/12/4TR_OpenHub.png"
                  className="attachment-large size-large wp-post-image"
                  alt="4TR_OpenHub"
                  decoding="async"
                  loading="lazy"
                  srcSet="https://corehandf.com/wp-content/uploads/2018/12/4TR_OpenHub.png 500w, https://corehandf.com/wp-content/uploads/2018/12/4TR_OpenHub-300x300.png 300w, https://corehandf.com/wp-content/uploads/2018/12/4TR_OpenHub-100x100.png 100w, https://corehandf.com/wp-content/uploads/2018/12/4TR_OpenHub-150x150.png 150w"
                  sizes="(max-width: 500px) 100vw, 500px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/4tr-treadmill/"
                  data-wpel-link="internal"
                >
                  4TR Treadmill
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/nautilus-instinct-multi-adjustable-bench-2/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/nautilus-instinct-multi-adjustable-bench-2/"
                data-wpel-link="internal"
              >
                <img
                  width={500}
                  height={500}
                  src="https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Benches-Racks-Multi-Adjust-Bench-9NN-B7501-60AR.png"
                  className="attachment-large size-large wp-post-image"
                  alt="Nautilus - Benches & Racks - Multi-Adjust Bench - 9NN-B7501 -60AR"
                  decoding="async"
                  loading="lazy"
                  srcSet="https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Benches-Racks-Multi-Adjust-Bench-9NN-B7501-60AR.png 500w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Benches-Racks-Multi-Adjust-Bench-9NN-B7501-60AR-300x300.png 300w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Benches-Racks-Multi-Adjust-Bench-9NN-B7501-60AR-100x100.png 100w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Benches-Racks-Multi-Adjust-Bench-9NN-B7501-60AR-150x150.png 150w"
                  sizes="(max-width: 500px) 100vw, 500px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/nautilus-instinct-multi-adjustable-bench-2/"
                  data-wpel-link="internal"
                >
                  Instinct Multi-Adjustable Bench
                </a>
              </div>
            </div>
          </div>
        </div>
        {/*/ .row */}
      </div>
      {/*/ .container */}
    </section>
    <section id="request-a-quote-form" className="section request-a-quote-form quote-form">
      <div className="container">
        <h2>
          <span>Start Your Consultation Today</span>
        </h2>
        <div className="row center-xs">
          <div className="col-md-6 offset-md-6">
          <Form />
          </div>
        </div>
      </div>
    </section>
    <a className="sticky-cta" href="#request-a-quote-form">
      <img src="/wp-content/uploads/2022/04/Icon_map-gym.png" />
      Start Consultation
    </a>{" "}
  </main>
  {/* /.main */}
</>


        </>
    )
}

export default Instinct ;