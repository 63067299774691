import React  from "react";
import Navber from "../../CommonCompounds/Nav/Nav";
import BuilderDeveloper from "../../Compounds/BuilderDeveloper/BuilderDeveloper"
import Footer from "../../CommonCompounds/Footer/Footer";


function BuilderDeveloperPage(){
    return (
        <>
         <Navber />
         <BuilderDeveloper />
         <Footer />
        </>
    )
}

export default BuilderDeveloperPage;