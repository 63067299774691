import React from "react";
import Navbar from "../../CommonCompounds/Nav/Nav.jsx";
import Footer from "../../CommonCompounds/Footer/Footer.jsx";
import BikeCustomization from "../../Compounds/Customization/bikeCustomization.jsx"

function BikeCustomizationPage () {
    return (
        <>
            <Navbar />
              <BikeCustomization />
            <Footer />
        </>
    );
}

export default BikeCustomizationPage ;