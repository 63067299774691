import React from "react";
import Navbar from "../../CommonCompounds/Nav/Nav.jsx";
import Footer from "../../CommonCompounds/Footer/Footer.jsx";

import NotFound from "../../Compounds/NotFound/NotFound.jsx";

function NotFoundPage() {
    return (
        <>
            <Navbar />
            <NotFound />
            <Footer />
        </>
    );
}

export default NotFoundPage;
