import React from "react";


function Benches(){
    return(
        <>
        <>
  <main className="main" id="main">
    <section className="breadcrumbs">
      <ul>
        {/*li><a href="#">Home</a></li>
          <li class="current">Star Trac</li*/}
        <li>
          <span>
            <span>
              <a href="https://corehandf.com/" data-wpel-link="internal">
                Home
              </a>
            </span>{" "}
            »{" "}
            <span>
              <a
                href="https://corehandf.com/product_line/"
                data-wpel-link="internal"
              >
                Product Lines
              </a>
            </span>{" "}
            »{" "}
            <span>
              <a
                href="https://corehandf.com/product_line/nautilus-brand/"
                data-wpel-link="internal"
              >
                Nautilus
              </a>
            </span>{" "}
            »{" "}
            <span className="breadcrumb_last" aria-current="page">
              Benches
            </span>
          </span>
        </li>{" "}
      </ul>
    </section>
    <section
      className="section product-line-banner-section"
      style={{
        background:
          "url(https://corehandf.com/wp-content/uploads/2023/04/Nautilus_Benches-e1684790823959.png) top center no-repeat",
        backgroundSize: "cover"
      }}
    >
      <div className="product-line-banner">
        <div className="content-wrap">
          <img src="https://corehandf.com/wp-content/uploads/2019/04/Nautilus-Logo-all-white1.png" />
          <h1 className="banner-title largest">Benches</h1>
        </div>
      </div>
    </section>
    <section className="section product-line-intro-section container">
      <h2>STRENGTH BUILT STRONGER</h2>
      <p>
        From Olympic benches that can be customized with or without weight
        storage to our one-of-a-kind abdominal and back extension benches, we’ve
        raised the bar for both aesthetics and function. Our benches and racks
        flow seamlessly with our other products, so you will notice a family
        look and feel as you position your free weights alongside our other
        strength lines. Our benches and racks don’t just look strong; they
        exceed industry requirements in all respects.
      </p>
    </section>
    <section className="section full-width-column-section container image-right">
      <div className="row">
        <div className="col-md-7">
          <div className="content-wrap">
            <h2>A PERFECT PARTNERSHIP</h2>
            <p>
              Throwdown Free Weights pair perfectly with Nautilus Strength
              products allowing you to get everything in one place. You can even
              find free weight sets crafted specifically for popular Nautilus
              Benches &amp; Racks, Leverage, and Plate-Loaded products.
            </p>
          </div>
        </div>
        <div className="col-md-5">
          <div className="img-video-wrap">
            <img src="https://corehandf.com/wp-content/uploads/2023/04/Throwdown-Weights-GripDiscs_Hero.webp" />
          </div>
        </div>
      </div>
    </section>
    <section className="section fifty-fifty-column-section container">
      <div className="row gx-5">
        <div className="col-md-6">
          <div className="content-wrap">
            <img src="https://corehandf.com/wp-content/uploads/2023/04/Nautilus-INSTINCT-Benches-Racks-Multi-Adjustable-Bench-100-9NN-B7506-05-Product-Image-1.png" />
            <h2>THOUGHTFUL DESIGN</h2>
            <p>
              Nautilus Benches have been designed not only for eye-catching
              aesthetics but to also withstand the wear and tear of constant
              use. Featuring molded urethane bar holders for quiet and safe bar
              placement, benches with integrated grips and wheels for easy
              transport, and all with rubber feet for floor protection.
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="content-wrap">
            <img src="https://corehandf.com/wp-content/uploads/2023/04/Olympic-Bench.png" />
            <h2>A COMPLETE SOLUTION</h2>
            <p>
              Is a bench simply just a bench? At Nautilus, we think the answer
              is no. Even when designing our Olympic Flat Bench, we are
              intentional and creating solutions. To prevent bar damage and to
              reduce noise, our bar catches are molded urethane. For
              portability, our benches have integrated handles, rubber feet, and
              smooth, upgraded wheels to protect your flooring. And our benches
              can be customized with or without integrated weight storage to
              maximize your weight room’s floor plan. We’ve raised the bar for
              both aesthetics and function.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className="series-prods-section">
      <div className="container">
        <h2>Browse Benches Products</h2>
        <div className="row">
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/nautilus-benches-racks-decline-bench-press/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/nautilus-benches-racks-decline-bench-press/"
                data-wpel-link="internal"
              >
                <img
                  width={500}
                  height={500}
                  src="https://corehandf.com/wp-content/uploads/2017/02/Benches__Racks_Decline_Bench_Press-60.png"
                  className="attachment-large size-large wp-post-image"
                  alt=""
                  decoding="async"
                  fetchpriority="high"
                  srcSet="https://corehandf.com/wp-content/uploads/2017/02/Benches__Racks_Decline_Bench_Press-60.png 500w, https://corehandf.com/wp-content/uploads/2017/02/Benches__Racks_Decline_Bench_Press-60-300x300.png 300w, https://corehandf.com/wp-content/uploads/2017/02/Benches__Racks_Decline_Bench_Press-60-100x100.png 100w, https://corehandf.com/wp-content/uploads/2017/02/Benches__Racks_Decline_Bench_Press-60-150x150.png 150w"
                  sizes="(max-width: 500px) 100vw, 500px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/nautilus-benches-racks-decline-bench-press/"
                  data-wpel-link="internal"
                >
                  Decline Bench Press
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/nautilus-benches-racks-incline-bench-press/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/nautilus-benches-racks-incline-bench-press/"
                data-wpel-link="internal"
              >
                <img
                  width={500}
                  height={500}
                  src="https://corehandf.com/wp-content/uploads/2017/02/Benches__Racks_Incline_Press-60.png"
                  className="attachment-large size-large wp-post-image"
                  alt=""
                  decoding="async"
                  srcSet="https://corehandf.com/wp-content/uploads/2017/02/Benches__Racks_Incline_Press-60.png 500w, https://corehandf.com/wp-content/uploads/2017/02/Benches__Racks_Incline_Press-60-300x300.png 300w, https://corehandf.com/wp-content/uploads/2017/02/Benches__Racks_Incline_Press-60-100x100.png 100w, https://corehandf.com/wp-content/uploads/2017/02/Benches__Racks_Incline_Press-60-150x150.png 150w"
                  sizes="(max-width: 500px) 100vw, 500px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/nautilus-benches-racks-incline-bench-press/"
                  data-wpel-link="internal"
                >
                  Incline Bench Press
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/nautilus-benches-racks-olympic-bench-press/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/nautilus-benches-racks-olympic-bench-press/"
                data-wpel-link="internal"
              >
                <img
                  width={500}
                  height={500}
                  src="https://corehandf.com/wp-content/uploads/2017/02/Benches__Racks_Olympic_Bench_Press-60.png"
                  className="attachment-large size-large wp-post-image"
                  alt=""
                  decoding="async"
                  srcSet="https://corehandf.com/wp-content/uploads/2017/02/Benches__Racks_Olympic_Bench_Press-60.png 500w, https://corehandf.com/wp-content/uploads/2017/02/Benches__Racks_Olympic_Bench_Press-60-300x300.png 300w, https://corehandf.com/wp-content/uploads/2017/02/Benches__Racks_Olympic_Bench_Press-60-100x100.png 100w, https://corehandf.com/wp-content/uploads/2017/02/Benches__Racks_Olympic_Bench_Press-60-150x150.png 150w"
                  sizes="(max-width: 500px) 100vw, 500px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/nautilus-benches-racks-olympic-bench-press/"
                  data-wpel-link="internal"
                >
                  Olympic Bench Press
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="/product-category/brand/nautilus/benches/"
              data-wpel-link="internal"
            >
              <div className="card">
                <div className="card-body">
                  <h3>
                    View All
                    <br />
                    Benches
                  </h3>
                </div>
              </div>
            </a>
          </div>
        </div>
        {/*/ .row */}
      </div>
      {/*/ .container */}
    </section>
    <section className="frequently-purchased-with-prods-section">
      <div className="container">
        <h2>Frequently Purchased With</h2>
        <div className="row">
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/nautilus-benches-racks-half-rack/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/nautilus-benches-racks-half-rack/"
                data-wpel-link="internal"
              >
                <img
                  width={500}
                  height={500}
                  src="https://corehandf.com/wp-content/uploads/2017/02/Benches__Racks_Half_Rack-60.png"
                  className="attachment-large size-large wp-post-image"
                  alt="Nautilus - Benches & Racks - Half Rack - 60"
                  decoding="async"
                  loading="lazy"
                  srcSet="https://corehandf.com/wp-content/uploads/2017/02/Benches__Racks_Half_Rack-60.png 500w, https://corehandf.com/wp-content/uploads/2017/02/Benches__Racks_Half_Rack-60-300x300.png 300w, https://corehandf.com/wp-content/uploads/2017/02/Benches__Racks_Half_Rack-60-100x100.png 100w, https://corehandf.com/wp-content/uploads/2017/02/Benches__Racks_Half_Rack-60-150x150.png 150w"
                  sizes="(max-width: 500px) 100vw, 500px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/nautilus-benches-racks-half-rack/"
                  data-wpel-link="internal"
                >
                  Half Rack
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/nautilus-instinct-dumbbell-rack-10-pair-2-tier/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/nautilus-instinct-dumbbell-rack-10-pair-2-tier/"
                data-wpel-link="internal"
              >
                <img
                  width={500}
                  height={236}
                  src="https://corehandf.com/wp-content/uploads/2016/12/Dumbell_Rack_10_2_Tier.png"
                  className="attachment-large size-large wp-post-image"
                  alt=""
                  decoding="async"
                  loading="lazy"
                  srcSet="https://corehandf.com/wp-content/uploads/2016/12/Dumbell_Rack_10_2_Tier.png 500w, https://corehandf.com/wp-content/uploads/2016/12/Dumbell_Rack_10_2_Tier-300x142.png 300w"
                  sizes="(max-width: 500px) 100vw, 500px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/nautilus-instinct-dumbbell-rack-10-pair-2-tier/"
                  data-wpel-link="internal"
                >
                  Instinct® Dumbbell Rack 10-Pair/2 Tier
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/nautilus-instinct-dumbbell-rack-10-pair-3-tier/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/nautilus-instinct-dumbbell-rack-10-pair-3-tier/"
                data-wpel-link="internal"
              >
                <img
                  width={500}
                  height={500}
                  src="https://corehandf.com/wp-content/uploads/2017/02/BenchesRacks_Instinct_3_Dumbbell_Rack-60.png"
                  className="attachment-large size-large wp-post-image"
                  alt=""
                  decoding="async"
                  loading="lazy"
                  srcSet="https://corehandf.com/wp-content/uploads/2017/02/BenchesRacks_Instinct_3_Dumbbell_Rack-60.png 500w, https://corehandf.com/wp-content/uploads/2017/02/BenchesRacks_Instinct_3_Dumbbell_Rack-60-300x300.png 300w, https://corehandf.com/wp-content/uploads/2017/02/BenchesRacks_Instinct_3_Dumbbell_Rack-60-100x100.png 100w, https://corehandf.com/wp-content/uploads/2017/02/BenchesRacks_Instinct_3_Dumbbell_Rack-60-150x150.png 150w"
                  sizes="(max-width: 500px) 100vw, 500px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/nautilus-instinct-dumbbell-rack-10-pair-3-tier/"
                  data-wpel-link="internal"
                >
                  Instinct Dumbbell Rack 10-Pair/3 Tier
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/nautilus-benches-racks-2-sided-olympic-weight-tree/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/nautilus-benches-racks-2-sided-olympic-weight-tree/"
                data-wpel-link="internal"
              >
                <img
                  width={500}
                  height={500}
                  src="https://corehandf.com/wp-content/uploads/2017/02/Benches__Racks_2_Sided_Olympic_Weight_Tree-26.png"
                  className="attachment-large size-large wp-post-image"
                  alt="Nautilus - Benches & Racks - 2 Sided Olympic Weight Tree - 26"
                  decoding="async"
                  loading="lazy"
                  srcSet="https://corehandf.com/wp-content/uploads/2017/02/Benches__Racks_2_Sided_Olympic_Weight_Tree-26.png 500w, https://corehandf.com/wp-content/uploads/2017/02/Benches__Racks_2_Sided_Olympic_Weight_Tree-26-300x300.png 300w, https://corehandf.com/wp-content/uploads/2017/02/Benches__Racks_2_Sided_Olympic_Weight_Tree-26-100x100.png 100w, https://corehandf.com/wp-content/uploads/2017/02/Benches__Racks_2_Sided_Olympic_Weight_Tree-26-150x150.png 150w"
                  sizes="(max-width: 500px) 100vw, 500px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/nautilus-benches-racks-2-sided-olympic-weight-tree/"
                  data-wpel-link="internal"
                >
                  2-Sided Olympic Weight Tree
                </a>
              </div>
            </div>
          </div>
        </div>
        {/*/ .row */}
      </div>
      {/*/ .container */}
    </section>
    <section id="request-a-quote-form" className="section request-a-quote-form quote-form">
      <div className="container">
        <h2>
          <span>Start Your Consultation Today</span>
        </h2>
        <div className="row center-xs">
          <div className="col-md-6 offset-md-6">
            {/*[if lte IE 8]>

<![endif]*/}
          </div>
        </div>
      </div>
    </section>
    <a className="sticky-cta" href="#request-a-quote-form">
      <img src="/wp-content/uploads/2022/04/Icon_map-gym.png" />
      Start Consultation
    </a>{" "}
  </main>
  {/* /.main -
   */}
</>

        </>
    )
}

export default Benches;