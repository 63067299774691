import React from "react";
import Navbar from "../../CommonCompounds/Nav/Nav.jsx"

import Footer from "../../CommonCompounds/Footer/Footer.jsx";
import PlateLoaded from "../../Compounds/PlateLoaded/PlateLoaded.jsx";


function MultiUnitHousing(){
    return(
        <>

       <Navbar />
       <PlateLoaded />
       <Footer />
        </>
    )
}
 export default MultiUnitHousing;