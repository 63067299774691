import React  from "react";


function FitAccessories(){
    return (

        <>
  <main className="main" id="main">
    <section className="breadcrumbs">
      <ul>
        {/*li><a href="#">Home</a></li>
          <li class="current">Star Trac</li*/}
        <li>
          <span>
            <span>
              <a href="https://corehandf.com/" data-wpel-link="internal">
                Home
              </a>
            </span>{" "}
            »{" "}
            <span>
              <a
                href="https://corehandf.com/product_line/"
                data-wpel-link="internal"
              >
                Product Lines
              </a>
            </span>{" "}
            »{" "}
            <span>
              <a
                href="https://corehandf.com/product_line/throwdown-3/"
                data-wpel-link="internal"
              >
                Throwdown
              </a>
            </span>{" "}
            »{" "}
            <span className="breadcrumb_last" aria-current="page">
              Fit Accessories
            </span>
          </span>
        </li>{" "}
      </ul>
    </section>
    <section
      className="section product-line-banner-section"
      style={{
        background:
          "url(https://corehandf.com/wp-content/uploads/2023/04/Throwdown_FXD_HeroShot.webp) top center no-repeat",
        backgroundSize: "cover"
      }}
    >
      <div className="product-line-banner">
        <div className="content-wrap">
          <img src="https://corehandf.com/wp-content/uploads/2021/10/throwdown-all-white-logo-crop-300x48-1.png" />
          <h1 className="banner-title largest">Fit Accessories</h1>
        </div>
      </div>
    </section>
    <section className="section product-line-intro-section container">
      <p>
        With over 25 product offerings the Throwdown FIT Accessories product
        line offers the equipment
        <br />
        you need in a single buying experience. Including foam rollers, jump and
        speed ropes, plyo boxes,
        <br />
        resistance bands, battle ropes and anchors, FIT dummies and more.
      </p>
    </section>
    <section className="section full-width-column-section container image-left">
      <div className="row">
        <div className="col-md-5">
          <div className="img-video-wrap">
            <img src="https://corehandf.com/wp-content/uploads/2023/04/Throwdown-Slam-Balls-KG-TD-SKB-XXKG.webp" />
          </div>
        </div>
        <div className="col-md-7">
          <div className="content-wrap">
            <h2>BALLS</h2>
            <p>
              From stability balls to slam balls, we have the products needed to
              round out your XTC Rig in whatever your programming requires.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className="section fifty-fifty-column-section container">
      <div className="row gx-5">
        <div className="col-md-6">
          <div className="content-wrap">
            <img src="https://corehandf.com/wp-content/uploads/2023/04/Throwdown-Upper-Cut-Bags-TD-UB.webp" />
            <h2>BAGS</h2>
            <p>
              Throwdown Bags are constructed of T-Flex Tarpoline shells with
              industrial fiber fill and feature nylon, adjustable, webbed
              hanging straps. Hardware kits are included with each bag and are
              made from custom premium wear resistant steel. No matter your set
              up, we have the bag for you.
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="content-wrap">
            <img src="https://corehandf.com/wp-content/uploads/2023/04/Throwdown-Weights-GripDiscs_Hero-1.webp" />
            <h2>WEIGHTS, PLATES, BARS</h2>
            <p>
              Throwdown FIT Accessories offers an exceptional value free weight
              solution to your functional training space
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className="section full-width-column-section container image-left">
      <div className="row">
        <div className="col-md-5">
          <div className="img-video-wrap">
            <img src="https://corehandf.com/wp-content/uploads/2023/04/Throwdown-FXD-Product-Image.webp" />
          </div>
        </div>
        <div className="col-md-7">
          <div className="content-wrap">
            <h2>RACKS, BENCHES</h2>
            <p>
              Elevate your group training space with the Throwdown FXD Bench!
              Compact and versatile, this bench has everything needed to work
              every muscle group in the body. Its solid construction is rugged
              enough for step ups and box jumps and features a jack leaver to
              easily engage the wheels and move the bench effortlessly. The dual
              articulating bench provides an easy transition from horizontal to
              seated incline, and every position in between and contains plenty
              of storage space for fitness accessories.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className="series-prods-section">
      <div className="container">
        <h2>Browse Fit Accessories Products</h2>
        <div className="row">
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/throwdown-agility-cones/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/throwdown-agility-cones/"
                data-wpel-link="internal"
              >
                <img
                  width={500}
                  height={500}
                  src="https://corehandf.com/wp-content/uploads/2020/09/Image-Throwdown-Agility-Cones-TD-AC-web.png"
                  className="attachment-large size-large wp-post-image"
                  alt="Image - Throwdown - Agility Cones"
                  decoding="async"
                  fetchpriority="high"
                  srcSet="https://corehandf.com/wp-content/uploads/2020/09/Image-Throwdown-Agility-Cones-TD-AC-web.png 500w, https://corehandf.com/wp-content/uploads/2020/09/Image-Throwdown-Agility-Cones-TD-AC-web-300x300.png 300w, https://corehandf.com/wp-content/uploads/2020/09/Image-Throwdown-Agility-Cones-TD-AC-web-100x100.png 100w, https://corehandf.com/wp-content/uploads/2020/09/Image-Throwdown-Agility-Cones-TD-AC-web-150x150.png 150w"
                  sizes="(max-width: 500px) 100vw, 500px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/throwdown-agility-cones/"
                  data-wpel-link="internal"
                >
                  Agility Cones
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/throwdown-fit-dummies/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/throwdown-fit-dummies/"
                data-wpel-link="internal"
              >
                <img
                  width={500}
                  height={500}
                  src="https://corehandf.com/wp-content/uploads/2020/09/Image-Throwdown-Fit-Dummies-TD-DMY-XX-web.png"
                  className="attachment-large size-large wp-post-image"
                  alt="Image - Throwdown - Fit Dummies"
                  decoding="async"
                  srcSet="https://corehandf.com/wp-content/uploads/2020/09/Image-Throwdown-Fit-Dummies-TD-DMY-XX-web.png 500w, https://corehandf.com/wp-content/uploads/2020/09/Image-Throwdown-Fit-Dummies-TD-DMY-XX-web-300x300.png 300w, https://corehandf.com/wp-content/uploads/2020/09/Image-Throwdown-Fit-Dummies-TD-DMY-XX-web-100x100.png 100w, https://corehandf.com/wp-content/uploads/2020/09/Image-Throwdown-Fit-Dummies-TD-DMY-XX-web-150x150.png 150w"
                  sizes="(max-width: 500px) 100vw, 500px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/throwdown-fit-dummies/"
                  data-wpel-link="internal"
                >
                  FIT Dummies
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/throwdown-fit-bumper-plates/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/throwdown-fit-bumper-plates/"
                data-wpel-link="internal"
              >
                <img
                  width={500}
                  height={500}
                  src="https://corehandf.com/wp-content/uploads/2020/09/Image-Throwdown-Fit-Bumper-Plates-Black-LB-TD-BP-XXLB-web.png"
                  className="attachment-large size-large wp-post-image"
                  alt="Image - Throwdown - Fit Bumper Plates Black - LB"
                  decoding="async"
                  srcSet="https://corehandf.com/wp-content/uploads/2020/09/Image-Throwdown-Fit-Bumper-Plates-Black-LB-TD-BP-XXLB-web.png 500w, https://corehandf.com/wp-content/uploads/2020/09/Image-Throwdown-Fit-Bumper-Plates-Black-LB-TD-BP-XXLB-web-300x300.png 300w, https://corehandf.com/wp-content/uploads/2020/09/Image-Throwdown-Fit-Bumper-Plates-Black-LB-TD-BP-XXLB-web-100x100.png 100w, https://corehandf.com/wp-content/uploads/2020/09/Image-Throwdown-Fit-Bumper-Plates-Black-LB-TD-BP-XXLB-web-150x150.png 150w"
                  sizes="(max-width: 500px) 100vw, 500px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/throwdown-fit-bumper-plates/"
                  data-wpel-link="internal"
                >
                  FIT Bumper Plates
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/?s=Fit+accessories+&post_type=product"
              data-wpel-link="internal"
            >
              <div className="card">
                <div className="card-body">
                  <h3>
                    View All
                    <br />
                    Fit Accessories
                  </h3>
                </div>
              </div>
            </a>
          </div>
        </div>
        {/*/ .row */}
      </div>
      {/*/ .container */}
    </section>
    <section className="frequently-purchased-with-prods-section">
      <div className="container">
        <h2>Frequently Purchased With</h2>
        <div className="row">
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/throwdown-abr-9x20-20-bag/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/throwdown-abr-9x20-20-bag/"
                data-wpel-link="internal"
              >
                <img
                  width={500}
                  height={500}
                  src="https://corehandf.com/wp-content/uploads/2020/12/Image-Throwdown-ABR-1820-60-Web.jpg"
                  className="attachment-large size-large wp-post-image"
                  alt="Throwdown - ABR- 1820 - 60"
                  decoding="async"
                  loading="lazy"
                  srcSet="https://corehandf.com/wp-content/uploads/2020/12/Image-Throwdown-ABR-1820-60-Web.jpg 500w, https://corehandf.com/wp-content/uploads/2020/12/Image-Throwdown-ABR-1820-60-Web-300x300.jpg 300w, https://corehandf.com/wp-content/uploads/2020/12/Image-Throwdown-ABR-1820-60-Web-100x100.jpg 100w, https://corehandf.com/wp-content/uploads/2020/12/Image-Throwdown-ABR-1820-60-Web-150x150.jpg 150w"
                  sizes="(max-width: 500px) 100vw, 500px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/throwdown-abr-9x20-20-bag/"
                  data-wpel-link="internal"
                >
                  9 x 18 Alpha Bag Rack 20-Bag
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/throwdown-xtr-standard-storage/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/throwdown-xtr-standard-storage/"
                data-wpel-link="internal"
              >
                <img
                  width={1024}
                  height={576}
                  src="https://corehandf.com/wp-content/uploads/2020/04/Throwdown-Storage-OptionC_softgoods-1024x576.jpg"
                  className="attachment-large size-large wp-post-image"
                  alt=""
                  decoding="async"
                  loading="lazy"
                  srcSet="https://corehandf.com/wp-content/uploads/2020/04/Throwdown-Storage-OptionC_softgoods-1024x576.jpg 1024w, https://corehandf.com/wp-content/uploads/2020/04/Throwdown-Storage-OptionC_softgoods-600x338.jpg 600w, https://corehandf.com/wp-content/uploads/2020/04/Throwdown-Storage-OptionC_softgoods-300x169.jpg 300w, https://corehandf.com/wp-content/uploads/2020/04/Throwdown-Storage-OptionC_softgoods-768x432.jpg 768w, https://corehandf.com/wp-content/uploads/2020/04/Throwdown-Storage-OptionC_softgoods-1536x864.jpg 1536w, https://corehandf.com/wp-content/uploads/2020/04/Throwdown-Storage-OptionC_softgoods.jpg 1920w"
                  sizes="(max-width: 1024px) 100vw, 1024px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/throwdown-xtr-standard-storage/"
                  data-wpel-link="internal"
                >
                  XTR Standard Storage
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/throwdown-fbr-12x24/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/throwdown-fbr-12x24/"
                data-wpel-link="internal"
              >
                <img
                  width={500}
                  height={500}
                  src="https://corehandf.com/wp-content/uploads/2020/12/Image-Throwdown-FBR-1224-XX-1.png"
                  className="attachment-large size-large wp-post-image"
                  alt="Throwdown - FBR - 1224"
                  decoding="async"
                  loading="lazy"
                  srcSet="https://corehandf.com/wp-content/uploads/2020/12/Image-Throwdown-FBR-1224-XX-1.png 500w, https://corehandf.com/wp-content/uploads/2020/12/Image-Throwdown-FBR-1224-XX-1-300x300.png 300w, https://corehandf.com/wp-content/uploads/2020/12/Image-Throwdown-FBR-1224-XX-1-100x100.png 100w, https://corehandf.com/wp-content/uploads/2020/12/Image-Throwdown-FBR-1224-XX-1-150x150.png 150w"
                  sizes="(max-width: 500px) 100vw, 500px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/throwdown-fbr-12x24/"
                  data-wpel-link="internal"
                >
                  12 x 24 Freestanding Bag Rack
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/throwdown-xtc-2x8-compact-rig/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/throwdown-xtc-2x8-compact-rig/"
                data-wpel-link="internal"
              >
                <img
                  width={1024}
                  height={576}
                  src="https://corehandf.com/wp-content/uploads/2020/08/Throwdown-XTC-Compact-Rig-2x8-9-XTC-208C-60-Matte-Black-Product-Image-1024x576.webp"
                  className="attachment-large size-large wp-post-image"
                  alt=""
                  decoding="async"
                  loading="lazy"
                  srcSet="https://corehandf.com/wp-content/uploads/2020/08/Throwdown-XTC-Compact-Rig-2x8-9-XTC-208C-60-Matte-Black-Product-Image-1024x576.webp 1024w, https://corehandf.com/wp-content/uploads/2020/08/Throwdown-XTC-Compact-Rig-2x8-9-XTC-208C-60-Matte-Black-Product-Image-300x169.webp 300w, https://corehandf.com/wp-content/uploads/2020/08/Throwdown-XTC-Compact-Rig-2x8-9-XTC-208C-60-Matte-Black-Product-Image-768x432.webp 768w, https://corehandf.com/wp-content/uploads/2020/08/Throwdown-XTC-Compact-Rig-2x8-9-XTC-208C-60-Matte-Black-Product-Image-1536x864.webp 1536w, https://corehandf.com/wp-content/uploads/2020/08/Throwdown-XTC-Compact-Rig-2x8-9-XTC-208C-60-Matte-Black-Product-Image-2048x1152.webp 2048w, https://corehandf.com/wp-content/uploads/2020/08/Throwdown-XTC-Compact-Rig-2x8-9-XTC-208C-60-Matte-Black-Product-Image-600x338.webp 600w"
                  sizes="(max-width: 1024px) 100vw, 1024px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/throwdown-xtc-2x8-compact-rig/"
                  data-wpel-link="internal"
                >
                  2 x 8 Compact XTC Rig
                </a>
              </div>
            </div>
          </div>
        </div>
        {/*/ .row */}
      </div>
      {/*/ .container */}
    </section>
    <section id="request-a-quote-form" className="section request-a-quote-form quote-form">
      <div className="container">
        <h2>
          <span>Start Your Consultation Today</span>
        </h2>
        <div className="row center-xs">
          <div className="col-md-6 offset-md-6">
            {/*[if lte IE 8]>

<![endif]*/}
          </div>
        </div>
      </div>
    </section>
    <a className="sticky-cta" href="#request-a-quote-form">
      <img src="/wp-content/uploads/2022/04/Icon_map-gym.png" />
      Start Consultation
    </a>{" "}
  </main>
  {/* /.main */}


        </>
    )
}

export default FitAccessories;