import React  from "react"
import Form from "../../CommonCompounds/Form/Form";
import ProductGallery from "../../CommonCompounds/CarourselProduct/CarourselProduct";


function BuilderDeveloper (){
    return(
        <>
    <main className="main" id="main">
  <section className="breadcrumbs">
    <ul>
      {/*li><a href="#">Home</a></li>
          <li class="current">Star Trac</li*/}
      <li>
        <span>
          <span>
            <a href="https://corehandf.com/" data-wpel-link="internal">
              Home
            </a>
          </span>{" "}
          »{" "}
          <span className="breadcrumb_last" aria-current="page">
            Builder / Developer
          </span>
        </span>
      </li>
    </ul>
  </section>
  <section
    className="section_slide solutions-banner row"
    style={{
      backgroundImage:
        "url(https://corehandf.com/wp-content/uploads/2021/11/Builder-Developer.webp)"
    }}
  >
    <div className="col-xs-12 col-sm-8 col-md-8 col-lg-5">
      <h1>
        <strong>Builder</strong> / Developer
      </h1>
    </div>
  </section>
  <section className="section single-content">
    <div className="container small">
      <div className="row">
        <div className="col-xs-12">
          <p>
            When it comes to designing a brand-new property there is no better
            partner than Core Health &amp; Fitness. With a One Team mentality,
            we go above and beyond to make sure the property that you’re
            developing has the flow and quality of equipment your guests and
            tenants will expect. Our portfolio of commercial brands –
            StairMaster, Schwinn, Nautilus, Star Trac and Throwdown – not only
            accommodate a wide range of aesthetics, but also provide a multitude
            of training options to keep pace with the trends in your market.{" "}
          </p>
          <p>
            With a global network of partners, we’re able to guide you through
            space design, equipment selection, the importance of flooring and so
            much more. When you request a consultation, your regional rep will
            get to know your goals and understand what sets your property apart
            so they can provide a solution that blends seamlessly with your
            overall design. Below are a few of the items you’ll be guided
            through:{" "}
          </p>
          <p>
            • 2D Rendering
            <br /> • 3D Layout
            <br /> • Budget
            <br /> • Financing{" "}
          </p>
          <p>
            When you start a consultation, your regional rep will utilize years
            of industry knowledge along with the latest FISA data to ensure
            you’re maximizing your space and are on trend with what’s being
            installed in your market and around the world. Below are just a few
            of the properties we’ve been able to collaborate with and go from
            concept to completion.{" "}
          </p>
          <a className="cta-button" href="#embed-form">
            Request a Consultation
          </a>{" "}
        </div>
      </div>
    </div>
  </section>
  <section >
    <ProductGallery />
   </section>
    <section className="request-a-quote-form">
    <div className="container">
      <h2 id="request-a-quote-form" className="section-title"><span>Start Your Consultation Today</span></h2>
      <div className="row center-xs">
        <div className="col-md-6 offset-md-6">
        <Form />
        </div>
      </div>
    </div>
  </section>
</main>
 </>

    )
}
export default BuilderDeveloper;