import React  from "react";
import images from "../../images/img";
import Form from "../../CommonCompounds/Form/Form";



function Schwinn(){
    return (
        <>
        <main className="main" id="main">
  <section className="breadcrumbs">
    <ul>
      {/*li><a href="#">Home</a></li>
          <li class="current">Star Trac</li*/}
      <li>
        <span>
          <span>
            <a href="https://corehandf.com/" data-wpel-link="internal">
              Home
            </a>
          </span>{" "}
          »{" "}
          <span className="breadcrumb_last" aria-current="page">
            Schwinn®
          </span>
        </span>
      </li>
    </ul>
  </section>
  {/*  Conditional for Industry page style layout */}
  <section id="build" className="section banner">
    <div className="row brand-banner">
      <div className="col-xs-12 brand-banner-left">
        <img
          src="https://corehandf.com/wp-content/uploads/2017/02/Schwinn-Logo-white_348x74.png"
          alt=""
        />
        <div
          className="bg"
          style={{
            background:
              "url(https://corehandf.com/wp-content/uploads/2019/12/Schwinn_930x700.jpg) center center no-repeat"
          }}
        ></div>
      </div>
    </div>
  </section>
  <section>
    <div className="container">
      <div className="cnt">
        <h1 className="banner-title">RIDE AS ONE</h1>
        <p>
          With historic roots in outdoor cycling, Schwinn®’s focus has always
          been on authenticity and quality. We brought the feel of the road to
          indoor cycling with the same expertise and education that drives the
          industry forward. Schwinn is an informational and educational source
          for all things Indoor Cycling.
        </p>
      </div>
    </div>
  </section>
  <section id="featured" className="section cards">
    <div className="container wide">
      <div className="row card-row">
        <div className="col-xs-3">
          <div className="card">
            <a
              href="/product-category/brand/schwinn/bikes/"
              data-wpel-link="internal"
            >
              <img src="https://corehandf.com/wp-content/uploads/2021/02/Schwinn-Xa-01-Product-Image.png" />
              <div className="card-info">
                <p className="name">Bikes</p>
              </div>
            </a>
          </div>
        </div>
        <div className="col-xs-3">
          <div className="card">
            <a
              href="/product-category/brand/schwinn/cycling-consoles-line/"
              data-wpel-link="internal"
            >
              <img src="https://corehandf.com/wp-content/uploads/2023/04/Schwinn-Console-Zone-Console-Standard-Screen-1-Product-Image.webp" />
              <div className="card-info">
                <p className="name">Consoles</p>
              </div>
            </a>
          </div>
        </div>
        <div className="col-xs-3">
          <div className="card">
            <a
              href="/product-category/brand/schwinn/bike-accessories/"
              data-wpel-link="internal"
            >
              <img src="https://corehandf.com/wp-content/uploads/2018/12/dumbellholder-web.png" />
              <div className="card-info">
                <p className="name">Accessories</p>
              </div>
            </a>
          </div>
        </div>
        <div className="col-xs-3">
          <div className="card view-all">
            <a href="/product-category/brand/schwinn" data-wpel-link="internal">
              <img src="" />
              <div className="card-info">
                <p className="name">
                  View All Schwinn <i className="fas fa-arrow-right fa-2x" />
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div className="container video-slider-wrap">
    <section id="blocks-1" className="video-slider">
      <div id="block-1" className="container block">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <figure className="block-image pad-the-video">
              <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
                <iframe
                  src="https://player.vimeo.com/video/693741643"
                  title="Schwinn Brand Video"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%"
                  }}
                  frameBorder={0}
                  allow="autoplay; fullscreen"
                  allowFullScreen=""
                />
              </div>
            </figure>
          </div>
          <div className="col-xs-12 col-md-6 content">
            <header className="block-header">
              <h3>RIDE AS ONE</h3>
            </header>
            <p>
              Cycling will always have a place in fitness. It is a space that
              embraces inclusivity, making room for everyone in a community that
              melds fun and sweat.​ Our philosophy has always been to create
              leading products, programs, and experiences, no matter the vision.
              We’ve built our reputation through consistent innovation rooted in
              authentic cycling. An authenticity that touches every part of what
              we do.
            </p>
          </div>
        </div>
      </div>
      <div id="block-2" className="container block">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <figure className="block-image">
              <a href="#" className="no-link">
                <img
                  src="https://corehandf.com/wp-content/uploads/2023/10/TrainEd_Thumbnail.png"
                  alt=""
                />
              </a>
            </figure>
          </div>
          <div className="col-xs-12 col-md-6 content">
            <header className="block-header">
              <h3>MORE THAN GREAT BIKES – EXPERTISE.</h3>
            </header>
            <p>
              Schwinn® brings to the studio more than just great bikes. We bring
              a proven, unparalleled education program that includes expert
              coaching techniques and top tier class design. Want more
              information? Click{" "}
              <a
                href="https://corehandf.com/certification/"
                data-wpel-link="internal"
              >
                here
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </section>
    
  </div>
  <section className="section view-other-brands">
    <div className="container">
      <header className="block-header">
        <h3>View Other Brands</h3>
      </header>
      <ul className="brand-logos">
        <li className="">
          <a href="/product-brand/throwdown" data-wpel-link="internal">
            <img src="https://corehandf.com/wp-content/uploads/2021/10/logoasociate_throwoown-3.png" />
          </a>
        </li>
        <li className="">
          <a href="/product-brand/nautilus" data-wpel-link="internal">
            <img src="https://corehandf.com/wp-content/uploads/2021/10/logoasociate_Nautilus-3.png" />
          </a>
        </li>
        <li className="">
          <a href="/product-brand/stairmaster" data-wpel-link="internal">
            <img src="https://corehandf.com/wp-content/uploads/2021/10/logoasociate_StairMaster-3.png" />
          </a>
        </li>
        <li className="">
          <a href="/product-brand/star-trac" data-wpel-link="internal">
            <img src="https://corehandf.com/wp-content/uploads/2021/10/logoasociate_startrac-3.png" />
          </a>
        </li>
      </ul>
    </div>
  </section>
  <section className="section brand-page-bottom">
    <div className="container">
      <div className="row center-xs">
        <div className="col-xs-4 offset-xs-3">
          <p>FOLLOW SCHWINN</p>
          <ul className="list flex-center flex-middle">
            <li>
              <a
                href="https://www.facebook.com/SchwinnIndoorCycling"
                target="_blank"
                data-wpel-link="external"
                rel="nofollow external noopener noreferrer"
              >
                <i className="fa fa-facebook" />
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/SCHWINNtraining"
                target="_blank"
                data-wpel-link="external"
                rel="nofollow external noopener noreferrer"
              >
                <i className="fa fa-twitter" />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/schwinnindoorcycling/"
                target="_blank"
                data-wpel-link="external"
                rel="nofollow external noopener noreferrer"
              >
                <i className="fa fa-instagram" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <section className="request-a-quote-form">
    <div className="container">
      <h2 id="request-a-quote-form" className="section-title"><span>Start Your Consultation Today</span></h2>
      <div className="row center-xs">
        <div className="col-md-6 offset-md-6">
        <Form />
        </div>
      </div>
    </div>
  </section>
  <a className="sticky-cta" href="#request-a-quote-form">
  <img src={images.mapgym} />
    Start Consultation
  </a>

</main>

        </>
    )
}

export default Schwinn;