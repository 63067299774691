import React from "react";
import Navbar from "../../CommonCompounds/Nav/Nav.jsx";
import Footer from "../../CommonCompounds/Footer/Footer.jsx";
import Warranty from "../../Compounds/Warranty/Warranty.jsx";

function WarrantyPage() {
    return (
        <>
            <Navbar />
            <Warranty />
            <Footer />
        </>
    );
}

export default WarrantyPage;

