import React from "react";
import ALLCordio from "../ProductCategory/type/main-floor-cardio/AllCardio";

function StorageRacks(){
    return(
        <>

        <ALLCordio />
        </>
    )
}
export default StorageRacks;
