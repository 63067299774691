import React from "react";
import ALLCordio from "../ProductCategory/type/main-floor-cardio/AllCardio";



function JacobsLadder(){
    return(
   <>
   <ALLCordio />
  </>
    )
};
export default JacobsLadder;
