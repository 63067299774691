import React  from "react";


function  HumanSport (){
    return(
        <>
<>
  <main className="main" id="main">
    <section className="breadcrumbs">
      <ul>
        {/*li><a href="#">Home</a></li>
          <li class="current">Star Trac</li*/}
        <li>
          <span>
            <span>
              <a href="https://corehandf.com/" data-wpel-link="internal">
                Home
              </a>
            </span>{" "}
            »{" "}
            <span>
              <a
                href="https://corehandf.com/product_line/"
                data-wpel-link="internal"
              >
                Product Lines
              </a>
            </span>{" "}
            »{" "}
            <span>
              <a
                href="https://corehandf.com/product_line/nautilus-brand/"
                data-wpel-link="internal"
              >
                Nautilus
              </a>
            </span>{" "}
            »{" "}
            <span className="breadcrumb_last" aria-current="page">
              HumanSport
            </span>
          </span>
        </li>
      </ul>
    </section>
    <section
      className="section product-line-banner-section"
      style={{
        background:
          "url(https://corehandf.com/wp-content/uploads/2023/04/Nautilus-HumanSport-Hero-Shot.webp) top center no-repeat",
        backgroundSize: "cover"
      }}
    >
      <div className="product-line-banner">
        <div className="content-wrap">
          <img src="https://corehandf.com/wp-content/uploads/2019/04/Nautilus-Logo-all-white1.png" />
          <h1 className="banner-title largest">HumanSport</h1>
        </div>
      </div>
    </section>
    <section className="section product-line-intro-section container">
      <h2>HumanSport</h2>
      <p>
        With a design inspired by the graceful, natural motions of the human
        body, HumanSport offers a truly unique and human way of conditioning.
        HumanSport is so simple, intuitive and natural that it feels as if it
        were custom-built for each and every user. Seven dual-function
        cable-based machines provide a complete range of workouts for total body
        training. When paired with the provided programming designed by our
        Master Trainers, HumanSport makes a powerful addition to any facility.
      </p>
    </section>
    <section className="section full-width-column-section container image-left">
      <div className="row">
        <div className="col-md-5">
          <div className="img-video-wrap">
            <img src="https://corehandf.com/wp-content/uploads/2023/04/HumanSport-FreedomTrainer-HSFT3-60AAE-Product-Image-Web.webp" />
          </div>
        </div>
        <div className="col-md-7">
          <div className="content-wrap">
            <h2>HUMANSPORT FUNCTIONAL TRAINING</h2>
            <p>
              HumanSport is designed to allow unlimited resistance
              <br /> training options through movements in the seated
              <br /> isolated position, seated unstable position and
              <br /> standing position. By utilizing all three, your fitness
              <br /> staff can create any number of unique goal-based
              <br /> programs to cater to your desired client groups.
              <br /> These programs can be used for one-on-one personal
              <br /> training or to capitalize on small group training. Give
              <br /> your members an outstanding functional training tool
              <br /> and gain the ability to target all new client groups with
              <br /> HumanSport.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className="section full-width-column-section container image-left">
      <div className="row">
        <div className="col-md-5">
          <div className="img-video-wrap">
            <div style={{ padding: "100% 0 0 0", position: "relative" }}>
              <iframe
                src="https://player.vimeo.com/video/791206580?h=02b66042f1&badge=0&autopause=0&player_id=0&app_id=58479"
                frameBorder={0}
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen=""
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%"
                }}
                title="Lock N Load"
              />
            </div>
          </div>
        </div>
        <div className="col-md-7">
          <div className="content-wrap">
            <h2>LOCK N LOAD WEIGHT SELECTION</h2>
            <p>
              Nautilus selectorized strength has some of the most innovative and
              easy to use weight selection systems ever. Lock N Load removes the
              need for pins, which can easily be lost or broken, meaning fewer
              maintenance headaches. Members will love the intuitive weight
              adjustment, which is as simple as flipping a switch.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className="section fifty-fifty-column-section container">
      <div className="row gx-5">
        <div className="col-md-6">
          <div className="content-wrap">
            <img src="https://corehandf.com/wp-content/uploads/2023/04/Nautilus-Human-Sport-Dual-Weight-Stack.webp" />
            <h2>DUAL WEIGHT STACK DESIGN</h2>
            <p>
              Designed with two weight stacks, HumanSport
              <br /> machines offer multiple training options for
              <br /> programs such as:
            </p>
            <ul>
              <li>Aerobics</li>
              <li>Ski</li>
              <li>Back Strength</li>
              <li>Running</li>
              <li>Stay Young &amp; Fit</li>
              <li>Soccer</li>
              <li>Tennis</li>
              <li>Cycling</li>
              <li>Golf</li>
              <li>Climbing</li>
              <li>Strength for Athletes</li>
            </ul>
          </div>
        </div>
        <div className="col-md-6">
          <div className="content-wrap">
            <img src="https://corehandf.com/wp-content/uploads/2023/04/Nautilus-HumanSport-Freedom-Trainer-HSFT3-Wheelchair-Product-Image-Web.webp" />
            <h2>WHEELCHAIR ACCESSIBLE OPTION</h2>
            <p>
              Weight stack utilizes Lock N Load switch technology for quick and
              easy weight selection and accessory handles are optimally placed
              to allow wheelchair access and meets ADA criteria
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className="series-prods-section">
      <div className="container">
        <h2>Browse HumanSport Products</h2>
        <div className="row">
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/nautilus-humansport-arm-crunch/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/nautilus-humansport-arm-crunch/"
                data-wpel-link="internal"
              >
                <img
                  width={500}
                  height={500}
                  src="https://corehandf.com/wp-content/uploads/2017/02/HumanSport_Carbon_ArmsChest-60.png"
                  className="attachment-large size-large wp-post-image"
                  alt="Nautilus - HumanSport - Arm Crunch - 60 Carbon Fiber"
                  decoding="async"
                  fetchpriority="high"
                  srcSet="https://corehandf.com/wp-content/uploads/2017/02/HumanSport_Carbon_ArmsChest-60.png 500w, https://corehandf.com/wp-content/uploads/2017/02/HumanSport_Carbon_ArmsChest-60-300x300.png 300w, https://corehandf.com/wp-content/uploads/2017/02/HumanSport_Carbon_ArmsChest-60-100x100.png 100w, https://corehandf.com/wp-content/uploads/2017/02/HumanSport_Carbon_ArmsChest-60-150x150.png 150w"
                  sizes="(max-width: 500px) 100vw, 500px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/nautilus-humansport-arm-crunch/"
                  data-wpel-link="internal"
                >
                  HumanSport® Arm Crunch
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/nautilus-humansport-pull-lift/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/nautilus-humansport-pull-lift/"
                data-wpel-link="internal"
              >
                <img
                  width={500}
                  height={500}
                  src="https://corehandf.com/wp-content/uploads/2017/02/HumanSport_Carbon_Pull_Lift-60.png"
                  className="attachment-large size-large wp-post-image"
                  alt="Nautilus - Human Sport - Pull Lift - 60 Carbon Fiber"
                  decoding="async"
                  srcSet="https://corehandf.com/wp-content/uploads/2017/02/HumanSport_Carbon_Pull_Lift-60.png 500w, https://corehandf.com/wp-content/uploads/2017/02/HumanSport_Carbon_Pull_Lift-60-300x300.png 300w, https://corehandf.com/wp-content/uploads/2017/02/HumanSport_Carbon_Pull_Lift-60-100x100.png 100w, https://corehandf.com/wp-content/uploads/2017/02/HumanSport_Carbon_Pull_Lift-60-150x150.png 150w"
                  sizes="(max-width: 500px) 100vw, 500px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/nautilus-humansport-pull-lift/"
                  data-wpel-link="internal"
                >
                  HumanSport® Pull Lift
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/nautilus-humansport-lat-pulley/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/nautilus-humansport-lat-pulley/"
                data-wpel-link="internal"
              >
                <img
                  width={500}
                  height={500}
                  src="https://corehandf.com/wp-content/uploads/2017/02/HumanSport_Carbon_Lat_Pully-60.png"
                  className="attachment-large size-large wp-post-image"
                  alt="Nautilus - HumanSport - Lat Pulley - 60 Carbon Fiber"
                  decoding="async"
                  srcSet="https://corehandf.com/wp-content/uploads/2017/02/HumanSport_Carbon_Lat_Pully-60.png 500w, https://corehandf.com/wp-content/uploads/2017/02/HumanSport_Carbon_Lat_Pully-60-300x300.png 300w, https://corehandf.com/wp-content/uploads/2017/02/HumanSport_Carbon_Lat_Pully-60-100x100.png 100w, https://corehandf.com/wp-content/uploads/2017/02/HumanSport_Carbon_Lat_Pully-60-150x150.png 150w"
                  sizes="(max-width: 500px) 100vw, 500px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/nautilus-humansport-lat-pulley/"
                  data-wpel-link="internal"
                >
                  HumanSport® Lat Pulley
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="/product-category/brand/nautilus/humansport-line/"
              data-wpel-link="internal"
            >
              <div className="card">
                <div className="card-body">
                  <h3>
                    View All
                    <br />
                    HumanSport
                  </h3>
                </div>
              </div>
            </a>
          </div>
        </div>
        {/*/ .row */}
      </div>
      {/*/ .container */}
    </section>
    <section id="request-a-quote-form" className="section request-a-quote-form quote-form">
      <div className="container">
        <h2>
          <span>Start Your Consultation Today</span>
        </h2>
        <div className="row center-xs">
          <div className="col-md-6 offset-md-6">
            {/*[if lte IE 8]>

<![endif]*/}
          </div>
        </div>
      </div>
    </section>
    <a className="sticky-cta" href="#request-a-quote-form">
      <img src="/wp-content/uploads/2022/04/Icon_map-gym.png" />
      Start Consultation
    </a>{" "}
  </main>
  {/* /.main */}
</>

        
        </>
    )
}

export default HumanSport ;