import React from "react";
import Navbar from "../../CommonCompounds/Nav/Nav.jsx";
import Footer from "../../CommonCompounds/Footer/Footer.jsx";
import Customization from "../../Compounds/Customization/Customization.jsx"

function CustomizationPage () {
    return (
        <>
            <Navbar />
              <Customization />
            <Footer />
        </>
    );
}

export default CustomizationPage ;



