import React from "react";
import { NavLink } from "react-router-dom";



function Blog(){
    return(
       <>
       <main className="main" id="main">
  <section className="section filter-banner">
    <div className="gray-banner">
      <h1>
        <strong>Core Health &amp; Fitness</strong> Blog
      </h1>
    </div>
  </section>
  <section id="updates" className="section updates">
    <div className="container small">
      <ul className="list">
        <li className="news">
          <NavLink
            to="/Blog1"
            data-wpel-link="internal"
          />
          <div>
            <div
              className="thumb"
              style={{
                background:
                  "url(https://corehandf.com/wp-content/uploads/2024/08/core-apac-trends-blog-header.png) 50% no-repeat"
              }}
              alt="Alt Tag"
            />
            <div className="blog-content">
              <h3>
                Asia-Pacific Fitness Trends Reveal Digital and Holistic Growth
              </h3>
            </div>
            <div className="">
              <p className="faux-btn">Read More</p>
            </div>
          </div>
        </li>
        <li className="news">
          <NavLink
            to="/Blog1"
            data-wpel-link="internal"
          />
          <div>
            <div
              className="thumb"
              style={{
                background:
                  "url(https://corehandf.com/wp-content/uploads/2024/08/core-blog-images_911-climb-header-image.png) 50% no-repeat"
              }}
              alt="Alt Tag"
            />
            <div className="blog-content">
              <h3>
                9/11 Memorial Stair Climb By StairMaster: Join, Host or Donate
              </h3>
            </div>
            <div className="">
              <p className="faux-btn">Read More</p>
            </div>
          </div>
        </li>
       
        <li className="news">
          <NavLink
            to="/Blog1"
            data-wpel-link="internal"
          />
          <div>
            <div
              className="thumb"
              style={{
                background:
                  "url(https://corehandf.com/wp-content/uploads/2024/06/new-finance-partner-header-3-1080-x-600-px.png) 50% no-repeat"
              }}
              alt="Alt Tag"
            />
            <div className="blog-content">
              <h3>
                New Core Financial Partnership Fuels Strategic Growth and
                Innovation
              </h3>
            </div>
            <div className="">
              <p className="faux-btn">Read More</p>
            </div>
          </div>
        </li> 
      </ul>
    </div>
  </section>
  <div
    id="blog-subscribe"
    style={{ margin: "auto", maxWidth: 600, textAlign: "center" }}
  >
    <h2 id="request-a-quote-form" className="section-title">
      <span>Sign Up For Our Newsletter</span>
    </h2>
  </div>
  <style
    dangerouslySetInnerHTML={{
      __html:
        '\n            .section-title {\n                font-size: 36px;\n                line-height: 36px;\n                text-align: center;\n                text-transform: uppercase;\n                position: relative;\n                color: #000;\n                font-family: "Montserrat", sans-serif;\n                font-weight: 300;\n                margin-bottom: 40px;\n            }\n\n            #blog-subscribe .hs-form-7ace6981-4201-4c91-ae4b-b81c30994dbd_e1a0416f-61f4-4225-9967-2e0e0ec9b6c2 .hs-submit>.actions {\n                text-align: center !important;\n            }\n        '
    }}
  />
</main>

       </>
    )
}

export default Blog;

