import React from "react";
import Navbar from "../../CommonCompounds/Nav/Nav.jsx";
import Footer from "../../CommonCompounds/Footer/Footer.jsx";
import SocialResponsibility from "../../Compounds/SocialResponsibility/SocialResponsibility";


function SocialResponsibilityPage(){
    return(

 <>
 <Navbar />
 <SocialResponsibility />
 <Footer />
 </>
    )
}

export default SocialResponsibilityPage;