import React from "react";
import Navbar from "../../CommonCompounds/Nav/Nav.jsx";
import Footer from "../../CommonCompounds/Footer/Footer.jsx";
import FitAccessories from "../../Compounds/FitAccessories/FitAccessories.jsx";

function FitaccessoriesPage() {
    return (
        <>
            <Navbar />
            <FitAccessories />
            <Footer />
        </>
    );
}

export default FitaccessoriesPage;
