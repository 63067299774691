import React from "react";
import Navbar from "../../CommonCompounds/Nav/Nav.jsx"
import Footer from "../../CommonCompounds/Footer/Footer.jsx";

import Oneseries from "../../Compounds/OneSeries/OneSeries.jsx";


function MunicipalitieSrecreationPage(){
    return(
        <>

       <Navbar />
       <Oneseries />
       <Footer />
        </>
    )
}
 export default MunicipalitieSrecreationPage;