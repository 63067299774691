import React from "react";



function Inspiration () {
    return (
<>
  <main className="main" id="main">
    <section className="breadcrumbs">
      <ul>
        {/*li><a href="#">Home</a></li>
          <li class="current">Star Trac</li*/}
        <li>
          <span>
            <span>
              <a href="https://corehandf.com/" data-wpel-link="internal">
                Home
              </a>
            </span>{" "}
            »{" "}
            <span>
              <a
                href="https://corehandf.com/product_line/"
                data-wpel-link="internal"
              >
                Product Lines
              </a>
            </span>{" "}
            »{" "}
            <span>
              <a
                href="https://corehandf.com/product_line/nautilus-brand/"
                data-wpel-link="internal"
              >
                Nautilus
              </a>
            </span>{" "}
            »{" "}
            <span className="breadcrumb_last" aria-current="page">
              Inspiration
            </span>
          </span>
        </li>{" "}
      </ul>
    </section>
    <section
      className="section product-line-banner-section"
      style={{
        background:
          "url(https://corehandf.com/wp-content/uploads/2023/04/Image-Lifestyle-Inspiration-Hero-Shot-Wide.webp) top center no-repeat",
        backgroundSize: "cover"
      }}
    >
      <div className="product-line-banner">
        <div className="content-wrap">
          <img src="https://corehandf.com/wp-content/uploads/2019/04/Nautilus-Logo-all-white1.png" />
          <h1 className="banner-title largest">Inspiration</h1>
        </div>
      </div>
    </section>
    <section className="section product-line-intro-section container">
      <h2>A STRONGER FIRST IMPRESSION</h2>
      <p>
        Designed with beautiful, modern aesthetics and uniform towers, Nautilus
        Inspiration Strength makes a powerful first impression. Each machine
        captures the essence of natural movement. Members will appreciate the
        many easy to use and easy to see features like our patented Lock N Load
        weight selection system, the wrap-around assisted seat adjustment,
        lenticular image placards, pre-stretch and range of motion controls
        designed to enhance each user’s experience.
      </p>
    </section>
    <section className="section full-width-column-section container image-left">
      <div className="row">
        <div className="col-md-5">
          <div className="img-video-wrap">
            <div style={{ padding: "100% 0 0 0", position: "relative" }}>
              <iframe
                src="https://player.vimeo.com/video/791206580?h=02b66042f1&badge=0&autopause=0&player_id=0&app_id=58479"
                frameBorder={0}
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen=""
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%"
                }}
                title="Lock N Load"
              />
            </div>
          </div>
        </div>
        <div className="col-md-7">
          <div className="content-wrap">
            <h2>LOCK N LOAD WEIGHT SELECTION</h2>
            <p>
              Selecting a weight with the intuitive Lock N Load design is as
              easy as flicking a switch. Universally color coded switches engage
              and disengage the weight via an internal pin, providing a safer,
              more durable and maintenance free operation. Lock N Load prevents
              unnecessary maintenance headaches since there are no pins to lose
              or replace.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className="section fifty-fifty-column-section container">
      <div className="row gx-5">
        <div className="col-md-6">
          <div className="content-wrap">
            <img src="https://corehandf.com/wp-content/uploads/2023/04/Nautilus-Inspiration-Pec-FlyRear-Deltoid-IPPF3-097-Product-Image-Web.webp" />
            <h2>SMART TOWER DESIGN</h2>
            <p>
              The beautifully designed Nautilus Inspiration line, with their
              uniform 64” (163 cm) towers, deliver superior linesof site across
              your venue and create a visually powerfulimpression during
              facility tours. The tower housing is designed to accommodate
              custom graphics and wraps to personalize the equipment in your
              facility.
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="content-wrap">
            <div style={{ padding: "100% 0 0 0", position: "relative" }}>
              <iframe
                src="https://player.vimeo.com/video/791211635?h=a491e331eb&badge=0&autopause=0&player_id=0&app_id=58479"
                frameBorder={0}
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen=""
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%"
                }}
                title="Nautilus Inspiration Tower Lenticular Animated"
              />
            </div>{" "}
            <h2>DESIGNED FOR ALL LEVELS</h2>
            <p>
              Nautilus Inspiration is inspired by the best aspects of multiple
              brands and designed to meet the needs of our users. With
              consideration to the end user, the trainer, technician, and our
              operators, Inspiration captures many of the “must-haves” including
              best in class biomechanics, logistics, aesthetics &amp; features,
              ease of use, and many other competitive advantages.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className="series-prods-section">
      <div className="container">
        <h2>Browse Inspiration Products</h2>
        <div className="row">
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/nautilus-inspiration-abduction-adduction/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/nautilus-inspiration-abduction-adduction/"
                data-wpel-link="internal"
              >
                <img
                  width={1024}
                  height={683}
                  src="https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Inspiration-Abduction-Adduction-IPAA5-60-04-1024x683.png"
                  className="attachment-large size-large wp-post-image"
                  alt=""
                  decoding="async"
                  fetchpriority="high"
                  srcSet="https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Inspiration-Abduction-Adduction-IPAA5-60-04-1024x683.png 1024w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Inspiration-Abduction-Adduction-IPAA5-60-04-300x200.png 300w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Inspiration-Abduction-Adduction-IPAA5-60-04-768x512.png 768w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Inspiration-Abduction-Adduction-IPAA5-60-04-1536x1024.png 1536w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Inspiration-Abduction-Adduction-IPAA5-60-04-2048x1365.png 2048w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Inspiration-Abduction-Adduction-IPAA5-60-04-600x400.png 600w"
                  sizes="(max-width: 1024px) 100vw, 1024px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/nautilus-inspiration-abduction-adduction/"
                  data-wpel-link="internal"
                >
                  Inspiration® Abduction/ Adduction
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/nautilus-inspiration-chest-press/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/nautilus-inspiration-chest-press/"
                data-wpel-link="internal"
              >
                <img
                  width={1024}
                  height={1024}
                  src="https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Inspiration-Chest-Press-IPVP5-60-web-1024x1024.jpg"
                  className="attachment-large size-large wp-post-image"
                  alt=""
                  decoding="async"
                  srcSet="https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Inspiration-Chest-Press-IPVP5-60-web-1024x1024.jpg 1024w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Inspiration-Chest-Press-IPVP5-60-web-300x300.jpg 300w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Inspiration-Chest-Press-IPVP5-60-web-150x150.jpg 150w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Inspiration-Chest-Press-IPVP5-60-web-768x768.jpg 768w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Inspiration-Chest-Press-IPVP5-60-web-1536x1536.jpg 1536w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Inspiration-Chest-Press-IPVP5-60-web-2048x2048.jpg 2048w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Inspiration-Chest-Press-IPVP5-60-web-70x70.jpg 70w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Inspiration-Chest-Press-IPVP5-60-web-600x600.jpg 600w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Inspiration-Chest-Press-IPVP5-60-web-100x100.jpg 100w"
                  sizes="(max-width: 1024px) 100vw, 1024px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/nautilus-inspiration-chest-press/"
                  data-wpel-link="internal"
                >
                  Inspiration® Chest Press
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/nautilus-inspiration-glute-press/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/nautilus-inspiration-glute-press/"
                data-wpel-link="internal"
              >
                <img
                  width={1024}
                  height={1024}
                  src="https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Inspiration-Glute-Press-IPGM5-60-web-1024x1024.png"
                  className="attachment-large size-large wp-post-image"
                  alt=""
                  decoding="async"
                  srcSet="https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Inspiration-Glute-Press-IPGM5-60-web-1024x1024.png 1024w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Inspiration-Glute-Press-IPGM5-60-web-300x300.png 300w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Inspiration-Glute-Press-IPGM5-60-web-150x150.png 150w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Inspiration-Glute-Press-IPGM5-60-web-768x768.png 768w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Inspiration-Glute-Press-IPGM5-60-web-1536x1536.png 1536w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Inspiration-Glute-Press-IPGM5-60-web-2048x2048.png 2048w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Inspiration-Glute-Press-IPGM5-60-web-70x70.png 70w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Inspiration-Glute-Press-IPGM5-60-web-600x600.png 600w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Inspiration-Glute-Press-IPGM5-60-web-100x100.png 100w"
                  sizes="(max-width: 1024px) 100vw, 1024px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/nautilus-inspiration-glute-press/"
                  data-wpel-link="internal"
                >
                  Inspiration® Glute Press
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="/product-category/brand/nautilus/inspiration-line/"
              data-wpel-link="internal"
            >
              <div className="card">
                <div className="card-body">
                  <h3>
                    View All
                    <br />
                    Inspiration
                  </h3>
                </div>
              </div>
            </a>
          </div>
        </div>
        {/*/ .row */}
      </div>
      {/*/ .container */}
    </section>
    <section className="frequently-purchased-with-prods-section">
      <div className="container">
        <h2>Frequently Purchased With</h2>
        <div className="row">
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/10trx-freerunner-treadmill/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/10trx-freerunner-treadmill/"
                data-wpel-link="internal"
              >
                <img
                  width={1024}
                  height={1024}
                  src="https://corehandf.com/wp-content/uploads/2018/12/Startrac-10-TRx-Product-Image-scaled-e1706729713642-1024x1024.webp"
                  className="attachment-large size-large wp-post-image"
                  alt=""
                  decoding="async"
                  loading="lazy"
                  srcSet="https://corehandf.com/wp-content/uploads/2018/12/Startrac-10-TRx-Product-Image-scaled-e1706729713642-1024x1024.webp 1024w, https://corehandf.com/wp-content/uploads/2018/12/Startrac-10-TRx-Product-Image-scaled-e1706729713642-300x300.webp 300w, https://corehandf.com/wp-content/uploads/2018/12/Startrac-10-TRx-Product-Image-scaled-e1706729713642-150x150.webp 150w, https://corehandf.com/wp-content/uploads/2018/12/Startrac-10-TRx-Product-Image-scaled-e1706729713642-768x768.webp 768w, https://corehandf.com/wp-content/uploads/2018/12/Startrac-10-TRx-Product-Image-scaled-e1706729713642-70x70.webp 70w, https://corehandf.com/wp-content/uploads/2018/12/Startrac-10-TRx-Product-Image-scaled-e1706729713642-600x600.webp 600w, https://corehandf.com/wp-content/uploads/2018/12/Startrac-10-TRx-Product-Image-scaled-e1706729713642-100x100.webp 100w, https://corehandf.com/wp-content/uploads/2018/12/Startrac-10-TRx-Product-Image-scaled-e1706729713642.webp 1536w"
                  sizes="(max-width: 1024px) 100vw, 1024px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/10trx-freerunner-treadmill/"
                  data-wpel-link="internal"
                >
                  10TRx FreeRunner™ Treadmill
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/throwdown-4-x-14-standard-xtc-rig/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/throwdown-4-x-14-standard-xtc-rig/"
                data-wpel-link="internal"
              >
                <img
                  width={1024}
                  height={683}
                  src="https://corehandf.com/wp-content/uploads/2020/04/Throwdown-XTC-Rig-4x14-9-XTC-414C-60-Matte-Black-Product-Image-1024x683.webp"
                  className="attachment-large size-large wp-post-image"
                  alt=""
                  decoding="async"
                  loading="lazy"
                  srcSet="https://corehandf.com/wp-content/uploads/2020/04/Throwdown-XTC-Rig-4x14-9-XTC-414C-60-Matte-Black-Product-Image-1024x683.webp 1024w, https://corehandf.com/wp-content/uploads/2020/04/Throwdown-XTC-Rig-4x14-9-XTC-414C-60-Matte-Black-Product-Image-300x200.webp 300w, https://corehandf.com/wp-content/uploads/2020/04/Throwdown-XTC-Rig-4x14-9-XTC-414C-60-Matte-Black-Product-Image-768x512.webp 768w, https://corehandf.com/wp-content/uploads/2020/04/Throwdown-XTC-Rig-4x14-9-XTC-414C-60-Matte-Black-Product-Image-1536x1024.webp 1536w, https://corehandf.com/wp-content/uploads/2020/04/Throwdown-XTC-Rig-4x14-9-XTC-414C-60-Matte-Black-Product-Image-2048x1365.webp 2048w, https://corehandf.com/wp-content/uploads/2020/04/Throwdown-XTC-Rig-4x14-9-XTC-414C-60-Matte-Black-Product-Image-600x400.webp 600w"
                  sizes="(max-width: 1024px) 100vw, 1024px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/throwdown-4-x-14-standard-xtc-rig/"
                  data-wpel-link="internal"
                >
                  4 x 14 Standard XTC Rig
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/stairmaster-10g/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/stairmaster-10g/"
                data-wpel-link="internal"
              >
                <img
                  width={1024}
                  height={1024}
                  src="https://corehandf.com/wp-content/uploads/2019/09/Stairmaster-10-G-Hero-Shot-Transparent-1024x1024.webp"
                  className="attachment-large size-large wp-post-image"
                  alt=""
                  decoding="async"
                  loading="lazy"
                  srcSet="https://corehandf.com/wp-content/uploads/2019/09/Stairmaster-10-G-Hero-Shot-Transparent-1024x1024.webp 1024w, https://corehandf.com/wp-content/uploads/2019/09/Stairmaster-10-G-Hero-Shot-Transparent-300x300.webp 300w, https://corehandf.com/wp-content/uploads/2019/09/Stairmaster-10-G-Hero-Shot-Transparent-150x150.webp 150w, https://corehandf.com/wp-content/uploads/2019/09/Stairmaster-10-G-Hero-Shot-Transparent-768x768.webp 768w, https://corehandf.com/wp-content/uploads/2019/09/Stairmaster-10-G-Hero-Shot-Transparent-1536x1536.webp 1536w, https://corehandf.com/wp-content/uploads/2019/09/Stairmaster-10-G-Hero-Shot-Transparent-2048x2048.webp 2048w, https://corehandf.com/wp-content/uploads/2019/09/Stairmaster-10-G-Hero-Shot-Transparent-70x70.webp 70w, https://corehandf.com/wp-content/uploads/2019/09/Stairmaster-10-G-Hero-Shot-Transparent-600x600.webp 600w, https://corehandf.com/wp-content/uploads/2019/09/Stairmaster-10-G-Hero-Shot-Transparent-100x100.webp 100w"
                  sizes="(max-width: 1024px) 100vw, 1024px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/stairmaster-10g/"
                  data-wpel-link="internal"
                >
                  10G
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-4">
            <a
              href="https://corehandf.com/product/schwinn-z-bike/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/schwinn-z-bike/"
                data-wpel-link="internal"
              >
                <img
                  width={1001}
                  height={1024}
                  src="https://corehandf.com/wp-content/uploads/2021/02/Schwinn-Z-Bike-01-Product-Image-1001x1024.webp"
                  className="attachment-large size-large wp-post-image"
                  alt=""
                  decoding="async"
                  loading="lazy"
                  srcSet="https://corehandf.com/wp-content/uploads/2021/02/Schwinn-Z-Bike-01-Product-Image-1001x1024.webp 1001w, https://corehandf.com/wp-content/uploads/2021/02/Schwinn-Z-Bike-01-Product-Image-293x300.webp 293w, https://corehandf.com/wp-content/uploads/2021/02/Schwinn-Z-Bike-01-Product-Image-768x786.webp 768w, https://corehandf.com/wp-content/uploads/2021/02/Schwinn-Z-Bike-01-Product-Image-1501x1536.webp 1501w, https://corehandf.com/wp-content/uploads/2021/02/Schwinn-Z-Bike-01-Product-Image-2002x2048.webp 2002w, https://corehandf.com/wp-content/uploads/2021/02/Schwinn-Z-Bike-01-Product-Image-600x614.webp 600w"
                  sizes="(max-width: 1001px) 100vw, 1001px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/schwinn-z-bike/"
                  data-wpel-link="internal"
                >
                  Z Bike
                </a>
              </div>
            </div>
          </div>
        </div>
        {/*/ .row */}
      </div>
      {/*/ .container */}
    </section>
    <section id="request-a-quote-form" className="section request-a-quote-form quote-form">
      <div className="container">
        <h2>
          <span>Start Your Consultation Today</span>
        </h2>
        <div className="row center-xs">
          <div className="col-md-6 offset-md-6">
            {/*[if lte IE 8]>

<![endif]*/}
          </div>
        </div>
      </div>
    </section>
    <a className="sticky-cta" href="#request-a-quote-form">
      <img src="/wp-content/uploads/2022/04/Icon_map-gym.png" />
      Start Consultation
    </a>{" "}
  </main>
  {/* /.main */}
</>


 )
}

export default Inspiration;