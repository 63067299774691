import Home from "../Pages/home/home"
import About from "../Pages/aboutus/About-us";
import Contact from "../Pages/contact/contact";
import Nautilus from "../Pages/nautilus/nautilus";
import Startrac from "../Pages/star-trac/star-trac";
import Stairmaster from "../Pages/stairmaster/stairmaster";
import Throwdown from "../Pages/throwdown/throwdown";
import Schwinn from "../Pages/schwinn/schwinn";
import Warranty from "../Pages/warranty/Warranty";
import Catalogue from "../Pages/catalogue/catalogue";
import OurCulture from "../Pages/ourculture/ourculture";
import SocialResponsibility  from "../Pages/socialresponsibility/socialresponsibility";
import Fitness from "../Pages/fitness/fitness"
import ALLCordio from "../Pages/main-floor-cardio/Product-category";
import Textrendmilj from "../Pages/main-floor-cardio/4tr-treadmill";
import StartVirtualBike from "../Pages/product/startrac-virtualBike/star-trac-virtual-bike"
import MultiUnitHousing from "../Pages/multi-unit-housing/multi-unit-housing";
import Hospitality from "../Pages/hospitality/hospitality";
import CorporateFitness from "../Pages/corporate-fitness/corporate-fitness";
import AthleticRecCenter from "../Pages/athletic-rec-centers/athletic-rec-centers";
import MunicipalitieSrecreation from "../Pages/municipalitie-srecreation/municipalitie-srecreation";
import Ymca from "../Pages/ymca/ymca";
import GovernmentMilitary from "../Pages/government-military/government-military";
import RehabFacility from "../Pages/rehab-facility/rehab-facility"
import BuilderDeveloper from "../Pages/builder-developer/builder-developer"
import Oneseries from "../Pages/one-series/one-series";
import Instinct from "../Pages/Instinct/instinct";
import Leverage from "../Pages/Leverage/leverage";
import MultiStations from "../Pages/multistations/multistations";
import HumanSport from "../Pages/humansport/humansport";
import PlateLoaded from "../Pages/plateloaded/plateloaded";
import Impact from "../Pages/Impact/impact";
import Benches from "../Pages/benches/benches";
import Racksplatforms from "../Pages/racksplatforms/racksplatforms";
import Freeweights from "../Pages/freeweights/freeweights";
import Rigs from "../Pages/rigs/rigs";
import StorageRacks from "../Pages/storageracks/storageracks";
import FitAccessories from "../Pages/fitaccessories/fitaccessories";
import Inspiration from "../Pages/inspiration/inspiration";
import Bagracks from "../Pages/bagracks/bagracks";
import Accessories from "../Pages/accessories/accessories";
import kevlar from "../Pages/kevlar/kevlar";
import Boxmaster from "../Pages/boxmaster/boxmaster";
import Pirates from "../Pages/pirates/pirates";
import cardio from "../Pages/cardio/cardio";
import NotFound from "../Pages/notfound/notfound";
import Fourseries from "../Pages/4series/4series";
import Nightseries from "../Pages/8series/8series";
import Tenseries from "../Pages/10series/10series";
import Hospitalit from "../Pages/hospitality/hospitality";
import Jacobsladder from "../Pages/jacobsladder/jacobsladder";
import Groupcycling from "../Pages/groupcycling/groupcycling";
import Functional from "../Pages/functional/functional";
import Strengthtraining from "../Pages/strengthtraining/strengthtraining";
import OurPartners from "../Pages/ourpartners/ourpartners";
import Customization from "../Pages/customization/customization";
import BikeBuilder from '../Pages/bikecustomization/bikecustomization.js';
import Blog from "../Pages/blog/blog";
import Blog1 from "../Pages/blog1/blog1";

import PrivacyPolicy from "../Pages/privacypolicy/privacypolicy.js"






const router = [
    { path: "/", exact: true, name: "Home", element: Home },
    { path: "/About", exact: true, name: "About", element: About },
    { path: "/Contact", exact: true, name: "Contact", element: Contact },
    { path: "/OurCulture", exact: true, name: "OurCulture", element: OurCulture },
    { path: "/SocialResponsibility", exact: true, name: "SocialResponsibility", element: SocialResponsibility },
    { path: "/Stairmaster", exact: true, name: "Stairmaster", element: Stairmaster },
    { path: "/Nautilus", exact: true, name: "Nautilus", element: Nautilus },
    { path: "/Startrac", exact: true, name: "Nautilus", element: Startrac },
    { path: "/Schwinn", exact: true, name: "Schwinn", element: Schwinn },
    { path: "/Throwdown", exact: true, name: "Throwdown", element: Throwdown },
    { path: "/Warranty", exact: true, name: "Warranty", element: Warranty },
    { path: "/Catalogue", exact: true, name: "Catalogue", element: Catalogue },
    { path: "/Fitness", exact: true, name: "fitness", element: Fitness },
    { path: "/ALLCordio", exact: true, name: "ALLCordio", element: ALLCordio },
    { path: "/getProductsListById/:id", exact: true, name: "ALLCordio", element: ALLCordio },
    { path: "/Product/:id", exact: true, name: "Catalogue", element: Textrendmilj },
    { path: "/StartVirtualBike", exact: true, name: "StartVirtualBike", element: StartVirtualBike },
    { path: "/MultiUnitHousing", exact: true, name: "MultiUnitHousing", element: MultiUnitHousing },
    { path: "/Hospitality", exact: true, name: "Hospitality", element: Hospitality },
    { path: "/CorporateFitness", exact: true, name: "CorporateFitness", element: CorporateFitness },
    { path: "/AthleticRecCenter", exact: true, name: "AthleticRecCenter", element:AthleticRecCenter  },
    { path: "/MunicipalitieSrecreation", exact: true, name: "AthleticRecCenter", element:MunicipalitieSrecreation  },
    { path: "/Ymca", exact: true, name: "Ymca", element:Ymca  },
    { path: "/GovernmentMilitary", exact: true, name: "GovernmentMilitary", element:GovernmentMilitary },
    { path: "/RehabFacility", exact: true, name: "RehabFacility", element:RehabFacility },
    { path: "/BuilderDeveloper", exact: true, name: "BuilderDeveloper", element: BuilderDeveloper},
    { path: "/Oneseries", exact: true, name: "Oneseries", element:Oneseries },
    { path: "/Instinct", exact: true, name: "Instinct", element: Instinct},
    { path: "/Leverage", exact: true, name: "Leverage", element: Leverage},
    { path: "/MultiStations", exact: true, name: "MultiStations", element: MultiStations},
    { path: "/HumanSport", exact: true, name: "HumanSport", element: HumanSport},
    { path: "/PlateLoaded", exact: true, name: "PlateLoaded", element: PlateLoaded},
    { path: "/Impact", exact: true, name: "Impact", element: Impact},
    { path: "/Benches", exact: true, name: "Benches", element: Benches},
    { path: "/Racksplatforms", exact: true, name: "Racksplatforms", element: Racksplatforms},
    { path: "/Freeweights", exact: true, name: "Freeweights", element: Freeweights},
    { path: "/Rigs", exact: true, name: "Rigs", element: Rigs},
    { path: "/StorageRacks", exact: true, name: "StorageRacks", element: StorageRacks},
    { path: "/FitAccessories", exact: true, name: "FitAccessories", element: FitAccessories},
    { path: "/Bagracks", exact: true, name: "Inspiration", element: Inspiration},
    { path: "/StorageRacks", exact: true, name: "Bagracks", element: Bagracks},
    { path: "/Accessories", exact: true, name: "Accessories", element: Accessories},
    { path: "/kevlar", exact: true, name: "kevlar", element: kevlar},
    { path: "/Boxmaster", exact: true, name: "Boxmaster", element: Boxmaster},
    { path: "/Pirates", exact: true, name: "Pirates", element: Pirates},
    { path: "/cardio", exact: true, name: "cardio", element: cardio},
    { path: "*", exact: false, name: "NotFound", element: NotFound},
    { path: "/Fourseries", exact: false, name: "Fourseries", element: Fourseries},  
    { path: "/Nightseries", exact: false, name: "Nightseries", element: Nightseries},
    { path: "/Tenseries", exact: false, name: "Tenseries", element: Tenseries},
    { path: "/Hospitalit", exact: false, name: "Hospitalit", element: Hospitalit},
    { path: "/Jacobsladder", exact: false, name: "Jacobsladder", element: Jacobsladder},  
    { path: "/Groupcycling", exact: false, name: "Groupcycling", element: Groupcycling},
    { path: "/Functional", exact: false, name: "Functional", element: Functional},
    { path: "/Strengthtraining", exact: false, name: "Strengthtraining", element: Strengthtraining},
    { path: "/Strengthtraining", exact: false, name: "Strengthtraining", element: Strengthtraining},
    { path: "/OurPartners", exact: false, name: "OurPartners", element: OurPartners},
    { path: "/Customization", exact: false, name: "Customization", element: Customization},
    { path: "/Blog", exact: false, name: "Blog", element: Blog},
    { path: "/Blog1", exact: false, name: "Blog1", element: Blog1},
    { path: "/bikeCustomization", exact: false, name: "bikeCustomization", element: BikeBuilder},
    { path: "/PrivacyPolicy", exact: false, name: "PrivacyPolicy", element: PrivacyPolicy},

    


]


export default router;