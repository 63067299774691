import React from "react";
import Navbar from "../../CommonCompounds/Nav/Nav.jsx";
import Footer  from "../../CommonCompounds/Nav/Nav.jsx";
import RehabFacility from "../../Compounds/RehabFacility/RehabFacility.jsx";


function RehabFacilityPage(){
    return(
        <>

       <Navbar />
       <RehabFacility />
       <Footer />
        </>
    )
}
 export default RehabFacilityPage;