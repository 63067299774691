import React from "react";
import Navbar from "../../CommonCompounds/Nav/Nav.jsx";
import Footer from "../../CommonCompounds/Footer/Footer.jsx";
import Textrendmilj from "../../Compounds/Product/Product.jsx";



function ProductCategoryPage() {
    return (
        <>
            <Navbar />
            <Textrendmilj />
            <Footer />
        </>
    );
}

export default ProductCategoryPage;