import React from "react";
import Navbar from "../../CommonCompounds/Nav/Nav.jsx"
import Footer from "../../CommonCompounds/Footer/Footer.jsx";

import ALLCordio from "../../Compounds/ProductCategory/type/main-floor-cardio/AllCardio.jsx";


function home(){
    return(
        <>

       <Navbar />
       <ALLCordio />
       <Footer />
        </>
    )
}
 export default home;