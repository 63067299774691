import React from "react";
import Navbar from "../../CommonCompounds/Nav/Nav.jsx"

import Footer from "../../CommonCompounds/Footer/Footer.jsx";
import MultiUnitHousing from "../../Compounds/MultiUnitHousing/MultiUnitHousing.jsx";


function MultiUnitHousingPage(){
    return(
        <>

       <Navbar />
       <MultiUnitHousing />
       <Footer />
        </>
    )
}
 export default MultiUnitHousingPage;