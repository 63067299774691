import React from "react";



function OurPartners (){
    return(
        <>
<>
  <main className="main" id="main">
    <section className="breadcrumbs">
      <ul>
        {/*li><a href="#">Home</a></li>
          <li class="current">Star Trac</li*/}
        <li>
          <span>
            <span>
              <a href="https://corehandf.com/" data-wpel-link="internal">
                Home
              </a>
            </span>{" "}
            »{" "}
            <span className="breadcrumb_last" aria-current="page">
              OUR PARTNERS
            </span>
          </span>
        </li>{" "}
      </ul>
    </section>
    <section className="section banner">
      <div
        className="single-banner"
        style={{
          background:
            '#2e2e2e url("https://corehandf.com/wp-content/uploads/2020/10/Our-Partners_Page_Header_cyan-scaled.jpg") center center no-repeat !important'
        }}
      >
        <div className="single-banner-content">
          <h1 className="banner-title largest">OUR PARTNERS</h1>
        </div>
      </div>
    </section>
    <section className="section single-content">
      <div className="container">
        <div className="row center-xs">
          <div className="col-xs-2">
            <div className="single-share">
              {/*ul class="list share-list">
							<li>Share</li>
							<li>
								<a href="#" class="facebook">
									<i class="fa fa-facebook"></i>
								</a>
							</li>
							<li>
								<a href="#" class="twitter">
									<i class="fa fa-twitter"></i>
								</a>
							</li>
							<li>
								<a href="#" class="google-plus">
									<i class="fa fa-google-plus"></i>
								</a>
							</li>
							<li>
								<a href="#" class="linkedin">
									<i class="fa fa-linkedin"></i>
								</a>
							</li>
							<li class="seperator"></li>
							<li>
								<a href="#">
									<i class="fa fa-envelope"></i>
								</a>
							</li>
						</ul*/}
            </div>
          </div>
          <div className="col-xs-10">
            <p style={{ textAlign: "center" }}>
              At Core Health &amp; Fitness, we know the importance of seeking
              out thought leaders and partners who are experts in their space.
              This is why we partner with the best technology companies in the
              fitness industry to create the best tech available.​
            </p>
            <p />
            <center />
            <center>
              {" "}
              <a
                href="https://www.ecofitnetworks.com/"
                data-wpel-link="external"
                target="_blank"
                rel="nofollow external noopener noreferrer"
              >
                <img
                  decoding="async"
                  className="alignnone wp-image-57332"
                  src="https://corehandf.com/wp-content/uploads/2021/11/Partner-Logos-300x300.png"
                  alt="Ecofit"
                  width={201}
                  height={201}
                  srcSet="https://corehandf.com/wp-content/uploads/2021/11/Partner-Logos.png 300w, https://corehandf.com/wp-content/uploads/2021/11/Partner-Logos-100x100.png 100w, https://corehandf.com/wp-content/uploads/2021/11/Partner-Logos-150x150.png 150w"
                  sizes="(max-width: 201px) 100vw, 201px"
                />
              </a>{" "}
              <a
                href="https://4iiii.com/"
                target="_blank"
                rel="noopener noreferrer nofollow external"
                data-wpel-link="external"
              >
                <img
                  decoding="async"
                  className="alignnone wp-image-41187"
                  src="https://corehandf.com/wp-content/uploads/2020/10/4iiii-150x150.png"
                  alt="4iiii"
                  width={201}
                  height={201}
                  srcSet="https://corehandf.com/wp-content/uploads/2020/10/4iiii-150x150.png 150w, https://corehandf.com/wp-content/uploads/2020/10/4iiii.png 300w, https://corehandf.com/wp-content/uploads/2020/10/4iiii-100x100.png 100w"
                  sizes="(max-width: 201px) 100vw, 201px"
                />
              </a>{" "}
              <a
                href="https://npe-inc.com/"
                target="_blank"
                rel="noopener noreferrer nofollow external"
                data-wpel-link="external"
              >
                <img
                  decoding="async"
                  className="alignnone wp-image-41182"
                  src="https://corehandf.com/wp-content/uploads/2020/10/North-Pole-Engineering-150x150.png"
                  alt="North-Pole-Engineering"
                  width={201}
                  height={201}
                  srcSet="https://corehandf.com/wp-content/uploads/2020/10/North-Pole-Engineering-150x150.png 150w, https://corehandf.com/wp-content/uploads/2020/10/North-Pole-Engineering.png 300w, https://corehandf.com/wp-content/uploads/2020/10/North-Pole-Engineering-100x100.png 100w"
                  sizes="(max-width: 201px) 100vw, 201px"
                />
              </a>{" "}
              <a
                href="https://www.myzone.org/"
                target="_blank"
                rel="noopener noreferrer nofollow external"
                data-wpel-link="external"
              >
                <img
                  loading="lazy"
                  decoding="async"
                  className="alignnone wp-image-41177"
                  src="https://corehandf.com/wp-content/uploads/2020/10/Myzone-150x150.png"
                  alt="Myzone"
                  width={201}
                  height={201}
                  srcSet="https://corehandf.com/wp-content/uploads/2020/10/Myzone-150x150.png 150w, https://corehandf.com/wp-content/uploads/2020/10/Myzone.png 300w, https://corehandf.com/wp-content/uploads/2020/10/Myzone-100x100.png 100w"
                  sizes="(max-width: 201px) 100vw, 201px"
                />
              </a>{" "}
              <a
                href="https://www.motosumo.com/"
                target="_blank"
                rel="noopener noreferrer nofollow external"
                data-wpel-link="external"
              >
                <img
                  loading="lazy"
                  decoding="async"
                  className="alignnone wp-image-41172"
                  src="https://corehandf.com/wp-content/uploads/2020/10/Motosumo-150x150.png"
                  alt="Motosumo"
                  width={201}
                  height={201}
                  srcSet="https://corehandf.com/wp-content/uploads/2020/10/Motosumo-150x150.png 150w, https://corehandf.com/wp-content/uploads/2020/10/Motosumo.png 300w, https://corehandf.com/wp-content/uploads/2020/10/Motosumo-100x100.png 100w"
                  sizes="(max-width: 201px) 100vw, 201px"
                />
              </a>{" "}
              <a
                href="https://www.mindbodyonline.com/business/partner/core-health-and-fitness"
                target="_blank"
                rel="noopener noreferrer nofollow external"
                data-wpel-link="external"
              >
                <img
                  loading="lazy"
                  decoding="async"
                  className="alignnone wp-image-41167"
                  src="https://corehandf.com/wp-content/uploads/2020/10/Mindbody-150x150.png"
                  alt="Mindbody"
                  width={200}
                  height={200}
                  srcSet="https://corehandf.com/wp-content/uploads/2020/10/Mindbody-150x150.png 150w, https://corehandf.com/wp-content/uploads/2020/10/Mindbody.png 300w, https://corehandf.com/wp-content/uploads/2020/10/Mindbody-100x100.png 100w"
                  sizes="(max-width: 200px) 100vw, 200px"
                />
              </a>{" "}
              <a
                href="https://intelligent-cycling.com/landingpage/"
                target="_blank"
                rel="noopener noreferrer nofollow external"
                data-wpel-link="external"
              >
                <img
                  loading="lazy"
                  decoding="async"
                  className="alignnone wp-image-41162"
                  src="https://corehandf.com/wp-content/uploads/2020/10/Intelligent-Cycle-150x150.png"
                  alt="Intelligent-Cycle"
                  width={201}
                  height={201}
                  srcSet="https://corehandf.com/wp-content/uploads/2020/10/Intelligent-Cycle-150x150.png 150w, https://corehandf.com/wp-content/uploads/2020/10/Intelligent-Cycle.png 300w, https://corehandf.com/wp-content/uploads/2020/10/Intelligent-Cycle-100x100.png 100w"
                  sizes="(max-width: 201px) 100vw, 201px"
                />
              </a>{" "}
              <a
                href="https://www.fitmetrix.io/"
                target="_blank"
                rel="noopener noreferrer nofollow external"
                data-wpel-link="external"
              >
                <img
                  loading="lazy"
                  decoding="async"
                  className="alignnone wp-image-41152"
                  src="https://corehandf.com/wp-content/uploads/2020/10/Fitmetrix-150x150.png"
                  alt="Fitmetrix"
                  width={201}
                  height={201}
                  srcSet="https://corehandf.com/wp-content/uploads/2020/10/Fitmetrix-150x150.png 150w, https://corehandf.com/wp-content/uploads/2020/10/Fitmetrix.png 300w, https://corehandf.com/wp-content/uploads/2020/10/Fitmetrix-100x100.png 100w"
                  sizes="(max-width: 201px) 100vw, 201px"
                />
              </a>
            </center>
            <p />
            <p style={{ textAlign: "center" }}>
              <strong>
                <a
                  href="https://corehandf.com/core-about-us"
                  rel="noopener noreferrer"
                  data-wpel-link="internal"
                >
                  ABOUT{" "}
                </a>
                |{" "}
                <a
                  href="https://corehandf.com/our-culture/"
                  rel="noopener noreferrer"
                  data-wpel-link="internal"
                >
                  CULTURE
                </a>{" "}
                |{" "}
                <a
                  href="https://corehandf.com/social-responsibility/"
                  rel="noopener noreferrer"
                  data-wpel-link="internal"
                >
                  SOCIAL RESPONSIBILITY
                </a>{" "}
                |{" "}
                <a
                  href="https://corehandf.com/our-brands/"
                  rel="noopener noreferrer"
                  data-wpel-link="internal"
                >
                  BRANDS
                </a>{" "}
                |{" "}
                <a
                  href="https://corehandf.com/our-partners/"
                  rel="noopener noreferrer"
                  data-wpel-link="internal"
                >
                  PARTNERS
                </a>
              </strong>{" "}
              <strong>
                |{" "}
                <a
                  href="https://corehandf.com/our-careers/"
                  rel="noopener noreferrer"
                  data-wpel-link="internal"
                >
                  CAREERS
                </a>
              </strong>
            </p>
          </div>
        </div>
      </div>
    </section>
  </main>
  {/* /.main */}
</>


        </>
    )
}

export default OurPartners;