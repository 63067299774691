import React from "react";
import ALLCordio from "../ProductCategory/type/main-floor-cardio/AllCardio";



function NightSeries(){
    return(
   <>
<>
  <main className="main" id="main">
    <section className="breadcrumbs">
      <ul>
        {/*li><a href="#">Home</a></li>
          <li class="current">Star Trac</li*/}
        <li>
          <span>
            <span>
              <a href="https://corehandf.com/" data-wpel-link="internal">
                Home
              </a>
            </span>{" "}
            »{" "}
            <span>
              <a
                href="https://corehandf.com/product_line/"
                data-wpel-link="internal"
              >
                Product Lines
              </a>
            </span>{" "}
            »{" "}
            <span>
              <a
                href="https://corehandf.com/product_line/star-trac-3/"
                data-wpel-link="internal"
              >
                Star Trac®
              </a>
            </span>{" "}
            »{" "}
            <span className="breadcrumb_last" aria-current="page">
              8 Series
            </span>
          </span>
        </li>{" "}
      </ul>
    </section>
    <section
      className="section product-line-banner-section"
      style={{
        background:
          "url(https://corehandf.com/wp-content/uploads/2023/04/Star-Trac-8-Series-Lifestyle-Image.webp) top center no-repeat",
        backgroundSize: "cover"
      }}
    >
      <div className="product-line-banner">
        <div className="content-wrap">
          <img src="https://corehandf.com/wp-content/uploads/2023/04/logo-starimaster-start-trac-trans-white-1.png" />
          <h1 className="banner-title largest">8 Series</h1>
        </div>
      </div>
    </section>
    <section className="section product-line-intro-section container">
      <h2>BUILT FOR QUALITY AND PERFORMANCE</h2>
      <p>
        The Star Trac 8 Series line was built to give you the quality and
        performance you expect from high-end machines with user-focused features
        like dual personal fans, a hot bar with heart rate monitoring and
        SoftTrac® technology. For a premium experience pair any 8 Series machine
        with the OpenHub console which provides entertainment, fitness tracking
        and asset management solutions that will offer a complete fitness
        experience to facility owners and their members.
      </p>
    </section>
    <section className="section full-width-column-section container image-left">
      <div className="row">
        <div className="col-md-5">
          <div className="img-video-wrap">
            <img src="https://corehandf.com/wp-content/uploads/2019/01/Star-Trac-20in-Console-Embedded-1-scaled.webp" />
          </div>
        </div>
        <div className="col-md-7">
          <div className="content-wrap">
            <h2>
              Embedded Console powered by Android<sup>TM</sup>
            </h2>
            <p>
              The 8 and 10 Series Cardio embedded consoles boast clear HD
              resolution, high-speed functionality, and familiar user-interface
              for workouts and entertainment. With on-board apps, IPTV,
              hassle-free maintenance, and brand customization options, our
              embedded consoles are the solution for modern fitness technology.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className="section fifty-fifty-column-section container">
      <div className="row gx-5">
        <div className="col-md-6">
          <div className="content-wrap">
            <img src="https://corehandf.com/wp-content/uploads/2023/04/Ecofit_WhiteBG_SM.webp" />
            <h2>MANAGEMENT SOLUTIONS</h2>
            <p>
              ECOFIT® asset management solutions presents business owners with
              data-driven insights to maximize equipment lifespan and improve
              facility functionality. Equipment analytic reports provide
              utilization statistics, wait-time trends, and much more.
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="content-wrap">
            <img src="https://corehandf.com/wp-content/uploads/2017/01/Star-Trac-Console-Openhub-LCD-Quick-Keys-Deadon.jpg" />
            <h2>QUICK KEY SELECTION</h2>
            <p>
              Quickly adjust speed and incline with the easy Quick Key selection
              on the 8-TRx and FreeRunner treadmill LCD displays. Members can
              quickly navigate the treadmill controls and adjust their workout
              with a row of large buttons on the display. This user-friendly
              feature allows for easy adjustment to the workout while in motion.
              The embedded OpenHub displays include a customizable Quick Key
              view for every type of equipment.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className="series-prods-section">
      <div className="container">
        <h2>Browse 8 Series Products</h2>
        <div className="row">
          <div className="col-md-3">
            <a
              href="https://corehandf.com/product/8-series-pvs/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/8-series-pvs/"
                data-wpel-link="internal"
              >
                <img
                  width={500}
                  height={500}
                  src="https://corehandf.com/wp-content/uploads/2017/02/8-Series-PVS.png"
                  className="attachment-large size-large wp-post-image"
                  alt=""
                  decoding="async"
                  fetchpriority="high"
                  srcSet="https://corehandf.com/wp-content/uploads/2017/02/8-Series-PVS.png 500w, https://corehandf.com/wp-content/uploads/2017/02/8-Series-PVS-300x300.png 300w, https://corehandf.com/wp-content/uploads/2017/02/8-Series-PVS-100x100.png 100w, https://corehandf.com/wp-content/uploads/2017/02/8-Series-PVS-150x150.png 150w"
                  sizes="(max-width: 500px) 100vw, 500px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/8-series-pvs/"
                  data-wpel-link="internal"
                >
                  8 Series PVS
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <a
              href="https://corehandf.com/product/2019-star-trac-15-inch-capacitive-touch-openhub-console/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/2019-star-trac-15-inch-capacitive-touch-openhub-console/"
                data-wpel-link="internal"
              >
                <img
                  width={1024}
                  height={1024}
                  src="https://corehandf.com/wp-content/uploads/2019/01/Star-Trac-15in-Console-Embedded-1024x1024.webp"
                  className="attachment-large size-large wp-post-image"
                  alt=""
                  decoding="async"
                  srcSet="https://corehandf.com/wp-content/uploads/2019/01/Star-Trac-15in-Console-Embedded-1024x1024.webp 1024w, https://corehandf.com/wp-content/uploads/2019/01/Star-Trac-15in-Console-Embedded-300x300.webp 300w, https://corehandf.com/wp-content/uploads/2019/01/Star-Trac-15in-Console-Embedded-150x150.webp 150w, https://corehandf.com/wp-content/uploads/2019/01/Star-Trac-15in-Console-Embedded-768x768.webp 768w, https://corehandf.com/wp-content/uploads/2019/01/Star-Trac-15in-Console-Embedded-1536x1536.webp 1536w, https://corehandf.com/wp-content/uploads/2019/01/Star-Trac-15in-Console-Embedded-2048x2048.webp 2048w, https://corehandf.com/wp-content/uploads/2019/01/Star-Trac-15in-Console-Embedded-70x70.webp 70w, https://corehandf.com/wp-content/uploads/2019/01/Star-Trac-15in-Console-Embedded-600x600.webp 600w, https://corehandf.com/wp-content/uploads/2019/01/Star-Trac-15in-Console-Embedded-100x100.webp 100w"
                  sizes="(max-width: 1024px) 100vw, 1024px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/2019-star-trac-15-inch-capacitive-touch-openhub-console/"
                  data-wpel-link="internal"
                >
                  Star Trac 16″ Capacitive OpenHub Console
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <a
              href="https://corehandf.com/product/startrac-8tr-treadmill/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/startrac-8tr-treadmill/"
                data-wpel-link="internal"
              >
                <img
                  width={1024}
                  height={1004}
                  src="https://corehandf.com/wp-content/uploads/2020/11/Star-Trac-8-TR-Hero-Shot-Transparent-1024x1004.png"
                  className="attachment-large size-large wp-post-image"
                  alt=""
                  decoding="async"
                  srcSet="https://corehandf.com/wp-content/uploads/2020/11/Star-Trac-8-TR-Hero-Shot-Transparent-1024x1004.png 1024w, https://corehandf.com/wp-content/uploads/2020/11/Star-Trac-8-TR-Hero-Shot-Transparent-300x294.png 300w, https://corehandf.com/wp-content/uploads/2020/11/Star-Trac-8-TR-Hero-Shot-Transparent-768x753.png 768w, https://corehandf.com/wp-content/uploads/2020/11/Star-Trac-8-TR-Hero-Shot-Transparent-1536x1506.png 1536w, https://corehandf.com/wp-content/uploads/2020/11/Star-Trac-8-TR-Hero-Shot-Transparent-70x70.png 70w, https://corehandf.com/wp-content/uploads/2020/11/Star-Trac-8-TR-Hero-Shot-Transparent-600x588.png 600w, https://corehandf.com/wp-content/uploads/2020/11/Star-Trac-8-TR-Hero-Shot-Transparent.png 1969w"
                  sizes="(max-width: 1024px) 100vw, 1024px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/startrac-8tr-treadmill/"
                  data-wpel-link="internal"
                >
                  8TR Treadmill
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <a
              href="/product-category/brand/star-trac/8-series-line/"
              data-wpel-link="internal"
            >
              <div className="card">
                <div className="card-body">
                  <h3>
                    View All
                    <br />8 Series
                  </h3>
                </div>
              </div>
            </a>
          </div>
        </div>
        {/*/ .row */}
      </div>
      {/*/ .container */}
    </section>
    <section className="frequently-purchased-with-prods-section">
      <div className="container">
        <h2>Frequently Purchased With</h2>
        <div className="row">
          <div className="col-md-3">
            <a
              href="https://corehandf.com/product/throwdown-xtc-2x4-compact-rig/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/throwdown-xtc-2x4-compact-rig/"
                data-wpel-link="internal"
              >
                <img
                  width={1024}
                  height={576}
                  src="https://corehandf.com/wp-content/uploads/2020/08/Throwdown-XTC-Compact-Rig-2x4-9-XTC-204C-60-Matte-Black-Product-Image-1024x576.webp"
                  className="attachment-large size-large wp-post-image"
                  alt=""
                  decoding="async"
                  loading="lazy"
                  srcSet="https://corehandf.com/wp-content/uploads/2020/08/Throwdown-XTC-Compact-Rig-2x4-9-XTC-204C-60-Matte-Black-Product-Image-1024x576.webp 1024w, https://corehandf.com/wp-content/uploads/2020/08/Throwdown-XTC-Compact-Rig-2x4-9-XTC-204C-60-Matte-Black-Product-Image-300x169.webp 300w, https://corehandf.com/wp-content/uploads/2020/08/Throwdown-XTC-Compact-Rig-2x4-9-XTC-204C-60-Matte-Black-Product-Image-768x432.webp 768w, https://corehandf.com/wp-content/uploads/2020/08/Throwdown-XTC-Compact-Rig-2x4-9-XTC-204C-60-Matte-Black-Product-Image-1536x864.webp 1536w, https://corehandf.com/wp-content/uploads/2020/08/Throwdown-XTC-Compact-Rig-2x4-9-XTC-204C-60-Matte-Black-Product-Image-2048x1152.webp 2048w, https://corehandf.com/wp-content/uploads/2020/08/Throwdown-XTC-Compact-Rig-2x4-9-XTC-204C-60-Matte-Black-Product-Image-600x338.webp 600w"
                  sizes="(max-width: 1024px) 100vw, 1024px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/throwdown-xtc-2x4-compact-rig/"
                  data-wpel-link="internal"
                >
                  2 x 4 Compact XTC Rig
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <a
              href="https://corehandf.com/product/nautilus-inspiration-back-extension/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/nautilus-inspiration-back-extension/"
                data-wpel-link="internal"
              >
                <img
                  width={1024}
                  height={683}
                  src="https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Inspiration-Back-Extension-IPBE5-60-0005-1024x683.png"
                  className="attachment-large size-large wp-post-image"
                  alt=""
                  decoding="async"
                  loading="lazy"
                  srcSet="https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Inspiration-Back-Extension-IPBE5-60-0005-1024x683.png 1024w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Inspiration-Back-Extension-IPBE5-60-0005-300x200.png 300w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Inspiration-Back-Extension-IPBE5-60-0005-768x512.png 768w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Inspiration-Back-Extension-IPBE5-60-0005-1536x1024.png 1536w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Inspiration-Back-Extension-IPBE5-60-0005-2048x1365.png 2048w, https://corehandf.com/wp-content/uploads/2017/02/Nautilus-Inspiration-Back-Extension-IPBE5-60-0005-600x400.png 600w"
                  sizes="(max-width: 1024px) 100vw, 1024px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/nautilus-inspiration-back-extension/"
                  data-wpel-link="internal"
                >
                  Inspiration® Back Extension
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <a
              href="https://corehandf.com/product/schwinn-ac-sport/"
              data-wpel-link="internal"
            ></a>
            <div className="card">
              <a
                href="https://corehandf.com/product/schwinn-ac-sport/"
                data-wpel-link="internal"
              >
                <img
                  width={1024}
                  height={1024}
                  src="https://corehandf.com/wp-content/uploads/2018/08/MicrosoftTeams-image-1-1024x1024.jpg"
                  className="attachment-large size-large wp-post-image"
                  alt=""
                  decoding="async"
                  loading="lazy"
                  srcSet="https://corehandf.com/wp-content/uploads/2018/08/MicrosoftTeams-image-1-1024x1024.jpg 1024w, https://corehandf.com/wp-content/uploads/2018/08/MicrosoftTeams-image-1-300x300.jpg 300w, https://corehandf.com/wp-content/uploads/2018/08/MicrosoftTeams-image-1-150x150.jpg 150w, https://corehandf.com/wp-content/uploads/2018/08/MicrosoftTeams-image-1-768x768.jpg 768w, https://corehandf.com/wp-content/uploads/2018/08/MicrosoftTeams-image-1-600x600.jpg 600w, https://corehandf.com/wp-content/uploads/2018/08/MicrosoftTeams-image-1-100x100.jpg 100w, https://corehandf.com/wp-content/uploads/2018/08/MicrosoftTeams-image-1.jpg 1500w"
                  sizes="(max-width: 1024px) 100vw, 1024px"
                />
              </a>
              <div className="card-body test">
                <a
                  href="https://corehandf.com/product/schwinn-ac-sport/"
                  data-wpel-link="internal"
                >
                  AC Sport
                </a>
              </div>
            </div>
          </div>
        </div>
        {/*/ .row */}
      </div>
      {/*/ .container */}
    </section>
    <section id="request-a-quote-form" className="section request-a-quote-form">
      <div className="container">
        <h2>
          <span>Start Your Consultation Today</span>
        </h2>
        <div className="row center-xs">
          <div className="col-md-6 offset-md-6">
            {/*[if lte IE 8]>

<![endif]*/}
          </div>
        </div>
      </div>
    </section>
    <a className="sticky-cta" href="#request-a-quote-form">
      <img src="/wp-content/uploads/2022/04/Icon_map-gym.png" />
      Start Consultation
    </a>{" "}
  </main>
  {/* /.main */}
</>

  </>
    )
};
export default NightSeries;
