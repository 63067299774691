import React from "react";
import Navbar from "../../CommonCompounds/Nav/Nav.jsx"
import Footer from "../../CommonCompounds/Footer/Footer.jsx";
import About from "../../Compounds/Aboutus/aboutus.jsx";


function home(){
    return(
        <>

       <Navbar />
       <About />
       <Footer />
        </>
    )
}
 export default home;