import React from "react";
import Navbar from "../../CommonCompounds/Nav/Nav.jsx";
import Footer from "../../CommonCompounds/Footer/Footer.jsx";
import Benches from "../../Compounds/Benches/Benches.jsx";

function BenchesPage() {
    return (
        <>
            <Navbar />
            <Benches />
            <Footer />
        </>
    );
}

export default BenchesPage;
