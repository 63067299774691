import React  from "react";
import Form from "../../CommonCompounds/Form/Form";
import images from "../../images/img";



function Startrac (){
    return (
        <>
        <main className="main" id="main">
  <section className="breadcrumbs">
    <ul>
      {/*li><a href="#">Home</a></li>
          <li class="current">Star Trac</li*/}
      <li>
        <span>
          <span>
            <a href="https://corehandf.com/" data-wpel-link="internal">
              Home
            </a>
          </span>{" "}
          »{" "}
          <span className="breadcrumb_last" aria-current="page">
            Star Trac®
          </span>
        </span>
      </li>
    </ul>
  </section>
  {/*  Conditional for Industry page style layout */}
  <section id="build" className="section banner">
    <div className="row brand-banner">
      <div className="col-xs-12 brand-banner-left">
        <img
          src="https://corehandf.com/wp-content/uploads/2017/02/Star-Trac-Logo-all-white.png"
          alt=""
        />
        <div
          className="bg"
          style={{
            background:
              "url(https://corehandf.com/wp-content/uploads/2020/05/Freerunner-brand-page.jpg) center center no-repeat"
          }}
        />
      </div>
    </div>
  </section>
  <section>
    <div className="container">
      <div className="cnt">
        <h1 className="banner-title">MADE TO PERFORM</h1>
        <p>
          Rooted in cardio, Star Trac has grown to innovate products that keep
          people moving and create lifelong health and fitness habits. As we
          focus on intuitive designs and reliability, we go the distance to
          provide fitness solutions for the best workout experiences. With
          dynamic technology and OpenHub connectivity, Star Trac offerings
          compliment all fitness programs.
        </p>
      </div>
    </div>
  </section>
  <section id="featured" className="section cards">
    <div className="container wide">
      <div className="row card-row">
        <div className="col-xs-3">
          <div className="card">
            <a
              href="/product-category/brand/star-trac/10-series-line/"
              data-wpel-link="internal"
            >
              <img src="https://corehandf.com/wp-content/uploads/2018/12/Startrac-10-TRx-Product-Image-scaled-e1706729713642.webp" />
              <div className="card-info">
                <p className="name">10 Series Line</p>
              </div>
            </a>
          </div>
        </div>
        <div className="col-xs-3">
          <div className="card">
            <a
              href="/product-category/brand/star-trac/8-series-line/"
              data-wpel-link="internal"
            >
              <img src="https://corehandf.com/wp-content/uploads/2020/11/Star-Trac-8-TR-Hero-Shot-Transparent.png" />
              <div className="card-info">
                <p className="name">8 SERIES LINE</p>
              </div>
            </a>
          </div>
        </div>
        <div className="col-xs-3">
          <div className="card">
            <a
              href="/product-category/brand/star-trac/virtual-bike/"
              data-wpel-link="internal"
            >
              <img src="https://corehandf.com/wp-content/uploads/2023/04/Star-Trac-Virtual-Bike-8VB-Product-Image-21-e1680728806784.png" />
              <div className="card-info">
                <p className="name">Virtual Bike</p>
              </div>
            </a>
          </div>
        </div>
        <div className="col-xs-3">
          <div className="card">
            <a
              href="/product-category/brand/star-trac/4-series-line/"
              data-wpel-link="internal"
            >
              <img src="https://corehandf.com/wp-content/uploads/2018/12/4TR_OpenHub.png" />
              <div className="card-info">
                <p className="name">4 Series Line - Light Commercial</p>
              </div>
            </a>
          </div>
        </div>
        <div className="col-xs-3">
          <div className="card">
            <a
              href="/product-category/brand/star-trac/s-series-line/"
              data-wpel-link="internal"
            >
              <img src="https://corehandf.com/wp-content/uploads/2017/02/Star-Trac-S-Series-STRX-Treadmill-9-3573-31-Product-Image-Web-3.png" />
              <div className="card-info">
                <p className="name">S SERIES LINE</p>
              </div>
            </a>
          </div>
        </div>
        <div className="col-xs-3">
          <div className="card view-all">
            <a
              href="/product-category/brand/star-trac"
              data-wpel-link="internal"
            >
              <img src="" />
              <div className="card-info">
                <p className="name">
                  View All Star-Trac <i className="fas fa-arrow-right fa-2x" />
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div className="container video-slider-wrap">
    <section id="blocks-1" className="video-slider">
      <div id="block-1" className="container block">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <figure className="block-image pad-the-video">
              <div style={{ padding: "52.73% 0 0 0", position: "relative" }}>
                <iframe
                  src="https://player.vimeo.com/video/416157572"
                  title="FreeRunner Redefine Performance"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%"
                  }}
                  frameBorder={0}
                  allow="autoplay; fullscreen"
                  allowFullScreen=""
                />
              </div>
            </figure>
          </div>
          <div className="col-xs-12 col-md-6 content">
            <header className="block-header">
              <h3>Redefined Performance: Star Trac FreeRunner</h3>
            </header>
            <p>
              Star Trac FreeRunner has redefined performance and has become the
              treadmill your members are waiting for. The innovative HexDek
              offers a cushioned response like you’ve never felt before, get on
              one today and experience it for yourself.
            </p>
          </div>
        </div>
      </div>
      <div id="block-2" className="container block">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <figure className="block-image pad-the-video">
              <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
                <iframe
                  src="https://player.vimeo.com/video/307796629?title=0&byline=0&portrait=0"
                  title="Star Trac FreeRunner Treadmill"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%"
                  }}
                  frameBorder={0}
                  allow="autoplay; fullscreen"
                  allowFullScreen=""
                />
              </div>
            </figure>
          </div>
          <div className="col-xs-12 col-md-6 content">
            <header className="block-header">
              <h3>The All-New 10 Series FreeRunner™</h3>
            </header>
            <p>
              The FreeRunner™ treadmill is a revolutionary new indoor running
              experience. The heart of this new experience is the patent-pending
              HexDeck System, featuring an aluminum deck supported by a
              proprietary, hexagonal polymer suspension system. The result is
              unprecedented cushion and responsiveness, for a feel that test
              users describe as “buttery,” “easy on the joints” and “like
              running on a track.” The next generation of treadmills arrives
              March 2019.
            </p>
          </div>
        </div>
      </div>
      <div id="block-3" className="container block">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <figure className="block-image pad-the-video">
              <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
                <iframe
                  src="https://player.vimeo.com/video/156920453?title=0&byline=0&portrait=0"
                  title="OPENHUB"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%"
                  }}
                  frameBorder={0}
                  allow="autoplay; fullscreen"
                  allowFullScreen=""
                />
              </div>
            </figure>
          </div>
          <div className="col-xs-12 col-md-6 content">
            <header className="block-header">
              <h3>NEW OPENHUB EMBEDDED CONSOLE</h3>
            </header>
            <p>
              The re-imagined 19″ Capacitive Touch OpenHub Console adds a
              premium new look and experience to the already-great Star Trac
              cardio line. The new embedded console offers a new user interface
              design, Star Trac 20 HIIT Programming, RunTV&nbsp;and web-based
              media access for news, entertainment and workout motivation. With
              standard features like HDMI streaming, Bluetooth connectivity and
              TV tuner options, this is a display that has something for every
              user.
            </p>
          </div>
        </div>
      </div>
      <div id="block-4" className="container block">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <figure className="block-image pad-the-video">
              <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
                <iframe
                  src="https://player.vimeo.com/video/235436935?title=0&byline=0&portrait=0"
                  title="Star Trac - VersaStrider"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%"
                  }}
                  frameBorder={0}
                  allow="autoplay; fullscreen"
                  allowFullScreen=""
                />
              </div>
            </figure>
          </div>
          <div className="col-xs-12 col-md-6 content">
            <header className="block-header">
              <h3>A CUSTOM FIT FOR EVERY PATH!</h3>
            </header>
            <p>
              Creating a truly custom feeling cardio experience is no easy task,
              but that is what the VersaStrider was built for. Using
              revolutionary MyStride<sup>™</sup>&nbsp;technology, this variable
              stride elliptical allows for a dynamic movement path that fits
              each user regardless of size, shape or intensity of the workout.
              Whether you are running at your full stride length or cooling down
              with a smaller stepping motion, the VersaStrider can adjust to
              your stride without the use of buttons. Paired with the
              unparalleled connectivity and customization of OpenHub cardio
              consoles, the VersaStrider is one machine that truly feels like a
              custom fit for every user.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section id="blocks-2" className="video-slider-nav">
      
    </section>
  </div>
  <section className="section view-other-brands">
    <div className="container">
      <header className="block-header">
        <h3>View Other Brands</h3>
      </header>
      <ul className="brand-logos">
        <li className="">
          <a href="/product-brand/throwdown" data-wpel-link="internal">
            <img src="https://corehandf.com/wp-content/uploads/2021/10/logoasociate_throwoown-3.png" />
          </a>
        </li>
        <li className="">
          <a href="/product-brand/nautilus" data-wpel-link="internal">
            <img src="https://corehandf.com/wp-content/uploads/2021/10/logoasociate_Nautilus-3.png" />
          </a>
        </li>
        <li className="">
          <a href="/product-brand/schwinn" data-wpel-link="internal">
            <img src="https://corehandf.com/wp-content/uploads/2021/10/Schwinn-Logo-Black-1-1024x287-3.webp" />
          </a>
        </li>
        <li className="">
          <a href="/product-brand/stairmaster" data-wpel-link="internal">
            <img src="https://corehandf.com/wp-content/uploads/2021/10/logoasociate_StairMaster-3.png" />
          </a>
        </li>
      </ul>
    </div>
  </section>
  <section className="section brand-page-bottom">
    <div className="container">
      <div className="row center-xs">
        <div className="col-xs-4 offset-xs-3">
          <p>FOLLOW STAR TRAC</p>
          <ul className="list flex-center flex-middle">
            <li>
              <a
                href="https://www.facebook.com/startracfitness"
                target="_blank"
                data-wpel-link="external"
                rel="nofollow external noopener noreferrer"
              >
                <i className="fa fa-facebook" />
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/startracfitness"
                target="_blank"
                data-wpel-link="external"
                rel="nofollow external noopener noreferrer"
              >
                <i className="fa fa-twitter" />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/startracfitness/"
                target="_blank"
                data-wpel-link="external"
                rel="nofollow external noopener noreferrer"
              >
                <i className="fa fa-instagram" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <section className="request-a-quote-form">
    <div className="container">
      <h2 id="request-a-quote-form" className="section-title"><span>Start Your Consultation Today</span></h2>
      <div className="row center-xs">
        <div className="col-md-6 offset-md-6">
        <Form />
        </div>
      </div>
    </div>
  </section>
  <a className="sticky-cta" href="#request-a-quote-form">
  <img src={images.mapgym} />
    Start Consultation
  </a>

</main>

        </>
    )
}

export default Startrac;