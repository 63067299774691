import React from "react";
import Navbar from "../../CommonCompounds/Nav/Nav.jsx";
import Footer from "../../CommonCompounds/Footer/Footer.jsx";
import Instinct from "../../Compounds/Instinct/Instinct.jsx"




function ProductCategoryPage() {
    return (
        <>
            <Navbar />
            <Instinct />
            <Footer />
        </>
    );
}

export default ProductCategoryPage;