import React from "react";
import Navbar from "../../CommonCompounds/Nav/Nav.jsx";
import Footer  from "../../CommonCompounds/Nav/Nav.jsx";
import ALLCordio from "../../Compounds/ProductCategory/type/main-floor-cardio/AllCardio.jsx";


function PiratesPage(){
    return(
        <>

       <Navbar />
       <ALLCordio />
       <Footer />
        </>
    )
}
 export default PiratesPage;