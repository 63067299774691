import React  from "react";
import ProductGallery from "../../CommonCompounds/CarourselProduct/CarourselProduct";
import { Form } from "react-router-dom";



function Ymca(){
    return(
        <>
          <main className="main" id="main">
    <section className="breadcrumbs">
      <ul>
        {/*li><a href="#">Home</a></li>
          <li class="current">Star Trac</li*/}
        <li>
          <span>
            <span>
              <a href="https://corehandf.com/" data-wpel-link="internal">
                Home
              </a>
            </span>{" "}
            »{" "}
            <span className="breadcrumb_last" aria-current="page">
              YMCA
            </span>
          </span>
        </li>{" "}
      </ul>
    </section>
    <section
      className="section_slide solutions-banner row"
      style={{
        backgroundImage:
          "url(https://corehandf.com/wp-content/uploads/2022/02/Core-Parks-And-Rac-Install-002-Lifestyle-Image.png)"
      }}
    >
      <div className="col-xs-12 col-sm-8 col-md-8 col-lg-5">
        <h1>
          <strong>YMCA</strong>
        </h1>
      </div>
    </section>
    <section className="section single-content">
      <div className="container small">
        <div className="row">
          <div className="col-xs-12">
            <p>
              Whether you’re breaking ground on a new facility, remodeling an
              existing space, or simply replacing existing equipment our One
              Team approach ensures we’re aligned to bring your community the
              solutions that are right for your space. When it comes to your
              facility, we understand that it’s as much about building a space
              for your community as it is about helping your members bring about
              a meaningful change in themselves. When you request a
              consultation, your regional rep will get to know your goals and
              understand the vision you have for your facility so they can
              provide the solutions that blend seamlessly with your overall
              design. Below are a few of the items you’ll be guided through:{" "}
            </p>
            <p>
              • 2D Rendering
              <br />
              • 3D Layout
              <br />
              • Education &amp; Group Training
              <br />
              • Technology &amp; Connected Fitness
              <br />
              • Budget
              <br />• Financing{" "}
            </p>
            <p>
              Our brands – Nautilus, Star Trac, StairMaster, Schwinn and
              Throwdown – allow us to tailor the experience of your facility so
              we can meet your exact needs. Your regional rep will utilize years
              of industry knowledge along with the latest FISA data to ensure
              you’re maximizing your space and providing the fitness programming
              that will help your community grow even stronger. Below are just a
              few of the fitness spaces we’ve been able to collaborate with and
              share our passion for fitness.{" "}
            </p>
            <a className="cta-button" href="#embed-form">
              Request a Consultation
            </a>{" "}
            <div
              id="metaslider-id-57847"
              style={{ width: "100%" }}
              className="ml-slider-3-90-1 metaslider metaslider-flex metaslider-57847 ml-slider ms-theme-cubic has-dots-nav"
              role="region"
              aria-roledescription="Slideshow"
              aria-label="Industry: YMCA/JCC"
            >
              <div id="metaslider_container_57847">
                <div id="metaslider_57847">
                  <ul className="slides">
                    <li
                      style={{ display: "block", width: "100%" }}
                      className="slide-57956 ms-image "
                      aria-roledescription="slide"
                      aria-label="slide-57956"
                    >
                      <img
                        src="https://corehandf.com/wp-content/uploads/2021/12/Aurora-Family-YMCA-at-Wheatlands-Throwdown-XTC-Rig-e1688661402872-523x340.jpg"
                        height={500}
                        width={770}
                        alt="Aurora Family YMCA at Wheatlands Throwdown XTC Rig"
                        className="slider-57847 slide-57956"
                        title="Aurora Family YMCA at Wheatlands Throwdown XTC Rig"
                      />
                    </li>
                    <li
                      style={{ display: "none", width: "100%" }}
                      className="slide-58053 ms-image "
                      aria-roledescription="slide"
                      aria-label="slide-58053"
                    >
                      <img
                        src="https://corehandf.com/wp-content/uploads/2021/12/YMCA-Schottland-Nautilus-640x415.jpg"
                        height={500}
                        width={770}
                        alt="YMCA Schottland Nautilus"
                        className="slider-57847 slide-58053"
                        title="YMCA Schottland Nautilus"
                      />
                    </li>
                    <li
                      style={{ display: "none", width: "100%" }}
                      className="slide-57961 ms-image "
                      aria-roledescription="slide"
                      aria-label="slide-57961"
                    >
                      <img
                        src="https://corehandf.com/wp-content/uploads/2021/12/YMCA-Thomasville-GA-Schwinn-AC-Power-2-e1638388365193-480x311.jpg"
                        height={500}
                        width={770}
                        alt="YMCA Thomasville GA Schwinn AC Power 2"
                        className="slider-57847 slide-57961"
                        title="YMCA Thomasville GA Schwinn AC Power 2"
                      />
                    </li>
                    <li
                      style={{ display: "none", width: "100%" }}
                      className="slide-58054 ms-image "
                      aria-roledescription="slide"
                      aria-label="slide-58054"
                    >
                      <img
                        src="https://corehandf.com/wp-content/uploads/2021/12/YMCA-Thomasville-GA-Schwinn-AC-Power-3-640x415.jpg"
                        height={500}
                        width={770}
                        alt="YMCA Thomasville GA Schwinn AC Power 3"
                        className="slider-57847 slide-58054"
                        title="YMCA Thomasville GA Schwinn AC Power 3"
                      />
                    </li>
                    <li
                      style={{ display: "none", width: "100%" }}
                      className="slide-57951 ms-image "
                      aria-roledescription="slide"
                      aria-label="slide-57951"
                    >
                      <img
                        src="https://corehandf.com/wp-content/uploads/2021/11/YMCA-JCC-640x415.webp"
                        height={500}
                        width={770}
                        alt="YMCA JCC"
                        className="slider-57847 slide-57951"
                        title="YMCA JCC"
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
      </div>
    </section>
    <section className="centers-section" style={{ display: "none" }}>
      <div className="swiper">
        <div className="swiper-container">
          <div className="swiper-wrapper">
            <div
              className="swiper-slide"
              style={{
                backgroundImage:
                  'url("https://corehandf.com/wp-content/uploads/2021/11/YMCA-JCC.webp")'
              }}
            >
              <div className="caption"></div>
            </div>
            <div
              className="swiper-slide"
              style={{
                backgroundImage:
                  'url("https://corehandf.com/wp-content/uploads/2021/10/Washington-YMCA-Star-Trac-8RDE-scaled.jpg")'
              }}
            >
              <div className="caption"></div>
            </div>
          </div>
        </div>
        <a href="#" className="swiper-button-prev  2" />
        <a href="#" className="swiper-button-next  2" />
      </div>
    </section>
   <section >
    <ProductGallery />
   </section>
    <section className="request-a-quote-form">
    <div className="container">
      <h2 id="request-a-quote-form" className="section-title"><span>Start Your Consultation Today</span></h2>
      <div className="row center-xs">
        <div className="col-md-6 offset-md-6">
        <Form />
        </div>
      </div>
    </div>
  </section>
  </main>
        <>

  {/* /.main */}
</>

        </>
    )

}

export default Ymca;