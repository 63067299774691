import React  from "react";
import Form from "../../CommonCompounds/Form/Form";
import ProductGallery from "../../CommonCompounds/CarourselProduct/CarourselProduct";

function RehabFacility(){
    return (
        <>
<main className="main" id="main">
  <section className="breadcrumbs">
    <ul>
      {/*li><a href="#">Home</a></li>
          <li class="current">Star Trac</li*/}
      <li>
        <span>
          <span>
            <a href="https://corehandf.com/" data-wpel-link="internal">
              Home
            </a>
          </span>{" "}
          »{" "}
          <span className="breadcrumb_last" aria-current="page">
            Rehab Facility
          </span>
        </span>
      </li>
    </ul>
  </section>
  <section
    className="section_slide solutions-banner row"
    style={{
      backgroundImage:
        "url(https://corehandf.com/wp-content/uploads/2022/02/Core-Rehab-Install-002-Lifestyle-Image.jpg)"
    }}
  >
    <div className="col-xs-12 col-sm-8 col-md-8 col-lg-5">
      <h1>
        <strong>Rehab</strong> Facility
      </h1>
    </div>
  </section>
  <section className="section single-content">
    <div className="container small">
      <div className="row">
        <div className="col-xs-12">
          <p>
            When it comes to selecting the solutions that are right for your
            patient’s prescribed recovery plan, we know that it’s vital to have
            equipment that is versatile. With a One Team mentality we work with
            your key decision makers to ensure your facility is both accessible
            and functional for all your patients.{" "}
          </p>
          <p>
            With a global network of partners, we’re able to guide you through
            space design, equipment selection, the importance of flooring and so
            much more. When you request a consultation, your regional rep will
            get to know your facility needs to ensure the needs of your existing
            and future patients are taken into consideration. Below are a few of
            the items you’ll be guided through:{" "}
          </p>
          <p>
            • 2D Rendering
            <br /> • 3D Layout
            <br /> • Budget
            <br /> • Financing{" "}
          </p>
          <p>
            Our brands – Nautilus, Star Trac, StairMaster, Throwdown and Schwinn
            – allow us to meet your every need, which in turn allows your staff
            to continue impacting the lives of their patients. Below are just a
            few of the spaces we’ve been able to collaborate with and share our
            passion for fitness.{" "}
          </p>
          <a className="cta-button" href="#embed-form">
            Request a Consultation
          </a>
          <div
            id="metaslider-id-57850"
            style={{ width: "100%" }}
            className="ml-slider-3-90-1 metaslider metaslider-flex metaslider-57850 ml-slider ms-theme-cubic has-dots-nav"
            role="region"
            aria-roledescription="Slideshow"
            aria-label="Industry: Rehab Facility"
          >
            <div id="metaslider_container_57850">
              <div id="metaslider_57850">
                <ul className="slides">
                  <li
                    style={{ display: "block", width: "100%" }}
                    className="slide-57851 ms-image "
                    aria-roledescription="slide"
                    aria-label="slide-57851"
                  >
                    <img
                      src="https://corehandf.com/wp-content/uploads/2021/11/Rehab-Facility-640x415.jpg"
                      height={500}
                      width={770}
                      alt="Rehab Facility"
                      className="slider-57850 slide-57851"
                      title="Rehab Facility"
                    />
                  </li>
                  <li
                    style={{ display: "none", width: "100%" }}
                    className="slide-57970 ms-image "
                    aria-roledescription="slide"
                    aria-label="slide-57970"
                  >
                    <img
                      src="https://corehandf.com/wp-content/uploads/2021/12/Piedmont-Healthcare-StairMaster-HIIT-Bike-Wellness-480x311.jpg"
                      height={500}
                      width={770}
                      alt="Piedmont Healthcare StairMaster HIIT Bike Wellness"
                      className="slider-57850 slide-57970"
                      title="Piedmont Healthcare StairMaster HIIT Bike Wellness"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="centers-section" style={{ display: "none" }}>
    <div className="swiper">
      <div className="swiper-container">
        <div className="swiper-wrapper">
          <div
            className="swiper-slide"
            style={{
              backgroundImage:
                'url("https://corehandf.com/wp-content/uploads/2021/11/Rehab-Facility.jpg")'
            }}
          >
            <div className="caption"></div>
          </div>
        </div>
      </div>
      <a href="#" className="swiper-button-prev just-one 1" />
      <a href="#" className="swiper-button-next just-one 1" />
    </div>
  </section>
<section >
  <ProductGallery />
</section>
  <section className="request-a-quote-form">
    <div className="container">
      <h2 id="request-a-quote-form" className="section-title"><span>Start Your Consultation Today</span></h2>
      <div className="row center-xs">
        <div className="col-md-6 offset-md-6">
        <Form />
        </div>
      </div>
    </div>
  </section>
</main>

        </>
    )
}

export default RehabFacility;