import React from "react";
import Navbar from "../../CommonCompounds/Nav/Nav.jsx";
import Footer from "../../CommonCompounds/Footer/Footer.jsx";
import Schwinn from "../../Compounds/Schwinn/Schwinn.jsx";
import { Form } from "react-router-dom";

function SchwinnPage() {
    return (
        <>
            <Navbar />
            <Schwinn />
            <Footer />
        </>
    );
}

export default SchwinnPage;
