import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from '../../const';
import ReactPlayer from 'react-player';
import Form from '../../CommonCompounds/Form/Form';

function Product() {
  const [productDetails, setProductDetails] = useState({});
  const [selectedImg, setSelectedImg] = useState({});
  const [activeTab, setActiveTab] = useState('Details');

  useEffect(() => {
    getProductDetailsById();
  }, []);

  const getProductDetailsById = async () => {
    var productId = window.location.href.split('/product/')[1];
    productId = productId.split('/')[0];
    const response = await axios.get(`${API_URL}getProductDetailsById/` + productId);
    if (response.data.result_Arr[0] && response.data.result_Arr[0].product_images && response.data.result_Arr[0].product_images.length > 0) {
      setSelectedImg(response.data.result_Arr[0].product_images[0].product_image_url);
    }
    setProductDetails(response.data.result_Arr[0]);
  }

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <main className="main" id="main">
      {/* Banner Section */}
      <section className="section banner">
        <div className="row product-banner">
          <div className="col-xs-6 product-banner-left banner-left">
            <div className="main-photo" style={{ justifyContent: "center", display: "flex", alignItems: "center" }}>
              <img src={API_URL + selectedImg} alt="Product" />
            </div>
            <div className="pagination-thumbs">
              <ul>
              {productDetails && productDetails.product_images && productDetails.product_images.length > 0 ? (
                productDetails.product_images.map((image, index) => (
                  <li key={index} className={selectedImg === image.product_image_url ? "active" : ""}>
                    <img 
                      src={API_URL + image.product_image_url} 
                      alt="Thumbnail" 
                      onClick={() => setSelectedImg(image.product_image_url)}
                      style={{ cursor: "pointer" }}
                    />
                  </li>
                ))
              ) : (
                <li>No images available</li>
              )}
              </ul>
            </div>
          </div>
          <div className="col-xs-6 product-banner-right banner-right">
            <div className="product-details">
              <div className="product-logo" />
              <h1 className="product-title">{productDetails.product_name || "N/A"}</h1>
              <p className="product-meta">{productDetails.product_model || "N/A"}</p>
              
              {/* Tabs Section */}
              <div className="tabs product-tabs">
                <ul id="tabs-cnt" className="tabs">
                  {
                    productDetails.product_details_tab ? 
                      <li>
                        <a className={activeTab === 'Details' ? 'active' : ''} onClick={() => handleTabClick('Details')} style={{cursor: "pointer"}}>
                          Details
                        </a>
                      </li>
                    : null
                  }
                  {
                    productDetails.product_feature_tab ? 
                      <li>
                        <a className={activeTab === 'Features' ? 'active' : ''} onClick={() => handleTabClick('Features')} style={{cursor: "pointer"}}>
                          Features
                        </a>
                      </li>
                    : null
                  }
                  {
                    productDetails.product_specification_tab ? 
                      <li>
                        <a className={activeTab === 'Specifications' ? 'active' : ''} onClick={() => handleTabClick('Specifications')} style={{cursor: "pointer"}}>
                          Specifications
                        </a>
                      </li>
                    : null
                  }
                  {
                    productDetails.product_technology_tab ? 
                      <li>
                        <a className={activeTab === 'Technology' ? 'active' : ''} onClick={() => handleTabClick('Technology')} style={{cursor: "pointer"}}>
                        Technology
                        </a>
                      </li>
                    : null
                  }
                  
                  <div className="red-line" />
                </ul>
                
                {/* Step 3: Conditional Rendering Based on Active Tab */}
                <div className="tab-content">
                  {activeTab === 'Details' && (
                    <div id="tab1" className="tab-cnt" style={{display: "block"}}>
                      {
                        productDetails.product_details_tab ? <div dangerouslySetInnerHTML={{ __html: productDetails.product_details_tab }}/> : <p>No details available.</p>
                      }
                    </div>
                  )}
                  {activeTab === 'Features' && (
                    <div id="tab2" className="tab-cnt" style={{display: "block"}}>
                      {
                        productDetails.product_feature_tab? <div dangerouslySetInnerHTML={{ __html: productDetails.product_feature_tab }}/>: <p>No Features available.</p>
                      }
                    </div>
                  )}
                  {activeTab === 'Specifications' && (
                    <div id="tab3" className="tab-cnt" style={{display: "block"}}>
                      {
                        productDetails.product_specification_tab ? <div dangerouslySetInnerHTML={{ __html: productDetails.product_specification_tab }}/> : <p>No specifications available.</p>
                      }
                    </div>
                  )}
                  {activeTab === 'Technology' && (
                    <div id="tab3" className="tab-cnt" style={{display: "block"}}>
                      {
                        productDetails.product_technology_tab ? <div dangerouslySetInnerHTML={{ __html: productDetails.product_technology_tab }}/> : <p>No Technology available.</p>
                      }
                    </div>
                  )}
                </div>
              </div>

              <div className="tabs-footer">
                <ul className="list list-inline">
                  <li>
                    <a target="_blank" id="download-product-sheet" href={productDetails.product_product_sheet_url || "#"}>
                      <i className="fa fa-download" />
                      Download Product Sheet
                    </a>
                  </li>
                </ul>
                <p>
                  <a target="_blank" className="btn red-btn prevent to-freerunner-form" style={{ cursor: "pointer" }}>
                    Request a Quote
                  </a>
                </p>
                <div style={{ display: "none" }} className="popup-form-single" id="hidden-content-1">
                  <h1>Download Spec Sheet</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Video Section */}
      <section className="section content">
        <div className="container">
          <div className="row flex-space-between">
            <div className="col-xs-8 col-xs-offset-2">
            {productDetails.product_videos && productDetails.product_videos.length > 0 ? (
                productDetails.product_videos.map((url, index) => (
                  <div key={index} style={{ position: "relative", paddingBottom: "56.25%", height: 0, marginTop: "20px" }}>
                    <ReactPlayer
                      url={url.product_video_url}
                      controls={true} // Enable controls for play, pause, etc.
                      playing={false} // Set playing to false to avoid auto-play issues
                      width="100%"
                      height="100%"
                      style={{ position: 'absolute', top: 0, left: 0 }}
                    />
                  </div>
                ))
              ) : (
                <p style={{ marginTop: "60px" }}>No videos available</p>
              )}

          </div>
          </div>
        </div>
      </section>

      {/* Request Quote Form Section */}
      <section className="request-a-quote-form">
        <div className="container">
          <h2 id="request-a-quote-form" className="section-title">
            <span>Start Your Consultation Today</span>
          </h2>
          <div className="row center-xs">
            <div className="col-md-6 offset-md-6">
              <Form/>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Product;
