import React from "react";
import Navbar from "../../../CommonCompounds/Nav/Nav.jsx";
import Footer  from "../../../CommonCompounds/Footer/Footer.jsx";
import StartVirtualBike from "../../../Compounds/Product/StartracVirtualBike/StartracVirtualBike.jsx";


function StartVirtualBikePage(){
    return(
        <>

       <Navbar />
      <StartVirtualBike />
       <Footer />
        </>
    )
}
 export default StartVirtualBikePage;