import React from "react";
import Navbar from "../../CommonCompounds/Nav/Nav.jsx"
import Footer from "../../CommonCompounds/Footer/Footer.jsx";
import NightSeries from "../../Compounds/8Series/8Series.jsx";


function NightseriesPage(){
    return(
        <>

       <Navbar />
       <NightSeries />
       <Footer />
        </>
    )
}
 export default NightseriesPage;