import React from "react";
import Navbar from "../../CommonCompounds/Nav/Nav.jsx";
import Footer from "../../CommonCompounds/Footer/Footer.jsx";
import Freeweights from "../../Compounds/FreeWeights/FreeWeights.jsx"

function FreeweightsPage() {
    return (
        <>
            <Navbar />
            <Freeweights />
            <Footer />
        </>
    );
}

export default FreeweightsPage;
